import React, { useEffect, useState, version } from "react";
import ReactModal from "react-modal";
import ButtonFilled from "../../components/ButtonFilled";
import InputComponent from "../../components/InputComponent";
import { apiEndPoint } from "../../contants/Endpoints";
import { authAxios } from "../../utils/axiosWrapper";

export default function TermsAndCondtionsIndex() {
    const [termsList, setTermsList] = useState(null);
    const [showModal, setShowModal] = useState(false);

    const [title, setTitle] = useState("");
    const [url, setUrl] = useState("");
    const [verstion, setVersion] = useState(0);
    const [details, setDetails] = useState("");
    const [force, setForce] = useState(false);
    const [userTypeState, setUserTypeState] = useState("USER");

    useEffect(() => {
        getData();
    }, []);

    const getData = () => {
        authAxios
            .get(`${apiEndPoint.GET_ALL_TERMS_CONDITIONS}?requestId=123`)
            .then((res) => {
                setTermsList(res.data.termsAndConditionDataList);
            });
    };

    const PostTerms = () => {
        const obj = {
            title,
            details,
            userType: userTypeState,
            force,
            requestid: "string",
            tncVersion: verstion,
            url,
        };

        authAxios
            .post(`${apiEndPoint.ADD_TERMS_CONDITIONS}`, obj)
            .then((res) => {
                setTitle("");
                setDetails("");
                setUserTypeState("USER");
                setForce(false);
                setVersion(0);
                setUrl("");

                getData();
                alert("succesful!");
                setShowModal(false);
            })
            .catch((err) => {

            });
    };

    return (
        <div className="container mx-auto">
            <div className="flex justify-between items-center">
                <h2 className="text-2xl my-6"> Terms and Conditions </h2>
                <div className="w-[300px]">
                    <ButtonFilled
                        buttonFilledHandle={() => {
                            setShowModal(true);
                        }}
                        title={"Add new"}
                        w={"100%"}
                    />
                </div>
            </div>
            <table id="students" className="min-w-full leading-normal">
                <thead>
                    <tr className="sticky h-[78px] px-5 py-3 border-b-2 border-gray-200 bg-btn-primary text-left text-xl font-normal text-bg-white tracking-wider">
                        <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xl font-normal text-gray-700 tracking-wider"></th>
                        <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xl font-normal text-gray-700 tracking-wider">
                            ID
                        </th>
                        <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xl font-normal text-gray-700 tracking-wider">
                            Title
                        </th>
                        <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xl font-normal text-gray-700 tracking-wider">
                            Details
                        </th>
                        <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xl font-normal text-gray-700 tracking-wider">
                            User type
                        </th>
                        <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xl font-normal text-gray-700 tracking-wider">
                            Version
                        </th>
                    </tr>
                </thead>
                <tbody className="">
                    {termsList &&
                        termsList.map((item, index) => {
                            const { details, title, userType, id, tncVersion } =
                                item; //destructuring
                            return (
                                <tr key={id || index} className="border-b">
                                    <td className="h-[80px] px-5 py-5 bg-white text-sm"></td>
                                    <td className="h-[80px] px-5 py-5 bg-white text-sm">
                                        {id}
                                    </td>
                                    <td className="h-[80px] px-5 py-5 bg-white text-sm text-left">
                                        {title}
                                    </td>
                                    <td className="h-[80px] px-5 py-5 bg-white text-sm text-left">
                                        {details.slice(0, 100)}
                                    </td>
                                    <td className="h-[80px] px-5 py-5 bg-white text-sm text-left">
                                        {userType}
                                    </td>
                                    <td className="h-[80px] px-5 py-5 bg-white text-sm text-left">
                                        {tncVersion}
                                    </td>
                                    {/* <td className="h-[80px] px-5 py-5 bg-white text-sm">
                                        <ButtonOutlined
                                            title={"See details"}
                                            w="100%"
                                            buttonOutlinedHandle={() =>
                                                onModalOpen(index)
                                            }
                                        />
                                    </td> */}
                                </tr>
                            );
                        })}
                    {(!termsList || termsList.length === 0) && (
                        <tr className="border">
                            <td className="h-[80px] px-5 py-5 bg-white text-sm text-left">
                                No data
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>

            <ReactModal
                style={{ content: { width: "60vw" } }}
                isOpen={showModal}
                onRequestClose={() => {
                    setShowModal(false);
                }}
            >
                <div className="px-10 py-8 relative">
                    <button
                        className="absolute top-5 right-6"
                        onClick={() => {
                            setShowModal(false);
                        }}
                    >
                        <svg
                            width="19"
                            height="19"
                            viewBox="0 0 19 19"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M11.4109 8.99928L18.701 1.70928C18.8648 1.51798 18.9504 1.27191 18.9407 1.02023C18.9309 0.768555 18.8266 0.529816 18.6485 0.351722C18.4704 0.173627 18.2317 0.0692943 17.98 0.0595732C17.7283 0.049852 17.4823 0.135459 17.291 0.299285L10.0009 7.58928L2.71095 0.289285C2.51965 0.125458 2.27357 0.0398528 2.0219 0.0495739C1.77022 0.0592951 1.53148 0.163627 1.35339 0.341722C1.17529 0.519816 1.07096 0.758555 1.06124 1.01023C1.05152 1.26191 1.13712 1.50798 1.30095 1.69928L8.59095 8.99928L1.29095 16.2893C1.18627 16.3789 1.10125 16.4893 1.04122 16.6133C0.981202 16.7374 0.947471 16.8725 0.942151 17.0102C0.936832 17.1479 0.960038 17.2853 1.01031 17.4136C1.06059 17.5419 1.13685 17.6585 1.2343 17.7559C1.33175 17.8534 1.4483 17.9296 1.57663 17.9799C1.70495 18.0302 1.84228 18.0534 1.98 18.0481C2.11772 18.0428 2.25286 18.009 2.37692 17.949C2.50098 17.889 2.6113 17.804 2.70095 17.6993L10.0009 10.4093L17.291 17.6993C17.4823 17.8631 17.7283 17.9487 17.98 17.939C18.2317 17.9293 18.4704 17.8249 18.6485 17.6468C18.8266 17.4688 18.9309 17.23 18.9407 16.9783C18.9504 16.7267 18.8648 16.4806 18.701 16.2893L11.4109 8.99928Z"
                                fill="#2A220E"
                                fillOpacity="0.45"
                            />
                        </svg>
                    </button>
                    <div className="text-center text-2xl mb-4">Add Item</div>

                    <div>Title</div>
                    <InputComponent
                        inputValue={title}
                        setInputValue={setTitle}
                        required={true}
                        name="title"
                    />

                    <div className="mt-4">URL</div>
                    <InputComponent
                        inputValue={url}
                        setInputValue={setUrl}
                        required={true}
                        name="url"
                    />

                    <div className="mt-4">Version</div>
                    <InputComponent
                        inputValue={verstion}
                        setInputValue={setVersion}
                        required={true}
                        number={true}
                        name="version"
                    />

                    <div className="my-4">
                        <label>
                            Force?
                            <input
                                className="ml-2"
                                onChange={() => {
                                    setForce(!force);
                                }}
                                type={"checkbox"}
                                checked={force}
                            />
                        </label>
                    </div>

                    <div>User Type</div>
                    <select
                        value={userTypeState}
                        onChange={(e) => setUserTypeState(e.target.value)}
                        className="mt-2 mb-4 w-full bg-[transparent] border border-[#9e9989] rounded-md py-2 cursor-pointer"
                    >
                        <option value={"USER"}> User </option>
                        <option value={"MERCHANT"}> Merchant </option>
                    </select>

                    <div className="mt-2"> Details </div>
                    <textarea
                        className="w-full bg-[transparent] rounded-[8px] validation flex border-[#9e9989] border p-2 outline-none"
                        value={details}
                        rows="6"
                        onChange={(e) => setDetails(e.target.value)}
                    />

                    <div className="flex justify-center gap-2 mt-8">
                        <ButtonFilled
                            title={"Submit"}
                            w="200px"
                            buttonFilledHandle={() => {
                                PostTerms();
                            }}
                        />
                    </div>
                </div>
            </ReactModal>
        </div>
    );
}
