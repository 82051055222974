import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import ModalCommon from "../../../components/common/ModalCommon";
import SingleSelectParentReturnObject from "../../../components/singleSelect/singleSelectObjectReturn/SingleSelectParentReturnObject";
import { apiEndPoint, baseUrl } from "../../../contants/Endpoints";
import { authAxios } from "../../../utils/axiosWrapper";
import Paginations from "../Paginations/Paginations";

const PropertyFacility = () => {
  const [modalShown, setToggleModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [inputData, setInputData] = useState({});
  let PageSize = 10;
  const [iconsList, setIconsList] = useState([]);
  const [iconsList2, setIconsList2] = useState([]);
  const [iconsList3, setIconsList3] = useState([]);
  const [iconsList4, setIconsList4] = useState([]);
  const [selectedIcon, setSelectedIcon] = useState(null);
  const [selectedIcon2, setSelectedIcon2] = useState(null);
  const [selectedIcon3, setSelectedIcon3] = useState(null);
  const [selectedIcon4, setSelectedIcon4] = useState(null);

  useEffect(() => {
    authAxios
      .get(`${baseUrl + apiEndPoint.ICON_LIST}`)
      .then((res) => {
        setIconsList(res?.data.iconData);
      })
      .catch((err) => {});
  }, []);

  useEffect(() => {

    setSelectedIcon2(inputData?.displayGroup);
    setSelectedIcon3(inputData?.facilityType);
    setSelectedIcon4(inputData?.uiFilter);
  }, [inputData]);

  console.log("selectedIcon3", selectedIcon?.url);
  const [tableData, setTableData] = useState([]);

  const [editCondition, setEditCondition] = useState(true);

  const payloadMethod = {
    id: editCondition === true ? 0 : inputData?.id,
    displayGroupId:
      selectedIcon2?.displayGroupId || inputData?.displayGroup?.displayGroupId,
    facilityTypeId:
      selectedIcon3?.facilityTypeId || inputData?.facilityType?.facilityTypeId,
    iconUrl: selectedIcon?.url || inputData?.iconUrl,

    label: inputData?.label,
    requestId: "1234",
    uiFilterId: selectedIcon4?.filterId || inputData?.uiFilter?.filterId,
    value: inputData?.value,
  };

  const tableRowAdd = () => {
    setInputData({});
    console.log("in", inputData);
    setEditCondition(true);
    setToggleModal(!modalShown);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setInputData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleEdit = (id) => {
    const selectedItem = tableData?.facilities?.find((item) => item?.id === id);

    setInputData(selectedItem);
    setEditCondition(false);
    setSelectedIcon(null)
    setToggleModal(true);
  };

  const handleSubmit = () => {
    authAxios
      .post(`${baseUrl}${apiEndPoint.ADMIN_ADD_ALL_PROPERTY}`, payloadMethod)
      .then((res) => {
        toast.success("Added successfully!");
        setToggleModal(!modalShown);
        getFetchData();
      })
      .catch((err) => {
        console.log("Error:", err);
        toast.warn("Add failed!");
      });

    setTableData([...tableData, inputData]);

    setInputData({});
  };

  // ADMIN_GET_DISPLAY_SPECIALITY

  const getFetchData = async () => {
    const res = await authAxios.get(
      `${baseUrl}${apiEndPoint.ADMIN_GET_ALL_PROPERTY}?pageNumber=${currentPage}&pageSize=10&requestId=1234`
    );

    setTableData(res?.data);
  };

  useEffect(() => {
    getFetchData();
  }, [currentPage]);

  useEffect(() => {
    authAxios
      .get(
        `${baseUrl}${apiEndPoint.ADMIN_GET_UIFILTER}?pageNumber=1&pageSize=1000&requestId=1234`
      )
      .then((res) => setIconsList4(res?.data?.entries));
  }, [currentPage]);

  useEffect(() => {
    authAxios
      .get(
        `${baseUrl}${
          apiEndPoint.ADMIN_GET_DISPLAY_SPECIALITY
        }?pageNumber=${1}&pageSize=10000&requestId=1234`
      )
      .then((res) => setIconsList2(res?.data?.entries));
  }, [currentPage]);

  useEffect(() => {
    authAxios
      .get(
        `${baseUrl}${
          apiEndPoint.ADMIN_GET_PROPERY_FACILITY
        }?pageNumber=${1}&pageSize=1000&requestId=1234`
      )
      .then((res) => setIconsList3(res?.data?.entries));
  }, []);

  return (
    <div className="w-[1100px] rounded-[8px] bg-bg-white  px-[16px] py-[18px] mt-[50px] mx-auto flex justify-center items-center h-full">
      <div className="w-full  ">
        <h2 className="text-base16 text-[#343a40]">Admin Facility Tool <span className="text-btn-secondary">By Property Facility</span> </h2>
        {/* table all hotel images */}
        <table class="w-full mt-[26px]">
          <thead>
            <tr className="text-left text-[#515d69] text-base16 font-bold w-full h-[40px] border-b border-[#f0f2f6]">
              <th className="">Icon</th>
              <th className="">ID & Label</th>
              <th className="">Value</th>
              <th className="flex justify-center items-center mt-[5px] gap-x-[3px] pr-[28px]">
                {" "}
                <span>Display </span> <span>group</span> name{" "}
              </th>
              <th className="">Facility type</th>
              <th className="">UI filter</th>
              <th className="">Action</th>
            </tr>
          </thead>
          <tbody>
            {tableData?.facilities?.map((row, index) => (
              <tr
                key={index}
                className="mx-[4px] border-[#f0f2f6] border-b text-[14px] text-[#515d69] text-left hover:bg-bg-semiwhite cursor-pointer "
              >
                <td className="px-[4px] py-[12px]">
                  <img
                    src={
                      row?.iconUrl ||
                      "https://img.freepik.com/premium-vector/young-good-looking-man-doing-arm-crossed-pose-with-confident_97632-4855.jpg?w=2000"
                    }
                    alt=""
                    className="object-cover  w-[50px] h-[50px] rounded-full"
                  />
                </td>

                <td className="px-[4px]">
                  <span className="flex flex-col">
                    <span className="text-[12px] text-[#515d69]">
                      #{row?.id}
                    </span>
                    <span className="text-[16px] font-extrabold text-[#343a40]">
                      {row?.label?.slice(0, 17) +
                        (row?.label?.length > 16 ? "..." : "")}
                    </span>
                  </span>
                </td>

                <td className="px-[4px] ">
                  {row?.value?.slice(0, 20) +
                    (row?.value?.length > 19 ? "..." : "")}{" "}
                </td>

                <td className="px-[4px] ">{row?.displayGroup?.label} </td>

                <td className="px-[4px] ">{row?.facilityType?.label} </td>

                {/* <td className="px-[4px]">Free</td> */}
                <td className="px-[4px]">{row?.uiFilter?.label}</td>
                <td className="px-[4px]">
                  <div className="flex gap-x-[4px] justify-start items-center ">
                    <button
                      onClick={() => handleEdit(row?.id)}
                      className="border px-[4px] flex justify-center items-center cursor-pointer w-[40px] h-[28px] py-[8px] border-[#07c074] text-[12px] text-[#07c074] rounded-[4px] hover:bg-[#07c074] hover:text-bg-primary"
                    >
                      Edit
                    </button>
                    <button className="border px-[4px] flex justify-center items-center cursor-pointer py-[8px] h-[28px] border-[#ff3d60] w-[58px] text-[12px] text-[#ff3d60] rounded-[4px] hover:bg-[#ff3d60] hover:text-bg-primary">
                      Delete
                    </button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <button
          onClick={() => tableRowAdd()}
          className="flex justify-end items-end w-full mt-[4px]"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-10 h-10 text-[#07c074] "
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
        </button>
        {/* <CommonTable tdData={tdData} /> */}

        <div className="mt-[22px]  w-[1100px] mx-auto">
          {
            <Paginations
              currentPage={currentPage}
              totalCount={Math.ceil(tableData?.totalEntries / 10)}
              pageSize={PageSize}
              onPageChange={(page) => setCurrentPage(page)}
            />
          }
        </div>
      </div>

      <ModalCommon
        shown={modalShown}
        close={() => {
          setToggleModal(false);
        }}
      >
        <div className="my-[50px] mx-[60px]">
          <div className="flex flex-col gap-y-[10px]">
            <div className=" pt-[10px]">
              <p className="pb-[5px]">Select speciality icons:</p>

             <div className="flex justify-start items-center gap-x-[8px]">

            
                    <div className="border w-[48px] h-[48px] flex justify-center items-center rounded-[4px]">
                      <img
                        className="w-[40px] h-[40px]"
                        src={ selectedIcon?.url  || inputData?.iconUrl }
                        alt=""
                      />
                    </div>
                  
              <SingleSelectParentReturnObject
                warningView={false}
                data={iconsList}
                displayField="title"
                placeholder={"Icons"}
                selectedFieldName={"title"}
                selectedValue={selectedIcon}
                setSelectedValue={setSelectedIcon}
                setSelectedObject={() => {}}
                image={true}
              />
             </div>
            </div>

            <div className="flex w-full justify-between items-center gap-x-[10px]">
              <div className="w-full">
                <label
                  for="label"
                  class="block mb-2 text-sm font-medium text-txt-hint dark:text-white"
                >
                  Label
                </label>
                <input
                  type="text"
                  name="label"
                  onChange={(event) => handleChange(event)}
                  id="label"
                  class="bg-btn-hint border border-txt-hint text-txt-primary text-sm rounded-lg focus:ring-btn-primary focus:border-btn-primary block w-full p-2.5 "
                  placeholder="Enter Label"
                  value={inputData?.label || ""}
                  required
                />
              </div>

              <div className="w-full">
                <label
                  for="value"
                  class="block mb-2 text-sm font-medium text-txt-hint dark:text-white"
                >
                  Value
                </label>
                <input
                  type="text"
                  name="value"
                  onChange={(event) => handleChange(event)}
                  id="value"
                  class="bg-btn-hint border border-txt-hint text-txt-primary text-sm rounded-lg focus:ring-btn-primary focus:border-btn-primary block w-full p-2.5 "
                  placeholder="Enter value"
                  value={inputData?.value || ""}
                  required
                />
              </div>
            </div>

            

            <div className="flex w-full justify-between items-center gap-x-[10px]">
              <div className=" pt-[10px] w-full">
                <p className="pb-[5px] block mb-2 text-sm font-medium text-txt-hint dark:text-white">
                  Display Group Name
                </p>

                
                
                  <SingleSelectParentReturnObject
                    warningView={false}
                    data={iconsList2}
                    displayField="label"
                    placeholder={"display group name"}
                    selectedFieldName={"label"}
                    selectedValue={selectedIcon2}
                    setSelectedValue={setSelectedIcon2}
                    setSelectedObject={() => {}}
                    image={false}
                  />
                
              </div>

              <div className=" pt-[10px] w-full">
                <p className="pb-[5px] block mb-2 text-sm font-medium text-txt-hint dark:text-white">
                  Facility Type
                </p>
                <SingleSelectParentReturnObject
                  warningView={false}
                  data={iconsList3}
                  displayField="label"
                  placeholder={"Facility Type"}
                  selectedFieldName={"label"}
                  selectedValue={selectedIcon3}
                  setSelectedValue={setSelectedIcon3}
                  setSelectedObject={() => {}}
                  image={false}
                />
              </div>
            </div>

            <div className=" pt-[10px] w-full">
              <p className="pb-[5px] block mb-2 text-sm font-medium text-txt-hint dark:text-white">
                UI Filter
              </p>
              <SingleSelectParentReturnObject
                warningView={false}
                data={iconsList4}
                displayField="label"
                placeholder={"UI Filter"}
                selectedFieldName={"label"}
                selectedValue={selectedIcon4}
                setSelectedValue={setSelectedIcon4}
                setSelectedObject={() => {}}
                image={false}
                isReadOnly={false}
                name="UISelected"
              />
            </div>

            <button
              onClick={() => handleSubmit()}
              className="px-[12px] py-[6px] mt-[10px] cursor-pointer text-bg-primary rounded-[4px] bg-btn-secondary flex justify-center w-full"
            >
              Submit
            </button>
          </div>
        </div>
      </ModalCommon>
    </div>
  );
};

export default PropertyFacility;
