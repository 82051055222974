import React, { useState, useEffect } from "react";
import InputComponent from "../InputComponent";
import AddMore from "../AddMore";
import MultiSelectParent from "../multiselect/MultiSelectParent";
import { useDispatch, useSelector } from "react-redux";
import { postCategoryInfoNext } from "../../store/registration/category/api/categoryApi";
import ButtonFilled from "../ButtonFilled";
import FileMultipleCategory from "../multipleimage/FileMultipleCategory";
import RemoveableImageUploadViewCategory from "../RemoveableImageUploadViewCategory";
import SingleSelectParent from "../singleSelect/SingleSelectParent";
import HorizontalBar from "../HorizontalBar/HorizontalBar";
import HotelMultiSelectParent from "../multiselect/hotelDropdown/HotelMultiSelectParent";
import Loading from "../Loader/Loading";
import Pagenation from "../pagenation/Pagenation";
import { authAxios } from "../../utils/axiosWrapper";
import SingleSelectParentHotelReturn from "../singleSelect/singleSelectHotelReturn/SingleSelectParentHotelReturn";
import { categorieDetails } from "../../store/admin/adminContract/api/adminContractApi";
import {
    DashboardRoutes,
    merchantRoute,
    ReviewFor,
    UserType,
} from "../../contants/Constants";
import { reviewSubmission } from "../../store/admin/review/api/reviewApi";
import { setterNullSafety } from "../../utils/setterException";
import { useLocation, useNavigate, useParams } from "react-router";
import {
    apiEndPoint,
    CANCELLATION_ENDPOINTS,
    PROPERTY_ENDPOINTS,
} from "../../contants/Endpoints";
import { Formik } from "formik";
import { toastMessage } from "../../utils/toast";
import * as Yup from "yup";
import dashboardOrRegistrationRoute, {
    RouteType,
} from "../../utils/dashboardOrRegistrationRoute";
import CustomValidation from "../../utils/customValidation";
import Checkbox from "../Checkbox";
import { noAuthAxios } from "../../utils/axiosWrapperOpen";
import SingleSelectSimpleArray from "../singleSelect/SingleSelectSimpleArray";
import SingleSelectFromSimpleArray from "../singleSelect/SingleSelectFromSimpleArray";
import { asyncLocalStorage } from "../../utils/asyncLocalStorage";
import Cancellation from "../Cancellation";
import { stringify } from "postcss";

const CategoriesComponent = ({ setAddCategory }) => {
    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();
    let param = useParams();
    let id = param.id;
    const fetchSummeryData = useSelector(
        (state) => state.fetchSummeryReducer.data
    );

    const [additionalPriceForEachPerson, setAdditionalPriceForEachPerson] =
        useState();
    const [freeAdditionalGuests, setFreeAdditionalGuests] = useState();
    const [urlTempStorageArray, setUrlTempStorageArray] = useState([]);
    const [images, setImages] = useState([]);
    const [categoryName, setPresidentialLux] = useState("");
    // const [baseRate, setbaseRate] = useState("");
    const [noOfRoomsInCategory, setNoOfRoomsInCategory] = useState();
    const [accessibilitySelected, setAccessibilitySelected] = useState([]);
    const [areaSleepingSelected, setAreaSleepingSelected] = useState([]);
    const [hotelsSelected, setHotelsSelected] = useState([]);
    const [unitTearSelected, setUnitTearSelected] = useState([]);
    const [guestUnitTypeSelected, setGuestUnitTypeSelected] = useState([]);
    const [maxNumberOfOccupants, setMaximunCapacity] = useState();
    const [maxNumberOfAdultOccupants, setMaximumAdults] = useState();
    const [viewsfromUnitSelected, setViewsfromUnitSelected] = useState([]);
    const [livingAreaSqMeters, setSqMeters] = useState(0);
    const [maxNumberOfChildOccupants, setMaximumChildren] = useState();
    const [livingAreaLayoutSelected, setLivingAreaLayoutSelected] = useState(
        []
    );
    const [livingAreaFeaturesSelected, setLivingAreaFeaturesSelected] =
        useState([]);
    const [eatingCookingSelected, setEatingCookingSelected] = useState([]);
    const [noOfBeds, setNumberofbeds] = useState();
    const [noOfKingBeds, setKingBeds] = useState(0);
    const [noOfQueenBeds, setQueenBeds] = useState(0);
    const [noOfDoubleBeds, setDoubleBeds] = useState(0);
    const [noOfSingleBeds, setSingleBeds] = useState(0);

    const [currency, setCurrency] = useState("BDT");
    const [priceWithVat, setPriceWithVat] = useState(0);
    const [priceWithoutVat, setPriceWithoutVat] = useState(0);
    const [isVatIncluded, setIsVatIncluded] = useState(false);
    const [vatPercent, setVatPercent] = useState(15);

    const [noOfBunkBeds, setBunkBeds] = useState(0);
    const [noOfExtraBeds, setExtraBeds] = useState(0);
    const [noOfRollAwayBeds, setAwayBeds] = useState(0);
    const [noOfCribs, setCribs] = useState(0);

    // extra helper variable
    const [hotels, setHotels] = useState([]);
    const [catFlag, setCatFlag] = useState("cat-form");
    const [propertyId, setPropertyId] = useState(0);
    const [needUpdate, setNeedUpdate] = useState(Math.random());
    const [needUpdateImage, setNeedUpdateImage] = useState(Math.random());
    const [propertyName, setPropertyName] = useState();
    const [loading, setLoading] = useState(false);
    const [facilitiesRejectArray, setFacilitiesRejectArray] = useState({});
    const [imageRejectArray, setImageRejectArray] = useState({});
    const [categoryRejectedArray, setCategoryRejectedArray] = useState({});

    // call from api data
    const [accessibilityApi, setAccessibilityApi] = useState([]);
    const [areaSleeping, setAreaSleepingApi] = useState([]);
    const [unitTearApi, setUnitTearApi] = useState([]);
    const [guestUnitTypeApi, setGuestUnitTypeApi] = useState([]);
    const [viewsfromUnitApi, setViewsfromUnitApi] = useState([]);
    const [livingAreaLayoutApi, setLivingAreaLayoutApi] = useState([]);
    const [livingAreaFeaturesApi, setLivingAreaFeaturesApi] = useState([]);
    const [eatingCookingApi, setEatingCookingApi] = useState([]);
    const [currencyList, setCurrencyList] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [disabling, setDisabling] = useState(false);
    const [isCommissionIncluded, setGhuddyCommissionIncluded] = useState(false);

    const response = useSelector((state) => state.categoriesDetailsGetReducer);

    //cancellation policy state
    const [validationError, setValidationError] = useState("");

    const [policy, setPolicy] = useState({
        100: {
            to: "",
            unit: "days",
            is100: false,
            isActive: false,
            policy: 100,
            timeInHour: null,
        },
        75: {
            to: "",
            unit: "days",
            is75: false,
            isActive: false,
            policy: 75,
            timeInHour: null,
        },
        50: {
            to: "",
            unit: "hours",
            is50: false,
            isActive: false,
            policy: 50,
            timeInHour: null,
        },
        25: {
            to: "",
            unit: "hours",
            is25: false,
            isActive: false,
            policy: 25,
            timeInHour: null,
        },
        0: {
            to: "",
            unit: "hours",
            is0: false,
            isActive: false,
            policy: 0,
            timeInHour: null,
        },
    });
    //cancellation policy get
    const [addedPolicy, setAddedPolicy] = useState([]);

    const postPolicy = (id) => {
        let isError = false;
        const selectedpolicy = [];
        const temp = [];

        Object.values(policy).map((item) => {
            if (item.isActive) {
                temp.push(item);
                var time;
                if (item.unit === "days") {
                    time = parseInt(item.to) * 24;
                } else {
                    time = parseInt(item.to);
                }
                const obj = { refundPercent: item.policy, timeInHour: time };
                selectedpolicy.push(obj);
            }
        });

        if (!validationError) {
            const postObject = {
                categoryId: id,
                policyData: selectedpolicy,
                requestId: "11",
            };

            authAxios
                .post(CANCELLATION_ENDPOINTS.POST_CANCELLATION_CATEGORY, postObject)
                .then((response) => {
                    console.log(response, "polll response");
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    };
    const getCancellation = () => {
        authAxios
            .get(
                `${CANCELLATION_ENDPOINTS.GET_CANCELLATION_BY_CATEGORY}${id}/cancellation?requestId=11`
            )
            .then((response) => {
                setAddedPolicy(
                    response?.data?.terms
                );
            })
            .catch((error) => {
                console.log(error, "cancell1212");
            });
    };

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: "smooth" });
    }, []);

    useEffect(() => {
        noAuthAxios
            .get(`${apiEndPoint.GET_CURRENCY}`)
            .then((res) => {
                setCurrencyList(res.data.currencyList);
            })
            .catch((err) => {});
        if (id == "0") {
            setIsLoading(false);
        } else {
            dispatch(categorieDetails(process.env.REACT_APP_USER_TYPE, id));
        }
    }, [needUpdate]);

    // for next button click
    const createContractMoveingDecideNext = (id) => {
        setUrlTempStorageArray([]);
        postPolicy(id);
        setDisabling(false);
        if (window.location.pathname.includes("registration")) {
            navigate(merchantRoute.CATEGORIES);
        } else {
            navigate(DashboardRoutes.CATEGORIES);
        }
    };

    const obj_update = {
        additionalPriceForEachPerson: additionalPriceForEachPerson,
        // baseRate: baseRate,
        categoryName: categoryName,
        // // ghuddyCommission: 10,
        // isCommissionIncluded,
        facilities: [
            ...accessibilitySelected,
            ...areaSleepingSelected,
            ...unitTearSelected,
            ...guestUnitTypeSelected,
            ...viewsfromUnitSelected,
            ...livingAreaLayoutSelected,
            ...livingAreaFeaturesSelected,
            ...eatingCookingSelected,
        ],
        freeAdditionalGuests: freeAdditionalGuests,
        livingAreaSqMeters: livingAreaSqMeters,
        maxNumberOfAdultOccupants: maxNumberOfAdultOccupants,
        maxNumberOfChildOccupants: maxNumberOfChildOccupants,
        maxNumberOfOccupants: maxNumberOfOccupants,
        noOfBeds: noOfBeds,
        noOfBunkBeds: noOfBunkBeds,
        noOfCribs: noOfCribs,
        noOfDoubleBeds: noOfDoubleBeds,
        noOfExtraBeds: noOfExtraBeds,
        noOfKingBeds: noOfKingBeds,
        noOfQueenBeds: noOfQueenBeds,
        noOfRollAwayBeds: noOfRollAwayBeds,
        noOfRoomsInCategory: noOfRoomsInCategory,
        noOfSingleBeds: noOfSingleBeds,
        numberOfGuest: 5,
        rentalPropertyIds: hotelsSelected.id
            ? [hotelsSelected.id]
            : hotelsSelected,
        requestId: "string",

        roomCategoryId: id ? id : 0,
        // unit: "PER_PERSON",
        // vatPercent,
        // isVatIncluded,
        currency,
    };

    const buttonFilledHandle = () => {
        if (CustomValidation() && !validationError) {
            setDisabling(true);
            dispatch(
                postCategoryInfoNext(
                    obj_update,
                    urlTempStorageArray,
                    createContractMoveingDecideNext,
                    () => {
                        setDisabling(false);
                    }
                )
            );
        } else {
            toastMessage("Please fill up all required fields!", "error");
        }
    };

    const adminReviewSubmit = () => {
        dispatch(
            reviewSubmission(ReviewFor.CATEGORY, id, {
                categoryRejected: categoryRejectedArray,
                facilitiesReject: facilitiesRejectArray,
                imageReject: imageRejectArray,
                requestId: "string",
            })
        );
    };

    useEffect(() => {
        if (
            id > 0 &&
            response &&
            response.data &&
            response.data.roomCategory &&
            response.data.roomCategory.categoryImages &&
            response.data.roomCategory.categoryName
        ) {
            getCancellation();
            // try{
            setterNullSafety(() => {
                setLoading(true);
            });

            setterNullSafety(() => {
                setImages(response.data.roomCategory.categoryImages);
            });
            // console.log(response.data, "response.data");
            setterNullSafety(() => {
                setPropertyId(response.data.roomCategory.rentalPropertyId);
            });
            setterNullSafety(() => {
                setPresidentialLux(response.data.roomCategory.categoryName);
            });
            setterNullSafety(() => {
                setAccessibilitySelected(
                    response.data.roomCategory.facilities.living_area_accessibility.map(
                        (i) => i.id
                    )
                );
            });
            // setterNullSafety(() => {
            //     setbaseRate(response.data.roomCategory.baseRate);
            // });
            setterNullSafety(() => {
                setGhuddyCommissionIncluded(
                    response.data.roomCategory.isCommissionIncluded
                );
            });
            setterNullSafety(() => {
                setHotelsSelected([
                    response.data.roomCategory.rentalPropertyId,
                ]);
            });
            setterNullSafety(() => {
                setNoOfRoomsInCategory(
                    response.data.roomCategory.noOfRoomsInCategory
                );
            });
            setterNullSafety(() => {
                setAreaSleepingSelected(
                    response.data.roomCategory.facilities.living_area_sleeping.map(
                        (i) => i.id
                    )
                );
            });
            setterNullSafety(() => {
                setUnitTearSelected(
                    response.data.roomCategory.facilities.unit_tier.map(
                        (i) => i.id
                    )
                );
            });
            setterNullSafety(() => {
                setGuestUnitTypeSelected(
                    response.data.roomCategory.facilities.guest_unit_type.map(
                        (i) => i.id
                    )
                );
            });
            setterNullSafety(() => {
                setMaximunCapacity(
                    response.data.roomCategory.maxNumberOfOccupants
                );
            });
            setterNullSafety(() => {
                setMaximumAdults(
                    response.data.roomCategory.maxNumberOfAdultOccupants
                );
            });
            setterNullSafety(() => {
                setMaximumChildren(
                    response.data.roomCategory.maxNumberOfChildOccupants
                );
            });
            setterNullSafety(() => {
                setViewsfromUnitSelected(
                    response.data.roomCategory.facilities.views_from_unit.map(
                        (i) => i.id
                    )
                );
            });
            setterNullSafety(() => {
                setSqMeters(response.data.roomCategory.livingAreaSqMeters);
            });
            setterNullSafety(() => {
                setLivingAreaLayoutSelected(
                    response.data.roomCategory.facilities.living_area_layout.map(
                        (i) => i.id
                    )
                );
            });
            setterNullSafety(() => {
                setLivingAreaFeaturesSelected(
                    response.data.roomCategory.facilities.living_area_features.map(
                        (i) => i.id
                    )
                );
            });
            setterNullSafety(() => {
                setEatingCookingSelected(
                    response.data.roomCategory.facilities.living_area_eating.map(
                        (i) => i.id
                    )
                );
            });
            setterNullSafety(() => {
                setNumberofbeds(response.data.roomCategory.noOfBeds);
            });
            setterNullSafety(() => {
                setKingBeds(response.data.roomCategory.noOfKingBeds);
            });
            setterNullSafety(() => {
                setQueenBeds(response.data.roomCategory.noOfQueenBeds);
            });
            setterNullSafety(() => {
                setDoubleBeds(response.data.roomCategory.noOfDoubleBeds);
            });
            setterNullSafety(() => {
                setSingleBeds(response.data.roomCategory.noOfSingleBeds);
            });
            setterNullSafety(() => {
                setBunkBeds(response.data.roomCategory.noOfBunkBeds);
            });
            setterNullSafety(() => {
                setExtraBeds(response.data.roomCategory.noOfExtraBeds);
            });
            setterNullSafety(() => {
                setAwayBeds(response.data.roomCategory.noOfRollAwayBeds);
            });
            setterNullSafety(() => {
                setCurrency(response.data.roomCategory.currency);
            });
            // setterNullSafety(() => {
            //     setIsVatIncluded(response.data.roomCategory.isVatIncluded);
            // });
            setterNullSafety(() => {
                setCribs(response.data.roomCategory.noOfCribs);
            });
            setterNullSafety(() => {
                setFacilitiesRejectArray(
                    response.data.roomCategory.facilityRejected
                );
            });
            setterNullSafety(() => {
                setCategoryRejectedArray(
                    response.data.roomCategory.categoryRejected
                );
            });
            setterNullSafety(() => {
                setAdditionalPriceForEachPerson(
                    response.data.roomCategory.additionalPriceForEachPerson
                );
            });
            setterNullSafety(() => {
                setFreeAdditionalGuests(
                    response.data.roomCategory.freeAdditionalGuests
                );
            });

            // }catch(e){
            //   console.log(e,"try")
            // }
            setIsLoading(false);
        }
    }, [response]);

    // fetch all tags
    useEffect(() => {
        const headers = {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        };

        authAxios
            .get(`${PROPERTY_ENDPOINTS.FETCH_FACILITIES}`, {
                headers: headers,
            })
            .then((response) => {
                setAccessibilityApi(
                    response.data.categoryFacilities.living_area_accessibility
                );
                setAreaSleepingApi(
                    response.data.categoryFacilities.living_area_sleeping
                );
                setUnitTearApi(response.data.categoryFacilities.unit_tier);
                setGuestUnitTypeApi(
                    response.data.categoryFacilities.guest_unit_type
                );
                setViewsfromUnitApi(
                    response.data.categoryFacilities.views_from_unit
                );
                setLivingAreaLayoutApi(
                    response.data.categoryFacilities.living_area_layout
                );
                setLivingAreaFeaturesApi(
                    response.data.categoryFacilities.living_area_features
                );
                setEatingCookingApi(
                    response.data.categoryFacilities.living_area_eating
                );

                setLoading(false);
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);

    // rental poperty id get
    useEffect(() => {
        if (!isLoading) {
            getHotelList();
        }
    }, [isLoading]);

    const getHotelList = () => {
        asyncLocalStorage.getItem("contractId").then((contractId) => {
            console.log(contractId, "test");
            if (contractId !== undefined && contractId !== "undefined") {
                authAxios
                    .get(
                        `${PROPERTY_ENDPOINTS.FETCH_RENTAL_ADMIN}?contractId=${contractId}&requestId=fhgf`
                    )
                    .then((response) => {
                        setHotels(response.data.propertyDataList);
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            }
        });
    };

    // for edit version component rental property name. this is get for this section
    useEffect(() => {
        if (propertyId && propertyId > 0) {
            authAxios
                .get(
                    `${PROPERTY_ENDPOINTS.FETCH_RENTAL_ADMIN_BY_ID}${propertyId}?requestId=fewfe`
                )
                .then((response) => {
                    console.log("category get api:", response.data);
                    setPropertyName(response.data.propertyData.propertyName);
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }, [propertyId]);

    // useEffect(() => {
    //   if (vatPercent <= 50) {
    //     if (!isVatIncluded) {
    //       const priceWithVat = parseFloat(baseRate) + (baseRate * (vatPercent / 100));
    //       const priceWithoutVat = parseFloat(baseRate);

    //       setPriceWithVat(priceWithVat);
    //       setPriceWithoutVat(priceWithoutVat);
    //     }
    //     else {
    //       const priceWithVat = parseFloat(baseRate);
    //       const priceWithoutVat = parseFloat(baseRate) - (baseRate * (vatPercent / 100));

    //       setPriceWithVat(priceWithVat);
    //       setPriceWithoutVat(priceWithoutVat);
    //     }
    //   }

    // }, [isVatIncluded, baseRate, vatPercent])

    // useEffect(() => {
    //     if (vatPercent > 0) {
    //         setIsVatIncluded(true);
    //     } else {
    //         setIsVatIncluded(false);
    //     }
    // }, [vatPercent]);

    const handleAddMore = () => {
        setCatFlag("cat-form");
    };
    return (
        <div className="">
            {process.env.REACT_APP_USER_TYPE === UserType.USER ? (
                <Pagenation owner="owner" />
            ) : null}
            {dashboardOrRegistrationRoute() === RouteType.REGISTRATION && (
                <HorizontalBar selectedBar="Categories" />
            )}
            {catFlag === "cat-box" ? (
                <AddMore title="Category" handleAddMore={handleAddMore} />
            ) : (
                <div className="relative 2xl:container mx-auto 2xl:px-0">
                    {response.loading || isLoading ? (
                        <div className="ml-[45%]">
                            <Loading />
                        </div>
                    ) : (
                        <>
                            <div
                                onClick={() => navigate(-1)}
                                className="flex mt-6 absolute left-8 2xl:left-0 cursor-pointer"
                            >
                                <div>
                                    <svg
                                        width="39"
                                        height="39"
                                        viewBox="0 0 49 49"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="cursor-pointer"
                                        // onClick={() => setAddCategory("start")}
                                    >
                                        <rect
                                            x="0.5"
                                            y="0.5"
                                            width="48"
                                            height="48"
                                            rx="24"
                                            fill="#FFFBEF"
                                        />
                                        <path
                                            d="M25.5 21L21.5 25L25.5 29"
                                            stroke="#376576"
                                            strokeWidth="2"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                        <rect
                                            x="0.5"
                                            y="0.5"
                                            width="48"
                                            height="48"
                                            rx="24"
                                            stroke="#376576"
                                        />
                                    </svg>
                                </div>
                                <div>
                                    <p className="h-full py-[2px] px-[10px] text-[27px] cursor-pointer">
                                        Category list
                                    </p>
                                </div>
                            </div>

                            <div key={1} className="w-full">
                                <div className="flex justify-center pt-24">
                                    <div className="">
                                        <div className="min-w-full flex flex-col gap-y-4">
                                            <InputComponent
                                                required={true}
                                                inputValue={categoryName}
                                                setInputValue={
                                                    setPresidentialLux
                                                }
                                                placeholder="Category Name"
                                                isAdmin={
                                                    process.env
                                                        .REACT_APP_USER_TYPE ===
                                                    UserType.ADMIN
                                                        ? true
                                                        : false
                                                }
                                                isReadOnly={false}
                                                rejectionArray={
                                                    categoryRejectedArray
                                                }
                                                setRejectionArray={
                                                    setCategoryRejectedArray
                                                }
                                                name="categoryName"
                                            />
                                            {/* extra field */}
                                            {/* <InputComponent
                                                required={true}
                                                inputValue={baseRate}
                                                setInputValue={setbaseRate}
                                                placeholder="Base Rate"
                                                isAdmin={
                                                    process.env
                                                        .REACT_APP_USER_TYPE ===
                                                    UserType.ADMIN
                                                        ? true
                                                        : false
                                                }
                                                isReadOnly={false}
                                                rejectionArray={
                                                    categoryRejectedArray
                                                }
                                                setRejectionArray={
                                                    setCategoryRejectedArray
                                                }
                                                name="baseRate"
                                                number={true}
                                            /> */}

                                            <SingleSelectFromSimpleArray
                                                selectedValue={currency}
                                                setSelectedValue={setCurrency}
                                                required={true}
                                                placeholder="Select Currency"
                                                data={Object.values(
                                                    currencyList
                                                )}
                                                rejectionArray={
                                                    categoryRejectedArray
                                                }
                                                setRejectionArray={
                                                    setCategoryRejectedArray
                                                }
                                                isAdmin={
                                                    process.env
                                                        .REACT_APP_USER_TYPE ===
                                                    UserType.ADMIN
                                                        ? true
                                                        : false
                                                }
                                                name="currency"
                                            />

                                            {/* <Checkbox
                                                checked={isCommissionIncluded}
                                                title={
                                                    "Ghuddy Commission Included ?"
                                                }
                                                handleChange={() => {
                                                    setGhuddyCommissionIncluded(
                                                        !isCommissionIncluded
                                                    );
                                                }}
                                            /> */}

                                            {/* <InputComponent
                                                required={true}
                                                inputValue={vatPercent}
                                                number={true}
                                                setInputValue={setVatPercent}
                                                placeholder="VAT (%)"
                                                isAdmin={
                                                    process.env
                                                        .REACT_APP_USER_TYPE ===
                                                    UserType.ADMIN
                                                        ? true
                                                        : false
                                                }
                                                isReadOnly={false}
                                                rejectionArray={
                                                    categoryRejectedArray
                                                }
                                                setRejectionArray={
                                                    setCategoryRejectedArray
                                                }
                                                name="vatPercent"
                                            /> */}

                                            {/* <InputComponent
                        required={true}
                        inputValue={priceWithVat}
                        setInputValue={setPriceWithVat}
                        placeholder="Price With VAT"
                        isAdmin={process.env.REACT_APP_USER_TYPE === UserType.ADMIN ? true : false}
                        isReadOnly={true}
                        rejectionArray={categoryRejectedArray}
                        setRejectionArray={setCategoryRejectedArray}
                        name="priceWithVat"
                      />

                      <InputComponent
                        required={true}
                        inputValue={priceWithoutVat}
                        setInputValue={setPriceWithoutVat}
                        placeholder="Price Without VAT"
                        isAdmin={process.env.REACT_APP_USER_TYPE === UserType.ADMIN ? true : false}
                        isReadOnly={true}
                        rejectionArray={categoryRejectedArray}
                        setRejectionArray={setCategoryRejectedArray}
                        name="priceWithoutVat"
                      /> */}

                                            <InputComponent
                                                required={true}
                                                inputValue={noOfRoomsInCategory}
                                                setInputValue={
                                                    setNoOfRoomsInCategory
                                                }
                                                placeholder="Number of Rooms"
                                                isAdmin={
                                                    process.env
                                                        .REACT_APP_USER_TYPE ===
                                                    UserType.ADMIN
                                                        ? true
                                                        : false
                                                }
                                                isReadOnly={false}
                                                rejectionArray={
                                                    categoryRejectedArray
                                                }
                                                setRejectionArray={
                                                    setCategoryRejectedArray
                                                }
                                                name="noOfRoomsInCategory"
                                            />
                                            <MultiSelectParent
                                                // required={true}
                                                selectedArray={
                                                    accessibilitySelected
                                                }
                                                setSelectedArray={
                                                    setAccessibilitySelected
                                                }
                                                data={accessibilityApi}
                                                placeholder="living area accessibility"
                                                isAdmin={
                                                    process.env
                                                        .REACT_APP_USER_TYPE ===
                                                    UserType.ADMIN
                                                        ? true
                                                        : false
                                                }
                                                isReadOnly={false}
                                                rejectionArray={
                                                    facilitiesRejectArray
                                                }
                                                setRejectionArray={
                                                    setFacilitiesRejectArray
                                                }
                                                name="accessibilitySelected"
                                            />
                                            <SingleSelectParentHotelReturn
                                                selectedValue={hotelsSelected}
                                                setSelectedValue={
                                                    setHotelsSelected
                                                }
                                                data={hotels}
                                                placeholder="Select Hotel"
                                                displayField={"propertyName"}
                                                selectedFieldName="propertyName"
                                                propertyName={propertyName}
                                                getData={getHotelList}
                                            />
                                            <MultiSelectParent
                                                // required={true}
                                                selectedArray={
                                                    areaSleepingSelected
                                                }
                                                setSelectedArray={
                                                    setAreaSleepingSelected
                                                }
                                                data={areaSleeping}
                                                placeholder="Sleeping Living Area"
                                                isAdmin={
                                                    process.env
                                                        .REACT_APP_USER_TYPE ===
                                                    UserType.ADMIN
                                                        ? true
                                                        : false
                                                }
                                                isReadOnly={false}
                                                rejectionArray={
                                                    facilitiesRejectArray
                                                }
                                                setRejectionArray={
                                                    setFacilitiesRejectArray
                                                }
                                                name="areaSleepingSelected"
                                            />
                                            <MultiSelectParent
                                                // required={true}
                                                selectedArray={unitTearSelected}
                                                setSelectedArray={
                                                    setUnitTearSelected
                                                }
                                                data={unitTearApi}
                                                placeholder="Unit Tier"
                                                isAdmin={
                                                    process.env
                                                        .REACT_APP_USER_TYPE ===
                                                    UserType.ADMIN
                                                        ? true
                                                        : false
                                                }
                                                isReadOnly={false}
                                                rejectionArray={
                                                    facilitiesRejectArray
                                                }
                                                setRejectionArray={
                                                    setFacilitiesRejectArray
                                                }
                                                name="unitTearSelected"
                                            />
                                            <MultiSelectParent
                                                required={false}
                                                selectedArray={
                                                    guestUnitTypeSelected
                                                }
                                                setSelectedArray={
                                                    setGuestUnitTypeSelected
                                                }
                                                data={guestUnitTypeApi}
                                                placeholder="Guest Unit Type"
                                                isAdmin={
                                                    process.env
                                                        .REACT_APP_USER_TYPE ===
                                                    UserType.ADMIN
                                                        ? true
                                                        : false
                                                }
                                                isReadOnly={false}
                                                rejectionArray={
                                                    facilitiesRejectArray
                                                }
                                                setRejectionArray={
                                                    setFacilitiesRejectArray
                                                }
                                                name="guestUnitTypeSelected"
                                            />
                                            <InputComponent
                                                required={true}
                                                inputValue={
                                                    maxNumberOfOccupants
                                                }
                                                setInputValue={
                                                    setMaximunCapacity
                                                }
                                                placeholder="Maximun Capacity"
                                                isAdmin={
                                                    process.env
                                                        .REACT_APP_USER_TYPE ===
                                                    UserType.ADMIN
                                                        ? true
                                                        : false
                                                }
                                                isReadOnly={false}
                                                rejectionArray={
                                                    categoryRejectedArray
                                                }
                                                setRejectionArray={
                                                    setCategoryRejectedArray
                                                }
                                                name="maxNumberOfOccupants"
                                            />
                                            <InputComponent
                                                required={true}
                                                inputValue={
                                                    maxNumberOfAdultOccupants
                                                }
                                                setInputValue={setMaximumAdults}
                                                placeholder="Maximum Adults"
                                                isAdmin={
                                                    process.env
                                                        .REACT_APP_USER_TYPE ===
                                                    UserType.ADMIN
                                                        ? true
                                                        : false
                                                }
                                                isReadOnly={false}
                                                rejectionArray={
                                                    categoryRejectedArray
                                                }
                                                setRejectionArray={
                                                    setCategoryRejectedArray
                                                }
                                                name="maxNumberOfAdultOccupants"
                                            />
                                            <InputComponent
                                                required={true}
                                                inputValue={
                                                    maxNumberOfChildOccupants
                                                }
                                                setInputValue={
                                                    setMaximumChildren
                                                }
                                                placeholder="Maximum Children"
                                                isAdmin={
                                                    process.env
                                                        .REACT_APP_USER_TYPE ===
                                                    UserType.ADMIN
                                                        ? true
                                                        : false
                                                }
                                                isReadOnly={false}
                                                rejectionArray={
                                                    categoryRejectedArray
                                                }
                                                setRejectionArray={
                                                    setCategoryRejectedArray
                                                }
                                                name="maxNumberOfChildOccupants"
                                            />
                                            <MultiSelectParent
                                                required={true}
                                                selectedArray={
                                                    viewsfromUnitSelected
                                                }
                                                setSelectedArray={
                                                    setViewsfromUnitSelected
                                                }
                                                data={viewsfromUnitApi}
                                                placeholder="Views from Unit"
                                                isAdmin={
                                                    process.env
                                                        .REACT_APP_USER_TYPE ===
                                                    UserType.ADMIN
                                                        ? true
                                                        : false
                                                }
                                                isReadOnly={false}
                                                rejectionArray={
                                                    facilitiesRejectArray
                                                }
                                                setRejectionArray={
                                                    setFacilitiesRejectArray
                                                }
                                                name="viewsfromUnitSelected"
                                            />
                                            <InputComponent
                                                required={true}
                                                inputValue={livingAreaSqMeters}
                                                setInputValue={setSqMeters}
                                                placeholder="Sq. meters"
                                                isAdmin={
                                                    process.env
                                                        .REACT_APP_USER_TYPE ===
                                                    UserType.ADMIN
                                                        ? true
                                                        : false
                                                }
                                                isReadOnly={false}
                                                rejectionArray={
                                                    categoryRejectedArray
                                                }
                                                setRejectionArray={
                                                    setCategoryRejectedArray
                                                }
                                                name="livingAreaSqMeters"
                                            />
                                            <MultiSelectParent
                                                // required={true}
                                                selectedArray={
                                                    livingAreaLayoutSelected
                                                }
                                                setSelectedArray={
                                                    setLivingAreaLayoutSelected
                                                }
                                                data={livingAreaLayoutApi}
                                                placeholder="Living Area Layout"
                                                isAdmin={
                                                    process.env
                                                        .REACT_APP_USER_TYPE ===
                                                    UserType.ADMIN
                                                        ? true
                                                        : false
                                                }
                                                isReadOnly={false}
                                                rejectionArray={
                                                    facilitiesRejectArray
                                                }
                                                setRejectionArray={
                                                    setFacilitiesRejectArray
                                                }
                                                name="livingAreaLayoutSelected"
                                            />
                                            <MultiSelectParent
                                                // required={true}
                                                selectedArray={
                                                    livingAreaFeaturesSelected
                                                }
                                                setSelectedArray={
                                                    setLivingAreaFeaturesSelected
                                                }
                                                data={livingAreaFeaturesApi}
                                                placeholder="Living Area Features"
                                                isAdmin={
                                                    process.env
                                                        .REACT_APP_USER_TYPE ===
                                                    UserType.ADMIN
                                                        ? true
                                                        : false
                                                }
                                                isReadOnly={false}
                                                rejectionArray={
                                                    facilitiesRejectArray
                                                }
                                                setRejectionArray={
                                                    setFacilitiesRejectArray
                                                }
                                                name="livingAreaFeaturesSelected"
                                            />
                                            <MultiSelectParent
                                                // required={true}
                                                selectedArray={
                                                    eatingCookingSelected
                                                }
                                                setSelectedArray={
                                                    setEatingCookingSelected
                                                }
                                                data={eatingCookingApi}
                                                placeholder="Living area Eating and Cooking"
                                                isAdmin={
                                                    process.env
                                                        .REACT_APP_USER_TYPE ===
                                                    UserType.ADMIN
                                                        ? true
                                                        : false
                                                }
                                                isReadOnly={false}
                                                rejectionArray={
                                                    facilitiesRejectArray
                                                }
                                                setRejectionArray={
                                                    setFacilitiesRejectArray
                                                }
                                                name="eatingCookingSelected"
                                            />
                                            <InputComponent
                                                required={true}
                                                inputValue={noOfBeds}
                                                setInputValue={setNumberofbeds}
                                                placeholder="Number of beds"
                                                isAdmin={
                                                    process.env
                                                        .REACT_APP_USER_TYPE ===
                                                    UserType.ADMIN
                                                        ? true
                                                        : false
                                                }
                                                isReadOnly={false}
                                                rejectionArray={
                                                    categoryRejectedArray
                                                }
                                                setRejectionArray={
                                                    setCategoryRejectedArray
                                                }
                                                name="noOfBeds"
                                            />
                                            <InputComponent
                                                required={true}
                                                inputValue={noOfKingBeds}
                                                setInputValue={setKingBeds}
                                                placeholder="Number of King Beds"
                                                isAdmin={
                                                    process.env
                                                        .REACT_APP_USER_TYPE ===
                                                    UserType.ADMIN
                                                        ? true
                                                        : false
                                                }
                                                isReadOnly={false}
                                                rejectionArray={
                                                    categoryRejectedArray
                                                }
                                                setRejectionArray={
                                                    setCategoryRejectedArray
                                                }
                                                name="noOfKingBeds"
                                            />
                                            <InputComponent
                                                required={true}
                                                inputValue={noOfQueenBeds}
                                                setInputValue={setQueenBeds}
                                                placeholder="Number of Queen Beds"
                                                isAdmin={
                                                    process.env
                                                        .REACT_APP_USER_TYPE ===
                                                    UserType.ADMIN
                                                        ? true
                                                        : false
                                                }
                                                isReadOnly={false}
                                                rejectionArray={
                                                    categoryRejectedArray
                                                }
                                                setRejectionArray={
                                                    setCategoryRejectedArray
                                                }
                                                name="noOfQueenBeds"
                                            />
                                            <InputComponent
                                                required={true}
                                                inputValue={noOfDoubleBeds}
                                                setInputValue={setDoubleBeds}
                                                placeholder="Number of Double Beds"
                                                isAdmin={
                                                    process.env
                                                        .REACT_APP_USER_TYPE ===
                                                    UserType.ADMIN
                                                        ? true
                                                        : false
                                                }
                                                isReadOnly={false}
                                                rejectionArray={
                                                    categoryRejectedArray
                                                }
                                                setRejectionArray={
                                                    setCategoryRejectedArray
                                                }
                                                name="noOfDoubleBeds"
                                            />
                                            <InputComponent
                                                required={true}
                                                inputValue={noOfSingleBeds}
                                                setInputValue={setSingleBeds}
                                                placeholder="Number of Single Beds"
                                                isAdmin={
                                                    process.env
                                                        .REACT_APP_USER_TYPE ===
                                                    UserType.ADMIN
                                                        ? true
                                                        : false
                                                }
                                                isReadOnly={false}
                                                rejectionArray={
                                                    categoryRejectedArray
                                                }
                                                setRejectionArray={
                                                    setCategoryRejectedArray
                                                }
                                                name="noOfSingleBeds"
                                            />
                                            <InputComponent
                                                required={true}
                                                inputValue={noOfBunkBeds}
                                                setInputValue={setBunkBeds}
                                                placeholder="Number of Bunk Beds"
                                                isAdmin={
                                                    process.env
                                                        .REACT_APP_USER_TYPE ===
                                                    UserType.ADMIN
                                                        ? true
                                                        : false
                                                }
                                                isReadOnly={false}
                                                rejectionArray={
                                                    categoryRejectedArray
                                                }
                                                setRejectionArray={
                                                    setCategoryRejectedArray
                                                }
                                                name="noOfBunkBeds"
                                            />
                                            <InputComponent
                                                required={true}
                                                inputValue={noOfExtraBeds}
                                                setInputValue={setExtraBeds}
                                                placeholder="Number of Extra Beds"
                                                isAdmin={
                                                    process.env
                                                        .REACT_APP_USER_TYPE ===
                                                    UserType.ADMIN
                                                        ? true
                                                        : false
                                                }
                                                isReadOnly={false}
                                                rejectionArray={
                                                    categoryRejectedArray
                                                }
                                                setRejectionArray={
                                                    setCategoryRejectedArray
                                                }
                                                name="noOfExtraBeds"
                                            />
                                            <InputComponent
                                                required={true}
                                                inputValue={noOfRollAwayBeds}
                                                setInputValue={setAwayBeds}
                                                placeholder="Number of Roll Away Beds"
                                                isAdmin={
                                                    process.env
                                                        .REACT_APP_USER_TYPE ===
                                                    UserType.ADMIN
                                                        ? true
                                                        : false
                                                }
                                                isReadOnly={false}
                                                rejectionArray={
                                                    categoryRejectedArray
                                                }
                                                setRejectionArray={
                                                    setCategoryRejectedArray
                                                }
                                                name="noOfRollAwayBeds"
                                            />
                                            <InputComponent
                                                required={true}
                                                inputValue={noOfCribs}
                                                setInputValue={setCribs}
                                                placeholder="Number of Cribs"
                                                isAdmin={
                                                    process.env
                                                        .REACT_APP_USER_TYPE ===
                                                    UserType.ADMIN
                                                        ? true
                                                        : false
                                                }
                                                isReadOnly={false}
                                                rejectionArray={
                                                    categoryRejectedArray
                                                }
                                                setRejectionArray={
                                                    setCategoryRejectedArray
                                                }
                                                name="noOfCribs"
                                            />
                                            {/* new field */}
                                            <InputComponent
                                                // required={true}
                                                inputValue={
                                                    additionalPriceForEachPerson
                                                }
                                                setInputValue={
                                                    setAdditionalPriceForEachPerson
                                                }
                                                placeholder="Additional Price per Person"
                                                isAdmin={
                                                    process.env
                                                        .REACT_APP_USER_TYPE ===
                                                    UserType.ADMIN
                                                        ? true
                                                        : false
                                                }
                                                isReadOnly={false}
                                                rejectionArray={
                                                    categoryRejectedArray
                                                }
                                                setRejectionArray={
                                                    setCategoryRejectedArray
                                                }
                                                name="additionalPriceForEachPerson"
                                            />
                                            <InputComponent
                                                // required={true}
                                                inputValue={
                                                    freeAdditionalGuests
                                                }
                                                setInputValue={
                                                    setFreeAdditionalGuests
                                                }
                                                placeholder="Free Additional Guests"
                                                isAdmin={
                                                    process.env
                                                        .REACT_APP_USER_TYPE ===
                                                    UserType.ADMIN
                                                        ? true
                                                        : false
                                                }
                                                isReadOnly={false}
                                                rejectionArray={
                                                    categoryRejectedArray
                                                }
                                                setRejectionArray={
                                                    setCategoryRejectedArray
                                                }
                                                name="freeAdditionalGuests"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className=" mt-[130px] mb-[60px] flex flex-col px-6">
                                    <Cancellation
                                        policy={policy}
                                        setPolicy={setPolicy}
                                        validationError={validationError}
                                        setValidationError={setValidationError}
                                        addedPolicy={addedPolicy}
                                    />
                                </div>
                                <div className="mt-10">
                                    {process.env.REACT_APP_USER_TYPE ===
                                    UserType.USER ? (
                                        <div className="mx-auto">
                                            <FileMultipleCategory
                                                id={id}
                                                setNeedUpdate={
                                                    setNeedUpdateImage
                                                }
                                                urlTempStorageArray={
                                                    urlTempStorageArray
                                                }
                                                setUrlTempStorageArray={
                                                    setUrlTempStorageArray
                                                }
                                            />
                                        </div>
                                    ) : null}

                                    <div className="grid grid-cols-6 gap-4 my-14 mx-12">
                                        {images &&
                                            images.length > 0 &&
                                            images.map((image, index) => {
                                                return (
                                                    <RemoveableImageUploadViewCategory
                                                        fetchSummeryData={
                                                            fetchSummeryData
                                                        }
                                                        key={image.id}
                                                        setNeedUpdate={
                                                            setNeedUpdateImage
                                                        }
                                                        url={image.url}
                                                        id={image.id}
                                                        userType={
                                                            process.env
                                                                .REACT_APP_USER_TYPE
                                                        }
                                                        setUrlTempStorageArray={
                                                            setImages
                                                        }
                                                        urlTempStorageArray={
                                                            images
                                                        }
                                                    />
                                                );
                                            })}

                                        {urlTempStorageArray &&
                                            urlTempStorageArray.length > 0 &&
                                            urlTempStorageArray.map(
                                                (image, index) => {
                                                    return (
                                                        <RemoveableImageUploadViewCategory
                                                            setNeedUpdate={
                                                                setNeedUpdateImage
                                                            }
                                                            fetchSummeryData={
                                                                fetchSummeryData
                                                            }
                                                            url={image.url}
                                                            userType={
                                                                process.env
                                                                    .REACT_APP_USER_TYPE
                                                            }
                                                            id={
                                                                -1 * (index + 1)
                                                            }
                                                            setUrlTempStorageArray={
                                                                setUrlTempStorageArray
                                                            }
                                                            urlTempStorageArray={
                                                                urlTempStorageArray
                                                            }
                                                        />
                                                    );
                                                }
                                            )}
                                    </div>
                                    {process.env.REACT_APP_USER_TYPE ===
                                    UserType.USER ? (
                                        response.loading ? null : (
                                            <div className="px-12">
                                                <ButtonFilled
                                                    title="Save"
                                                    buttonFilledHandle={
                                                        buttonFilledHandle
                                                    }
                                                    w="291px"
                                                    h="49px"
                                                    disable={
                                                        fetchSummeryData.status ===
                                                            "PENDING" ||
                                                        disabling
                                                            ? "yes"
                                                            : "no"
                                                    }
                                                />
                                            </div>
                                        )
                                    ) : response.loading ? null : (
                                        <div className="flex justify-center">
                                            <ButtonFilled
                                                title="Review Submit"
                                                buttonFilledHandle={
                                                    adminReviewSubmit
                                                }
                                                w="291px"
                                                h="49px"
                                            />
                                        </div>
                                    )}
                                </div>
                            </div>
                        </>
                    )}
                </div>
            )}
        </div>
    );
};

export default CategoriesComponent;
