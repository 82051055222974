export default function PaginationComponent({
    totalElements = 0,
    pageSize = 0,
    currentPage = 0,
    dataSize = 0,
    setCurrentPage,
    setPageSize,
    showCount = true,
}) {
    const totalPage = Math.ceil(totalElements / pageSize);
    return (
        <div className="flex items-center justify-between bg-white px-4 py-3 sm:px-6">
            <div className="flex flex-1 justify-between sm:hidden">
                <div className="relative inline-flex items-center rounded-md border border-[#9e9989] bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50">
                    Previous
                </div>
                <div className="relative ml-3 inline-flex items-center rounded-md border border-[#9e9989] bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50">
                    Next
                </div>
            </div>
            <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
                <div className="flex items-center gap-4">
                    {showCount && (
                        <p className="text-sm text-gray-700">
                            <span className="font-medium">
                                {" "}
                                {currentPage * pageSize + 1}{" "}
                            </span>{" "}
                            to{" "}
                            <span className="font-medium">
                                {" "}
                                {currentPage * pageSize + dataSize}{" "}
                            </span>{" "}
                            of{" "}
                            <span className="font-medium">
                                {" "}
                                {totalElements}{" "}
                            </span>{" "}
                        </p>
                    )}

                    <div>
                        Per page:
                        <select
                            onChange={(e) => setPageSize(e.target.value)}
                            value={pageSize}
                            className="cursor-pointer hover:bg-[#e7e7e7] border border-[#9e9989] bg-[transparent] p-1 ml-2 rounded-md"
                        >
                            <option value={5}> 5 </option>
                            <option value={10}> 10 </option>
                            <option value={15}> 15 </option>
                        </select>
                    </div>
                </div>
                <div className="max-w-7xl">
                    <nav
                        className="isolate inline-flex -space-x-px rounded-md shadow-sm flex-wrap"
                        aria-label="Pagination"
                    >
                        <button
                            onClick={() => {
                                currentPage > 0 &&
                                    setCurrentPage(currentPage - 1);
                            }}
                            className="relative inline-flex hover:bg-btn-secondary hover:text-txt-secondary items-center rounded-l-md border border-[#9e9989] bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20"
                        >
                            <span className="">Previous</span>
                        </button>
                        {/* Current: "z-10 bg-indigo-50  text-indigo-600", Default: "bg-white border-[#9e9989] text-gray-500 hover:bg-gray-50" */}
                        {[...new Array(totalPage)].map((item, index) => (
                            <button
                                key={index}
                                onClick={() => {
                                    setCurrentPage(index);
                                }}
                                aria-current="page"
                                className={`relative hover:bg-btn-secondary hover:text-txt-secondary z-10 inline-flex items-center border  px-4 py-2 text-sm font-medium text-indigo-600 focus:z-20  ${
                                    currentPage === index &&
                                    "bg-btn-primary text-txt-secondary border-btn-primary "
                                } border-[#9e9989]`}
                            >
                                {index + 1}
                            </button>
                        ))}
                        <button
                            onClick={() => {
                                currentPage < totalPage &&
                                    setCurrentPage(currentPage + 1);
                            }}
                            className="relative inline-flex hover:bg-btn-secondary hover:text-txt-secondary items-center rounded-r-md border border-[#9e9989] bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20"
                        >
                            <span className="">Next</span>
                        </button>
                    </nav>
                </div>
            </div>
        </div>
    );
}
