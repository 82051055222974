import React, { useState, useEffect } from "react";
import InputComponent from "../InputComponent";
import Calender from "../Calender";
import ButtonOutlined from "../ButtonOutlined";
import { useDispatch, useSelector } from "react-redux";
import {
  postContractInfoSave,
  editContractInfoSave,
} from "../../store/registration/contract/api/contractApi";
import ButtonFilled from "../ButtonFilled";
import SingleSelectParent from "../singleSelect/SingleSelectParent";
import SingleSelectFromSimpleArray from "../singleSelect/SingleSelectFromSimpleArray";
import HorizontalBar from "../HorizontalBar/HorizontalBar";
import Loading from "../Loader/Loading";
import Pagenation from "../pagenation/Pagenation";
import { contractInfo } from "../../store/admin/contract/api/adminContractApi";
import {
  merchantRoute,
  ReviewFor,
  SummeryStatus,
  UserType,
} from "../../contants/Constants";
import { reviewSubmission } from "../../store/admin/review/api/reviewApi";
import { useNavigate } from "react-router";
import { fetchSummeryGet } from "../../store/registration/landingAccessFetchSummery/api/AccessFetchSummeryApi";
import { apiEndPoint, PROPERTY_ENDPOINTS } from "../../contants/Endpoints";
import { authAxios } from "../../utils/axiosWrapper";
import { Formik, useFormik } from "formik";
import { toastMessage } from "../../utils/toast";
import * as Yup from "yup";
import dashboardOrRegistrationRoute, {
  RouteType,
} from "../../utils/dashboardOrRegistrationRoute";
import CustomValidation from "../../utils/customValidation";

const ContractComponent = ({ withoutHeader }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let superContractId = localStorage.getItem("superContractId");

  const [isLoading, setIsLoading] = useState(true);

  const [contractName, setContractName] = useState("");
  const [signedBy, setSignedby] = useState("");
  const [signDate, setSignData] = useState("");
  const [startDate, setStartDate] = useState("");
  const [validDate, setValidDate] = useState("");
  const [organizationName, setOrganizationName] = useState("");
  const [postOffice, setPostOffice] = useState("");
  const [countryCode, setCountry] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [street, setAddress] = useState("");
  const [optionalPart, setAddressOptional] = useState("");
  const [division, setDivision] = useState("");
  const [city, setCity] = useState("");
  const [municipility, setMunicipility] = useState("");
  const [community, setCommunity] = useState("");

  const [apiCity, setApiCity] = useState();
  const [apiMunicipility, setApiMunicipility] = useState();
  const [apicomm, setApicomm] = useState();
  const [apiDivision, setApiDivision] = useState();
  const [allCountries, setAllCountries] = useState([]);
  const [apiZips, setApiZips] = useState();

  const [validationError, setValidationError] = useState(false);


  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(contractInfo(process.env.REACT_APP_USER_TYPE, superContractId));
  }, [superContractId]);

  const fetchSummeryData = useSelector(
    (state) => state.fetchSummeryReducer.data
  );

  console.log(fetchSummeryData, "fetchSummeryData");

  // some helper state
  // const [rejectionArray, setRejectionArray] = useState([]);
  const [addressRejectArray, setAddressRejectArray] = useState({});
  const [contractInfoRejectArray, setContractInfoRejectArray] = useState({});
  console.log(
    addressRejectArray,
    "addressRejectArray",
    contractInfoRejectArray
  );
  // console.log(rejectionArray, "rejectionArray");
  let response = useSelector((state) => state.contractInfoGetReducer);

  const contract_obj = {
    activationDate: "2022-02-17T15:32:09.080Z",
    address: {
      admin1: division,
      admin2: municipility,
      admin3: city,
      admin4: community,
      billingAddress: true,
      country: countryCode,
      optionalPart: optionalPart,
      organizationName: organizationName,
      primaryAddress: true,
      requestId: "ytwfvew",
      street: street,
      zip: zipCode,
    },
    contractId: localStorage.getItem("contractId"),
    contractName: contractName,
    description: "string",
    organizationName: organizationName,
    requestId: localStorage.getItem("id"),

    endDate: validDate,
    signDate: signDate,
    startDate: startDate,
    signedBy: signedBy,
  };

  useEffect(() => {
    fetchSummeryGet(localStorage.getItem("id"));
  }, []);

  const adminReviewSubmit = () => {
    dispatch(
      reviewSubmission(
        ReviewFor.CONTRACT,
        superContractId,
        {
          addressReject: addressRejectArray,
          contractInfoReject: contractInfoRejectArray,
          requestId: "string",
        },
        () => { }
      )
    );
  };

  // for save button click
  const createContractMoveingDecideSave = () => {
    navigate(merchantRoute.LANDING);
  };
  // for next Move
  const createContractMoveingDecideNext = () => {
    navigate(merchantRoute.DOCUMENT);
  };

  // button outline(Save)
  const buttonOutlinedHandle = () => {
    if (CustomValidation()) {
      if (
        fetchSummeryData.contractInfoStatus === SummeryStatus.DRAFT ||
        fetchSummeryData.contractInfoStatus ===
        SummeryStatus.REJECTED ||
        fetchSummeryData.contractInfoStatus === SummeryStatus.ACCEPTED
      ) {
        dispatch(
          editContractInfoSave(
            contract_obj,
            createContractMoveingDecideSave
          )
        );
      } else {
        dispatch(
          postContractInfoSave(
            contract_obj,
            createContractMoveingDecideSave
          )
        );
      }
    }
  };

  // button filled (next)
  const buttonFilledHandle = () => {
    if (CustomValidation()) {
      if (
        fetchSummeryData.contractInfoStatus === SummeryStatus.DRAFT ||
        fetchSummeryData.contractInfoStatus ===
        SummeryStatus.REJECTED ||
        fetchSummeryData.contractInfoStatus === SummeryStatus.ACCEPTED
      ) {
        dispatch(
          editContractInfoSave(
            contract_obj,
            createContractMoveingDecideNext
          )
        );
      } else {
        dispatch(
          postContractInfoSave(
            contract_obj,
            createContractMoveingDecideNext
          )
        );
      }
    }
  };

  // get the data and fill up the existing data
  useEffect(() => {
    if (response && response.data && response.data.contractAddress) {
      setContractName(response.data.contractName);
      setSignedby(response.data.contractSignedBy);
      setSignData(response.data.contractSignDate);
      setStartDate(response.data.createdDate);
      setValidDate(response.data.endDate);
      setOrganizationName(response.data.organizationName);
      setAddress(response.data.contractAddress.street);
      setAddressOptional(response.data.contractAddress.optionalPart);
      setCountry(response.data.contractAddress.country.countryCode);
      setZipCode(response.data.contractAddress.zip);
      setDivision(response.data.contractAddress.admin1);
      setCity(response.data.contractAddress.admin3);
      setMunicipility(response.data.contractAddress.admin2);
      setCommunity(response.data.contractAddress.admin4);
      setContractInfoRejectArray(response.data.contractInfoReject);
      setAddressRejectArray(response.data.contractAddress.addressReject);
      setIsLoading(false);
    }
  }, [response]);

  // country fetch
  useEffect(() => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("jwt")}`,
    };

    if(countryCode){
      authAxios
      .get(
        `${PROPERTY_ENDPOINTS.FETCH_ADDRESS_ATTRIBUTE_BY_COUNTRY_CODE
        }${countryCode}?requestId=${localStorage.getItem("id")}`,
        {
          headers: headers,
        }
      )
      .then((response) => {
        console.log("response contry data: ", response.data);
        setApiDivision(response.data.admin1s);
        setApiCity(response.data.admin2s);
        setApiMunicipility(response.data.admin3s);
        setApicomm(response.data.places);
        setApiZips(response.data.zips);
      })
      .catch((error) => {
        console.log(error);
        console.log("error");
      });
    }
  }, [countryCode]);

  // fetch all countries
  useEffect(() => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("jwt")}`,
    };

    authAxios
      .get(
        `${apiEndPoint.GET_COUNTRY_DETAILS}?requestId=${localStorage.getItem(
          "id"
        )}`,
        {
          headers: headers,
        }
      )
      .then((response) => {
        setAllCountries(response.data.countries);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <div className="w-full">
      {withoutHeader ? (
        <></>
      ) : (
        <>
          {process.env.REACT_APP_USER_TYPE === UserType.USER ? (
            <Pagenation />
          ) : null}
          {dashboardOrRegistrationRoute() === RouteType.REGISTRATION && (
            <HorizontalBar selectedBar="Contract" />
          )}
        </>
      )}

      {response.loading ? (
        <div className="ml-[45%]">
          <Loading />
        </div>
      ) : (
        <div className="">

          <div
            className={`px-10  xl:container mx-auto ${process.env.REACT_APP_USER_TYPE === UserType.ADMIN
              ? ""
              : "grid grid-cols-2 gap-28 xl:p-0"
              }`}
          >
            <div className="flex flex-col gap-y-4">
              <h2 className="tracking-wide font-fontFamily-sans  text-[27px] font-normal leading-[37.8px] text-txt-primary">
                Contract Details
              </h2>
              <InputComponent
                required={true}
                inputValue={contractName}
                setInputValue={setContractName}
                placeholder="Contract Name"
                isAdmin={
                  process.env.REACT_APP_USER_TYPE === UserType.ADMIN
                    ? true
                    : false
                }
                isReadOnly={false}
                rejectionArray={contractInfoRejectArray}
                setRejectionArray={setContractInfoRejectArray}
                name="contractName"
              />
              <InputComponent
                required={true}
                inputValue={signedBy}
                setInputValue={setSignedby}
                placeholder="Contract Signed By"
                isAdmin={
                  process.env.REACT_APP_USER_TYPE === UserType.ADMIN
                    ? true
                    : false
                }
                isReadOnly={false}
                rejectionArray={contractInfoRejectArray}
                setRejectionArray={setContractInfoRejectArray}
                name="signedBy"
              />
              <div className="">
                <Calender
                  setSelectedDate={setSignData}
                  selectedData={
                    signDate
                      ? new Date(signDate)
                      : null
                  }
                  placeholder="Contract Sign Date"
                  isAdmin={
                    process.env.REACT_APP_USER_TYPE === UserType.ADMIN
                      ? true
                      : false
                  }
                  isReadOnly={false}
                  rejectionArray={contractInfoRejectArray}
                  setRejectionArray={setContractInfoRejectArray}
                  name="setSignData"
                />
              </div>
              <div className="">
                <Calender
                  setSelectedDate={setStartDate}
                  selectedData={
                    startDate
                      ? new Date(startDate)
                      : null
                  }
                  placeholder="Contract Start Date"
                  isAdmin={
                    process.env.REACT_APP_USER_TYPE === UserType.ADMIN
                      ? true
                      : false
                  }
                  isReadOnly={false}
                  rejectionArray={contractInfoRejectArray}
                  setRejectionArray={setContractInfoRejectArray}
                  name="setStartDate"
                />
              </div>
              <div className="">
                <Calender
                  setSelectedDate={setValidDate}
                  selectedData={
                    validDate
                      ? new Date(validDate)
                      : null
                  }
                  placeholder="Contract Valid Until"
                  isAdmin={
                    process.env.REACT_APP_USER_TYPE === UserType.ADMIN
                      ? true
                      : false
                  }
                  isReadOnly={false}
                  rejectionArray={contractInfoRejectArray}
                  setRejectionArray={setContractInfoRejectArray}
                  name="setValidDate"
                />
              </div>
              <InputComponent
                inputValue={organizationName}
                setInputValue={setOrganizationName}
                placeholder="Organization Name (Optional)"
                isAdmin={
                  process.env.REACT_APP_USER_TYPE === UserType.ADMIN
                    ? true
                    : false
                }
                isReadOnly={false}
                rejectionArray={contractInfoRejectArray}
                setRejectionArray={setContractInfoRejectArray}
                name="organizationName"
              />
              <InputComponent
                inputValue={postOffice}
                setInputValue={setPostOffice}
                placeholder="Post Office (Optional)"
                isAdmin={
                  process.env.REACT_APP_USER_TYPE === UserType.ADMIN
                    ? true
                    : false
                }
                isReadOnly={false}
                rejectionArray={addressRejectArray}
                setRejectionArray={setAddressRejectArray}
                name="postOffice"
              />
              <br></br>
            </div>
            <div className="flex flex-col gap-y-4">
              <h2 className="tracking-wide font-fontFamily-sans  text-[27px] font-normal leading-[37.8px] text-txt-primary">
                Billing Details
              </h2>
              {/* <div className="grid grid-cols-2 gap-4"> */}
              <div
                className={`my-0 ${process.env.REACT_APP_USER_TYPE === UserType.ADMIN
                  ? "grid grid-cols-1 gap-y-4"
                  : "grid grid-cols-2 gap-x-4"
                  }`}
              >
                {allCountries && allCountries.length > 0 && (
                  <SingleSelectParent
                    required={true}
                    selectedValue={countryCode}
                    setSelectedValue={setCountry}
                    data={allCountries}
                    placeholder="Country"
                    displayField={"country"}
                    selectedFieldName="countryCode"
                    isAdmin={
                      process.env.REACT_APP_USER_TYPE === UserType.ADMIN
                        ? true
                        : false
                    }
                    isReadOnly={false}
                    rejectionArray={addressRejectArray}
                    setRejectionArray={setAddressRejectArray}
                    name="countryCode"
                  />
                )}
                <SingleSelectFromSimpleArray
                  required={true}
                  data={apiZips}
                  selectedValue={zipCode}
                  setSelectedValue={setZipCode}
                  placeholder="Zip Code"
                  isAdmin={
                    process.env.REACT_APP_USER_TYPE === UserType.ADMIN
                      ? true
                      : false
                  }
                  isReadOnly={false}
                  rejectionArray={addressRejectArray}
                  setRejectionArray={setAddressRejectArray}
                  name="zip"
                />
              </div>
              <InputComponent
                required={true}
                inputValue={street}
                setInputValue={setAddress}
                placeholder="Address"
                isAdmin={
                  process.env.REACT_APP_USER_TYPE === UserType.ADMIN
                    ? true
                    : false
                }
                isReadOnly={false}
                rejectionArray={addressRejectArray}
                setRejectionArray={setAddressRejectArray}
                name="street"
              />
              <InputComponent
                inputValue={optionalPart}
                setInputValue={setAddressOptional}
                placeholder="Address (optional)"
                isAdmin={
                  process.env.REACT_APP_USER_TYPE === UserType.ADMIN
                    ? true
                    : false
                }
                isReadOnly={false}
                rejectionArray={addressRejectArray}
                setRejectionArray={setAddressRejectArray}
                name="optionalPart"
              />
              <div
                className={`${process.env.REACT_APP_USER_TYPE === UserType.ADMIN
                  ? "grid grid-cols-1 gap-4"
                  : "grid grid-cols-2 gap-4"
                  }`}
              >
                <SingleSelectFromSimpleArray
                  required={true}
                  data={apiDivision}
                  selectedValue={division}
                  setSelectedValue={setDivision}
                  placeholder="Division"
                  isAdmin={
                    process.env.REACT_APP_USER_TYPE === UserType.ADMIN
                      ? true
                      : false
                  }
                  isReadOnly={false}
                  rejectionArray={addressRejectArray}
                  setRejectionArray={setAddressRejectArray}
                  name="admin1"
                />
                <SingleSelectFromSimpleArray
                  // key={Math.floor(Math.random() * (9999 - 1000) + 1000)}
                  required={true}
                  data={apiCity}
                  selectedValue={city}
                  setSelectedValue={setCity}
                  placeholder="City"
                  isAdmin={
                    process.env.REACT_APP_USER_TYPE === UserType.ADMIN
                      ? true
                      : false
                  }
                  isReadOnly={false}
                  rejectionArray={addressRejectArray}
                  setRejectionArray={setAddressRejectArray}
                  name="admin3"
                />
              </div>
              <div className="grid grid-cols-1 w-full">
                <SingleSelectFromSimpleArray
                  required={true}
                  data={apiMunicipility}
                  selectedValue={municipility}
                  setSelectedValue={setMunicipility}
                  placeholder="Municipility"
                  isAdmin={
                    process.env.REACT_APP_USER_TYPE === UserType.ADMIN
                      ? true
                      : false
                  }
                  isReadOnly={false}
                  rejectionArray={addressRejectArray}
                  setRejectionArray={setAddressRejectArray}
                  name="admin2"
                />
              </div>
              <div className="grid grid-cols-1 w-full">
                <SingleSelectFromSimpleArray
                  required={true}
                  data={apicomm}
                  selectedValue={community}
                  setSelectedValue={setCommunity}
                  placeholder="Community"
                  isAdmin={
                    process.env.REACT_APP_USER_TYPE === UserType.ADMIN
                      ? true
                      : false
                  }
                  isReadOnly={false}
                  rejectionArray={addressRejectArray}
                  setRejectionArray={setAddressRejectArray}
                  name="admin4"
                />
              </div>
            </div>

            <div>
              {process.env.REACT_APP_USER_TYPE === UserType.USER ? (
                response.loading ? null : (
                  <div className="flex mt-16 px-10 xl:p-0 xl:container mx-auto">
                    <ButtonOutlined
                      title="Save"
                      buttonOutlinedHandle={buttonOutlinedHandle}
                      w="176px"
                      h="49px"
                      disable={
                        (fetchSummeryData.status === "PENDING") ? "yes" : "no"
                      }
                    />
                    <ButtonFilled
                      title="Next"
                      buttonFilledHandle={buttonFilledHandle}
                      w="291px"
                      h="49px"
                      disable={
                        (fetchSummeryData.status === "PENDING") ? "yes" : "no"
                      }
                    />
                  </div>
                )
              ) : response.loading ? null : (
                <div className="flex justify-center">
                  <ButtonFilled
                    title="Review Submit"
                    buttonFilledHandle={adminReviewSubmit}
                    w="291px"
                    h="49px"
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ContractComponent;
