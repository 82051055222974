import React, { useState, useEffect } from "react";
import ButtonOutlined from "../../ButtonOutlined";
import { useDispatch, useSelector } from "react-redux";
import ModalGlobal from "../../ModalGlobal";

import Loading from "../../Loader/Loading";
import ButtonFilled from "../../ButtonFilled";
import { reviewSubmission } from "../../../store/admin/review/api/reviewApi";
import {
    DashboardRoutes,
    merchantRoute,
    ReviewFor,
    SummeryStatus,
} from "../../../contants/Constants";
import SuccessSVG from "../../warning/SuccessSVG";
import WarningSVG from "../../warning/WarningSVG";
import { fetchSummeryGet } from "../../../store/registration/landingAccessFetchSummery/api/AccessFetchSummeryApi";
import { reviewSubmitDisableDecision } from "../../../utils/reviewSubmitDisableDecision";
import { useNavigate } from "react-router";

const RegistrationLanding = ({}) => {
    let navigate = useNavigate();
    const dispatch = useDispatch();

    // helper state
    const [fetchSummeryCallAgain, setFetchSummeryCallAgain] = useState(
        Math.random()
    );

    const fetchSummeryData = useSelector(
        (state) => state.fetchSummeryReducer.data
    );
    console.log(fetchSummeryData, "fetchSummeryData");

    useEffect(() => {
        if (fetchSummeryData.status === "ACCEPTED") {
            navigate(DashboardRoutes.DASHBOARD);
        }
    }, [fetchSummeryData]);

    useEffect(() => {
        dispatch(fetchSummeryGet(localStorage.getItem("id")));
    }, [fetchSummeryCallAgain]);

    // modal trigger
    const [modalTrigger, setModalTrigger] = useState(false);

    let adminSubmit = useSelector((state) => state.submitReviewReducer);

    const handleRegistrationSubmit = () => {
        dispatch(
            reviewSubmission(
                ReviewFor.SUBMIT,
                localStorage.getItem("contractId"),
                {},
                () => setFetchSummeryCallAgain(Math.random())
            )
        );
    };

    useEffect(() => {
        if (adminSubmit.data && adminSubmit.data.status === 200) {
            setModalTrigger(true);
        }
    }, [adminSubmit]);

    // function support for landing warning
    const landingStatusShow = (summeryData, numberOfIssues) => {
        if (
            fetchSummeryData.status === SummeryStatus.EMPTY ||
            fetchSummeryData.status === SummeryStatus.DRAFT ||
            fetchSummeryData.status === SummeryStatus.PENDING
        ) {
            return null;
        } else {
            if (numberOfIssues === 0) {
                return <SuccessSVG />;
            } else {
                return <WarningSVG />;
            }
        }
    };

    return (
        <div>
            <div className="bg-btn-primary py-4 px-8">
                <p className="text-txt-secondary text-[12px] underline underline-offset-[8px]">
                    Dashboard
                </p>
            </div>
            <div className="min-h-screen flex justify-center">
                {!fetchSummeryData.contractInfoStatus ? (
                    <Loading />
                ) : (
                    <div className="w-1/2 custom-shadow my-20 p-5 flex justify-center rounded-[24px]">
                        <div className="flex flex-col justify-center items-center gap-4">
                            <h3 className="text-[27px] py-[30px] text-center font-fontFamily-sans tracking-wide text-txt-primary">
                                Welcome to Ghuddy Merchant!
                            </h3>
                            <p className="text-center px-[66px] pb-[35px]">
                                Thank you for registration to proceed with the
                                full capability of the portal. Please proceed
                                with the contact creation.
                            </p>
                            {fetchSummeryData.status === "PENDING" ? (
                                <p className="text-center bg-btn-warning px-2 py-2 rounded-xl">
                                    Our team are working on your submission,
                                    Stay in touch!
                                </p>
                            ) : null}
                            <p className="text-center text-[18px] leading-[25px]">
                                How to register hotel:
                            </p>
                            <div className="flex gap-2">
                                <ButtonOutlined
                                    title="Create Owner"
                                    w="253px"
                                    h="49px"
                                    mr="0px"
                                    // buttonOutlinedHandle={() => setPosition("owner-move")}
                                    buttonOutlinedHandle={() =>
                                        navigate(merchantRoute.OWNER)
                                    }
                                />
                                {landingStatusShow(
                                    fetchSummeryData.status,
                                    fetchSummeryData.ownerIssues,
                                    fetchSummeryData.ownerStatus
                                )}
                            </div>
                            <div className="flex gap-2">
                                <ButtonOutlined
                                    title="Create Contract"
                                    w="253px"
                                    h="49px"
                                    mr="0px"
                                    disable={
                                        fetchSummeryData.contractInfoStatus ===
                                        "EMPTY"
                                            ? "yes"
                                            : "no"
                                    }
                                    // buttonOutlinedHandle={() => setPosition("contract-move")}
                                    buttonOutlinedHandle={() =>
                                        navigate(merchantRoute.CONTRACT)
                                    }
                                />
                                {landingStatusShow(
                                    fetchSummeryData.status,
                                    fetchSummeryData.contractInfoIssues,
                                    fetchSummeryData.contractInfoStatus
                                )}
                            </div>
                            <div className="flex gap-2">
                                <ButtonOutlined
                                    title="Update Documents"
                                    w="253px"
                                    h="49px"
                                    mr="0px"
                                    disable={
                                        fetchSummeryData.documentStatus ===
                                        "EMPTY"
                                            ? "yes"
                                            : "no"
                                    }
                                    // buttonOutlinedHandle={() => setPosition("document-move")}
                                    buttonOutlinedHandle={() =>
                                        navigate(merchantRoute.DOCUMENT)
                                    }
                                />
                                {landingStatusShow(
                                    fetchSummeryData.status,
                                    fetchSummeryData.documentIssues,
                                    fetchSummeryData.documentStatus
                                )}
                            </div>
                            <div className="flex gap-2">
                                <ButtonOutlined
                                    title="Create Hotel"
                                    w="253px"
                                    h="49px"
                                    mr="0px"
                                    disable={
                                        fetchSummeryData.propertyStatus ===
                                        "EMPTY"
                                            ? "yes"
                                            : "no"
                                    }
                                    // buttonOutlinedHandle={() => setPosition("add-hotel-move")}
                                    buttonOutlinedHandle={() =>
                                        navigate(merchantRoute.HOTELS)
                                    }
                                />
                                {landingStatusShow(
                                    fetchSummeryData.status,
                                    fetchSummeryData.propertyIssues,
                                    fetchSummeryData.propertyStatus
                                )}
                            </div>
                            <div className="flex gap-2">
                                <ButtonOutlined
                                    title="Create Categories"
                                    w="253px"
                                    h="49px"
                                    mr="0px"
                                    disable={
                                        fetchSummeryData.categoryStatus ===
                                        "EMPTY"
                                            ? "yes"
                                            : "no"
                                    }
                                    // buttonOutlinedHandle={() => setPosition("category-move")}
                                    buttonOutlinedHandle={() =>
                                        navigate(merchantRoute.CATEGORIES)
                                    }
                                />
                                {landingStatusShow(
                                    fetchSummeryData.status,
                                    fetchSummeryData.categoryIssues,
                                    fetchSummeryData.categoryStatus
                                )}
                            </div>
                            <div className="flex gap-2">
                                <ButtonFilled
                                    title="Submit for Review"
                                    w="253px"
                                    h="49px"
                                    arrow="arrow"
                                    ml="0px"
                                    disable={reviewSubmitDisableDecision(
                                        fetchSummeryData.status,
                                        fetchSummeryData.categoryStatus
                                    )}
                                    buttonFilledHandle={
                                        handleRegistrationSubmit
                                    }
                                />
                                {fetchSummeryData.status ===
                                    SummeryStatus.EMPTY ||
                                fetchSummeryData.status ===
                                    SummeryStatus.DRAFT ||
                                fetchSummeryData.status ===
                                    SummeryStatus.PENDING ? null : (
                                    <div className="w-[49px]"></div>
                                )}
                            </div>
                            <ModalGlobal
                                header="The request is submitted successfully"
                                body="Our team will review the submission, and notify you within 72 hours. Stay in touch!"
                                openDefault={modalTrigger}
                            />
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default RegistrationLanding;
