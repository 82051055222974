import React, { useEffect, useRef, useState } from "react";
import ReactOutsideClickHandler from "react-outside-click-handler";
import MultiSelect from "./MultiSelect";
//h-[${height}]
// import '../../components/dropdown/selectscrollbar.css';
import RootComponent from "../rootComponent/RootComponent";
import { customValidationSingle } from "../../utils/customValidation";

function MultiSelectParent({
    data,
    selectedArray,
    setSelectedArray,
    placeholder,
    displayField,
    warningView = true,
    propertyName,
    type,
    warningText,
    isAdmin,
    isReadOnly,
    warningType,
    rejectionArray,
    setRejectionArray,
    name,
    required = false,
    height="49px"
}) {
    const [selectedString, setSelectedString] = React.useState("");
    const [visible, setVisible] = React.useState(false);
    const [touched, setTouched] = React.useState(false);
    const [width, setWidth] = React.useState(0);
    const [colorChange, setColorChange] = useState(false);

    const [inputFieldValue, setInputFieldValue] = useState("");
    useEffect(() => {
        if (!visible) {
            setInputFieldValue("");
        }
    }, [visible]);

    useEffect(() => {
        if (selectedArray.length === 0) {
            setSelectedString("");
            setColorChange(false);
        }
        if (selectedArray.length > 0) {
            let temp = "";
            data.map((i) => {
                if (selectedArray.includes(i.id)) {
                    temp = `${temp} ${
                        i[displayField ? displayField : "label"]
                    }, `;
                }
            });
            setSelectedString(temp);
        }
    }, [selectedArray, data]);

    const submitValues = () => {
        if (selectedArray.length === 0) {
            setSelectedString("");
            setColorChange(false);
        }
        if (selectedArray.length > 0) {
            let temp = "";
            data.map((i) => {
                if (selectedArray.includes(i.id)) {
                    temp = `${temp} ${
                        i[displayField ? displayField : "label"]
                    }, `;
                }
            });
            setSelectedString(temp);
            setColorChange(true);
        }
        setVisible(false);
        required &&
            setTimeout(() => {
                customValidationSingle(name);
            }, 200);
    };

    const ref = useRef(null);
    useEffect(() => {
        setWidth(ref.current ? ref.current.offsetWidth : 0);
    }, [ref.current]);

    const focusHandler = () => {
        const input = document.getElementById(`input${name}`);
        input.focus();
    };

    let component = (
        <ReactOutsideClickHandler
            onOutsideClick={() => {
                setVisible(false);
                touched && submitValues();
            }}
        >
            <div className="w-full flex flex-col rounded-lg relative">
                <div
                    id={name}
                    className={`overflow-hidden inline-block ${
                        required && "required"
                    }`}
                >
                    <button
                        ref={ref}
                        onClick={() => {
                            setVisible(!visible);
                            setTouched(true);
                        }}
                        className={`w-full validation h-[49px] flex items-center`}
                        onKeyUp={(e) => {
                            if (e.keyCode === 32 || e.keyCode === 13) {
                                e.preventDefault();
                            }
                        }}
                    >
                        <div className="cursor-auto text-[#9e9989] md:text-xs text-left validation h-full rounded-[8px] pl-3 flex items-center justify-center border-[#9e9989] border border-r-0 rounded-r-none ">
                            {placeholder && (
                                <span className="w-full whitespace-nowrap">
                                    {placeholder}{required ? "*" : null}:{" "}
                                </span>
                            )}
                        </div>
                        <input
                            autoComplete="off"
                            id={`input${name}`}
                            className={`w-full text-left text-xs pl-4 h-full  bg-[transparent] validation focus:outline-none active:outline-none border-[#9e9989] border border-l-0 rounded-l-none border-r-0 ${
                                colorChange
                                    ? "text-txt-primary"
                                    : "text-txt-hint"
                            } `}
                            value={visible ? inputFieldValue : selectedString}
                            onClick={(e) => e.preventDefault()}
                            onChange={(e) => {
                                setInputFieldValue(e.target.value);
                            }}
                            onKeyUp={(e) => {
                                if (e.keyCode === 32 || e.keyCode === 13) {
                                    e.preventDefault();
                                }
                            }}
                            onBlur={() => {
                                required && customValidationSingle(name);
                            }}
                        />

                        <div className="validation bg-btn-primary ml-auto py-4 w-[49px] h-full rounded-r-[8px] rounded-l-none border border-l-0 border-[#314747] px-5 hover:bg-btn-secondary duration-500 flex justify-center items-center">
                            <svg
                                width="12"
                                height="12"
                                viewBox="0 0 12 12"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M2 4.5L6 8.5L10 4.5"
                                    stroke="#FFFBEF"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>
                        </div>
                    </button>
                    <div className="errorMessage validationError"></div>
                </div>
                {visible && (
                    <div
                        className={`pt-10 rounded-[32px] overflow-hidden mt-16 bg-bg-primary custom-shadow z-50 absolute w-full`}
                        // style={{ width: width }}
                    >
                        <div
                            id="scroll-x"
                            className="h-auto max-h-[190px] px-6 overflow-y-auto"
                        >
                            {data && data.length
                                ? data
                                      .filter((item) => {
                                          return item[
                                              displayField
                                                  ? displayField
                                                  : "label"
                                          ]
                                              .toLowerCase()
                                              .includes(
                                                  inputFieldValue.toLowerCase()
                                              );
                                      })
                                      .map((item, index) => {
                                          return (
                                              // <MultiSelect
                                              //   setInputFieldValue={setInputFieldValue}
                                              //   displayField={displayField}
                                              //   key={index}
                                              //   selectedArray={selectedArray}
                                              //   setSelectedArray={setSelectedArray}
                                              //   item={item}
                                              //   data={data}
                                              //   focusHandler={() => { }}
                                              // />

                                              <MultiSelect
                                                  setInputFieldValue={
                                                      setInputFieldValue
                                                  }
                                                  displayField={displayField}
                                                  key={index}
                                                  selectedArray={selectedArray}
                                                  setSelectedArray={
                                                      setSelectedArray
                                                  }
                                                  item={item}
                                                  data={data}
                                                  focusHandler={focusHandler}
                                              />
                                          );
                                      })
                                : null}
                        </div>
                        {visible && (
                            <button
                                onClick={() => {
                                    submitValues();
                                }}
                                className="w-full py-2 bg-btn-primary rounded-[32px] h-[65px] text-bg-white hover:bg-btn-secondary active:bg-txt-secondary active:border active:border-btn-primary active:text-btn-primary"
                            >
                                Submit
                            </button>
                        )}
                    </div>
                )}
            </div>
        </ReactOutsideClickHandler>
    );
    let readOnlyViewComponent = <p>{inputFieldValue}</p>;
    return (
        <div className="w-full">
            <RootComponent
                component={component}
                readOnlyViewComponent={readOnlyViewComponent}
                warningText={warningText}
                warningType={warningType}
                isAdmin={isAdmin}
                warningView={warningView}
                isReadOnly={isReadOnly}
                rejectionArray={rejectionArray}
                setRejectionArray={setRejectionArray}
                name={name}
            />
        </div>
    );
}

export default MultiSelectParent;
