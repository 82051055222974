import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router";
import { toast } from "react-toastify";
import InputComponent from "../../../../components/InputComponent";
import SingleSelectParentReturnObject from "../../../../components/singleSelect/singleSelectObjectReturn/SingleSelectParentReturnObject";
import { adminRoute } from "../../../../contants/Constants";
import { apiEndPoint, baseUrl } from "../../../../contants/Endpoints";
import { authAxios } from "../../../../utils/axiosWrapper";
import CommonHotelSpeciality from "../../CommonAllHotelTable/CommonHotelSpeciality";

const AdminHotelSpecialityTool = () => {
    const navigate = useNavigate()
    const [hotelData, setHotelData] = useState([]);
    const [iconsList, setIconsList] = useState([]);
    const [selectedIcon, setSelectedIcon] = useState(null);
    const [selectedIcon2, setSelectedIcon2] = useState(null);
    const [speciality, setSpeciality] = useState([]);
    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const [propertyHotel, setPropertyHotel] = useState();
    const [loadExistingSpecility, setLoadExistingSpecility] = useState(false)
    const [specialityId, setSpecialityId] = useState("")

    const [enabledInput, setEnabledInput] = useState(false);
    const [enabledArea, setEnabledArea] = useState(false);
    const [singleSpeciality, setSingleSpeciality] = useState({})
    const [isOpen, setIsOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState("");
    const [editedName, setEditedName] = useState("");
    const [editedDescription, setEditedDescription] = useState("");
    const [iconId, setIconId] = useState("");


    useEffect(() => {
        setEditedDescription(singleSpeciality?.description)
        setEditedName(singleSpeciality?.title)
    }, [singleSpeciality])
    console.log("editedDescription", editedDescription)
    let PageSize = 10;
    const [currentPage, setCurrentPage] = useState(1);
    const { pathname } = useLocation();
    const { id } = useParams();
    const idInt = Number(id)
    console.log("id: ", id)

    const getInitialState = () => {
        const value = "Deactivate";
        return value;
    };
    const [value, setValue] = useState(getInitialState);

    const getSpecialtyData = () => {
        if (value === "active") {
            authAxios
                .get(`${baseUrl + apiEndPoint.FETCH_ALL_HOTELS_FOR_ADMIN}?pageNumber=${currentPage}&pageSize=10&requestId=123`)
                .then((res) => {
                    setHotelData(res?.data);
                })
                .catch((err) => { });
        } else {
            authAxios
                .get(`${baseUrl + apiEndPoint.FETCH_PROPERTY_WITHOUT_SPECIALITY}?pageNumber=${currentPage}&pageSize=10&requestId=123`)
                .then((res) => {
                    setHotelData(res?.data);
                })
                .catch((err) => { });
        }
    }

    useEffect(() => {
        getSpecialtyData()
    }, [value, currentPage]);

    // FETCH_ALL_HOTELS_FOR_ADMIN

    useEffect(() => {
        authAxios.get(`${baseUrl + apiEndPoint.ICON_LIST}`)
            .then((res) => {
                setIconsList(res?.data.iconData);
            })
            .catch((err) => {
            });
    }, [])

    useEffect(() => {
        if (typeof id !== "undefined") {
            authAxios.get(`${baseUrl + apiEndPoint.FETCH_PROPERTY_SPECIALITY_BY_ID}/${parseInt(id)}?requestId=123`)
                .then((res) => {
                    setSpeciality(res?.data);
                    console.log("Hotel speciality: ", speciality);
                })
                .catch((err) => {
                    console.log("Could not fetch data");
                })
        }
    }, [id, loadExistingSpecility])


    //fetch single hotel    
    useEffect(() => {
        if (typeof id !== "undefined") {
            authAxios.get(`${baseUrl + apiEndPoint.FETCH_PROPERTY_HOTEL_ID}/${parseInt(id)}?requestId=123`)
                .then((res) => {
                    setPropertyHotel(res?.data);
                })
                .catch((err) => {

                })
        }
    }, [id])

    const resetFields = () => {
        setName("");
        setDescription("")
        setSelectedIcon(null)
        setSelectedIcon2(null)
    }

    const handleSubmitPropertySpeciality = () => {

        if (!description.trim() || !name.trim() || selectedIcon === null) {
            alert('Please fill all the required fields');
            return;
        }
        const iconId = selectedIcon.id;
        const method = {
            description,
            iconId,
            specialityId: 0,
            rentalId: idInt,
            title: name,
        }
        authAxios.post(`${baseUrl}${apiEndPoint.CREATE_UPDATE_SPECIALITY}?requestId=123`, method)
            .then((res) => {
                console.log("response:", res);
                toast.success("Added successfully!");
                resetFields()

            })
            .then(() => {
                window.location.reload()
            })
            .catch((err) => {
                console.log("Error:", err);
                toast.success("Add failed!");
            });
        setLoadExistingSpecility(true);
    }

    const handleSubmitEditedPropertySpeciality = () => {
        console.log("selected icon 3", selectedIcon2);
        if (selectedIcon2 === null) {
            alert('Please set an icon');
            toggleModal()
        }

        if (editedDescription === "") {
            setEditedDescription(singleSpeciality?.description)
        }
        if (editedName === "") {
            setEditedName(singleSpeciality?.title)
        }



        if (editedDescription !== "" && editedName !== "" && selectedIcon2 !== null) {
            console.log("test")
            const iconId = selectedIcon2;
            const method = {
                description: editedDescription,
                iconId,
                specialityId: parseInt(specialityId),
                rentalId: idInt,
                title: editedName,
            }
            authAxios.post(`${baseUrl}${apiEndPoint.CREATE_UPDATE_SPECIALITY}?requestId=123`, method)
                .then((res) => {
                    console.log("response:", res);
                    toast.success("Added successfully!");
                    resetFields()

                })
                .then(() => {
                    window.location.reload()
                })
                .catch((err) => {
                    console.log("Error:", err);
                    toast.success("Add failed!");
                });
        }



        setLoadExistingSpecility(true);
    }

    console.log("speciality id:", specialityId);
    const deleteSpeciality = (specialityId) => {
        console.log("speciality id:", specialityId);
        var confirmDelete = window.confirm("Are you sure you want to delete this item?");

        if (confirmDelete) {
            authAxios.delete(`${baseUrl + apiEndPoint.DELETE_SPECIALITY}/${parseInt(id)}/${parseInt(specialityId)}?requestId=123`)
                .then((res) => {
                    console.log("res: ", res)
                    toast.success("deleted successfully!");
                })
                .then(() => {
                    window.location.reload();
                })
                .catch((err) => {
                    console.log("Error:", err);
                    toast.success("delete failed!");
                });
        }
        else {
            return;
        }
        return;

    }
    const toggleModal = () => {
        document.getElementById('modal').classList.toggle('hidden')
    }

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const handleOptionClick = (option) => {
        setSelectedOption(option.title);
        setSelectedIcon2(option.id)
        setIsOpen(false);
    };
    // fetch single speciality for default value
    useEffect(() => {
        fetchSingleSpeciality()
    }, [specialityId])

    const fetchSingleSpeciality = () => {
        const fetchedId = speciality?.rentalPropertySpecialityDataList?.find(special => special.specialityId === specialityId)
        setSingleSpeciality(fetchedId)
        console.log("Called..");

    }
    console.log("Single Speciality", singleSpeciality);
    // const [newIcon, setNewIcon] = useState("")

    useEffect(() => {
        fetchSingleIcon()
    }, [iconId])

    const fetchSingleIcon = () => {

        const fetchedIcon = iconsList?.find(icon => icon.id === iconId)
        setSelectedOption(fetchedIcon?.title)
        setSelectedIcon2(fetchedIcon?.id)
        // setNewIcon(fetchedIcon)
    }
    // console.log("New Icon ID:", newIcon);

    return (
        <>
            <div className="px-[16px]  min-h-screen pb-[20px]">
                <div className="max-w-[1100px]  mx-auto">
                    <h2 className="text-center text-[22px] pt-[30px]">
                        Set Hotel Speciality Tool
                    </h2>

                    {
                        pathname === "/admin/hotel_speciality" ? <CommonHotelSpeciality
                            currentPage={currentPage}
                            totalCount={hotelData?.totalPages}
                            pageSize={PageSize}
                            currentData={hotelData}
                            setCurrentPage={setCurrentPage}
                            value={value}
                            setValue={setValue}
                        /> : <>
                            <div className="mt-[10px]">
                                <div title="back to all hotel" className="cursor-pointer">
                                    <p onClick={() => navigate(adminRoute.ADMIN_SET_HOTEL_SPECIALITY)}>
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            strokeWidth={1.5}
                                            stroke="currentColor"
                                            className="w-6 h-6"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M15.75 19.5L8.25 12l7.5-7.5"
                                            />
                                        </svg>
                                    </p>
                                </div>
                                <h1 className=" text-center">{
                                    propertyHotel?.propertyData?.propertyName
                                }</h1>
                                <div className="flex sticky top-[100px] justify-between items-center text-start text-left mt-[30px] border text-bg-white border-btn-secondary bg-btn-primary">
                                    <p className="w-full px-[6px] text-[16px] text-center">Set Specialities</p>
                                    <div className="w-[1px] h-[40px] bg-bg-primary"></div>
                                    <p className="w-full text-[16px] pl-[10px] text-center">
                                        Existing Specialities
                                    </p>
                                </div>
                                {/* all Specialities */}
                                <div className="flex justify-between items-start h-[450px]  border-b border-l border-r border-btn-primary">
                                    <div className="w-full overflow-y-scroll h-[450px] relative">
                                        <div className="absolute left-0 w-[50%] h-full flex m">
                                            <div className="w-[70%]">
                                                <div className="px-[5px] pt-[10px] w-full">
                                                    <p className="pb-[5px]">Speciality name:</p>
                                                    <InputComponent
                                                        warningView={false}
                                                        required={true}
                                                        inputValue={name}
                                                        setInputValue={setName}
                                                        isReadOnly={false}
                                                        placeholder="Speciality"
                                                        name="specialityName"
                                                    />
                                                </div>
                                                <div className="px-[5px] pt-[10px] w-full">
                                                    <p className="pb-[5px]">Speciality description:</p>
                                                    <textarea
                                                        id="specialityDesc"
                                                        name="description"
                                                        placeholder="Specilaity description"
                                                        value={description}
                                                        onChange={(e) => {
                                                            setDescription(
                                                                e.target.value
                                                            );
                                                        }}
                                                        className="resize-none w-[91.5%] validation h-[12rem] align-text-top bg-bg-primary border border-[#9e9989] rounded-[0.8rem]  outline-none p-4 pt-[20px] "
                                                    />
                                                </div>
                                            </div>
                                            <div className=" pt-[10px]">
                                                <p className="pb-[5px]">Select speciality icons:</p>
                                                <SingleSelectParentReturnObject
                                                    warningView={false}
                                                    data={iconsList}
                                                    displayField="title"
                                                    placeholder={"Icons"}
                                                    selectedFieldName={"title"}
                                                    selectedValue={selectedIcon}
                                                    setSelectedValue={setSelectedIcon}
                                                    setSelectedObject={() => { }}
                                                    image={true}
                                                />
                                            </div>
                                        </div>
                                        <div className="left-[50%] absolute w-[1px] h-full bg-txt-black"></div>
                                        <div className="absolute w-[50%] right-0 h-full overflow-scroll overflow-x-scroll">
                                            <div className="w-full">
                                                {speciality?.rentalPropertySpecialityDataList?.length >= 1 && speciality?.rentalPropertySpecialityDataList?.map((speciality, index) => (
                                                    <div className="p-[1rem] shadow-lg">
                                                        <div className="pb-[5px]"><img src={speciality.iconUrl} alt="" width={40} height={40} /></div>
                                                        <div className="pb-[5px] flex">
                                                            <p className="pr-[5px] font-bold">Name:</p>
                                                            <p>{speciality.title}</p>
                                                        </div>
                                                        <div className="flex max-w-[70rem]">
                                                            <p className="pr-[5px] font-bold">Description:</p>
                                                            <p className="">{speciality.description}</p>
                                                        </div>
                                                        <div className="flex justify-between mt-5">
                                                            <div className=" cursor-pointer" onClick={() => {
                                                                deleteSpeciality(speciality.specialityId)
                                                            }}>
                                                                <img
                                                                    src="/delete-speciality.svg"
                                                                    alt=""
                                                                    className="h-[24px] w-[24px]"
                                                                />
                                                            </div>
                                                            <div className="cursor-pointer" onClick={() => {
                                                                setSpecialityId(speciality.specialityId)
                                                                setIconId(speciality.iconId)
                                                                fetchSingleSpeciality()
                                                                fetchSingleIcon()
                                                                toggleModal()
                                                            }}>
                                                                <img
                                                                    src="/edit-speciality.svg"
                                                                    alt=""
                                                                    className="h-[24px] w-[24px]"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <button onClick={() => handleSubmitPropertySpeciality()} className=" flex justify-center text-bg-white bg-btn-secondary px-[6px] py-[4px] rounded-lg w-[160px] mx-auto mt-[60px]">
                                    Submit
                                </button>
                            </div>
                        </>
                    }
                </div>
                <div className="fixed z-30 overflow-y-auto top-0 w-full left-0 hidden" id="modal">
                    <div className="flex items-center justify-center pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                        <div className="fixed inset-0 transition-opacity">
                            <div className=" inset-0 bg-gray-900 opacity-75" />
                        </div>
                        <span className="hidden sm:inline-block sm:align-middle sm:h-screen">&#8203;</span>
                        <div className="inline-block align-center bg-white rounded-lg text-left shadow-[-3px_-2px_153px_74px_rgba(0,0,0,0.62);] transform transition-all sm:my-8 sm:align-middle sm:max-w-[55rem] sm:w-full bg-bg-primary" role="dialog" aria-modal="true" aria-labelledby="modal-headline">
                            <div className=" w-full h-full flex m">
                                <div className="w-[70%]">
                                    <div className="px-[5px] pt-[10px] w-full">
                                        <p className="pb-[5px]">Speciality name</p>
                                        <input
                                            className="shadow appearance-none  w-[90.5%] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline bg-bg-primary border border-[#9e9989] rounded-[0.4rem]"
                                            type="text"
                                            // value={editedName}
                                            defaultValue={singleSpeciality?.title}
                                            onChange={(e) => {
                                                setEditedName(
                                                    e.target.value
                                                );
                                            }}
                                            placeholder="Speciality name"
                                            onInput={() => {
                                                setEnabledInput(true)
                                            }}
                                        />
                                    </div>
                                    <div className="px-[5px] pt-[10px] w-full">
                                        <p className="pb-[5px]">Speciality description:</p>
                                        <textarea
                                            id="specialityDesc"
                                            name="description"
                                            placeholder="Specilaity description"
                                            // value={editedDescription}
                                            defaultValue={singleSpeciality?.description}
                                            onChange={(e) => {
                                                setEditedDescription(
                                                    e.target.value
                                                );
                                            }}
                                            className="resize-none w-[91.5%] validation h-[12rem] align-text-top bg-bg-primary border border-[#9e9989] rounded-[0.8rem]  outline-none p-4 pt-[20px] "
                                            onInput={
                                                () => {
                                                    setEnabledArea(true)
                                                }
                                            }
                                        />
                                    </div>
                                </div>
                                <div className=" pt-[10px]">
                                    <p className="pb-[5px]">Select speciality icons:</p>
                                    <div className="relative inline-block text-left">
                                        <div
                                            onClick={() => {
                                                toggleDropdown()
                                                setEnabledArea(true)
                                                setEnabledInput(true)
                                            }}
                                            className="cursor-pointer bg-white border border-gray-300 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline min-w-[18rem]"
                                        >
                                            {selectedOption || "Select an icon"}
                                            <svg
                                                className={`fill-current h-4 w-4 absolute top-0 right-0 mt-3 mr-4 transition ${isOpen ? "transform rotate-180" : ""
                                                    }`}
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 20 20"
                                            >
                                                <path
                                                    d="M14.95 7.05a.999.999 0 0 0-1.41 0L10 10.59l-3.54-3.54a.999.999 0 1 0-1.41 1.41l4.24 4.24c.39.39 1.02.39 1.41 0l4.24-4.24a.999.999 0 0 0 0-1.41z"
                                                />
                                            </svg>
                                        </div>
                                        {isOpen && (
                                            <div className="absolute z-50 bg-white border border-gray-300 py-2 mt-1 w-full max-h-48 overflow-y-auto rounded shadow">
                                                {iconsList.map((option) => (
                                                    <div
                                                        key={option.id}
                                                        onClick={() => {
                                                            handleOptionClick(option)
                                                        }}
                                                        className="px-4 py-2 cursor-pointer hover:bg-gray-100"
                                                    >
                                                        <img
                                                            src={option.url}
                                                            alt={option.title}
                                                            className="w-6 h-6 mr-2 inline-block"
                                                        />
                                                        <span>{option.title}</span>
                                                    </div>
                                                ))}
                                            </div>
                                        )}
                                    </div>

                                </div>
                            </div>
                            <div className="bg-gray-200 px-4 py-3 text-right">
                                <button type="button" className="py-2 px-4 bg-gray-500 text-white rounded hover:bg-gray-700 mr-2" onClick={() => {
                                    toggleModal()
                                    setEnabledArea(false)
                                    setEnabledInput(false)
                                }}><i className="fas fa-times"></i>Cancel</button>
                                {
                                    ((enabledArea || enabledInput) || selectedOption !== "") && <button onClick={() => {
                                        handleSubmitEditedPropertySpeciality()
                                        toggleModal()
                                    }} className="px-8 py-3 text-white bg-blue-600 rounded focus:outline-none opacity-100"><i className="fas fa-plus"></i>Update</button>
                                }
                                {
                                    ((!enabledArea && !enabledInput) && selectedOption === '') && <button disabled type="button" className="px-8 py-3 text-white bg-blue-600 rounded focus:outline-none disabled:opacity-25"><i className="fas fa-plus"></i>Update</button>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default AdminHotelSpecialityTool;
