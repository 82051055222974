import { SummeryStatus } from "../contants/Constants"

export const reviewSubmitDisableDecision = (status, categoryStatus) => {
    if(status == SummeryStatus.OPEN || status == SummeryStatus.EMPTY ||status == SummeryStatus.PENDING){
        return "yes"
    }else{
        if(categoryStatus == SummeryStatus.EMPTY || categoryStatus == SummeryStatus.OPEN){
            return "yes"
        }else{
            return "no"
        }
    }
}
