import React from "react";
import { useLocation, useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { adminRoute } from "../../contants/Constants";
function Sidebar({ setPosition }) {
  const { pathname } = useLocation();
  let navigate = useNavigate();
  const [rolePermissionIsHidden, setRolePermissionIsHidden] =
    React.useState(true);
  const [showContractMenu, setShowContractMenu] = React.useState(false);
  const [showInvoiceMenu, setShowInvoiceMenu] = React.useState(false);
  const [showBookingMenu, setShowBookingMenu] = React.useState(false);
  const [showHotelMenu, setShowHotelMenu] = React.useState(false);
  const [showToolsMenu, setShowToolsMenu] = React.useState(false);
  const [showTourMenu, setShowTourMenu] = React.useState(false);
  const [showFacilityMenu, setShowFacilityMenu] = React.useState(false);
  const changeRolePermissionVisibility = () => {
    setRolePermissionIsHidden(!rolePermissionIsHidden);
  };

  const checkRoute = (route) => {
    if (window.location.pathname == route) {
      return true;
    }
    return false;
  };

  return (
    <div className="bg-bg-primary fixed h-screen pb-[50px] overflow-scroll shadow-lg">
      <div
        className="w-64 bg-bg-primary h-full overflow-y-scroll"
        aria-label="Sidebar"
      >
        <div className="overflow-y-auto py-4 px-3 bg-gray-50 rounded dark:bg-gray-800">
          <ul className="space-y-2">
            <li>
              <Link
                to={adminRoute.ADMIN_LANDING}
                className={`${checkRoute(adminRoute.ADMIN_LANDING) &&
                  "bg-btn-primary text-bg-primary"
                  } cursor-pointer flex items-center p-2 text-base font-normal text-gray-900  dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700`}
              >
                <svg
                  className="w-6 h-6 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M2 10a8 8 0 018-8v8h8a8 8 0 11-16 0z"></path>
                  <path d="M12 2.252A8.014 8.014 0 0117.748 8H12V2.252z"></path>
                </svg>
                <span className="ml-3">Dashboard</span>
              </Link>
            </li>

            <li>
              <button
                type="button"
                className="cursor-pointer flex items-center p-2 w-full text-base font-normal text-gray-900  transition duration-75 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"
                aria-controls="dropdown-example"
                data-collapse-toggle="dropdown-example"
                onClick={() => {
                  setShowContractMenu(!showContractMenu);
                }}
              >
                <svg
                  className="flex-shrink-0 w-6 h-6 text-gray-500 transition duration-75 group-hover:text-gray-900 dark:text-gray-400 dark:group-hover:text-white"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M10 2a4 4 0 00-4 4v1H5a1 1 0 00-.994.89l-1 9A1 1 0 004 18h12a1 1 0 00.994-1.11l-1-9A1 1 0 0015 7h-1V6a4 4 0 00-4-4zm2 5V6a2 2 0 10-4 0v1h4zm-6 3a1 1 0 112 0 1 1 0 01-2 0zm7-1a1 1 0 100 2 1 1 0 000-2z"
                    clipRule="evenodd"
                  ></path>
                </svg>
                <span
                  className="flex-1 ml-3 text-left whitespace-nowrap"
                  sidebar-toggle-item=""
                >
                  Contracts
                </span>
                <svg
                  sidebar-toggle-item=""
                  className="w-6 h-6"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </button>
              <ul
                id="dropdown-example"
                className={`py-2 space-y-2 ${!showContractMenu ? "hidden" : ""
                  }`}
              >
                <li>
                  <Link
                    to={adminRoute.ADMIN_ALL_CONTRACTS}
                    className={`${checkRoute(adminRoute.ADMIN_ALL_CONTRACTS) &&
                      "bg-btn-primary text-bg-primary"
                      } cursor-pointer flex items-center p-2 pl-11 w-full text-base font-normal text-gray-900  transition duration-75 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700`}
                  >
                    All Contracts
                  </Link>
                </li>
                <li>
                  <Link
                    to={adminRoute.ADMIN_SUBMITTED_CONTRACTS}
                    className={`${checkRoute(adminRoute.ADMIN_SUBMITTED_CONTRACTS) &&
                      "bg-btn-primary text-bg-primary"
                      } flex items-center p-2 pl-11 w-full text-base font-normal text-gray-900  transition duration-75 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700`}
                  >
                    Review Contracts
                  </Link>
                </li>
              </ul>
            </li>
            <li>
              <button
                type="button"
                className="cursor-pointer flex items-center p-2 w-full text-base font-normal text-gray-900  transition duration-75 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"
                aria-controls="dropdown-example"
                data-collapse-toggle="dropdown-example"
                onClick={() => {
                  setShowInvoiceMenu(!showInvoiceMenu);
                }}
              >
                <svg
                  className="flex-shrink-0 w-6 h-6 text-gray-500 transition duration-75 group-hover:text-gray-900 dark:text-gray-400 dark:group-hover:text-white"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M10 2a4 4 0 00-4 4v1H5a1 1 0 00-.994.89l-1 9A1 1 0 004 18h12a1 1 0 00.994-1.11l-1-9A1 1 0 0015 7h-1V6a4 4 0 00-4-4zm2 5V6a2 2 0 10-4 0v1h4zm-6 3a1 1 0 112 0 1 1 0 01-2 0zm7-1a1 1 0 100 2 1 1 0 000-2z"
                    clipRule="evenodd"
                  ></path>
                </svg>
                <span
                  className="flex-1 ml-3 text-left whitespace-nowrap"
                  sidebar-toggle-item=""
                >
                  Invoice tool
                </span>
                <svg
                  sidebar-toggle-item=""
                  className="w-6 h-6"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </button>
              <ul
                id="dropdown-example"
                className={`py-2 space-y-2 ${!showInvoiceMenu ? "hidden" : ""}`}
              >
                <li>
                  <Link
                    to={adminRoute.ADMIN_ALL_INVOICE_RECORDS}
                    className={`${checkRoute(adminRoute.ADMIN_ALL_INVOICE_RECORDS) &&
                      "bg-btn-primary text-bg-primary"
                      } cursor-pointer flex items-center p-2 pl-11 w-full text-base font-normal text-gray-900  transition duration-75 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700`}
                  >
                    All Invoice Records
                  </Link>
                </li>
                <li>
                  <Link
                    to={adminRoute.ADMIN_CREATE_INVOICE}
                    className={`${checkRoute(adminRoute.ADMIN_CREATE_INVOICE) &&
                      "bg-btn-primary text-bg-primary"
                      } flex items-center p-2 pl-11 w-full text-base font-normal text-gray-900  transition duration-75 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700`}
                  >
                    Create new Invoice
                  </Link>
                </li>
                {/* <li>
                                    <Link
                                        to={adminRoute.ADMIN_CREATE_PAYMENT_SLIP}
                                        className={`${
                                            checkRoute(
                                                adminRoute.ADMIN_CREATE_PAYMENT_SLIP
                                            ) &&
                                            "bg-btn-primary text-bg-primary"
                                        } flex items-center p-2 pl-11 w-full text-base font-normal text-gray-900  transition duration-75 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700`}
                                    >
                                        Create Payment Slip
                                    </Link>
                                </li> */}

                <li>
                  <Link
                    to={adminRoute.ADMIN_CREATE_PAYMENT_METHOD}
                    className={`${checkRoute(adminRoute.ADMIN_CREATE_PAYMENT_METHOD) &&
                      "bg-btn-primary text-bg-primary"
                      } flex items-center p-2 pl-11 w-full text-base font-normal text-gray-900  transition duration-75 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700`}
                  >
                    Create Payment Method
                  </Link>
                </li>
                <li>
                  <Link
                    to={adminRoute.ADMIN_VERIFY_PAYMENTS}
                    className={`${checkRoute(adminRoute.ADMIN_VERIFY_PAYMENTS) &&
                      "bg-btn-primary text-bg-primary"
                      } flex items-center p-2 pl-11 w-full text-base font-normal text-gray-900  transition duration-75 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700`}
                  >
                    Verify Payment
                  </Link>
                </li>
              </ul>
            </li>
            <li>
              <button
                type="button"
                className="cursor-pointer flex items-center p-2 w-full text-base font-normal text-gray-900  transition duration-75 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"
                aria-controls="dropdown-example"
                data-collapse-toggle="dropdown-example"
                onClick={() => {
                  setShowInvoiceMenu(!showInvoiceMenu);
                }}
              >
                <svg
                  className="flex-shrink-0 w-6 h-6 text-gray-500 transition duration-75 group-hover:text-gray-900 dark:text-gray-400 dark:group-hover:text-white"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M10 2a4 4 0 00-4 4v1H5a1 1 0 00-.994.89l-1 9A1 1 0 004 18h12a1 1 0 00.994-1.11l-1-9A1 1 0 0015 7h-1V6a4 4 0 00-4-4zm2 5V6a2 2 0 10-4 0v1h4zm-6 3a1 1 0 112 0 1 1 0 01-2 0zm7-1a1 1 0 100 2 1 1 0 000-2z"
                    clipRule="evenodd"
                  ></path>
                </svg>
                <span
                  className="flex-1 ml-3 text-left whitespace-nowrap"
                  sidebar-toggle-item=""
                >
                  Invoices
                </span>
                <svg
                  sidebar-toggle-item=""
                  className="w-6 h-6"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </button>
              <ul
                id="dropdown-example"
                className={`py-2 space-y-2 ${!showInvoiceMenu ? "hidden" : ""}`}
              >
                <li>
                  <Link
                    to={adminRoute.ADMIN_ALL_INVOICE}
                    className={`${checkRoute(adminRoute.ADMIN_ALL_INVOICE) &&
                      "bg-btn-primary text-bg-primary"
                      } cursor-pointer flex items-center p-2 pl-11 w-full text-base font-normal text-gray-900  transition duration-75 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700`}
                  >
                    All Invoices
                  </Link>
                </li>
                <li>
                  <Link
                    to={adminRoute.ADMIN_INVOICE_TOOL}
                    className={`${checkRoute(adminRoute.ADMIN_INVOICE_TOOL) &&
                      "bg-btn-primary text-bg-primary"
                      } flex items-center p-2 pl-11 w-full text-base font-normal text-gray-900  transition duration-75 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700`}
                  >
                    Create Invoice
                  </Link>
                </li>
              </ul>
            </li>
            <li>
              <button
                type="button"
                className="cursor-pointer flex items-center p-2 w-full text-base font-normal text-gray-900  transition duration-75 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"
                aria-controls="dropdown-example"
                data-collapse-toggle="dropdown-example"
                onClick={() => {
                  setShowBookingMenu(!showBookingMenu);
                }}
              >
                <svg
                  className="flex-shrink-0 w-6 h-6 text-gray-500 transition duration-75 group-hover:text-gray-900 dark:text-gray-400 dark:group-hover:text-white"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M10 2a4 4 0 00-4 4v1H5a1 1 0 00-.994.89l-1 9A1 1 0 004 18h12a1 1 0 00.994-1.11l-1-9A1 1 0 0015 7h-1V6a4 4 0 00-4-4zm2 5V6a2 2 0 10-4 0v1h4zm-6 3a1 1 0 112 0 1 1 0 01-2 0zm7-1a1 1 0 100 2 1 1 0 000-2z"
                    clipRule="evenodd"
                  ></path>
                </svg>
                <span
                  className="flex-1 ml-3 text-left whitespace-nowrap"
                  sidebar-toggle-item=""
                >
                  Bookings
                </span>
                <svg
                  sidebar-toggle-item=""
                  className="w-6 h-6"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </button>
              <ul
                id="dropdown-example"
                className={`py-2 space-y-2 ${!showBookingMenu ? "hidden" : ""}`}
              >
                <li>
                  <Link
                    to={adminRoute.ADMIN_DIRECT_BOOKINGS}
                    className={`${checkRoute(adminRoute.ADMIN_DIRECT_BOOKINGS) &&
                      "bg-btn-primary text-bg-primary"
                      } cursor-pointer flex items-center p-2 pl-11 w-full text-base font-normal text-gray-900  transition duration-75 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700`}
                  >
                    Direct Bookings
                  </Link>
                </li>
                <li>
                  <Link
                    to={adminRoute.ADMIN_NON_EXPRESS_BOOKINGS}
                    className={`${checkRoute(adminRoute.ADMIN_NON_EXPRESS_BOOKINGS) &&
                      "bg-btn-primary text-bg-primary"
                      } cursor-pointer flex items-center p-2 pl-11 w-full text-base font-normal text-gray-900  transition duration-75 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700`}
                  >
                    Non Express Bookings
                  </Link>
                </li>
                <li>
                  <Link
                    to={adminRoute.ADMIN_BOOKINGS}
                    className={`${checkRoute(adminRoute.ADMIN_BOOKINGS) &&
                      "bg-btn-primary text-bg-primary"
                      } cursor-pointer flex items-center p-2 pl-11 w-full text-base font-normal text-gray-900  transition duration-75 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700`}
                  >
                    All Bookings
                  </Link>
                </li>
                <li>
                  <Link
                    to={adminRoute.ADMIN_REJECTIONS_BOOKINGS}
                    className={`${checkRoute(adminRoute.ADMIN_REJECTIONS_BOOKINGS) &&
                      "bg-btn-primary text-bg-primary"
                      } flex items-center p-2 pl-11 w-full text-base font-normal text-gray-900  transition duration-75 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700`}
                  >
                    Review Rejections
                  </Link>
                </li>
                <li>
                  <Link
                    to={adminRoute.ADMIN_CANCELLATION_BOOKINGS}
                    className={`${checkRoute(adminRoute.ADMIN_CANCELLATION_BOOKINGS) &&
                      "bg-btn-primary text-bg-primary"
                      } flex items-center p-2 pl-11 w-full text-base font-normal text-gray-900  transition duration-75 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700`}
                  >
                    Review Cancellations
                  </Link>
                </li>
              </ul>
            </li>
            <li>
              <button
                type="button"
                className="cursor-pointer flex items-center p-2 w-full text-base font-normal text-gray-900  transition duration-75 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"
                aria-controls="dropdown-example"
                data-collapse-toggle="dropdown-example"
                onClick={() => {
                  setShowHotelMenu(!showHotelMenu);
                }}
              >
                <svg
                  className="flex-shrink-0 w-6 h-6 text-gray-500 transition duration-75 group-hover:text-gray-900 dark:text-gray-400 dark:group-hover:text-white"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M10 2a4 4 0 00-4 4v1H5a1 1 0 00-.994.89l-1 9A1 1 0 004 18h12a1 1 0 00.994-1.11l-1-9A1 1 0 0015 7h-1V6a4 4 0 00-4-4zm2 5V6a2 2 0 10-4 0v1h4zm-6 3a1 1 0 112 0 1 1 0 01-2 0zm7-1a1 1 0 100 2 1 1 0 000-2z"
                    clipRule="evenodd"
                  ></path>
                </svg>
                <span
                  className="flex-1 ml-3 text-left whitespace-nowrap"
                  sidebar-toggle-item=""
                >
                  Hotels
                </span>
                <svg
                  sidebar-toggle-item=""
                  className="w-6 h-6"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </button>
              <ul
                id="dropdown-example"
                className={`py-2 space-y-2 ${!showHotelMenu ? "hidden" : ""}`}
              >
                <li>
                  <Link
                    to={adminRoute.ADMIN_HOTEL_DETAILS}
                    className={`${checkRoute(adminRoute.ADMIN_HOTEL_DETAILS) &&
                      "bg-btn-primary text-bg-primary"
                      } cursor-pointer flex items-center p-2 pl-11 w-full text-base font-normal text-gray-900  transition duration-75 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700`}
                  >
                    Hotel Details
                  </Link>
                </li>
                <li>
                  <Link
                    to={adminRoute.ADMIN_SYNC_HOTEL}
                    className={`${checkRoute(adminRoute.ADMIN_SYNC_HOTEL) &&
                      "bg-btn-primary text-bg-primary"
                      } cursor-pointer flex items-center p-2 pl-11 w-full text-base font-normal text-gray-900  transition duration-75 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700`}
                  >
                    Sync Hotel
                  </Link>
                </li>
                {/* rasel */}
                <li>
                  <Link
                    to={adminRoute.ADMIN_THUMB_IMAGE}
                    className={`${checkRoute(adminRoute.ADMIN_THUMB_IMAGE) &&
                      "bg-btn-primary text-bg-primary"
                      } cursor-pointer flex items-center p-2 pl-11 w-full text-base font-normal text-gray-900  transition duration-75 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700`}
                  >
                    Add Thumb Image
                  </Link>
                </li>

                <li>
                  <Link
                    to={adminRoute.ADMIN_FETCH_ALL_HOTEL_FACILITY}
                    className={`${checkRoute(adminRoute.ADMIN_FETCH_ALL_HOTEL_FACILITY) &&
                      "bg-btn-primary text-bg-primary"
                      } cursor-pointer flex items-center p-2 pl-11 w-full text-base font-normal text-gray-900  transition duration-75 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700`}
                  >
                    Set display Facilities
                  </Link>
                </li>

                <li>
                  <Link
                    to={adminRoute.ADMIN_SET_HOTEL_SPECIALITY}
                    className={`${checkRoute(adminRoute.ADMIN_SET_HOTEL_SPECIALITY) &&
                      "bg-btn-primary text-bg-primary"
                      } cursor-pointer flex items-center p-2 pl-11 w-full text-base font-normal text-gray-900  transition duration-75 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700`}
                  >
                    Set Speciality
                  </Link>
                </li>
              </ul>
            </li>
            <li>
              <button
                type="button"
                className="cursor-pointer flex items-center p-2 w-full text-base font-normal text-gray-900  transition duration-75 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"
                aria-controls="dropdown-example"
                data-collapse-toggle="dropdown-example"
                onClick={() => {
                  setShowToolsMenu(!showToolsMenu);
                }}
              >
                <svg
                  className="flex-shrink-0 w-6 h-6 text-gray-500 transition duration-75 group-hover:text-gray-900 dark:text-gray-400 dark:group-hover:text-white"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M10 2a4 4 0 00-4 4v1H5a1 1 0 00-.994.89l-1 9A1 1 0 004 18h12a1 1 0 00.994-1.11l-1-9A1 1 0 0015 7h-1V6a4 4 0 00-4-4zm2 5V6a2 2 0 10-4 0v1h4zm-6 3a1 1 0 112 0 1 1 0 01-2 0zm7-1a1 1 0 100 2 1 1 0 000-2z"
                    clipRule="evenodd"
                  ></path>
                </svg>
                <span
                  className="flex-1 ml-3 text-left whitespace-nowrap"
                  sidebar-toggle-item=""
                >
                  Price tools
                </span>
                <svg
                  sidebar-toggle-item=""
                  className="w-6 h-6"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </button>
              <ul
                id="dropdown-example"
                className={`py-2 space-y-2 ${!showToolsMenu ? "hidden" : ""}`}
              >
                <li>
                  <Link
                    to={adminRoute.ADMIN_SET_HOTEL_PRICE}
                    className={`${checkRoute(adminRoute.ADMIN_SET_HOTEL_PRICE) &&
                      "bg-btn-primary text-bg-primary"
                      } cursor-pointer flex items-center p-2 pl-11 w-full text-base font-normal text-gray-900  transition duration-75 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700`}
                  >
                    Set Hotel Price
                  </Link>
                </li>
                <li>
                  <Link
                    to={adminRoute.ADMIN_CALCULATOR}
                    className={`${checkRoute(adminRoute.ADMIN_CALCULATOR) &&
                      "bg-btn-primary text-bg-primary"
                      } cursor-pointer flex items-center p-2 pl-11 w-full text-base font-normal text-gray-900  transition duration-75 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700`}
                  >
                    Price Calculator
                  </Link>
                </li>
                <li>
                  <Link
                    to={adminRoute.ADMIN_DEAL_TOOL}
                    className={`${checkRoute(adminRoute.ADMIN_DEAL_TOOL) &&
                      "bg-btn-primary text-bg-primary"
                      } cursor-pointer flex items-center p-2 pl-11 w-full text-base font-normal text-gray-900  transition duration-75 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700`}
                  >
                    Deals tool
                  </Link>
                </li>
              </ul>
            </li>

            {/* ifcode */}
            <li>
              <Link
                to={adminRoute.ADMIN_COUPON_MANAGE}
                className={`${checkRoute(adminRoute.ADMIN_COUPON_MANAGE) &&
                  "bg-btn-primary text-bg-primary"
                  } cursor-pointer flex items-center p-2 text-base font-normal text-gray-900  dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700`}
              >
                <svg
                  className="w-6 h-6 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M2 10a8 8 0 018-8v8h8a8 8 0 11-16 0z"></path>
                  <path d="M12 2.252A8.014 8.014 0 0117.748 8H12V2.252z"></path>
                </svg>
                <span className="ml-3"> Coupons </span>
              </Link>
            </li>

            <li>
              <Link
                to={adminRoute.ADMIN_FACILITY_MANAGE}
                className={`${checkRoute(adminRoute.ADMIN_FACILITY_MANAGE) &&
                  "bg-btn-primary text-bg-primary"
                  } cursor-pointer flex items-center p-2 text-base font-normal text-gray-900  dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700`}
              >
                <svg
                  className="w-6 h-6 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M2 10a8 8 0 018-8v8h8a8 8 0 11-16 0z"></path>
                  <path d="M12 2.252A8.014 8.014 0 0117.748 8H12V2.252z"></path>
                </svg>
                <span className="ml-3"> Facilities </span>
              </Link>
            </li>

            {/* facility toll */}
            <li>
              <button
                type="button"
                className="cursor-pointer flex items-center p-2 w-full text-base font-normal text-gray-900  transition duration-75 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"
                aria-controls="dropdown-example"
                data-collapse-toggle="dropdown-example"
                onClick={() => {
                  setShowFacilityMenu(!showFacilityMenu);
                }}
              >
                <svg
                  className="flex-shrink-0 w-6 h-6 text-gray-500 transition duration-75 group-hover:text-gray-900 dark:text-gray-400 dark:group-hover:text-white"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M10 2a4 4 0 00-4 4v1H5a1 1 0 00-.994.89l-1 9A1 1 0 004 18h12a1 1 0 00.994-1.11l-1-9A1 1 0 0015 7h-1V6a4 4 0 00-4-4zm2 5V6a2 2 0 10-4 0v1h4zm-6 3a1 1 0 112 0 1 1 0 01-2 0zm7-1a1 1 0 100 2 1 1 0 000-2z"
                    clipRule="evenodd"
                  ></path>
                </svg>
                <span
                  className="flex-1 ml-3 text-left whitespace-nowrap"
                  sidebar-toggle-item=""
                >
                  Facility Tool
                </span>
                <svg
                  sidebar-toggle-item=""
                  className="w-6 h-6"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </button>
              <ul
                id="dropdown-example"
                className={`py-2 space-y-2 ${!showFacilityMenu ? "hidden" : ""}`}
              >
                <li>
                  <Link
                    to={adminRoute.ADMIN_PROPERTY_FACILITY_TOOL}
                    className={`${checkRoute(adminRoute.ADMIN_PROPERTY_FACILITY_TOOL) &&
                      "bg-btn-primary text-bg-primary"
                      } cursor-pointer flex items-center p-2 pl-11 w-full text-base font-normal text-gray-900  transition duration-75 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700`}
                  >
                    Property Facility
                  </Link>

                  <Link
                    to={adminRoute.ADMIN_DISPLAY_GROUP_TOOL}
                    className={`${checkRoute(adminRoute.ADMIN_DISPLAY_GROUP_TOOL) &&
                      "bg-btn-primary text-bg-primary"
                      } cursor-pointer flex items-center p-2 pl-11 w-full text-base font-normal text-gray-900  transition duration-75 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700`}
                  >
                    Display Group
                  </Link>

                  <Link
                    to={adminRoute.ADMIN_FACILITY_TYPE}
                    className={`${checkRoute(adminRoute.ADMIN_FACILITY_TYPE) &&
                      "bg-btn-primary text-bg-primary"
                      } cursor-pointer flex items-center p-2 pl-11 w-full text-base font-normal text-gray-900  transition duration-75 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700`}
                  >
                    Property Facility Type
                  </Link>

                  <Link
                    to={adminRoute.ADMIN_UI_FILTER}
                    className={`${checkRoute(adminRoute.ADMIN_UI_FILTER) &&
                      "bg-btn-primary text-bg-primary"
                      } cursor-pointer flex items-center p-2 pl-11 w-full text-base font-normal text-gray-900  transition duration-75 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700`}
                  >
                    UI Filter
                  </Link>

                  <Link
                    to={adminRoute.ADMIN_ROOM_CATEGORY_FILTER}
                    className={`${checkRoute(adminRoute.ADMIN_ROOM_CATEGORY_FILTER) &&
                      "bg-btn-primary text-bg-primary"
                      } cursor-pointer flex items-center p-2 pl-11 w-full text-base font-normal text-gray-900  transition duration-75 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700`}
                  >
                    Room category facility
                  </Link>

                  <Link
                    to={adminRoute.ADMIN_ROOM_CATEGORY_TYPE}
                    className={`${checkRoute(adminRoute.ADMIN_ROOM_CATEGORY_TYPE) &&
                      "bg-btn-primary text-bg-primary"
                      } cursor-pointer flex items-center p-2 pl-11 w-full text-base font-normal text-gray-900  transition duration-75 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700`}
                  >
                    Category facility Type
                  </Link>


                </li>
              </ul>
            </li>
            <li>
              <Link
                to={adminRoute.ADMIN_TOUR_MANAGE}
                className={`${window.location.pathname.includes("tour_management") &&
                  "bg-btn-primary text-bg-primary"
                  } cursor-pointer flex items-center p-2 text-base font-normal text-gray-900  dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700`}
              >
                <svg
                  className="w-6 h-6 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M2 10a8 8 0 018-8v8h8a8 8 0 11-16 0z"></path>
                  <path d="M12 2.252A8.014 8.014 0 0117.748 8H12V2.252z"></path>
                </svg>
                <span className="ml-3"> Tour Management </span>
              </Link>
            </li>
            <li>
              <Link
                to={adminRoute.ADMIN_TOURS}
                className={`${checkRoute(adminRoute.ADMIN_TOURS) &&
                  "bg-btn-primary text-bg-primary"
                  } cursor-pointer flex items-center p-2 text-base font-normal text-gray-900  dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700`}
              >
                <svg
                  className="w-6 h-6 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M2 10a8 8 0 018-8v8h8a8 8 0 11-16 0z"></path>
                  <path d="M12 2.252A8.014 8.014 0 0117.748 8H12V2.252z"></path>
                </svg>
                <span className="ml-3"> Tour Bookings </span>
              </Link>
            </li>
            <li>
              <Link
                to={adminRoute.ADMIN_ICON_MANAGE}
                className={`${checkRoute(adminRoute.ADMIN_ICON_MANAGE) &&
                  "bg-btn-primary text-bg-primary"
                  } cursor-pointer flex items-center p-2 text-base font-normal text-gray-900  dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700`}
              >
                <svg
                  className="w-6 h-6 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M2 10a8 8 0 018-8v8h8a8 8 0 11-16 0z"></path>
                  <path d="M12 2.252A8.014 8.014 0 0117.748 8H12V2.252z"></path>
                </svg>
                <span className="ml-3">Icons and Images</span>
              </Link>
            </li>

            <li>
              <Link
                to={adminRoute.ADMIN_REVIEW_CATEGORY}
                className={`${checkRoute(adminRoute.ADMIN_REVIEW_CATEGORY) &&
                  "bg-btn-primary text-bg-primary"
                  } cursor-pointer flex items-center p-2 text-base font-normal text-gray-900  dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700`}
              >
                <svg
                  className="w-6 h-6 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M2 10a8 8 0 018-8v8h8a8 8 0 11-16 0z"></path>
                  <path d="M12 2.252A8.014 8.014 0 0117.748 8H12V2.252z"></path>
                </svg>
                <span className="ml-3">New Category</span>
              </Link>
            </li>

            <li>
              <Link
                to={adminRoute.ADMIN_INSPIRED_LOCATION}
                className={`${checkRoute(adminRoute.ADMIN_INSPIRED_LOCATION) &&
                  "bg-btn-primary text-bg-primary"
                  } cursor-pointer flex items-center p-2 text-base font-normal text-gray-900  dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700`}
              >
                <svg
                  className="w-6 h-6 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M2 10a8 8 0 018-8v8h8a8 8 0 11-16 0z"></path>
                  <path d="M12 2.252A8.014 8.014 0 0117.748 8H12V2.252z"></path>
                </svg>
                <span className="ml-3">Inspired Locations</span>
              </Link>
            </li>

            {/* ifcode */}
            <li>
              <button
                type="button"
                className="cursor-pointer flex items-center p-2 w-full text-base font-normal text-gray-900  transition duration-75 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"
                aria-controls="dropdown-example"
                data-collapse-toggle="dropdown-example"
                onClick={changeRolePermissionVisibility}
              >
                <svg
                  className="flex-shrink-0 w-6 h-6 text-gray-500 transition duration-75 group-hover:text-gray-900 dark:text-gray-400 dark:group-hover:text-white"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M10 2a4 4 0 00-4 4v1H5a1 1 0 00-.994.89l-1 9A1 1 0 004 18h12a1 1 0 00.994-1.11l-1-9A1 1 0 0015 7h-1V6a4 4 0 00-4-4zm2 5V6a2 2 0 10-4 0v1h4zm-6 3a1 1 0 112 0 1 1 0 01-2 0zm7-1a1 1 0 100 2 1 1 0 000-2z"
                    clipRule="evenodd"
                  ></path>
                </svg>
                <span
                  className="flex-1 ml-3 text-left whitespace-nowrap"
                  sidebar-toggle-item=""
                >
                  Role Permissions
                </span>
                <svg
                  sidebar-toggle-item=""
                  className="w-6 h-6"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </button>
              <ul
                id="dropdown-example"
                className={`py-2 space-y-2 ${rolePermissionIsHidden ? "hidden" : ""
                  }`}
              >
                <li>
                  <Link
                    to={adminRoute.ADMIN_ROLES}
                    className={` cursor-pointer flex items-center p-2 pl-11 w-full text-base font-normal text-gray-900  transition duration-75 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700`}
                  >
                    Roles
                  </Link>
                </li>
                <li>
                  <Link
                    to={adminRoute.ADMIN_PERMISSIONS}
                    className={`flex items-center p-2 pl-11 w-full text-base font-normal text-gray-900  transition duration-75 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700`}
                  >
                    Permissions
                  </Link>
                </li>
                <li>
                  <Link
                    to={adminRoute.ADMIN_PERMISSIONS_ASSIGN}
                    className={`flex items-center p-2 pl-11 w-full text-base font-normal text-gray-900  transition duration-75 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700`}
                  >
                    Assign Permissions
                  </Link>
                </li>
              </ul>
            </li>
            <li>
              <Link
                to={adminRoute.ADMIN_FAQ}
                className={`${checkRoute(adminRoute.ADMIN_FAQ) &&
                  "bg-btn-primary text-bg-primary"
                  } cursor-pointer flex items-center p-2 text-base font-normal text-gray-900  dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700`}
              >
                <svg
                  className="w-6 h-6 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M2 10a8 8 0 018-8v8h8a8 8 0 11-16 0z"></path>
                  <path d="M12 2.252A8.014 8.014 0 0117.748 8H12V2.252z"></path>
                </svg>
                <span className="ml-3">F.A.Q</span>
              </Link>
            </li>
            <li>
              <Link
                to={adminRoute.ADMIN_PAYMENTS}
                className={`${checkRoute(adminRoute.ADMIN_PAYMENTS) &&
                  "bg-btn-primary text-bg-primary"
                  } cursor-pointer flex items-center p-2 text-base font-normal text-gray-900  dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700`}
              >
                <svg
                  className="w-6 h-6 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M2 10a8 8 0 018-8v8h8a8 8 0 11-16 0z"></path>
                  <path d="M12 2.252A8.014 8.014 0 0117.748 8H12V2.252z"></path>
                </svg>
                <span className="ml-3">Payments</span>
              </Link>
            </li>

            <li>
              <Link
                to={adminRoute.ADMIN_TERMS_CONDITIONS}
                className={`${checkRoute(adminRoute.ADMIN_TERMS_CONDITIONS) &&
                  "bg-btn-primary text-bg-primary"
                  } cursor-pointer flex items-center p-2 text-base font-normal text-gray-900  dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700`}
              >
                <svg
                  className="w-6 h-6 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M2 10a8 8 0 018-8v8h8a8 8 0 11-16 0z"></path>
                  <path d="M12 2.252A8.014 8.014 0 0117.748 8H12V2.252z"></path>
                </svg>
                <span className="ml-3">Terms and Conditions</span>
              </Link>
            </li>
            <li>
              <Link
                to={adminRoute.ADMIN_CREATE_USER}
                className={`${checkRoute(adminRoute.ADMIN_CREATE_USER) &&
                  "bg-btn-primary text-bg-primary"
                  } cursor-pointer flex items-center p-2 text-base font-normal text-gray-900  dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700`}
              >
                <svg
                  className="flex-shrink-0 w-6 h-6 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z"
                    clipRule="evenodd"
                  ></path>
                </svg>
                <span className="flex-1 ml-3 whitespace-nowrap">Users</span>
              </Link>
            </li>



            {/* rasel  */}
            {/* <li>
                            <Link
                                to={adminRoute.ADMIN_THUMB_IMAGE}
                                className={`${
                                    ((checkRoute(adminRoute.ADMIN_THUMB_IMAGE)) || (pathname.includes(adminRoute.ADMIN_THUMB_IMAGE))) &&
                                    "bg-btn-primary text-bg-primary"
                                } cursor-pointer flex items-center p-2 text-base font-normal text-gray-900  dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700`}
                            >
                                <svg
                                    className="flex-shrink-0 w-6 h-6 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
                                    fill="currentColor"
                                    viewBox="0 0 20 20"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        fillRule="evenodd"
                                        d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z"
                                        clipRule="evenodd"
                                    ></path>
                                </svg>
                                <span className="flex-1 ml-3 whitespace-nowrap">
                                    Hotel Image
                                </span>
                            </Link>
                        </li>

                        <li>
                            <Link
                                to={adminRoute.FETCH_ALL_HOTEL_FACILITY}
                                className={`${
                                    ((checkRoute(adminRoute.FETCH_ALL_HOTEL_FACILITY)) || (pathname.includes(adminRoute.FETCH_ALL_HOTEL_FACILITY)))
                                     &&
                                    "bg-btn-primary text-bg-primary"
                                } cursor-pointer flex items-center p-2 text-base font-normal text-gray-900  dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700`}
                            >
                                <svg
                                    className="flex-shrink-0 w-6 h-6 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
                                    fill="currentColor"
                                    viewBox="0 0 20 20"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        fillRule="evenodd"
                                        d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z"
                                        clipRule="evenodd"
                                    ></path>
                                </svg>
                                <span className="flex-1 ml-3 whitespace-nowrap">
                                    Hotel Facility
                                </span>
                            </Link>
                        </li> */}

            <li className="mb-[20px]">
              <button
                type="button"
                className="cursor-pointer flex items-center p-2 w-full text-base font-normal text-gray-900  transition duration-75 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"
                aria-controls="dropdown-example"
                data-collapse-toggle="dropdown-example"
                onClick={() => {
                  setShowTourMenu(!showTourMenu);
                }}
              >
                <svg
                  className="flex-shrink-0 w-6 h-6 text-gray-500 transition duration-75 group-hover:text-gray-900 dark:text-gray-400 dark:group-hover:text-white"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M10 2a4 4 0 00-4 4v1H5a1 1 0 00-.994.89l-1 9A1 1 0 004 18h12a1 1 0 00.994-1.11l-1-9A1 1 0 0015 7h-1V6a4 4 0 00-4-4zm2 5V6a2 2 0 10-4 0v1h4zm-6 3a1 1 0 112 0 1 1 0 01-2 0zm7-1a1 1 0 100 2 1 1 0 000-2z"
                    clipRule="evenodd"
                  ></path>
                </svg>
                <span
                  className="flex-1 ml-3 text-left whitespace-nowrap"
                  sidebar-toggle-item=""
                >
                  Tour Management
                </span>
                <svg
                  sidebar-toggle-item=""
                  className="w-6 h-6"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </button>
              <ul
                id="dropdown-example"
                className={`py-2 space-y-2 ${!showTourMenu ? "hidden" : ""}`}
              >
                <li>
                  <Link
                    to={adminRoute.ADMIN_TOUR_CREATE_TOOL}
                    className={`${checkRoute(adminRoute.ADMIN_TOUR_CREATE_TOOL) &&
                      "bg-btn-primary text-bg-primary"
                      } cursor-pointer flex items-center p-2 pl-11 w-full text-base font-normal text-gray-900  transition duration-75 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700`}
                  >
                    Tour Management
                  </Link>
                </li>
              </ul>
            </li>

          </ul>
        </div>
      </div>
    </div>
  );
}
export default Sidebar;
