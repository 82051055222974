import React from "react";
import "@mobiscroll/react/dist/css/mobiscroll.min.css";
import { Datepicker, Button, Page, setOptions } from "@mobiscroll/react";
import "./index.css";
import "./calender.css";
import RootComponent from "./rootComponent/RootComponent";

setOptions({
  theme: "ios",
  themeVariant: "light",
});
function Calender({
  setSelectedDate,
  placeholder,
  selectedData,
  type,
  warningText,
  isAdmin,
  isReadOnly,
  warningType,
  rejectionArray,
  setRejectionArray,
  name,
}) {
  const [isOpen, setIsOpen] = React.useState(false);
  function handleIconClick() {
    document.getElementById(`input${name}`).click();
  }
  const inputProps = {
    className: "md-mobile-picker-input cursor-pointer",
    placeholder: placeholder,
    id: `input${name}`,
  };

  const component = (
    <div id={name} className="bg-bg-primary required w-full">
      <button type="button" className=" border-[#9e9989] validation border h-[50px] rounded-[8px] w-full flex items-center">
        <Datepicker
          controls={["calendar"]}
          inputComponent="input"
          inputProps={inputProps}
          value={selectedData}
          onChange={(event) => {
            setSelectedDate(event.value);
          }}
          // max={new Date()}
        />
        <button
          type="button"
          className="bg-btn-primary w-[49px] h-full rounded-[8px] px-4 hover:bg-btn-secondary duration-500 outline-none"
          onClick={handleIconClick}
        >
          <svg
            width="17"
            height="17"
            viewBox="0 0 17 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12.7498 2.83203H4.24984C2.68503 2.83203 1.4165 4.10056 1.4165 5.66536V12.7487C1.4165 14.3135 2.68503 15.582 4.24984 15.582H12.7498C14.3146 15.582 15.5832 14.3135 15.5832 12.7487V5.66536C15.5832 4.10056 14.3146 2.83203 12.7498 2.83203Z"
              stroke="#FFFBEF"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M5.6665 1.41602V4.24935"
              stroke="#FFFBEF"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M11.333 1.41602V4.24935"
              stroke="#FFFBEF"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M1.4165 7.08203H15.5832"
              stroke="#FFFBEF"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </button>
      </button>
      <div className="errorMessage validationError">
      </div>
    </div>
  );
  let readOnlyViewComponent = <p>{selectedData}</p>;
  return (
    <div className="w-full">
      <RootComponent
        component={component}
        readOnlyViewComponent={readOnlyViewComponent}
        warningText={warningText}
        warningType={warningType}
        isAdmin={isAdmin}
        isReadOnly={isReadOnly}
        rejectionArray={rejectionArray}
        setRejectionArray={setRejectionArray}
        name={name}
      />
    </div>
  );
}

export default Calender;
