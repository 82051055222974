export const S3_BUCKET = "ghuddy-raw-dev";

export const Movement = {
    CREATE_OWNER: "/user/owner/",
    CREATE_CONTRACT: "contract/addinfo/",
    CREATE_HOTEL_PROPERTY: "admin/controller/rentalproperty/",
    CREATE_CATEGORY: "admin/controller/roomcategory/",
    SUBMIT_FOR_REVIEW: "contract/submit/",
    SIGNUP: "open/firebase/signup/",
    SIGNIN: "open/signin/",
    RENTAL_PROPERTY_IMAGE_UPLOAD: "admin/controller/rentalproperty/images/add",
    CATEGORY_IMAGE_UPLOAD: "admin/rentalproperty/category/images/add",
};

export const ReviewFor = {
    OWNER: "OWNER",
    CONTRACT: "CONTRACT",
    DOCUMENT: "DOCUMENT",
    HOTEL: "HOTEL",
    CATEGORY: "CATEGORY",
    SUBMIT: "SUBMIT",
};

export const UserType = {
    ADMIN: "ADMIN",
    USER: "USER",
};

export const SummeryStatus = {
    DRAFT: "DRAFT",
    PENDING: "PENDING",
    OPEN: "OPEN",
    EMPTY: "EMPTY",
    REJECTED: "REJECTED",
    ACCEPTED: "ACCEPTED",
};

export const PositionMove = {
    OWNER: "owner-move",
    CONTRACT: "contract-move",
    DOCUMENT: "document-move",
    HOTEL: "add-hotel-move",
    CATEGORY: "category-move",
    LANDING: "nothing",
};

export const NavbarSelection = {
    DASHBOARD_SELECT: "DASHBOARD_SELECT",
    BOOKING_SELECT: "BOOKING_SELECT",
    HOTEL_SELECT: "HOTEL_SELECT",
    CREW_SELECT: "CREW_SELECT",
    CATEGORY_SELECT: "CATEGORY_SELECT",
};

export const DashboardRoutes = {
    DASHBOARD: "/dashboard",
    HOTELS: "/dashboard/hotels",
    HOTEL_DETAILS: "/dashboard/hotels/details/",
    CATEGORIES: "/dashboard/categories",
    CATEGORIES_DETAILS: "/dashboard/categories/details/",
    BOOKING: "/dashboard/booking",
    CREW: "/dashboard/crew",
    POLICIES: "/dashboard/policies",
};

export const merchantRoute = {
    SIGNUP: "/signup",
    LOGIN: "/login",
    HOME: "/",

    LANDING: "/registration",
    OWNER: "/registration/owner",
    CONTRACT: "/registration/contract-info",
    DOCUMENT: "/registration/document",
    HOTELS: "/registration/hotels",
    HOTEL: "/registration/hotels/",
    CATEGORIES: "/registration/categories",
    CATEGORY: "/registration/categories/",
};

export const adminRoute = {
    ADMIN_LANDING: "/admin",
    ADMIN_ALL_CONTRACTS: "/admin/contracts/all",
    ADMIN_SUBMITTED_CONTRACTS: "/admin/contracts/submitted",
    ADMIN_PAYMENTS: "/admin/payments",
    ADMIN_CALCULATOR: "/admin/price-calculator",
    ADMIN_FAQ: "/admin/faq",
    ADMIN_OWNER: "/admin/owner",
    ADMIN_REVIEW_HOTEL: "/admin/review/hotel",
    ADMIN_INSPIRED_LOCATION: "/admin/inspired-location",
    ADMIN_ADD_LOCATION: "/admin/inspired-location/add",
    ADMIN_REVIEW_CATEGORY: "/admin/review/category",
    ADMIN_ICON_MANAGE: "/admin/icons-images",
    ADMIN_COUPON_MANAGE: "/admin/coupon-manage",
    ADMIN_COUPON_CREATE: "/admin/coupon-manage/create",
    ADMIN_BOOKINGS: "/admin/bookings",
    ADMIN_SYNC_HOTEL: "/admin/sync-hotel",
    ADMIN_CREATE_USER: "/admin/users/create",
    ADMIN_DIRECT_BOOKINGS: "/admin/direct-bookings",
    ADMIN_REJECTIONS_BOOKINGS: "/admin/bookings/rejections",
    ADMIN_HOTEL_LIST: "/admin/hotels",
    ADMIN_HOTEL_DETAILS: "/admin/hotel-details",
    ADMIN_DEAL_TOOL: "/admin/deals-tool",
    ADMIN_ALL_INVOICE: "/admin/all-invoice",
    ADMIN_INVOICE_TOOL: "/admin/invoice-tool",

    ADMIN_CREATE_INVOICE: "/admin/create-invoice",
    ADMIN_ALL_INVOICE_RECORDS: "/admin/all-invoice-records",
    ADMIN_SINGLE_INVOICE_DETAILS: "/admin/invoice-details/",
    ADMIN_CREATE_PAYMENT_SLIP: "/admin/create-payment-slip/",
    ADMIN_CREATE_PAYMENT_METHOD: "/admin/create-payment-method",
    ADMIN_VERIFY_PAYMENTS: "/admin/verify-payments",

    ADMIN_SET_HOTEL_PRICE : "/admin/set-hotel-price",


    ADMIN_CANCELLATION_BOOKINGS: "/admin/bookings/cancellations",
    ADMIN_CONTRACT: "/admin/contarct-info",
    ADMIN_DOCUMENT: "/admin/document",
    ADMIN_HOTELS: "/admin/review/hotels",
    ADMIN_HOTEL: "/admin/review/hotel/",
    ADMIN_CATEGORIES: "/admin/categories",
    ADMIN_CATEGORY: "/admin/category/",
    ADMIN_TERMS_CONDITIONS: "/admin/terms-and-conditions/",
    ADMIN_TERMS_CONDITIONS_CREATE: "/admin/terms-and-conditions/create",
    ADMIN_NON_EXPRESS_BOOKINGS: "/admin/booking/non-express",

    ADMIN_ROLES: "/admin/roles",
    ADMIN_PERMISSIONS: "/admin/permissions",
    ADMIN_PERMISSIONS_ASSIGN: "/admin/permissions/assign",
    ADMIN_FACILITY_MANAGE: "/admin/facility/manage",
    ADMIN_TOURS: "/admin/all-tours",
    ADMIN_TOUR_CREATE_TOOL: "/admin/tour-create",
    ADMIN_TOUR_MANAGE: "/admin/tour_management",
    ADMIN_TOUR_CREATE: "/admin/tour_management/create",
    ADMIN_TOUR_Details_BASE: "/admin/tour_management/details",
    ADMIN_TOUR_Details: "/admin/tour_management/details/:id",
    ADMIN_TOUR_ADD_CATEGORY: "/admin/tour_management/:id/add-category",
    ADMIN_TOUR_EDIT_CATEGORY: "/admin/tour_management/package/:catId/edit",
    ADMIN_PROPERTY_FACILITY_TOOL: "/admin/property-facility",
    ADMIN_DISPLAY_GROUP_TOOL: "/admin/display-group",
    ADMIN_FACILITY_TYPE: "/admin/facility-type",
    ADMIN_UI_FILTER: "/admin/ui-filter",
    ADMIN_ROOM_CATEGORY_FILTER: "/admin/room-category-filter",
    ADMIN_ROOM_CATEGORY_TYPE: "/admin/room-category-type",


    ADMIN_THUMB_IMAGE: "/admin/hotel_data_management/thumbImage",
    ADMIN_THUMB_IMAGE_DETAILS: "/admin/hotel_data_management/thumbImage/:id",
    ADMIN_FETCH_ALL_HOTEL_FACILITY: "/admin/hotel_facility",
    ADMIN_FETCH_ALL_HOTEL_FACILITY_DETAILS: "/admin/hotel_facility/:id",


    ADMIN_SET_HOTEL_SPECIALITY: "/admin/hotel_speciality",
    ADMIN_SET_HOTEL_SPECIALITY_DETAILS: "/admin/hotel_speciality/:id"



};

export const UpdateTypeCategory = {
    DEFAULT: "DEFAULT",
    DATA: "DATA",
    IMAGE: "IMAGE",
};
