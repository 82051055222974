import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import ModalCommon from "../../../components/common/ModalCommon";
import SingleSelectParentReturnObject from "../../../components/singleSelect/singleSelectObjectReturn/SingleSelectParentReturnObject";
import { apiEndPoint, baseUrl } from "../../../contants/Endpoints";
import { authAxios } from "../../../utils/axiosWrapper";
import Paginations from "../Paginations/Paginations";

const DispalayGroup = () => {
  const [modalShown, setToggleModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  let PageSize = 10;
  const [iconsList, setIconsList] = useState([]);
  const [selectedIcon, setSelectedIcon] = useState(null);

  useEffect(() => {
    authAxios
      .get(`${baseUrl + apiEndPoint.ICON_LIST}`)
      .then((res) => {
        setIconsList(res?.data.iconData);
      })
      .catch((err) => {});
  }, []);

  const [tableData, setTableData] = useState([]);
  const [inputData, setInputData] = useState({
    icon: "",
    id: 0,
    label: "",
    value: "",
  });

  const [editCondition, setEditCondition] = useState(true);
  console.log("inputData", inputData);
  const iconUrl = selectedIcon?.url || inputData?.iconUrl;
  const payloadMethod = {
    id: editCondition === true ? 0 : inputData?.displayGroupId,
    label: inputData?.label,
    requestId: "1234",
    url: iconUrl,
    value: inputData?.value,
  };

  const tableRowAdd = () => {
    setInputData({});
    console.log("in", inputData);
    setEditCondition(true);
    setToggleModal(!modalShown);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setInputData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleEdit = (id) => {
    const selectedItem = tableData?.entries?.find(
      (item) => item?.displayGroupId === id
    );
    setInputData(selectedItem);
    setEditCondition(false);
    setSelectedIcon(null)
    setToggleModal(true);
  };

  const handleSubmit = () => {
    authAxios
      .post(
        `${baseUrl}${apiEndPoint.ADMIN_ADD_DISPLAY_SPECIALITY}`,
        payloadMethod
      )
      .then((res) => {
        toast.success("Added successfully!");
        setToggleModal(!modalShown);
        getFetchData();
      })
      .catch((err) => {
        console.log("Error:", err);
        toast.warn("Add failed!");
      });

    setTableData([...tableData, inputData]);

    setInputData({});
  };

  useEffect(() => {
    getFetchData();
  }, [currentPage]);

  const getFetchData = async () => {
    const res = await authAxios.get(
      `${baseUrl}${apiEndPoint.ADMIN_GET_DISPLAY_SPECIALITY}?pageNumber=${currentPage}&pageSize=10&requestId=1234`
    );

    setTableData(res?.data);
  };

  return (
    <div className="w-[1100px] rounded-[8px] bg-bg-white  px-[16px] py-[18px] mt-[50px] mx-auto flex justify-center items-center h-full">
      <div className="w-full  ">
        <h2 className="text-base16 text-[#343a40]">Admin Facility Tool <span className="text-btn-secondary">By Display Group</span> </h2>
        {/* table all hotel images */}
        <table class="w-full mt-[26px]">
          <thead>
            <tr className="text-left text-[#515d69] text-base16 font-bold h-[40px] border-b border-[#f0f2f6]">
              <th className="">Icon</th>
              <th className="">ID & Label</th>
              <th className="">Value</th>

              <th className="">Action</th>
            </tr>
          </thead>
          <tbody>
            {tableData?.entries?.length > 1 &&
              tableData?.entries?.map((row, index) => (
                <tr
                  key={index}
                  className="mx-[4px] border-[#f0f2f6] border-b text-[14px] text-[#515d69] text-left hover:bg-bg-semiwhite cursor-pointer "
                >
                  <td className="px-[4px] py-[12px]">
                    <img
                      src={row?.iconUrl}
                      alt=""
                      className="object-cover  w-[50px] h-[50px] rounded-full"
                    />
                  </td>

                  <td className="px-[4px]">
                    <span className="flex flex-col">
                      <span className="text-[12px] text-[#515d69]">
                        #{row?.displayGroupId}
                      </span>
                      <span className="text-[16px] font-extrabold text-[#343a40]">
                        {row?.label}
                      </span>
                    </span>
                  </td>

                  <td className="px-[4px] ">{row?.value} </td>

                  <td className="px-[4px]">
                    <div className="flex gap-x-[4px] justify-start items-center ">
                      <button
                        onClick={() => handleEdit(row?.displayGroupId)}
                        className="border px-[4px] flex justify-center items-center cursor-pointer w-[40px] h-[28px] py-[8px] border-[#07c074] text-[12px] text-[#07c074] rounded-[4px] hover:bg-[#07c074] hover:text-bg-primary"
                      >
                        Edit
                      </button>
                      <button className="border px-[4px] flex justify-center items-center cursor-pointer py-[8px] h-[28px] border-[#ff3d60] w-[58px] text-[12px] text-[#ff3d60] rounded-[4px] hover:bg-[#ff3d60] hover:text-bg-primary">
                        Delete
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
        <button
          onClick={() => tableRowAdd()}
          className="flex justify-end items-end w-full mt-[4px]"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-10 h-10 text-[#07c074] "
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
        </button>
        {/* <CommonTable tdData={tdData} /> */}

        <div className="mt-[22px]  w-[1100px] mx-auto">
          {
            <Paginations
              currentPage={currentPage}
              totalCount={Math.ceil(tableData?.numberOfEntries / 10)}
              pageSize={PageSize}
              onPageChange={(page) => setCurrentPage(page)}
            />
          }
        </div>
      </div>

      <ModalCommon
        shown={modalShown}
        close={() => {
          setToggleModal(false);
        }}
      >
        <div className="my-[50px] mx-[60px]">
          <div className="flex flex-col gap-y-[10px]">
           
            <div className=" pt-[10px]">
              <p className="pb-[5px]">Select speciality icons:</p>

              <div className="flex justify-start items-center gap-x-[8px]">
               
                  <div className="border w-[48px] h-[48px] flex justify-center items-center rounded-[4px]">
                    <img
                      className="w-[40px] h-[40px]"
                      src={selectedIcon?.url || inputData?.iconUrl}
                      alt=""
                    />
                  </div>
                
                <SingleSelectParentReturnObject
                  warningView={false}
                  data={iconsList}
                  displayField="title"
                  placeholder={"Icons"}
                  selectedFieldName={"title"}
                  selectedValue={selectedIcon}
                  setSelectedValue={setSelectedIcon}
                  setSelectedObject={() => {}}
                  image={true}
                />
              </div>
            </div>

           

            <div className="w-full">
              <label
                for="label"
                class="block mb-2 text-sm font-medium text-txt-hint dark:text-white"
              >
                Label
              </label>
              <input
                type="text"
                name="label"
                onChange={(event) => handleChange(event)}
                id="label"
                class="bg-btn-hint border border-txt-hint text-txt-primary text-sm rounded-lg focus:ring-btn-primary focus:border-btn-primary block w-full p-2.5 "
                placeholder="Enter Label"
                value={inputData?.label || ""}
                required
              />
            </div>
            {/* </div> */}

            <div>
              <label
                for="value"
                class="block mb-2 text-sm font-medium text-txt-hint dark:text-white"
              >
                Value
              </label>
              <input
                type="text"
                name="value"
                onChange={(event) => handleChange(event)}
                id="value"
                class="bg-btn-hint border border-txt-hint text-txt-primary text-sm rounded-lg focus:ring-btn-primary focus:border-btn-primary block w-full p-2.5 "
                placeholder="Enter value"
                value={inputData?.value || ""}
                required
              />
            </div>

            <button
              onClick={() => handleSubmit()}
              className="px-[12px] py-[6px] mt-[10px] cursor-pointer text-bg-primary rounded-[4px] bg-btn-secondary flex justify-center w-full"
            >
              Submit
            </button>
          </div>
        </div>
      </ModalCommon>
    </div>
  );
};

export default DispalayGroup;
