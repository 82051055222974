import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { DashboardRoutes, NavbarSelection } from '../../contants/Constants';
import SecondaryNav from '../../pages/admin/comp/SecondaryNav';
import { hotelsInfo } from '../../store/admin/hotel/api/adminContractApi';
import DashboardHotelCard from '../card/DashboardHotelCard';
import HotelCard from '../card/HotelCard';
import { HorizontalThreeDot } from '../common/AllSVGImport';

const DashboardHotelList = () => {
  const [listHotel, setListHotel] = useState();
  const response = useSelector((state) => state.hotelsInfoGetReducer);
  let navigate = useNavigate()

  console.log(response.data, "resres");
  const dispatch = useDispatch();

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(hotelsInfo("USER", 0));
  }, []);

  const handleNewHotel = () => {
    window.location.href=`${DashboardRoutes.HOTEL_DETAILS + 0}`
  };

  useEffect(() => {
    if (response && response.data && response.data.propertyDataList) {
      console.log("response: ", response.data);
      setListHotel(response.data.propertyDataList);
    }
  }, [response]);
  return (
    <div>
      <SecondaryNav />
      <div className='container mx-auto mt-10'>
        <div className="px-6 py-4 flex items-center">
          <p className="text-[27px]">Hotels</p>
          <HorizontalThreeDot />
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-0.5 px-4 pt-4 mb-[249px]">
          {listHotel && listHotel.length > 0
            ? listHotel.map((each) => {
              return (
                <div
                  className="w-auto h-auto text-lg m-[10px] custom-shadow rounded-[16px]"
                  key={each.id}
                >
                  <DashboardHotelCard
                    hotel={each}
                    hotelList={listHotel}
                  />
                </div>
              );
            })
            : null}
          <div
            onClick={handleNewHotel}
            className="h-[312px] w-auto flex items-center justify-center cursor-pointer font-medium text-center text-btn-secondary custom-shadow py-[50px] bg-bg-primary text-lg m-[10px] rounded-[16px] "
          >
            <div className='flex-col flex items-center justify-center gap-6'>
              <h1 className="">Add New</h1>
              <div className="bg-btn-secondary text-bg-white rounded-full w-[2.5rem] h-[2.5rem] flex items-center justify-center">
                <h1>
                  +
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DashboardHotelList
