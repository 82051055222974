import React, { useEffect, useState } from "react";
import ReactTooltip from "react-tooltip";
import { stringify } from "uuid";

const Cancellation = (props) => {
    const {
        policy,
        setPolicy,
        validationError,
        setValidationError,
        addedPolicy,
    } = props;
    const [timeUnit, setTimeUnit] = useState("days");
    const [selected, setSelected] = useState("");
    const [index, setIndex] = useState(null);
    const [inputText, setInputText] = useState();
    const [warning, setWarning] = useState("");
    const [validation, setvalidation] = useState([
        null,
        null,
        null,
        null,
        null,
    ]);

    const mapIndex = {
        100: 0,
        75: 1,
        50: 2,
        25: 3,
        0: 4,
    };
    const handleFocus = () => {
        if (!selected) {
            setWarning("Please select a refund policy first to set ");
        }
        const input = document.getElementById("to");
        if (input) {
            input.focus();
        }
    };

    const handleChecked = (e) => {
        const val = e.target.value;
        var time;
        if (val === "days") {
            setTimeUnit("days");
            time = parseInt(inputText) * 24;
        } else {
            setTimeUnit("hours");
            time = parseInt(inputText);
        }
        validation[index] = time;
        const isValidate = checkValidation();
        console.log(isValidate, "opsy");
        if (isValidate === true) {
            setValidationError("");
            setPolicy((current) => {
                return {
                    ...current,
                    [selected]: {
                        ...current[selected],
                        unit: val,
                        timeInHour: time,
                    },
                };
            });
        } else {
            setValidationError(
                "Before number cannot be bigger, than in previous cell"
            );
        }
    };
    useEffect(() => {
        if (addedPolicy?.length > 0) {
            setTimeUnit("hours");
            addedPolicy.map((cancelPolicy) => {
                console.log(cancelPolicy?.refundPercent, "cancell1212");
                const tempSelected = cancelPolicy?.refundPercent;
                const isValidate = checkValidation();
                console.log(isValidate, "opsy");
                let tempTime;
                let tempUnit;

                // if(cancelPolicy.timeInHour > 48){
                //     tempTime =
                // }

                validation[mapIndex[tempSelected]] = cancelPolicy?.timeInHour;

                if (isValidate === true) {
                    setPolicy((current) => {
                        return {
                            ...current,
                            [tempSelected]: {
                                ...current[tempSelected],
                                to: cancelPolicy?.timeInHour,
                                isActive: true,
                                unit: "hours",
                                timeInHour: cancelPolicy?.timeInHour,
                            },
                        };
                    });
                }
            });
        }
    }, [addedPolicy]);

    useEffect(() => {
        console.log(policy, "opsy");
        setPolicy((current) => {
            return {
                ...current,
                [selected]: {
                    ...current[selected],
                    unit: timeUnit,
                },
            };
        });
    }, [selected, setPolicy, timeUnit]);

    useEffect(() => {
        if (!selected) {
            document.getElementById("to").disabled = true;
        } else {
            document.getElementById("to").disabled = false;
        }
    }, [selected]);

    //console.log(policy[100].from,'fafa-')
    const handleSelect = (item, i) => {
        setWarning("");
        if (policy[item].unit === "days") {
            setTimeUnit("days");
        } else {
            setTimeUnit("hours");
        }
        setSelected(item);
        setIndex(i);
    };

    const handleDelete = (item) => {
        console.log(selected, "and 11", item);
        if (selected === item) {
            setPolicy((current) => {
                return {
                    ...current,
                    [selected]: {
                        ...current[selected],
                        isActive: false,
                        to: "",
                        timeInHour: null,
                    },
                };
            });
        }
    };

    //selected='50'
    const checkValidation = () => {
        var arr = [];
        validation.map((item) => {
            if (item !== null) {
                arr.push(item);
            }
        });
        console.log(validation, "opsy");
        console.log(arr, "opsy");
        let second_index;
        for (let first_index = 0; first_index < arr.length; first_index++) {
            second_index = first_index + 1;
            if (arr[first_index] - arr[second_index] <= 0) return false;
        }
        return true;
    };

    const handleLimitTo = (e) => {
        const temp = e.target.value;
        setInputText(temp);
        var time;
        if (timeUnit === "days") {
            time = parseInt(e.target.value) * 24;
        } else {
            time = parseInt(e.target.value);
        }

        validation[index] = time;
        const isValidate = checkValidation();
        console.log(isValidate, "opsy");

        if (isValidate === true) {
            if (time > 0) {
                setValidationError("");
                setPolicy((current) => {
                    return {
                        ...current,
                        [selected]: {
                            ...current[selected],
                            to: temp,
                            isActive: true,
                            timeInHour: time,
                        },
                    };
                });
            } else {
                setValidationError("Please, give a valid input");
            }
        } else {
            setValidationError(
                "Before number cannot be bigger, than in previous cell"
            );
        }
    };

    return (
        <div>
            <h1 className="text-title">Cancellation Policy</h1>
            <p className="mb-[25px]">
                Percentage returned, excluding Service Fee
            </p>
            {validationError && <p>{validationError}</p>}

            <div className="shadow-customShadow overflow-hidden rounded-[24px]">
                <div className="grid grid-cols-5 text-txt-primary text-[13px] xl:text-[18px] text-center rounded-[24px] overflow-hidden">
                    <div className=" w-full flex flex-col">
                        <span className="bg-btn-primary cursor-pointer text-bg-primary w-[100%] text-[22px] xl:text-[27px] py-[6px] rounded-bl-[24px]">
                            100%
                        </span>
                        <span
                            onClick={() => handleSelect("100", 0)}
                            className={`relative mt-[16px] xl:w-[220px] h-[220px] xl:h-[260px] border border-bg-semiblack rounded-[24px] pt-[15px]  mx-[10px] ${
                                selected === "100" &&
                                "border-btn-primary border-[2px]"
                            }`}
                        >
                            {policy[100].isActive && (
                                <div className="flex flex-col justify-between h-full ">
                                    <p className="px-[13px]">
                                        If Customer Cancels before{" "}
                                        {policy[100].to} {policy[100]?.unit}{" "}
                                        from the Arival time, (Not later than{" "}
                                        {policy[100].timeInHour} hours before
                                        Check In)
                                    </p>
                                    <span
                                        onClick={() => handleDelete("100")}
                                        className="cursor-pointer absolute bottom-[10px] right-[-2px]"
                                    >
                                        <img
                                            src="/delete.svg"
                                            alt=""
                                            className="h-[30px] w-[55px]"
                                        />
                                    </span>
                                </div>
                            )}
                        </span>
                    </div>

                    <div className=" w-full flex flex-col ">
                        <span className="bg-btn-secondary cursor-pointer text-bg-primary w-[100%] text-[22px] xl:text-[27px] py-[6px]">
                            75%
                        </span>

                        <span
                            onClick={() => handleSelect("75", 1)}
                            className={`relative mt-[16px] xl:w-[220px] h-[220px] xl:h-[260px] border border-bg-semiblack rounded-[24px] pt-[25px]  mx-[10px] ${
                                selected === "75" &&
                                "border-btn-primary border-[2px]"
                            }`}
                        >
                            {policy[75].isActive && (
                                <div className="flex flex-col justify-between h-full">
                                    <p className="px-[13px]">
                                        If Customer Cancels before{" "}
                                        {policy[75].to} {policy[75].unit} from
                                        the Arival time, (Not later than{" "}
                                        {policy[75].timeInHour} hours before
                                        Check In)
                                    </p>
                                    {/* <span onClick={()=>{handleSelect('is75')}} className='border border-b-0 w-full rounded-[24px] py-[6px] border-bg-semiblack cursor-pointer '>{ policy[75].is75 ? 'Selected' : 'Select'}</span> */}
                                    <span
                                        onClick={() => handleDelete("75")}
                                        className="cursor-pointer absolute bottom-[10px] right-[-2px]"
                                    >
                                        <img
                                            src="/delete.svg"
                                            alt=""
                                            className="h-[30px] w-[55px]"
                                        />
                                    </span>
                                </div>
                            )}
                        </span>
                    </div>
                    <div className=" w-full flex flex-col">
                        <span className="bg-[#FFCC00] cursor-pointer text-bg-primary w-[100%] text-[22px] xl:text-[27px] py-[6px]">
                            50%
                        </span>
                        <span
                            onClick={() => handleSelect("50", 2)}
                            className={`relative mt-[16px] xl:w-[220px] h-[220px] xl:h-[260px] border border-bg-semiblack rounded-[24px] pt-[15px]  mx-[10px] ${
                                selected === "50" &&
                                "border-btn-primary border-[2px]"
                            }`}
                        >
                            {policy[50].isActive && (
                                <div className="flex flex-col justify-between h-full">
                                    <p className="px-[13px]">
                                        If Customer Cancels before{" "}
                                        {policy[50].to} {policy[50]?.unit} from
                                        the Arival time, (Not later than{" "}
                                        {policy[50].timeInHour} hours before
                                        Check In)
                                    </p>
                                    <span
                                        onClick={() => handleDelete("50")}
                                        className="cursor-pointer absolute bottom-[10px] right-[-2px]"
                                    >
                                        <img
                                            src="/delete.svg"
                                            alt=""
                                            className="h-[30px] w-[55px]"
                                        />
                                    </span>
                                </div>
                            )}
                        </span>
                    </div>
                    <div className=" w-full flex flex-col">
                        <span className="bg-btn-orange cursor-pointer text-bg-primary w-[100%] text-[22px] xl:text-[27px] py-[6px]">
                            25%
                        </span>
                        <span
                            onClick={() => handleSelect("25", 3)}
                            className={`relative mt-[16px] xl:w-[220px] h-[220px] xl:h-[260px] border border-bg-semiblack rounded-[24px] pt-[15px]  mx-[10px] ${
                                selected === "25" &&
                                "border-btn-primary border-[2px]"
                            }`}
                        >
                            {policy[25].isActive && (
                                <div className="flex flex-col justify-between h-full">
                                    <p className="px-[13px]">
                                        If Customer Cancels before{" "}
                                        {policy[25].to} {policy[25]?.unit} from
                                        the Arival time, (Not later than{" "}
                                        {policy[25].timeInHour} hours before
                                        Check In)
                                    </p>
                                    <span
                                        onClick={() => handleDelete("25")}
                                        className="cursor-pointer absolute bottom-[10px] right-[-2px]"
                                    >
                                        <img
                                            src="/delete.svg"
                                            alt=""
                                            className="h-[30px] w-[55px]"
                                        />
                                    </span>
                                </div>
                            )}
                        </span>
                    </div>
                    <div className=" w-full flex flex-col">
                        <span className="bg-btn-pink cursor-pointer text-bg-primary w-[100%] text-[22px] xl:text-[27px] py-[6px] rounded-br-[24px]">
                            0%
                        </span>
                        <span
                            onClick={() => handleSelect("0", 4)}
                            className={`relative mt-[16px] xl:w-[220px] h-[220px] xl:h-[260px] border border-bg-semiblack rounded-[24px] pt-[15px]  mx-[10px] ${
                                selected === "0" &&
                                "border-btn-primary border-[2px]"
                            }`}
                        >
                            {policy[0].isActive && (
                                <div className="flex flex-col justify-between h-full">
                                    <p className="px-[13px]">
                                        If Customer Cancels {policy[0].to} or
                                        less {policy[0]?.unit} before the Check
                                        In
                                    </p>
                                    <span
                                        onClick={() => handleDelete("0")}
                                        className="cursor-pointer absolute bottom-[10px] right-[-2px]"
                                    >
                                        <img
                                            src="/delete.svg"
                                            alt=""
                                            className="h-[30px] w-[55px]"
                                        />
                                    </span>
                                </div>
                            )}
                        </span>
                    </div>
                </div>

                {warning && (
                    <div className="w-full flex py-[10px] text-btn-pink justify-center">
                        <span>{warning}</span>
                    </div>
                )}

                {/* input buttons for time limits */}

                <div className="mt-[78px] mb-[70px] w-full flex flex-col items-center">
                    <div
                        onClick={handleFocus}
                        className="cursor-pointer flex space-x-[2rem] mb-[25px]"
                    >
                        <span
                            className={`text-base12 py-[1.6rem] px-[3.3rem] shadow-customShadow rounded-full ${
                                validationError ? "border border-[#FE9A9A]" : ""
                            } `}
                        >
                            Limit :
                            <input
                                value={
                                    validationError
                                        ? inputText
                                        : policy[selected]?.to
                                }
                                type="number"
                                id="to"
                                onChange={(e) => handleLimitTo(e)}
                                className="border-b border-btn-secondary w-[30px] bg-bg-primary outline-none text-center"
                            ></input>
                            <span className="text-txt-hint">
                                {policy[selected]?.unit === "days" ? "D" : "H"}
                            </span>
                        </span>
                    </div>

                    <form className="flex space-x-[35px] mb-[4px]">
                        <div className="flex items-center space-x-[5px] ">
                            <label
                                htmlFor="default-radio-1"
                                className="cursor-pointer"
                            >
                                Days:
                            </label>
                            <input
                                id="default-radio-1"
                                type="radio"
                                value="days"
                                checked={timeUnit === "days"}
                                onClick={handleChecked}
                                // defaultChecked
                                name="default-radio"
                                className="cursor-pointer w-[16px] h-[16px] bg-btn-primary text-txt-primary border-btn-primary accent-btn-primary"
                            />
                        </div>
                        <div className="flex items-center space-x-[5px] ">
                            <label
                                htmlFor="default-radio-2"
                                className="cursor-pointer"
                            >
                                Hours:
                            </label>
                            <input
                                id="default-radio-2"
                                type="radio"
                                value="hours"
                                checked={timeUnit === "hours"}
                                onClick={handleChecked}
                                name="default-radio"
                                className="cursor-pointer w-[16px] h-[16px]  checked:bg-bg-primary accent-btn-primary"
                            />
                        </div>
                    </form>

                    {validationError && (
                        <span className="text-btn-pink">{validationError}</span>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Cancellation;
