import React, { useEffect, useState } from "react";
import ReactModal from "react-modal";
import ButtonFilled from "../../components/ButtonFilled";
import ButtonOutlined from "../../components/ButtonOutlined";
import InputComponent from "../../components/InputComponent";
import { apiEndPoint } from "../../contants/Endpoints";
import { authAxios } from "../../utils/axiosWrapper";
import { toastMessage } from "../../utils/toast";

export default function AdminCouponMangerNew() {
  const [couponList, setCouponList] = React.useState([]);
  const [selectedItemId, setSelectedItemId] = useState(null);
  const [discountType, setDiscountType] = useState("");
  // const [discountOn, setDiscountOn] = useState("TOUR");
  //   const [selectedItem, setSelectedItem] = useState(null);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [couponCode, setCode] = useState();
  const [showModal, setShowModal] = useState(false);
  const [redemptionLimit, setRedemptionLimit] = useState();
  const [discountValue, setDiscountValue] = useState();
  const [perUserRedemptionLimit, setperUserRedemptionLimit] = useState();
  const [isHidden, setIsHidden] = useState(true);
  const [active, setActive] = useState(false);
  const [page, setPage] = useState(1);
  const [couponCodePattern, setCouponCodePatten] = useState("");
  const [validFrom, setValidFrom] = useState();
  const [validUntil, setValidUntil] = useState();

  const discountTypes = ["PERCENTAGE", "FIXED_AMOUNT"];

  //get all coupons
  const getCoupons = () => {
    const reqObject = {
      couponCodePattern: "",
      pageNumber: page,
      pageSize: 10,
      requestId: "1234",
      validFrom: "",
      validUntil: "",
    };

    authAxios
      .post(`${apiEndPoint.ADMIN_GET_SEARCH_COUPONS}`, reqObject)
      .then((res) => {
        setCouponList(res?.data?.entries);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    getCoupons();
  }, [page]);

  //search coupons by pattern
  const searchCoupons = () => {
    const reqObject = {
      couponCodePattern: couponCodePattern,
      pageNumber: 1,
      pageSize: 10,
      requestId: "1235",
      validFrom: "",
      validUntil: "",
    };

    authAxios
      .post(`${apiEndPoint.ADMIN_GET_SEARCH_COUPONS}`, reqObject)
      .then((res) => {
        setCouponList(res?.data?.entries);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    if (couponCodePattern !== "") {
      searchCoupons();
    } else {
      getCoupons();
    }
  }, [couponCodePattern]);

  //search coupons by date
  const searchCouponsByDate = () => {
    if (validFrom || validUntil) {
      const reqObject = {
        couponCodePattern: "",
        pageNumber: 1,
        pageSize: 10,
        requestId: "123",
        validFrom: validFrom,
        validUntil: validUntil,
      };

      authAxios
        .post(`${apiEndPoint.ADMIN_GET_SEARCH_COUPONS}`, reqObject)
        .then((res) => {
          setCouponList(res?.data?.entries);
        })
        .catch((err) => {});
    } else {
      return;
    }
  };

  useEffect(() => {
    searchCouponsByDate();
  }, [validFrom, validUntil]);

  //edit coupons
  const handleEdit = () => {
    const reqObject = {
      active: active,
      couponCode,
      discountType,
      discountValue,
      id: selectedItemId,
      perUserRedemptionLimit: perUserRedemptionLimit,
      redemptionLimit,
      validFrom: startDate,
      validUntil: endDate,
    };
    authAxios
      .post(`${apiEndPoint.ADMIN_UPDATE_COUPONS}`, reqObject)
      .then((res) => {
        toastMessage("Coupon updated successfully!");
        setShowModal(false);
        getCoupons();
      })
      .catch((err) => {
        toastMessage("Something went wrong! Try again!!", "error");
      });
  };

  //add a new coupon
  const addNew = () => {
    const reqObject = {
      active: false,
      couponCode,
      discountType,
      discountValue,
      perUserRedemptionLimit: perUserRedemptionLimit,
      redemptionLimit: redemptionLimit,
      validFrom: startDate,
      validUntil: endDate,
    };
    authAxios
      .post(`${apiEndPoint.ADMIN_UPDATE_COUPONS}`, reqObject)
      .then((res) => {
        toastMessage("Coupon created successfully!");
        setShowModal(false);
        getCoupons();
      })
      .catch((err) => {
        toastMessage("Something went wrong! Try again!", "error");
      });
  };

  //activate a coupon
  const activateCoupon = (id) => {
    authAxios
      .put(`${apiEndPoint.ADMIN_ACTIVATE_COUPONS}/${id}`)
      .then((res) => {
        toastMessage("Coupon activated successfully!");
        getCoupons();
      })
      .catch((err) => {
        toastMessage("Something went wrong! Try again!", "error");
      });
  };

  //deactivate a coupon
  const deActivateCoupon = (id) => {
    authAxios
      .put(`${apiEndPoint.ADMIN_DEACTIVATE_COUPONS}/${id}`)
      .then((res) => {
        toastMessage("Coupon deactivated successfully!");
        getCoupons();
      })
      .catch((err) => {
        toastMessage("Something went wrong! Try again!", "error");
      });
  };
  // console.log("date: ", validFrom, " ", validUntil);
  return (
    <div className="p-4 ">
      <div className="flex justify-between items-center gap-2">
        <h2 className="text-3xl mb-6 text-center">Coupons</h2>
        <ButtonFilled
          title={"Create new"}
          w="20%"
          arrow={"no"}
          buttonFilledHandle={() => {
            setSelectedItemId(null);
            setCode("");
            setStartDate(new Date());
            setEndDate(new Date());
            setDiscountValue();
            setRedemptionLimit();
            setperUserRedemptionLimit();
            setDiscountType(discountTypes[0]);
            setShowModal(true);
          }}
        />
      </div>
      <div className="w-[30rem] flex mb-[1rem]">
        <div className="mt-[1.8rem] mr-[1rem]">
          <input
            className="pl-[1rem] w-[20rem] h-[3rem] rounded-[8px] focus:outline-none border-[1px] focus:border-[#4E90A8]"
            type="search"
            name=""
            id=""
            placeholder="Search coupon/s"
            onChange={(e) => {
              setCouponCodePatten(e.target.value);
            }}
          />
        </div>
        <div className="flex gap-2 w-full ">
          <div className="w-full">
            <div className="mb-1">Valid from</div>
            <input
              type={"date"}
              value={validFrom}
              onChange={(e) => {
                setValidFrom(e.target.value);
              }}
              className="bg-[transparent] border border-[#9e9989] rounded-xl h-[49px] px-4 text-[#1b1b1b] w-full"
            />
          </div>
          <div className="w-full">
            <div className="mb-1">Valid until</div>
            <input
              type={"date"}
              value={validUntil}
              onChange={(e) => {
                setValidUntil(e.target.value);
              }}
              className="bg-[transparent] border border-[#9e9989] rounded-xl h-[49px] px-4 text-[#1b1b1b] w-full"
            />
          </div>
        </div>
      </div>
      <table id="students" className="min-w-full leading-normal ">
        <thead>
          <tr className="h-[78px] px-5 py-3 border-b-2 border-gray-200 bg-btn-primary text-left text-xl font-normal text-bg-white tracking-wider">
            <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-base font-normal text-gray-700 tracking-wider">
              Coupon code
            </th>
            <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-base font-normal text-gray-700 tracking-wider">
              Type
            </th>
            <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-base font-normal text-gray-700 tracking-wider">
              Discount value
            </th>
            <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-base font-normal text-gray-700 tracking-wider">
              Valid from
            </th>
            <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-base font-normal text-gray-700 tracking-wider">
              Valid until
            </th>
            <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-base font-normal text-gray-700 tracking-wider">
              Redemption limit
            </th>
            <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-base font-normal text-gray-700 tracking-wider">
              Redemption limit (per user)
            </th>
            <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-base font-normal text-gray-700 tracking-wider">
              Action
            </th>
          </tr>
        </thead>
        <div className="my-[14px]"></div>
        <tbody>
          {couponList &&
            couponList?.map((member, index) => {
              const {
                id,
                couponCode,
                discountType,
                discountValue,
                validFrom,
                validUntil,
                redemptionLimit,
                active,
                perUserRedemptionLimit,
              } = member; //destructuring
              return (
                <tr className="cursor-pointer">
                  <td className="h-[80px] px-5 py-5 border-b border-t border-l bg-white text-sm">
                    {couponCode}
                  </td>
                  <td className="h-[80px] px-5 py-5 border-b border-t bg-white text-sm">
                    {discountType}
                  </td>
                  <td className="h-[80px] px-5 py-5 border-b border-t bg-white text-sm">
                    {discountType === "PERCENTAGE"
                      ? discountValue + " %"
                      : discountValue}
                  </td>
                  <td className="h-[80px] px-5 py-5 border-b border-t bg-white text-sm">
                    {new Date(validFrom).toDateString()}
                  </td>
                  <td className="h-[80px] px-5 py-5 border-b border-t bg-white text-sm">
                    {new Date(validUntil).toDateString()}
                  </td>
                  <td className="h-[80px] px-5 py-5 border-b border-t bg-white text-sm">
                    {redemptionLimit}
                  </td>
                  <td className="h-[80px] px-5 py-5 border-b border-t bg-white text-sm">
                    {perUserRedemptionLimit}
                  </td>
                  <td className="h-[80px] px-5 py-2 border-b border-t border-r bg-white text-sm">
                    <div className="flex w-[150px] mb-[5px]">
                      <ButtonOutlined
                        title={"Edit"}
                        w="100%"
                        buttonOutlinedHandle={() => {
                          setSelectedItemId(id);
                          setCode(couponCode);
                          setActive(active);
                          setDiscountType(discountType);
                          setDiscountValue(discountValue);
                          setperUserRedemptionLimit(perUserRedemptionLimit);
                          setRedemptionLimit(redemptionLimit);
                          setStartDate(validFrom);
                          setEndDate(validUntil);
                          setShowModal(true);
                        }}
                      />
                    </div>
                    <div className="flex w-[150px] mb-[5px]">
                      <ButtonOutlined
                        title={active ? "Active" : "Activate"}
                        w="100%"
                        buttonOutlinedHandle={() => {
                          activateCoupon(id);
                        }}
                        disable={active && "yes"}
                      />
                    </div>
                    <div className="flex w-[150px]">
                      <ButtonOutlined
                        title={!active ? "Deactivated" : "Deactivate"}
                        w="100%"
                        buttonOutlinedHandle={() => {
                          deActivateCoupon(id);
                        }}
                        disable={!active && "yes"}
                      />
                    </div>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
      {
        <div className="flex justify-between">
          <button
            className={`${
              page === 1
                ? "bg-txt-hint text-txt-secondary border-bg-semiblack w-[25rem] h-[2.5rem] rounded-[8px] mt-[1rem]"
                : "bg-btn-primary hover:bg-btn-secondary transition duration-300 hover:text-bg-white w-[25rem] h-[2.5rem] rounded-[8px] mt-[1rem]"
            }`}
            onClick={() => {
              if (page > 1) {
                setPage(page - 1);
              }
            }}
            disabled={page === 1 ? true : false}
          >
            Previous
          </button>

          <button
            className={`${
              couponList?.length < 10
                ? "bg-txt-hint text-txt-secondary border-bg-semiblack w-[25rem] h-[2.5rem] rounded-[8px] mt-[1rem]"
                : "bg-btn-primary hover:bg-btn-secondary transition duration-300 hover:text-bg-white w-[25rem] h-[2.5rem] rounded-[8px] mt-[1rem]"
            }`}
            onClick={() => {
              setPage(page + 1);
            }}
            disabled={couponList?.length < 10 ? true : false}
          >
            Next
          </button>
        </div>
      }
      <ReactModal
        style={{ content: { width: "60vw" } }}
        isOpen={showModal}
        onRequestClose={() => {
          setShowModal(false);
        }}
      >
        <div className="px-10 py-8 relative">
          <button
            className="absolute top-5 right-6"
            onClick={() => {
              setShowModal(false);
            }}
          >
            <svg
              width="19"
              height="19"
              viewBox="0 0 19 19"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M11.4109 8.99928L18.701 1.70928C18.8648 1.51798 18.9504 1.27191 18.9407 1.02023C18.9309 0.768555 18.8266 0.529816 18.6485 0.351722C18.4704 0.173627 18.2317 0.0692943 17.98 0.0595732C17.7283 0.049852 17.4823 0.135459 17.291 0.299285L10.0009 7.58928L2.71095 0.289285C2.51965 0.125458 2.27357 0.0398528 2.0219 0.0495739C1.77022 0.0592951 1.53148 0.163627 1.35339 0.341722C1.17529 0.519816 1.07096 0.758555 1.06124 1.01023C1.05152 1.26191 1.13712 1.50798 1.30095 1.69928L8.59095 8.99928L1.29095 16.2893C1.18627 16.3789 1.10125 16.4893 1.04122 16.6133C0.981202 16.7374 0.947471 16.8725 0.942151 17.0102C0.936832 17.1479 0.960038 17.2853 1.01031 17.4136C1.06059 17.5419 1.13685 17.6585 1.2343 17.7559C1.33175 17.8534 1.4483 17.9296 1.57663 17.9799C1.70495 18.0302 1.84228 18.0534 1.98 18.0481C2.11772 18.0428 2.25286 18.009 2.37692 17.949C2.50098 17.889 2.6113 17.804 2.70095 17.6993L10.0009 10.4093L17.291 17.6993C17.4823 17.8631 17.7283 17.9487 17.98 17.939C18.2317 17.9293 18.4704 17.8249 18.6485 17.6468C18.8266 17.4688 18.9309 17.23 18.9407 16.9783C18.9504 16.7267 18.8648 16.4806 18.701 16.2893L11.4109 8.99928Z"
                fill="#2A220E"
                fillOpacity="0.45"
              />
            </svg>
          </button>
          <div className="text-center text-2xl mb-4">
            {selectedItemId ? "Edit Coupon" : "Add Coupon"}
          </div>
          <div>Coupon Code</div>
          <InputComponent
            name={"code"}
            inputValue={couponCode}
            setInputValue={setCode}
            required={true}
            warningView={false}
          />
          <div>Discount Type</div>
          <div className="h-[49px] w-full relative">
            <div
              onClick={() => setIsHidden(!isHidden)}
              className="cursor-pointer hover:border-[#b1ad9c] border-[#9e9989] border h-[50px] rounded-[8px] w-full flex items-center px-4 justify-between"
            >
              {!discountType && <div className="text-bg-semiblack"></div>}
              <div>{discountType}</div>

              <svg
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M2 4.5L6 8.5L10 4.5"
                  stroke="#9e9989"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
            <ul
              id="scroll-x"
              className={
                "max-h-[200px] w-full h-auto overflow-scroll custom-shadow dropdown-menu absolute text-gray-700 bg-bg-primary mt-2 rounded-[8px] custom-shadow z-10 " +
                (isHidden ? "hidden" : "")
              }
            >
              {discountTypes.map((each, index) => {
                return (
                  <li key={index}>
                    <div
                      onClick={() => {
                        setDiscountType(each);
                        setIsHidden(true);
                      }}
                      className="cursor-pointer text-txt-primary hover:bg-btn-secondary hover:text-txt-secondary py-2 px-4 block whitespace-no-wrap"
                    >
                      {each}
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>

          {discountType === "PERCENTAGE" ? (
            <div>
              <div>Discount Percent</div>
              <InputComponent
                name={"code1"}
                inputValue={discountValue}
                setInputValue={setDiscountValue}
                required={true}
                warningView={false}
              />
            </div>
          ) : (
            <div>
              <div>Discount Amount</div>
              <InputComponent
                name={"code2"}
                inputValue={discountValue}
                setInputValue={setDiscountValue}
                required={true}
                warningView={false}
              />
            </div>
          )}

          <div>
            <div>Redemption Limit</div>
            <InputComponent
              name={"code3"}
              inputValue={redemptionLimit}
              setInputValue={setRedemptionLimit}
              required={true}
              warningView={false}
            />
          </div>
          <div>
            <div>Redemption Limit Per Person</div>
            <InputComponent
              name={"code4"}
              inputValue={perUserRedemptionLimit}
              setInputValue={setperUserRedemptionLimit}
              required={true}
              warningView={false}
            />
          </div>
          <div className="flex gap-2 w-full">
            <div className="w-full">
              <div className="mb-1"> Start Date </div>
              <input
                type={"date"}
                value={startDate}
                onChange={(e) => {
                  setStartDate(e.target.value);
                }}
                className="bg-[transparent] border border-[#9e9989] rounded-xl h-[49px] px-4 text-[#1b1b1b] w-full"
              />
            </div>
            <div className="w-full">
              <div className="mb-1"> End Date </div>
              <input
                type={"date"}
                value={endDate}
                onChange={(e) => {
                  setEndDate(e.target.value);
                }}
                className="bg-[transparent] border border-[#9e9989] rounded-xl h-[49px] px-4 text-[#1b1b1b] w-full"
              />
            </div>
          </div>
          {/* <Single */}

          <div className="flex justify-center gap-2 mt-8">
            <ButtonFilled
              title={"Submit"}
              w="200px"
              buttonFilledHandle={() => {
                if (
                  couponCode &&
                  discountValue &&
                  redemptionLimit &&
                  perUserRedemptionLimit &&
                  startDate &&
                  endDate
                ) {
                  selectedItemId && handleEdit();
                  !selectedItemId && addNew();
                } else {
                  toastMessage("Please fill all the required fields");
                }
              }}
            />
          </div>
        </div>
      </ReactModal>
    </div>
  );
}
