import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import ButtonFilled from "../../components/ButtonFilled";
import InputComponent from "../../components/InputComponent";
import FileMultipleCategory from "../../components/multipleimage/FileMultipleCategory";
import RemoveableImageUploadViewCategory from "../../components/RemoveableImageUploadViewCategory";
import SingleSelectFromSimpleArray from "../../components/singleSelect/SingleSelectFromSimpleArray";
import SingleSelectParent from "../../components/singleSelect/SingleSelectParent";
import { UserType } from "../../contants/Constants";
import { apiEndPoint, PROPERTY_ENDPOINTS } from "../../contants/Endpoints";
import { authAxios } from "../../utils/axiosWrapper";
import { noAuthAxios } from "../../utils/axiosWrapperOpen";
import { toastMessage } from "../../utils/toast";

export default function AdminAddLocation() {
    const navigate = useNavigate();
    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const [basePrice, setPrice] = useState(0);
    const [imageUrl, setImageUrl] = useState("");
    const [address, setAddress] = useState("");
    //const [country, setCountry] = useState("");

    // api country data
    const [allCountries, setAllCountries] = useState();
    const [apiCity, setApiCity] = useState();
    const [apiMunicipility, setApiMunicipility] = useState();
    const [apicomm, setApicomm] = useState();
    const [apiDivision, setApiDivision] = useState();

    const [countryCode, setCountryCode] = useState("");
    const [division, setDivision] = useState("");
    const [city, setCity] = useState("");
    const [municipility, setMunicipility] = useState("");
    const [community, setCommunity] = useState("");

    const [urlTempStorageArray, setUrlTempStorageArray] = useState([]);

    const SubmitData = () => {
        authAxios
            .post(`${apiEndPoint.ADMIN_ADD_LOCATION}`, {
                name,
                admin1: division,
                admin2: city,
                admin3: municipility,
                admin4: community,
                countryCode: countryCode,
                description,
                basePrice,
                imageUrl: urlTempStorageArray[0].url,
                address,
                requestId: "string",
                currency:"BDT",
            })
            .then((res) => {
                toastMessage("Location Added successfully!");
                ResetData();
            })
            .catch((err) => {});
    };

    const ResetData = () => {
        setName("");
        setAddress("");
        setDescription("");
        setImageUrl("");
        urlTempStorageArray([]);
        setPrice(0);
    };

    const addressObj =  {
        admin1: division,
        admin2: city,
        admin3: municipility,
        admin4: community,
        countryCode: countryCode
    }

    useEffect(()=>{
        const headers = {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        };
        noAuthAxios
        .get(
            `${
                apiEndPoint.GET_COUNTRY_DETAILS
            }?requestId=${localStorage.getItem("id")}`,
            {
                headers: headers,
            }
        )
        .then((response) => {
            setAllCountries(response.data.countries);
        })
        .catch((error) => {
            console.log(error);
        });

    },[])

    
    // fetch country
    useEffect(() => {
        if (countryCode) {
            const headers = {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("jwt")}`,
            };

            noAuthAxios
                .get(
                    `${
                        PROPERTY_ENDPOINTS.FETCH_ADDRESS_ATTRIBUTE_BY_COUNTRY_CODE
                    }${countryCode}?requestId=${localStorage.getItem("id")}`,
                    {
                        headers: headers,
                    }
                )
                .then((response) => {
                    setApiDivision(response.data.admin1s);
                    setApiCity(response.data.admin2s);
                    setApiMunicipility(response.data.admin3s);
                    setApicomm(response.data.places);
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }, [countryCode]);

    useEffect(() => {
        console.log(urlTempStorageArray, "test");
    }, [urlTempStorageArray]);

    return (
        <div className="container mx-auto">
            <div className="flex gap-4 items-center">
                <button onClick={()=>navigate(-1)} > <i className="fa fa-arrow-left" >  </i> </button>
                <div className="text-2xl">Add Inspired Locations</div>
            </div>

            <div className="xl:w-2/3 mt-20">
                <div>Location Name</div>
                <InputComponent
                    inputValue={name}
                    setInputValue={setName}
                    name="name"
                    placeholder={"Name"}
                />

                <div className="mt-2">
                    <div>Country</div>
                <SingleSelectParent
                                                    required={true}
                                                    selectedValue={countryCode}
                                                    setSelectedValue={
                                                        setCountryCode
                                                    }
                                                    data={allCountries}
                                                    placeholder="Country"
                                                    displayField={"country"}
                                                    selectedFieldName="countryCode"
                                                    // isAdmin={
                                                    //     process.env
                                                    //         .REACT_APP_USER_TYPE ===
                                                    //     UserType.ADMIN
                                                    //         ? true
                                                    //         : false
                                                    // }
                                                    isReadOnly={false}
                                                    // rejectionArray={
                                                    //     addressRejectArray
                                                    // }
                                                    // setRejectionArray={
                                                    //     setAddressRejectArray
                                                    // }
                                                    name="country"
                                                />
                </div>

                <div className="mt-2" >
                                              <div>Division</div>    
                                                <SingleSelectFromSimpleArray
                                                    required={true}
                                                    data={apiDivision}
                                                    selectedValue={division}
                                                    setSelectedValue={
                                                        setDivision
                                                    }
                                                    placeholder="Division"
                                                    // isAdmin={
                                                    //     process.env
                                                    //         .REACT_APP_USER_TYPE ===
                                                    //     UserType.ADMIN
                                                    //         ? true
                                                    //         : false
                                                    // }
                                                    // isReadOnly={false}
                                                    // rejectionArray={
                                                    //     addressRejectArray
                                                    // }
                                                    // setRejectionArray={
                                                    //     setAddressRejectArray
                                                    // }
                                                    name="division"
                                                />
                                                </div>
                        <div className="mt-2">
                        <div>City</div>   
                                                <SingleSelectFromSimpleArray
                                                    required={true}
                                                    data={apiCity}
                                                    selectedValue={city}
                                                    setSelectedValue={setCity}
                                                    placeholder="City"
                                                    // isAdmin={
                                                    //     process.env
                                                    //         .REACT_APP_USER_TYPE ===
                                                    //     UserType.ADMIN
                                                    //         ? true
                                                    //         : false
                                                    // }
                                                    // isReadOnly={false}
                                                    // rejectionArray={
                                                    //     addressRejectArray
                                                    // }
                                                    // setRejectionArray={
                                                    //     setAddressRejectArray
                                                    // }
                                                    name="city"
                                                />
                                            </div>

                                            <div className="grid grid-cols-1 mt-2">
                                            <div>Municipality</div>   
                                                <SingleSelectFromSimpleArray
                                                    required={true}
                                                    data={apiMunicipility}
                                                    selectedValue={municipility}
                                                    setSelectedValue={
                                                        setMunicipility
                                                    }
                                                    placeholder="Municipility"
                                                    // isAdmin={
                                                    //     process.env
                                                    //         .REACT_APP_USER_TYPE ===
                                                    //     UserType.ADMIN
                                                    //         ? true
                                                    //         : false
                                                    // }
                                                    // isReadOnly={false}
                                                    // rejectionArray={
                                                    //     addressRejectArray
                                                    // }
                                                    // setRejectionArray={
                                                    //     setAddressRejectArray
                                                    // }
                                                    name="municipility"
                                                />
                                            </div>
                                            <div className="grid grid-cols-1 mt-2">
                                                <div>Community</div>
                                                <SingleSelectFromSimpleArray
                                                    required={true}
                                                    data={apicomm}
                                                    selectedValue={community}
                                                    setSelectedValue={
                                                        setCommunity
                                                    }
                                                    placeholder="Community"
                                                    // isAdmin={
                                                    //     process.env
                                                    //         .REACT_APP_USER_TYPE ===
                                                    //     UserType.ADMIN
                                                    //         ? true
                                                    //         : false
                                                    // }
                                                    // isReadOnly={false}
                                                    // rejectionArray={
                                                    //     addressRejectArray
                                                    // }
                                                    // setRejectionArray={
                                                    //     setAddressRejectArray
                                                    // }
                                                    name="community"
                                                />
                                            </div>



                <div className="mt-2">Location Description</div>
                <textarea
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    name="description"
                    placeholder={"Description"}
                    rows="4"
                    className="w-full bg-bg-primary outline-none border border-[#9e9989] rounded-[8px] p-4 text-[#9e9989]"
                />
                <div className="mt-2">Base Price</div>
                <InputComponent
                    inputValue={basePrice}
                    setInputValue={setPrice}
                    name="price"
                    number={true}
                    placeholder={"Base Price"}
                />
                <div className="mt-2">Address</div>
                <InputComponent
                    inputValue={address}
                    setInputValue={setAddress}
                    name="address"
                    // number={true}
                    placeholder={"Address"}
                />
                <div className="mt-10">Upload Image</div>
                {/* <RemoveableImageUploadViewCategory/> */}
                <div className="mb-10">
                    <FileMultipleCategory
                        id={1}
                        setNeedUpdate={() => {}}
                        urlTempStorageArray={urlTempStorageArray}
                        setUrlTempStorageArray={setUrlTempStorageArray}
                        multiple={false}
                    />
                </div>
                {/* {urlTempStorageArray.map((item)=>( */}
                <div className="text-txt-primary">
                    Image File:{" "}
                    {urlTempStorageArray.length > 0
                        ? urlTempStorageArray[0].fileName
                        : "No file selected"}
                </div>
                {/* ))} */}

                <div className="flex justify-center mt-10">
                    <div className="w-[400px]">
                        <ButtonFilled
                            w={"100%"}
                            title={"Submit"}
                            arrow="no"
                            buttonFilledHandle={SubmitData}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}
