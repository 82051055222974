
import styles from './ModalCommon.module.css';

const ModalCommon = (props) => {
    const { children, shown, close } = props;

    return (
        <div
            className={`${styles.modal_backdrop} ${shown && styles.show}`}
            onClick={() => {
                // close modal when outside of modal is clicked
                close();
            }}
        >
            <div
                className={styles.modal_content}
                onClick={(e) => {
                    // do not close modal if anything inside modal content is clicked
                    e.stopPropagation();
                }}
            >
                <button className="absolute top-[10px] right-[15px]" onClick={close}>
                    {/* <img src="/tourdetails/carbon_close.svg" width="32px" height="32px" alt="" />
                     */}
                     <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
  <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
</svg>

                </button>
                {children}
            </div>
        </div>
    );
};

export default ModalCommon;