import React, { useEffect, useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import { toast } from 'react-toastify';
import ButtonFilled from '../../../components/ButtonFilled';
import InputComponent from '../../../components/InputComponent';
import SingleSelectFromSimpleArray from '../../../components/singleSelect/SingleSelectFromSimpleArray';
import { apiEndPoint, baseUrl } from '../../../contants/Endpoints';
import { authAxios } from '../../../utils/axiosWrapper';
import DayComponents from './DayComponents';

const AdminSetFilterPrice = (props) => {
  const {categoryId, baseData, toggleState, setToggleState} = props;
  // const [baseData,setBaseData]= useState([]);
  const [policyName,setPolicyName] = useState('');
  const allMethodType = ["WEEKEND", "ONE_TIME", "SEASONAL", "SPECIAL"];
  const dayTagName = ["MONDAY",
    "TUESDAY",
    "WEDNESDAY",
    "THURSDAY",
    "FRIDAY",
    "SATURDAY",
    "SUNDAY"];
  const [days,setDays] = useState([]);
  const [isAllday,setIsAllDay]= useState(false);
  const [isAlldayCheck,setIsAllDayCheck]= useState(false);
  const [policyType,setPolicyType] = useState('');
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const [deltaRackPrice, setdeltaRackPrice] = useState(0);
  const [deltaGhuddyCommissionAmount, setdeltaGhuddyCommissionAmount] = useState(0);
  const [deltaGhuddyCommissionPercentPoint, setdeltaGhuddyCommissionPercentPoint] = useState(0);
  const [deltaCorporatePrice, setdeltaCorporatePrice] = useState(0);
  const [deltaCorporateDiscountPercentPoint, setdeltaCorporateDiscountPercentPoint] = useState(0);

  const [deltaShurjoCommissionPercentPoint, setdeltaShurjoCommissionPercentPoint] = useState(0);
  const [deltaShurjoCommissionAmount, setdeltaShurjoCommissionAmount] = useState(0);
  
  const [deltaAfterGhuddyCommission, setdeltaAfterGhuddyCommision] = useState(0);
  const [deltaGhuddyWebSiteBlackPrice, setdeltaGhuddyWebSiteBlackPrice] = useState(0);

  const [deltaGhuddyWebSiteRedPrice, setdeltaGhuddyWebSiteRedPrice] = useState(0);

  const [deltaGhuddySubsidyAmount, setdeltaGhuddySubsidyAmount] = useState(0);
  const [deltaGhuddySubsidyPercentPoint, setdeltaGhuddySubsidyPercentpoint] = useState(0);

  const [deltaTotalDiscountPercentPoint, setdeltaTotalDiscountPercentPoint] = useState(0);
  const [deltaTotalGhuddyCommissionAmount, setdeltaTotalGhuddyCommissionAmount] =
    useState(0);
  const [deltaTotalGhuddyCommissionPercent, setdeltaTotalGhuddyCommissionPercent] =
    useState(0);
    const [deltaTotalShurjoCommissionAmount,setdeltaTotalShurjoCommissionAmount] = useState(0); 

  const [deltaTotalRooms, setdeltaTotalRooms] = useState(0);
  const [deltaBookableRooms, setdeltaBookableRooms] = useState(0);
  const [deltaDeactivatedRooms, setdeltaDeactivatedRooms] = useState(0);
 

  //fetch already set price
  // useEffect(()=>{
  //  if(categoryId) {authAxios.get(`${baseUrl}${apiEndPoint.ADMIN_GET_PRICE_DATA_BY_ID}${categoryId}?requestId=12`)
  //   .then((res)=>{
  //    console.log(res.data,'check price');
  //    if(res.data){
  //     setBaseData(res.data);
  //    }
  //    else {
  //     setBaseData([])
  //    }
    
  //   })}
    
  // },[categoryId])
 
  /////////////////////// calculation
  //price after ghuddy commission
  useEffect(() => {
    const temp = parseFloat(deltaCorporatePrice) + parseFloat(deltaGhuddyCommissionAmount);
    setdeltaAfterGhuddyCommision(temp);
  }, [
    deltaRackPrice,
    deltaGhuddyCommissionAmount,
    deltaCorporatePrice
  ]);

  useEffect(() => {
    const temp =
      parseFloat(deltaAfterGhuddyCommission) + parseFloat(deltaShurjoCommissionAmount);
    setdeltaGhuddyWebSiteBlackPrice(temp);
  }, [deltaAfterGhuddyCommission, deltaShurjoCommissionAmount]);

  useEffect(() => {
    const subsidyTemp =
      parseFloat(deltaGhuddyWebSiteBlackPrice) - parseFloat(deltaGhuddyWebSiteRedPrice);

    const subsidyPercentTemp =
      (parseFloat(subsidyTemp) / parseFloat(deltaGhuddyWebSiteBlackPrice)) * 100;

    const tempTotalShurjoCommision = (parseFloat(deltaGhuddyWebSiteRedPrice)* parseFloat(deltaShurjoCommissionPercentPoint)/100);
    setdeltaTotalShurjoCommissionAmount(tempTotalShurjoCommision);

    setdeltaGhuddySubsidyAmount(subsidyTemp);
    setdeltaGhuddySubsidyPercentpoint(subsidyPercentTemp);
  }, [deltaGhuddyWebSiteBlackPrice, deltaGhuddyWebSiteRedPrice]);

  useEffect(() => {
    const tempTotalDiscount =
      ((deltaRackPrice - deltaGhuddyWebSiteRedPrice) / deltaRackPrice) * 100;
    setdeltaTotalDiscountPercentPoint(tempTotalDiscount);
  }, [deltaRackPrice, deltaGhuddyWebSiteRedPrice]);

  useEffect(() => {
    // const tempTotalCommission =
    //   parseFloat(ghuddyCommissionAmount) + parseFloat(shurjoCommissionAmount);
    
    const tempTotalCommission =
    parseFloat(deltaGhuddyWebSiteRedPrice) -(parseFloat(deltaShurjoCommissionAmount) + parseFloat(deltaCorporatePrice));
    
      setdeltaTotalGhuddyCommissionAmount(tempTotalCommission);
    const tempTotalCommisionPercent =
      (tempTotalCommission / deltaCorporatePrice) * 100;
    setdeltaTotalGhuddyCommissionPercent(tempTotalCommisionPercent);
  }, [
    deltaGhuddyCommissionAmount,
    deltaGhuddyCommissionPercentPoint,
    deltaShurjoCommissionAmount,
    deltaShurjoCommissionPercentPoint,
    deltaCorporatePrice,
    deltaGhuddyWebSiteRedPrice
  ]);

  const handleNumberInput = (val,setval)=>{
    const valInt = parseInt(val);
    setval(valInt);
  }

  const handleAmountAndPercent = (
    type,
    val,
    setAmount,
    setPercent,
    base,
  ) => {
    if (type === "amount") {
      setAmount(parseFloat(val));
      setPercent(parseFloat((base - val) * 100) / base);
    } else {
      setPercent(parseFloat(val));
      setAmount(parseFloat(base - base * (val / 100)));
    }
  };

  const handleConversion = (type, val, setPercentAmount, setPercent, base) => {
    const valInt = parseFloat(val);
    if (type === "amount") {
      setPercentAmount(valInt);
      setPercent((valInt * 100) / base);
    } else {
      setPercent(valInt);
      setPercentAmount((base * valInt) / 100);
    }
  };

  


  const handleSetFilterPrice = ()=>{
    if(baseData){
      let filterObject ={
        days,
        deltaBookableRooms: deltaBookableRooms - baseData.bookableRooms,
        deltaCorporateDiscountPercentPoint : deltaCorporateDiscountPercentPoint - baseData.corporateDiscountPercent,
        deltaCorporatePrice : deltaCorporatePrice -baseData.corporatePrice,
        deltaDeactivatedRooms : deltaDeactivatedRooms - baseData.deactivatedRooms,
        deltaGhuddyCommissionAmount : deltaGhuddyCommissionAmount - baseData.ghuddyCommissionAmount,
        deltaGhuddyCommissionPercentPoint : deltaGhuddyCommissionPercentPoint - baseData.ghuddyCommissionPercent,
        deltaGhuddySubsidyAmount : deltaGhuddySubsidyAmount - baseData.ghuddySubsidyAmount,
        deltaGhuddySubsidyPercentPoint : deltaGhuddySubsidyPercentPoint - baseData.ghuddySubsidyPercent,
        deltaGhuddyWebSiteBlackPrice : deltaGhuddyWebSiteBlackPrice - baseData.ghuddyWebSiteBlackPrice, 
        deltaGhuddyWebSiteRedPrice : deltaGhuddyWebSiteRedPrice - baseData.ghuddyWebSiteRedPrice,
        deltaRackPrice : deltaRackPrice - baseData?.rackPrice,
        deltaShurjoCommissionAmount : deltaShurjoCommissionAmount - baseData.shurjoCommissionAmount    ,
        deltaShurjoCommissionPercentPoint : deltaShurjoCommissionPercentPoint - baseData.shurjoCommissionPercent,
        deltaTotalDiscountPercentPoint : deltaTotalDiscountPercentPoint  - baseData.totalDiscountPercent,
      
        deltaTotalGhuddyCommissionAmount : deltaTotalGhuddyCommissionAmount - baseData.totalGhuddyCommissionAmount,
        deltaTotalGhuddyCommissionPercentPoint : deltaTotalGhuddyCommissionPercent - baseData.totalGhuddyCommissionPercent,
        deltaTotalShurjoCommissionAmount : deltaTotalShurjoCommissionAmount - baseData.totalShurjoCommissionAmount,
        deltaTotalRooms : deltaTotalRooms - baseData.totalRooms,
        endDate,
        policyName,
        policyType,
        requestId: "filter",
        startDate
      }
      console.log(filterObject,'filterObject')
    
      authAxios.post(`${baseUrl}${apiEndPoint.ADMIN_SET_AVAILABILITY_FILTER}${categoryId}`,filterObject)
      .then((res)=>{
        console.log(res.data);
        toast.success("availablity set successfully!");
        setToggleState(!toggleState);
      })
      .then(()=>{
        filterObject={};
        setdeltaRackPrice(0);
        setdeltaGhuddyCommissionAmount(0);
        setdeltaGhuddyCommissionPercentPoint(0);
        setdeltaCorporatePrice(0);
        setdeltaCorporateDiscountPercentPoint(0);

        setdeltaShurjoCommissionPercentPoint(0);
        setdeltaShurjoCommissionAmount(0);
          
        setdeltaAfterGhuddyCommision(0);
        setdeltaGhuddyWebSiteBlackPrice(0);

        setdeltaGhuddyWebSiteRedPrice(0);

        setdeltaGhuddySubsidyAmount(0);
        setdeltaGhuddySubsidyPercentpoint(0);

        setdeltaTotalDiscountPercentPoint(0);
        setdeltaTotalGhuddyCommissionAmount(0);
        setdeltaTotalGhuddyCommissionPercent(0);
        setdeltaTotalShurjoCommissionAmount(0); 

        setdeltaTotalRooms(0);
        setdeltaBookableRooms(0);
        setdeltaDeactivatedRooms(0);
        
      })
      .catch((error) => {
              console.error("merror:", error, filterObject);
      });


    }
  }


  return (
    <div>
   <div className="text-center text-[27px] py-[20px] z-[50] sticky top-[210px]  shadow bg-bg-primary ">  <h1>2. SET FILTER PRICE</h1></div>

    <div className="flex justify-between my-6">
        <div className="w-1/2">
        <InputComponent
        name={"policyName"}
        inputValue={policyName}
        setInputValue={(val) => setPolicyName(val)}
        warningView={false}
        placeholder="Policy Name"
      />
        </div>

      <div className="w-1/3">
      <div className='bg-bg-primary w-[250px] '>
        {allMethodType.length>0 &&
        <SingleSelectFromSimpleArray
                        required={true}
                        data={allMethodType}
                        selectedValue={policyType}
                        setSelectedValue={
                            setPolicyType
                        }
                        placeholder="Policy Type"
                        isAdmin={false}
                        isReadOnly={false}
                        // rejectionArray={
                        //     addressRejectArray
                        // }
                        // setRejectionArray={
                        //     setAddressRejectArray
                        // }
                        name="MethodSelector"
                    />}
      </div>
      </div>

    </div>

    <div className="flex space-x-[120px] mt-[30px]">
                <div className="my-2">
                  <span>Start Date</span>
                <ReactDatePicker
                    title="Start Date"
                    placeholderText="Start Date"
                    className={` border  text-btn-primary  pl-6 rounded-[8px] bg-[transparent] h-[49px] w-full  "border-btn-primary`}
                    selected={startDate}
                    dateFormat="dd/MM/yyyy"
                    onChange={(date) =>{ setStartDate(date);
                    }}
                />
                </div>
                <div className="my-2">
                  <span>End Date</span>
                <ReactDatePicker
                    title="End Date"
                    placeholderText="End Date"
                    className="border border-btn-primary text-btn-primary  pl-6 rounded-[8px] bg-[transparent] h-[49px] w-full"
                    selected={endDate}
                    dateFormat="dd/MM/yyyy"
                    onChange={(date) => setEndDate(date)}
                />
                </div>
      </div>

      <div>
        <div className='flex items-center '>
          <div className='text-[18px]'> Select Days : </div>
          <div className="flex items-center pl-3">
                    <input onChange={()=>{
                      if(isAllday !== isAlldayCheck){
                        setIsAllDay(!isAlldayCheck);
                        setIsAllDayCheck(!isAlldayCheck);
                      }
                      else{
                        setIsAllDay(!isAllday);
                        setIsAllDayCheck(!isAlldayCheck);
                      }
                    
                    }} 
                    checked={isAlldayCheck} id="vue-checkbox-list" type="checkbox"  className="w-2 h-2 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500" />
                    <label htmlFor="vue-checkbox-list" className="w-full py-2 ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Select All day</label>
          </div>
        </div>
        <div>
        <ul className="items-center w-full text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg sm:flex dark:bg-gray-700 dark:border-gray-600 dark:text-white">
          {dayTagName.map((day)=>
          <DayComponents 
          setIsAllDayCheck={setIsAllDayCheck} 
          isAlldayCheck={isAlldayCheck} 
          dayTagName={dayTagName} 
          setIsAllDay={setIsAllDay} 
          key={day} 
          isAllday={isAllday} 
          days={days} 
          setDays={setDays} 
          dayName={day} />
          )
          }
        </ul>
        </div>
      </div>

      <div className="flex flex-col">
        <div className="my-2">
          <div className="text-lg mt-[1rem] mb-2">
            <span>RackPrice:</span>

            <InputComponent
              name={"deltaRackPrice"}
              inputValue={deltaRackPrice}
              setInputValue={(val) => handleNumberInput(val,setdeltaRackPrice)}
              warningView={false}
              type="number"
              placeholder="New Rack Price"
              required
              spanValue="BDT"
              addSpan={true}
            />
           
            {/* <input type="number" value={deltaRackPrice}
            onChange={(e) => handleNumberInput(e.target.value,setdeltaRackPrice)}
            /> */}
          </div>

         {/* <div className='border relative'>
        {baseData?.rackPrice && <input
            type="range"
            min="0"
            max="100000"
            step={1}
            value={`${baseData?.rackPrice}`}
            readOnly
            className="sliderCustom absolute left-0 sliderCustom2"
            id="myRange2"
          />}

        <input
          type="range"
          min="0"
          max="100000"
          step={1}
          value={`${deltaRackPrice}`}
          onChange={(e) => handleNumberInput(e.target.value,setdeltaRackPrice)}
          className="sliderCustom "
          id="myRange"
        />
         
         </div> */}
        </div>

        <div>
          <div className="text-base mb-2">Corporate Discount</div>
          <div className="flex gap-2 items-center">
            <InputComponent
              name={"deltaCorporatediscountAmount"}
              warningView={false}
              placeholder={"Corporate Rate"}
              inputValue={deltaCorporatePrice}
              setInputValue={(val) => {
                // setDiscount(val);
                handleAmountAndPercent(
                  "amount",
                  val,
                  setdeltaCorporatePrice,
                  setdeltaCorporateDiscountPercentPoint,
                  deltaRackPrice
                );
              }}
              addSpan={true}
              spanValue="BDT"
              type="number"
            />
            or
            <InputComponent
              name={"deltaDiscountPercent"}
              warningView={false}
              inputValue={deltaCorporateDiscountPercentPoint}
              setInputValue={(val) => {
                // setDiscountPercent(val);
                handleAmountAndPercent(
                  "percent",
                  val,
                  setdeltaCorporatePrice,
                  setdeltaCorporateDiscountPercentPoint,
                  deltaRackPrice
                );
              }}
              placeholder={"Corporate discount %"}
              type="number"
              addSpan={true}
              spanValue="%"
            />
          </div>
        </div>

        <div>
          <div className="text-base mb-2">Ghuddy commision </div>
          <div className="flex gap-2 items-center">
            <InputComponent
              name={"deltaGhuddyCommissionAmount"}
              warningView={false}
              inputValue={deltaGhuddyCommissionAmount}
              setInputValue={(val) => {
                // setDiscount(val);
                handleConversion(
                  "amount",
                  val,
                  setdeltaGhuddyCommissionAmount,
                  setdeltaGhuddyCommissionPercentPoint,
                  deltaCorporatePrice
                );
              }}
              addSpan={true}
              spanValue="BDT"
              type="number"
            />
            or
            <InputComponent
              name={"deltaGhuddyCommissionPercemt"}
              warningView={false}
              inputValue={deltaGhuddyCommissionPercentPoint}
              setInputValue={(val) => {
                // setDiscountPercent(val);
                handleConversion(
                  "percent",
                  val,
                  setdeltaGhuddyCommissionAmount,
                  setdeltaGhuddyCommissionPercentPoint,
                  deltaCorporatePrice
                );
              }}
              addSpan={true}
              spanValue="%"
              type="number"
            />
          </div>
        </div>
        <div className="py-4">
          Price after ghuddy commission :{deltaAfterGhuddyCommission}
        </div>
        <div>
          <div className="text-base mb-2">Shurjo commision </div>
          <div className="flex gap-2 items-center">
            <InputComponent
              name={"deltaShurjoCommissionAmount"}
              warningView={false}
              inputValue={deltaShurjoCommissionAmount}
              setInputValue={(val) => {
                // setDiscount(val);
                handleConversion(
                  "amount",
                  val,
                  setdeltaShurjoCommissionAmount,
                  setdeltaShurjoCommissionPercentPoint,
                  deltaAfterGhuddyCommission
                );
              }}
              addSpan={true}
              spanValue="BDT"
              type="number"
            />
            or
            <InputComponent
              name={"deltaShurjoCommissionPercemt"}
              warningView={false}
              inputValue={deltaShurjoCommissionPercentPoint}
              setInputValue={(val) => {
                // setDiscountPercent(val);
                handleConversion(
                  "percent",
                  val,
                  setdeltaShurjoCommissionAmount,
                  setdeltaShurjoCommissionPercentPoint,
                  deltaAfterGhuddyCommission
                );
              }}
              addSpan={true}
              spanValue="%"
              type="number"
            />
          </div>
        </div>
        <div className="py-4">
          Price after shurjo commision (website black price) :{" "}
          {deltaGhuddyWebSiteBlackPrice}
        </div>

        <div className="text-base mb-2">ghuddy website Red price </div>
        <div className="flex gap-2 items-center">
          <InputComponent
            name={"deltaGhuddyWebsiteRedPrice"}
            warningView={false}
            inputValue={deltaGhuddyWebSiteRedPrice}
            setInputValue={(val) => {
              handleNumberInput(val,setdeltaGhuddyWebSiteRedPrice)
            }}
            addSpan={true}
            spanValue="BDT"
            type="number"
          />
        </div>

        <div>
          <div>Ghuddy Subsidy Amount : {deltaGhuddySubsidyAmount ? deltaGhuddySubsidyAmount.toFixed(2) : 0 } BDT</div>
          <div>Ghuddy Subsidy Percent : {deltaGhuddySubsidyPercentPoint ? deltaGhuddySubsidyPercentPoint.toFixed(2) : 0} %</div>
          <div>
            total shurjo commission Amount : {deltaTotalShurjoCommissionAmount ? deltaTotalShurjoCommissionAmount.toFixed(3) : 0 } %
          </div>
          <div>Ghuddy total discount {deltaTotalDiscountPercentPoint ? deltaTotalDiscountPercentPoint.toFixed(2) : 0 } % </div>
          <div>
            total ghuddy commision amount: {deltaTotalGhuddyCommissionAmount ? deltaTotalGhuddyCommissionAmount.toFixed(2) : 0 } BDT
          </div>
          <div>
            total ghuddy commission Percent : {deltaTotalGhuddyCommissionPercent ? deltaTotalGhuddyCommissionPercent.toFixed(2) : 0 } %
          </div>

        </div>

        <div className="space-y-[10px]">
          <InputComponent
            name={"deltaTotalRoom"}
            inputValue={deltaTotalRooms}
            setInputValue={(val) => handleNumberInput(val,setdeltaTotalRooms)}
            warningView={false}
            placeholder="Total Room"
            type="number"
          />

          <InputComponent
            name={"deltaBookableRooms"}
            inputValue={deltaBookableRooms}
            setInputValue={(val) => handleNumberInput(val,setdeltaBookableRooms)}
            warningView={false}
            placeholder="Bookable Rooms"
            type="number"
          />

          <InputComponent
            name={"deltaDeactivatedRoom"}
            inputValue={deltaDeactivatedRooms}
            setInputValue={(val) =>  handleNumberInput(val,setdeltaDeactivatedRooms)}
            warningView={false}
            placeholder="Deactivated Room"
            type="number"
          />
        </div>

        <div className="mt-[2rem]">
          <ButtonFilled
            title="Submit"
            buttonFilledHandle={handleSetFilterPrice}
            w="100%"
            h="49px"
            ml={"ml-[0px]"}
          />
        </div>
      </div>

</div>
  );
};

export default AdminSetFilterPrice;
