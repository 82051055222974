import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router";
import YearPicker from "../../../components/calender/YearPicker";
import BackButton from "../../../components/common/BackButton";
import SearchPhoneTwo from "../../../components/dropdown/SearchPhoneTwo";
import InputComponent from "../../../components/InputComponent";
import Loading from "../../../components/Loader/Loading";
import MapModal from "../../../components/modal/MapModal";
import MultiSelectParent from "../../../components/multiselect/MultiSelectParent";
import MultiSelectParentObjectReturn from "../../../components/multiselect/MultiSelectParentObjectReturn";
import RemoveableImageUploadView from "../../../components/RemoveableImageUploadView";
import SingleSelectFromSimpleArray from "../../../components/singleSelect/SingleSelectFromSimpleArray";
import SingleSelectParentReturnObject from "../../../components/singleSelect/singleSelectObjectReturn/SingleSelectParentReturnObject";
import SingleSelectParent from "../../../components/singleSelect/SingleSelectParent";
import TimePicker from "../../../components/TimePicker";
import { DashboardRoutes, merchantRoute, UserType } from "../../../contants/Constants";
import { hotelsDetails } from "../../../store/admin/hotel/api/adminContractApi";
import { postHotelPropertyInfoSave } from "../../../store/registration/hotelProperty/api/rentalApi";
import { returnAdminOrUserBasedOnPath } from "../../../utils/returnAdminOrUserBasedOnPath";
import { setterNullSafety } from "../../../utils/setterException";
import SecondaryNav from "../comp/SecondaryNav";
import FileMultiple from "../../../components/multipleimage/FileMultiple"
import ButtonOutlined from "../../../components/ButtonOutlined";
import ButtonFilled from "../../../components/ButtonFilled";
import { apiEndPoint, PROPERTY_ENDPOINTS } from "../../../contants/Endpoints";
import { Formik, Field, ErrorMessage, getIn } from 'formik';
import { authAxios } from "../../../utils/axiosWrapper";

function MerchantDashboardHotelDetails() {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  let param = useParams();
  let id = param.id;
  let superContractId = param.adminContractId;

  const [selectedObject, setSelectedObject] = useState();
  //temp uploaded images url
  const [urlTempStorageArray, setUrlTempStorageArray] = useState([]);
  const fetchSummeryData = useSelector(
    (state) => state.fetchSummeryReducer.data
  );

  // map
  const [lat, setLat] = useState();
  const [lng, setLng] = useState();
  // info
  const [images, setImages] = useState();
  const [hotelName, setHotelName] = useState("");
  const [country, setCountry] = useState("");
  const [hotelWebsite, setHotelWebsite] = useState("");
  const [address, setAddress] = useState("");
  const [addressOptional, setAddressOptional] = useState("");

  // extra 3 field
  const [contactCode, setcontactCode] = useState("");
  const [contactNo, setcontactNo] = useState("");
  const [email, setemail] = useState("");
  // some helper state
  const [countryPhoneCodeArr, setCountryPhoneCodeArr] = useState([]);

  const [addressRejectArray, setAddressRejectArray] = useState({});
  const [facilitiesRejectArray, setFacilitiesRejectArray] = useState({});
  const [imageRejectArray, setImageRejectArray] = useState({});
  const [propertyRejectArray, setPropertyRejectArray] = useState({});

  const [zipCode, setZipCode] = useState("");
  const [division, setDivision] = useState("");
  const [city, setCity] = useState("");
  const [municipility, setMunicipility] = useState("");
  const [community, setCommunity] = useState("");
  //   property
  const [renovationDate, setRenovationDate] = useState("");
  const [buildDate, setBuildDate] = useState("");
  const [rooms, setRooms] = useState("");
  const [floors, setFloors] = useState("");

  //   policies
  const [checkinTime, setCheckinTime] = useState("");
  const [checkoutTime, setCheckoutTime] = useState("");
  const [numberofChildren, setNumberofChildren] = useState("");
  const [childAge, setChildAge] = useState("");
  const [policy, setPolicy] = useState([]);

  //   services
  const [servicesTag, setServicesTag] = useState([]);
  const [spokenLanguages, setSpokenLanguages] = useState([]);
  const [classRating, setClassRating] = useState([]);

  //   drink and food
  const [numberRestaurants, setNumberRestaurants] = useState("");
  const [restaurants, setRestaurants] = useState([]);
  const [foodDrink, setFoodDrink] = useState([]);

  //   parking
  const [parking, setParking] = useState("");

  // families
  const [families, setFamilies] = useState([]);

  // House keeping
  const [houseKeeping, setHouseKeeping] = useState([]);

  // Health and safety
  const [healthSafety, sethealthSafety] = useState([]);

  // Accessibility
  const [accessibility, setAccessibility] = useState([]);

  // Wellness
  const [wellnessTag, setWellnessTag] = useState([]);
  const [spots, setSpots] = useState([]);

  //   pools
  const [numberPools, setNumberPools] = useState("");
  const [indoorPools, setIndoorPools] = useState("");
  const [outdoorPools, setOutdoorPools] = useState("");

  // Activities
  const [activitiesTag, setActivitiesTag] = useState([]);

  // payment options
  const [paymentOption, setPaymentOption] = useState([]);

  // cancellation policy
  const [cancellation, setCancellation] = useState([]);

  // Connectivity
  const [connectivity, setConnectivity] = useState([]);

  // Business
  const [meetingRoom, setMeetingRoom] = useState("");
  const [businessTag, setbusinessTag] = useState([]);

  // Transportation
  const [transportation, setTransportation] = useState([]);

  // api country data
  const [apiCity, setApiCity] = useState();
  const [apiMunicipility, setApiMunicipility] = useState();
  const [apicomm, setApicomm] = useState();
  const [apiDivision, setApiDivision] = useState();
  const [allCountries, setAllCountries] = useState();
  const [apiZips, setApiZips] = useState();

  // tag calls
  const [policysTag, setPolicysTag] = useState([]);
  const [serviceTag, setServiceTag] = useState([]);
  const [languageTag, setLanguageTag] = useState([]);
  const [restaurantsTag, setRestaurantsTag] = useState([]);
  const [foodDrinkTag, setFoodDrinkTag] = useState([]);
  const [familyTags, setFamilyTags] = useState([]);
  const [houseKeepingTag, sethouseKeepingTag] = useState([]);
  const [healthSafetyTag, setHealthSafetyTag] = useState([]);
  const [accessibilityTag, setAccessibilityTag] = useState([]);
  const [wellnesTag, setWellnesTag] = useState([]);
  const [spotsAroundTag, setSpotsAroundTag] = useState([]);
  const [activitityTag, setActivitityTag] = useState([]);
  const [paymentOptionTag, setPaymentOptionTag] = useState([]);
  const [cancellationTag, setCancellationTag] = useState([]);
  const [connectivityTag, setConnectivityTag] = useState([]);
  const [businesssTag, setBusinesssTag] = useState([]);
  const [transportationTag, setTransportationTag] = useState([]);

  const [energyefficiency, setEnergyefficiency] = useState([]);
  const [enhancedcleaning, setEnhancedcleaning] = useState([]);
  const [increasedfoodsafety, setIncreasedfoodsafety] = useState([]);
  const [minimizedcontact, setMinimizedcontact] = useState([]);
  const [personalprotection, setPersonalprotection] = useState([]);
  const [physicaldistancing, setPhysicaldistancing] = useState([]);
  const [parkings, setParkings] = useState([]);
  const [pools, setPools] = useState([]);
  const [pets, setPets] = useState([]);
  const [rentalPropertyClasses, setRentalPropertyClasses] = useState([]);

  const [wastereduction, setWastereduction] = useState([]);
  const [sustainablesourcing, setSustainablesourcing] = useState([]);
  const [sustainabilitycertifications, setSustainabilitycertifications] =
    useState([]);
  const [waterconservation, setWaterconservation] = useState([]);

  // for selection
  const [policysTagSelected, setPolicysTagSelected] = useState([]);
  const [enhancedcleaningSelected, setEnhancedcleaningSelected] = useState([]);
  const [serviceTagSelected, setServiceTagSelected] = useState([]);
  const [languageTagSelected, setLanguageTagSelected] = useState([]);
  const [restaurantsTagSelected, setRestaurantsTagSelected] = useState([]);
  const [foodDrinkTagSelected, setFoodDrinkTagSelected] = useState([]);
  const [familyTagsSelected, setFamilyTagsSelected] = useState([]);
  const [houseKeepingTagSelected, sethouseKeepingTagSelected] = useState([]);
  const [healthSafetyTagSelected, setHealthSafetyTagSelected] = useState([]);
  const [accessibilityTagSelected, setAccessibilityTagSelected] = useState([]);
  const [wellnesTagSelected, setWellnesTagSelected] = useState([]);
  const [spotsAroundTagSelected, setSpotsAroundTagSelected] = useState([]);
  const [activitityTagSelected, setActivitityTagSelected] = useState([]);
  const [paymentOptionTagSelected, setPaymentOptionTagSelected] = useState([]);
  const [cancellationTagSelected, setCancellationTagSelected] = useState([]);
  const [connectivityTagSelected, setConnectivityTagSelected] = useState([]);
  const [businesssTagSelected, setBusinesssTagSelected] = useState([]);
  const [transportationTagSelected, setTransportationTagSelected] = useState(
    []
  );
  const [energyefficiencySelected, setEnergyefficiencySelected] = useState([]);
  const [increasedfoodsafetySelected, setIncreasedfoodsafetySelected] =
    useState([]);
  const [minimizedcontactSelected, setMinimizedcontactSelected] = useState([]);
  const [personalprotectionSelected, setPersonalprotectionSelected] = useState(
    []
  );
  const [physicaldistancingSelected, setPhysicaldistancingSelected] = useState(
    []
  );
  const [parkingsSelected, setParkingsSelected] = useState([]);
  const [poolsSelected, setPoolsSelected] = useState([]);
  const [petsSelected, setPetsSelected] = useState([]);
  const [rentalPropertyClassesSelected, setRentalPropertyClassesSelected] =
    useState();
  const [wasteReductionSelected, setwasteReductionSelected] = useState([]);
  const [sustainableSourcingSelected, setSustainableSourcingSelected] =
    useState([]);
  const [
    sustainabilitycertificationsSelected,
    setSustainabilitycertificationsSelected,
  ] = useState([]);
  const [waterconservationSelect, setWaterconservationSelect] = useState([]);

  // modal trigger
  const [modalTrigger, setModalTrigger] = useState(false);
  const handleMapModal = () => {
    console.log("handleMapModal");
    setModalTrigger(true);
  };
  // some helper state
  const [needUpdate, setNeedUpdate] = useState(Math.random());
  const [loading, setLoading] = useState(false);

  function timeconverter(time) {
    try {
      if (time && time.toString().length > 24) {
        return time.toString().slice(16, 24);
      } else {
        return time;
      }
    } catch (err) {
      alert("invalid time zone", err);
    }
  }
  let obj_update = {
    address: {
      admin1: division,
      admin2: city,
      admin3: municipility,
      admin4: "string",
      billingAddress: true,
      country: country,
      latitude: lat,
      longitude: lng,
      optionalPart: addressOptional,
      organizationName: "string",
      place: community,
      primaryAddress: true,
      requestId: "string",
      street: address,
      zip: zipCode,
    },
    builtYear: buildDate,
    checkInTime: timeconverter(checkinTime) + ".999999999",
    checkOutTime: timeconverter(checkoutTime) + ".999999999",
    contactCode: contactCode,
    contactNo: contactNo,
    contractId: localStorage.getItem("contractId"),
    email: email,

    description: "string",

    facilities: [
      ...energyefficiencySelected,
      ...wasteReductionSelected,
      ...personalprotectionSelected,
      ...sustainableSourcingSelected,
      ...sustainabilitycertificationsSelected,
      ...waterconservationSelect,
      ...minimizedcontactSelected,
      ...poolsSelected,
      ...petsSelected,
      ...parkingsSelected,
      ...enhancedcleaningSelected,
      ...physicaldistancingSelected,
      ...increasedfoodsafetySelected,
      ...wellnesTagSelected,
      ...accessibilityTagSelected,
      ...houseKeepingTagSelected,
      ...familyTagsSelected,
      ...foodDrinkTagSelected,
      ...policysTagSelected,
      ...serviceTagSelected,
      ...transportationTagSelected,
      ...businesssTagSelected,
      ...connectivityTagSelected,
      ...activitityTagSelected,
      ...paymentOptionTagSelected,
    ],
    languages: languageTagSelected,
    lastRenovatedYear: renovationDate,
    maxChildAge: childAge,
    maxNoOfKidsStayForFree: 0,
    noOfIndoorPools: indoorPools,
    noOfMeetingRooms: meetingRoom,
    noOfOutdoorPools: outdoorPools,
    noOfPools: numberPools,
    noOfRestaurants: numberRestaurants,
    numberOfFloor: floors,
    numberOfRooms: rooms,
    propertyId: id,
    propertyName: hotelName,
    // propertyType: selectedObject,
    propertyType: rentalPropertyClassesSelected,
    requestId: "string",
    servicesClassRating: 0,
    website: hotelWebsite,
  };

  // for save button click
  const createContractMoveingDecideSave = () => {
    navigate(DashboardRoutes.HOTELS)
  };

  // useEffect for loading upper part
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // api call for post
  const buttonOutlinedHandle = () => {
    dispatch(
      postHotelPropertyInfoSave(
        obj_update,
        urlTempStorageArray,
        createContractMoveingDecideSave,
        ()=>{}
      )
    );
  };

  // for next button click
  const createContractMoveingDecideNext = () => {
    navigate(DashboardRoutes.HOTELS)
  };
  const buttonFilledHandle = () => {
    dispatch(
      postHotelPropertyInfoSave(
        obj_update,
        urlTempStorageArray,
        createContractMoveingDecideNext,
        ()=>{}
      )
    );
  };

  // fetch country
  useEffect(() => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("jwt")}`,
    };

    authAxios
      .get(
        `${PROPERTY_ENDPOINTS.FETCH_ADDRESS_ATTRIBUTE_BY_COUNTRY_CODE}${country}?requestId=${localStorage.getItem(
          "id"
        )}`,
        {
          headers: headers,
        }
      )
      .then((response) => {
        setApiDivision(response.data.admin1s);
        setApiCity(response.data.admin2s);
        setApiMunicipility(response.data.admin3s);
        setApicomm(response.data.places);
        setApiZips(response.data.zips);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [country]);

  // fetch country all
  useEffect(() => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("jwt")}`,
    };

    authAxios
      .get(
        `${apiEndPoint.GET_COUNTRY_DETAILS}?requestId=${localStorage.getItem(
          "id"
        )}`,
        {
          headers: headers,
        }
      )
      .then((response) => {
        setAllCountries(response.data.countries);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  // fetch all country -> phone code
  useEffect(() => {
    if (localStorage.getItem("id")) {
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("jwt")}`,
      };

      authAxios
        .get(
          `${apiEndPoint.GET_COUNTRY_DETAILS}?requestId=${localStorage.getItem(
            "id"
          )}`,
          {
            headers: headers,
          }
        )
        .then((response) => {
          //console.log("response all country data:", response.data.countries);
          setCountryPhoneCodeArr(response.data.countries);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, []);

  const response = useSelector((state) => state.hotelsInfoDetailsGetReducer);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    // window.scrollTo(0, 0);
    dispatch(hotelsDetails(returnAdminOrUserBasedOnPath(location.pathname), id));
  }, [needUpdate]);

  useEffect(() => {
    if (id == 0) {
      return null;
    } else {
      setLoading(false);
      if (response && response.data && response.data.propertyData) {
        // try{
        setterNullSafety(() => {
          setImages(response.data.propertyData.images);
        });
        setterNullSafety(() => {
          setLng(response.data.propertyData.address.longitude);
        });
        setterNullSafety(() => {
          setLat(response.data.propertyData.address.latitude);
        });
        setterNullSafety(() => {
          setemail(response.data.propertyData.email);
        });
        setterNullSafety(() => {
          setcontactCode(response.data.propertyData.contactCode);
        });
        setterNullSafety(() => {
          setcontactNo(response.data.propertyData.contactNo);
        });
        setterNullSafety(() => {
          setAddress(response.data.propertyData.address.street);
        });
        setterNullSafety(() => {
          setAddressOptional(response.data.propertyData.address.optionalPart);
        });
        setterNullSafety(() => {
          setCountry(response.data.propertyData.address.country);
        });
        setterNullSafety(() => {
          setDivision(response.data.propertyData.address.admin1);
        });
        setterNullSafety(() => {
          setCity(response.data.propertyData.address.admin2);
        });
        setterNullSafety(() => {
          setMunicipility(response.data.propertyData.address.admin3);
        });
        setterNullSafety(() => {
          setCommunity(response.data.propertyData.address.place);
        });
        setterNullSafety(() => {
          setZipCode(response.data.propertyData.address.zip);
        });
        setterNullSafety(() => {
          setRenovationDate(response.data.propertyData.lastRenovatedYear);
        });
        setterNullSafety(() => {
          setBuildDate(response.data.propertyData.builtYear);
        });
        setterNullSafety(() => {
          setHotelName(response.data.propertyData.propertyName);
        });
        setterNullSafety(() => {
          setHotelWebsite(response.data.propertyData.website);
        });
        setterNullSafety(() => {
          setRooms(response.data.propertyData.numberOfRooms);
        });
        setterNullSafety(() => {
          setFloors(response.data.propertyData.numberOfFloor);
        });
        setterNullSafety(() => {
          setMeetingRoom(response.data.propertyData.noOfMeetingRooms);
        });
        setterNullSafety(() => {
          setNumberPools(response.data.propertyData.noOfPools);
        });
        setterNullSafety(() => {
          setIndoorPools(response.data.propertyData.noOfIndoorPools);
        });
        setterNullSafety(() => {
          setOutdoorPools(response.data.propertyData.noOfOutdoorPools);
        });
        setterNullSafety(() => {
          setChildAge(response.data.propertyData.maxChildAge);
        });
        setterNullSafety(() => {
          setCheckinTime(response.data.propertyData.checkInTime);
        });
        setterNullSafety(() => {
          setCheckoutTime(response.data.propertyData.checkOutTime);
        });
        setterNullSafety(() => {
          setNumberRestaurants(response.data.propertyData.noOfRestaurants);
        });
        setterNullSafety(() => {
          // tags
          setRentalPropertyClassesSelected(
            response.data.propertyData.propertyType
          );
        });
        setterNullSafety(() => {
          setServiceTagSelected(
            response.data.propertyData.facilities.services.map((i) => i.id)
          );
        });
        setterNullSafety(() => {
          setLanguageTagSelected(response.data.propertyData.languages);
        });
        setterNullSafety(() => {
          sethouseKeepingTagSelected(
            response.data.propertyData.facilities.housekeeping.map((i) => i.id)
          );
        });
        setterNullSafety(() => {
          setEnhancedcleaningSelected(
            response.data.propertyData.facilities.enhancedcleaning.map(
              (i) => i.id
            )
          );
        });
        setterNullSafety(() => {
          setIncreasedfoodsafetySelected(
            response.data.propertyData.facilities.increasedfoodsafety.map(
              (i) => i.id
            )
          );
        });
        setterNullSafety(() => {
          setMinimizedcontactSelected(
            response.data.propertyData.facilities.minimizedcontact.map(
              (i) => i.id
            )
          );
        });
        setterNullSafety(() => {
          setPersonalprotectionSelected(
            response.data.propertyData.facilities.personalprotection.map(
              (i) => i.id
            )
          );
        });
        setterNullSafety(() => {
          setPhysicaldistancingSelected(
            response.data.propertyData.facilities.physicaldistancing.map(
              (i) => i.id
            )
          );
        });
        setterNullSafety(() => {
          setParkingsSelected(
            response.data.propertyData.facilities.parking.map((i) => i.id)
          );
        });
        setterNullSafety(() => {
          setPolicysTagSelected(
            response.data.propertyData.facilities.policies.map((i) => i.id)
          );
        });
        setterNullSafety(() => {
          setPaymentOptionTagSelected(
            response.data.propertyData.facilities.paymentoptions.map(
              (i) => i.id
            )
          );
        });
        setterNullSafety(() => {
          setPoolsSelected(
            response.data.propertyData.facilities.pools.map((i) => i.id)
          );
        });
        setterNullSafety(() => {
          setWellnesTagSelected(
            response.data.propertyData.facilities.wellness.map((i) => i.id)
          );
        });
        setterNullSafety(() => {
          setActivitityTagSelected(
            response.data.propertyData.facilities.activities.map((i) => i.id)
          );
        });
        setterNullSafety(() => {
          setTransportationTagSelected(
            response.data.propertyData.facilities.transportation.map(
              (i) => i.id
            )
          );
        });
        setterNullSafety(() => {
          setFamilyTagsSelected(
            response.data.propertyData.facilities.families.map((i) => i.id)
          );
        });
        setterNullSafety(() => {
          setConnectivityTagSelected(
            response.data.propertyData.facilities.connectivity.map((i) => i.id)
          );
        });
        setterNullSafety(() => {
          setAccessibilityTagSelected(
            response.data.propertyData.facilities.accessibility.map((i) => i.id)
          );
        });
        setterNullSafety(() => {
          setBusinesssTagSelected(
            response.data.propertyData.facilities.business.map((i) => i.id)
          );
        });
        setterNullSafety(() => {
          setPetsSelected(
            response.data.propertyData.facilities.pets.map((i) => i.id)
          );
        });
        setterNullSafety(() => {
          setFoodDrinkTagSelected(
            response.data.propertyData.facilities.foodanddrink.map((i) => i.id)
          );
        });
        setterNullSafety(() => {
          setWaterconservationSelect(
            response.data.propertyData.facilities.waterconservation.map(
              (i) => i.id
            )
          );
        });
        setterNullSafety(() => {
          setSustainabilitycertificationsSelected(
            response.data.propertyData.facilities.sustainabilitycertifications.map(
              (i) => i.id
            )
          );
        });
        setterNullSafety(() => {
          setSustainableSourcingSelected(
            response.data.propertyData.facilities.sustainablesourcing.map(
              (i) => i.id
            )
          );
        });
        setterNullSafety(() => {
          setwasteReductionSelected(
            response.data.propertyData.facilities.wastereduction.map(
              (i) => i.id
            )
          );
        });
        setterNullSafety(() => {
          setEnergyefficiencySelected(
            response.data.propertyData.facilities.energyefficiency.map(
              (i) => i.id
            )
          );
        });
        setterNullSafety(() => {
          setAddressRejectArray(
            response.data.propertyData.address.addressRejected
          );
        });
        setterNullSafety(() => {
          setFacilitiesRejectArray(
            response.data.propertyData.facilitiesRejected
          );
        });
        setterNullSafety(() => {
          setPropertyRejectArray(response.data.propertyData.propertyRejected);
        });
        setterNullSafety(() => {
          setLoading(false);
        });
        // }catch(e){
        //   console.log(e)
        // }
      }
    }
  }, [response]);


  // fetch all tags
  useEffect(() => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("jwt")}`,
    };

    authAxios
      .get(
        `${PROPERTY_ENDPOINTS.FETCH_FACILITIES}`,
        {
          headers: headers,
        }
      )
      .then((response) => {
        console.log("fetchallfacilities:", response.data);

        setServiceTag(response.data.rentalPropertyFacilities.services); //done
        sethouseKeepingTag(response.data.rentalPropertyFacilities.housekeeping); //done
        setEnergyefficiency(
          response.data.rentalPropertyFacilities.energyefficiency
        ); //done
        setIncreasedfoodsafety(
          response.data.rentalPropertyFacilities.increasedfoodsafety
        ); //done
        setMinimizedcontact(
          response.data.rentalPropertyFacilities.minimizedcontact
        ); //done
        setPersonalprotection(
          response.data.rentalPropertyFacilities.personalprotection
        ); //done
        setPhysicaldistancing(
          response.data.rentalPropertyFacilities.physicaldistancing
        ); //done
        setParkings(response.data.rentalPropertyFacilities.parking); //done
        setPolicysTag(response.data.rentalPropertyFacilities.policies); //done
        setPaymentOptionTag(
          response.data.rentalPropertyFacilities.paymentoptions
        );
        setFoodDrinkTag(response.data.rentalPropertyFacilities.foodanddrink);
        setPools(response.data.rentalPropertyFacilities.pools); //done
        setWellnesTag(response.data.rentalPropertyFacilities.wellness); //done
        setActivitityTag(response.data.rentalPropertyFacilities.activities);
        setTransportationTag(
          response.data.rentalPropertyFacilities.transportation
        );
        setFamilyTags(response.data.rentalPropertyFacilities.families);
        setConnectivityTag(response.data.rentalPropertyFacilities.connectivity);
        setBusinesssTag(response.data.rentalPropertyFacilities.business);
        setAccessibilityTag(
          response.data.rentalPropertyFacilities.accessibility
        );
        setPets(response.data.rentalPropertyFacilities.pets); //done
        setLanguageTag(response.data.languages);
        // setPets(response.data.rentalPropertyClasses); //done
        setEnergyefficiency(
          response.data.rentalPropertyFacilities.energyefficiency
        ); //done
        setEnhancedcleaning(
          response.data.rentalPropertyFacilities.enhancedcleaning
        );
        setWastereduction(
          response.data.rentalPropertyFacilities.wastereduction
        ); //done
        setSustainablesourcing(
          response.data.rentalPropertyFacilities.sustainablesourcing
        ); //done
        setSustainabilitycertifications(
          response.data.rentalPropertyFacilities.sustainabilitycertifications
        ); //done
        setWaterconservation(
          response.data.rentalPropertyFacilities.waterconservation
        ); //done
        setEnergyefficiency(
          response.data.rentalPropertyFacilities.energyefficiency
        ); //done
        setEnhancedcleaning(
          response.data.rentalPropertyFacilities.enhancedcleaning
        );
        setWastereduction(
          response.data.rentalPropertyFacilities.wastereduction
        ); //done
        setSustainablesourcing(
          response.data.rentalPropertyFacilities.sustainablesourcing
        ); //done
        setSustainabilitycertifications(
          response.data.rentalPropertyFacilities.sustainabilitycertifications
        ); //done
        setWaterconservation(
          response.data.rentalPropertyFacilities.waterconservation
        ); //done
        setRentalPropertyClasses(response.data.rentalPropertyClasses);
      })
      .catch((error) => {
        console.log(error, "try");
      });
  }, []);
  return (
    <div>
      <SecondaryNav />
      <div className="min-h-screen content-center justify-items-center justify-center pl-12 pr-12 pb-12 pt-12">
        {response.loading ? (
          <div className="ml-[45%]">
            <Loading />
          </div>
        ) : (
          <>
            <BackButton />
            <Formik
              initialValues={{
                hotelWebsite,
                addressOptional,
                hotelName,
                // address,
                email,
                contactNo,
                numberPools,
                restaurants,
                meetingRoom,
                indoorPools,
                rooms,
                floors,
                outdoorPools,
                childAge,
                numberRestaurants,
              }}
            >
              <div
                className="grid grid-cols-2 gap-28 py-10"
              >
                <div>
                  <div className="flex flex-col gap-y-4">
                    <h2 className="font-fontFamily-sans my-5 text-[27px] font-normal leading-[37.8px] text-txt-primary ">
                      Hotel Information
                    </h2>
                    <InputComponent
                      inputValue={hotelName}
                      setInputValue={setHotelName}
                      placeholder="Hotel Name"
                      isAdmin={
                        returnAdminOrUserBasedOnPath(location.pathname) ==
                          UserType.ADMIN
                          ? true
                          : false
                      }
                      isReadOnly={false}
                      rejectionArray={propertyRejectArray}
                      setRejectionArray={setPropertyRejectArray}
                      name="hotelName"
                    />
                    {/* extra 3 field */}
                    <InputComponent
                      inputValue={email}
                      setInputValue={setemail}
                      placeholder="Email"
                      isAdmin={
                        returnAdminOrUserBasedOnPath(location.pathname) ==
                          UserType.ADMIN
                          ? true
                          : false
                      }
                      isReadOnly={false}
                      rejectionArray={propertyRejectArray}
                      setRejectionArray={setPropertyRejectArray}
                      name="email"
                    />
                    <SearchPhoneTwo
                      inputValue={contactNo}
                      setInputValue={setcontactNo}
                      title={contactCode}
                      setTitle={setcontactCode}
                      titleArr={countryPhoneCodeArr.map(function (item) {
                        return item.phoneCode;
                      })}
                      isAdmin={
                        returnAdminOrUserBasedOnPath(location.pathname) ==
                          UserType.ADMIN
                          ? true
                          : false
                      }
                      isReadOnly={false}
                      rejectionArray={propertyRejectArray}
                      setRejectionArray={setPropertyRejectArray}
                      name="contactNo"
                    />
                    {/* extra 3 field */}
                    {/* <div className="grid grid-cols-2 gap-4"> */}
                    <div
                      className={`${returnAdminOrUserBasedOnPath(location.pathname) ==
                          UserType.ADMIN
                          ? "grid grid-cols-1"
                          : "grid grid-cols-2 gap-4"
                        }`}
                    >
                      <SingleSelectParent
                        selectedValue={country}
                        setSelectedValue={setCountry}
                        data={allCountries}
                        placeholder="Country"
                        displayField={"country"}
                        selectedFieldName="countryCode"
                        isAdmin={
                          returnAdminOrUserBasedOnPath(location.pathname) ==
                            UserType.ADMIN
                            ? true
                            : false
                        }
                        isReadOnly={false}
                        rejectionArray={addressRejectArray}
                        setRejectionArray={setAddressRejectArray}
                        name="country"
                      />
                      <SingleSelectFromSimpleArray
                        data={apiZips}
                        selectedValue={zipCode}
                        setSelectedValue={setZipCode}
                        placeholder="Zip Code"
                        isAdmin={
                          returnAdminOrUserBasedOnPath(location.pathname) ==
                            UserType.ADMIN
                            ? true
                            : false
                        }
                        isReadOnly={false}
                        rejectionArray={addressRejectArray}
                        setRejectionArray={setAddressRejectArray}
                        name="zip"
                      />
                    </div>
                    <InputComponent
                      inputValue={hotelWebsite}
                      setInputValue={setHotelWebsite}
                      placeholder="Hotel Website"
                      isAdmin={
                        returnAdminOrUserBasedOnPath(location.pathname) ===
                          UserType.ADMIN
                          ? true
                          : false
                      }
                      isReadOnly={false}
                      rejectionArray={propertyRejectArray}
                      setRejectionArray={setPropertyRejectArray}
                      name="hotelWebsite"
                    />
                    <div className="flex gap-2 w-full">
                      <InputComponent
                        inputValue={address}
                        setInputValue={setAddress}
                        placeholder="Address"
                        warningView={true}
                        name={"address"}
                      />
                      <div className="ml-auto">
                        <MapModal
                          setLat={setLat}
                          setLng={setLng}
                          lat={lat}
                          lng={lng}
                          isAdmin={
                            returnAdminOrUserBasedOnPath(location.pathname) ==
                              UserType.ADMIN
                              ? true
                              : false
                          }
                          isReadOnly={false}
                          rejectionArray={addressRejectArray}
                          setRejectionArray={setAddressRejectArray}
                          name="street"
                        />
                      </div>
                    </div>
                    {/* modal work */}

                    {/* modal work end */}
                    <InputComponent
                      inputValue={addressOptional}
                      setInputValue={setAddressOptional}
                      placeholder="Address (optional)"
                      isAdmin={
                        returnAdminOrUserBasedOnPath(location.pathname) ==
                          UserType.ADMIN
                          ? true
                          : false
                      }
                      isReadOnly={false}
                      rejectionArray={addressRejectArray}
                      setRejectionArray={setAddressRejectArray}
                      name="optionalPart"
                    />
                    <div
                      className={`${returnAdminOrUserBasedOnPath(location.pathname) ==
                          UserType.ADMIN
                          ? "grid grid-cols-1"
                          : "grid grid-cols-2 gap-4"
                        }`}
                    >
                      <SingleSelectFromSimpleArray
                        data={apiDivision}
                        selectedValue={division}
                        setSelectedValue={setDivision}
                        placeholder="Division"
                        isAdmin={
                          returnAdminOrUserBasedOnPath(location.pathname) ==
                            UserType.ADMIN
                            ? true
                            : false
                        }
                        isReadOnly={false}
                        rejectionArray={addressRejectArray}
                        setRejectionArray={setAddressRejectArray}
                        name="admin1"
                      />
                      <SingleSelectFromSimpleArray
                        data={apiCity}
                        selectedValue={city}
                        setSelectedValue={setCity}
                        placeholder="City"
                        isAdmin={
                          returnAdminOrUserBasedOnPath(location.pathname) ==
                            UserType.ADMIN
                            ? true
                            : false
                        }
                        isReadOnly={false}
                        rejectionArray={addressRejectArray}
                        setRejectionArray={setAddressRejectArray}
                        name="admin2"
                      />
                    </div>
                    <div className="grid grid-cols-1">
                      <SingleSelectFromSimpleArray
                        data={apiMunicipility}
                        selectedValue={municipility}
                        setSelectedValue={setMunicipility}
                        placeholder="Municipility"
                        isAdmin={
                          returnAdminOrUserBasedOnPath(location.pathname) ==
                            UserType.ADMIN
                            ? true
                            : false
                        }
                        isReadOnly={false}
                        rejectionArray={addressRejectArray}
                        setRejectionArray={setAddressRejectArray}
                        name="admin3"
                      />
                    </div>
                    <div className="grid grid-cols-1">
                      <SingleSelectFromSimpleArray
                        data={apicomm}
                        selectedValue={community}
                        setSelectedValue={setCommunity}
                        placeholder="Community"
                        isAdmin={
                          returnAdminOrUserBasedOnPath(location.pathname) ==
                            UserType.ADMIN
                            ? true
                            : false
                        }
                        isReadOnly={false}
                        rejectionArray={addressRejectArray}
                        setRejectionArray={setAddressRejectArray}
                        name="place"
                      />
                    </div>
                  </div>
                  <div>
                    <h2 className="font-fontFamily-sans mb-5 mt-7 text-[27px] font-normal leading-[37.8px] text-txt-primary">
                      Energy Efficiency
                    </h2>
                    <MultiSelectParent
                      selectedArray={energyefficiencySelected}
                      setSelectedArray={setEnergyefficiencySelected}
                      data={energyefficiency}
                      placeholder="Energy Efficiency Tags"
                      isAdmin={
                        returnAdminOrUserBasedOnPath(location.pathname) ==
                          UserType.ADMIN
                          ? true
                          : false
                      }
                      isReadOnly={false}
                      rejectionArray={facilitiesRejectArray}
                      setRejectionArray={setFacilitiesRejectArray}
                      name="energyefficiency"
                    />
                  </div>
                  <div>
                    <h2 className="font-fontFamily-sans mb-5 mt-7 text-[27px] font-normal leading-[37.8px] text-txt-primary">
                      Waste Reduction
                    </h2>
                    <MultiSelectParent
                      selectedArray={wasteReductionSelected}
                      setSelectedArray={setwasteReductionSelected}
                      data={wastereduction}
                      placeholder="Waste Reduction Tags"
                      isAdmin={
                        returnAdminOrUserBasedOnPath(location.pathname) ==
                          UserType.ADMIN
                          ? true
                          : false
                      }
                      isReadOnly={false}
                      rejectionArray={facilitiesRejectArray}
                      setRejectionArray={setFacilitiesRejectArray}
                      name="wastereduction"
                    />
                  </div>
                  <div>
                    <h2 className="font-fontFamily-sans mb-5 mt-7 text-[27px] font-normal leading-[37.8px] text-txt-primary">
                      Personal Protection
                    </h2>
                    <MultiSelectParent
                      selectedArray={personalprotectionSelected}
                      setSelectedArray={setPersonalprotectionSelected}
                      data={personalprotection}
                      placeholder="Personal Protection"
                      isAdmin={
                        returnAdminOrUserBasedOnPath(location.pathname) ==
                          UserType.ADMIN
                          ? true
                          : false
                      }
                      isReadOnly={false}
                      rejectionArray={facilitiesRejectArray}
                      setRejectionArray={setFacilitiesRejectArray}
                      name="personalprotection"
                    />
                  </div>
                  <div>
                    <h2 className="font-fontFamily-sans mb-5 mt-7 text-[27px] font-normal leading-[37.8px] text-txt-primary">
                      Sustainable Sourcing
                    </h2>
                    <MultiSelectParent
                      selectedArray={sustainableSourcingSelected}
                      setSelectedArray={setSustainableSourcingSelected}
                      data={sustainablesourcing}
                      placeholder="Sustainable Sourcing Tags"
                      isAdmin={
                        returnAdminOrUserBasedOnPath(location.pathname) ==
                          UserType.ADMIN
                          ? true
                          : false
                      }
                      isReadOnly={false}
                      rejectionArray={facilitiesRejectArray}
                      setRejectionArray={setFacilitiesRejectArray}
                      name="sustainablesourcing"
                    />
                  </div>
                  <div>
                    <h2 className="font-fontFamily-sans mb-5 mt-7 text-[27px] font-normal leading-[37.8px] text-txt-primary">
                      Sustainability Certifications
                    </h2>
                    <MultiSelectParent
                      selectedArray={sustainabilitycertificationsSelected}
                      setSelectedArray={setSustainabilitycertificationsSelected}
                      data={sustainabilitycertifications}
                      placeholder="Certifications Tags"
                      isAdmin={
                        returnAdminOrUserBasedOnPath(location.pathname) ==
                          UserType.ADMIN
                          ? true
                          : false
                      }
                      isReadOnly={false}
                      rejectionArray={facilitiesRejectArray}
                      setRejectionArray={setFacilitiesRejectArray}
                      name="sustainabilitycertifications"
                    />
                  </div>
                  <div>
                    <h2 className="font-fontFamily-sans mb-5 mt-7 text-[27px] font-normal leading-[37.8px] text-txt-primary">
                      Water Conservation
                    </h2>
                    <MultiSelectParent
                      selectedArray={waterconservationSelect}
                      setSelectedArray={setWaterconservationSelect}
                      data={waterconservation}
                      placeholder="Water Tags"
                      isAdmin={
                        returnAdminOrUserBasedOnPath(location.pathname) ==
                          UserType.ADMIN
                          ? true
                          : false
                      }
                      isReadOnly={false}
                      rejectionArray={facilitiesRejectArray}
                      setRejectionArray={setFacilitiesRejectArray}
                      name="waterconservation"
                    />
                  </div>
                  <div>
                    <h2 className="font-fontFamily-sans mb-5 mt-7 text-[27px] font-normal leading-[37.8px] text-txt-primary">
                      Minimized Contact
                    </h2>
                    <MultiSelectParent
                      selectedArray={minimizedcontactSelected}
                      setSelectedArray={setMinimizedcontactSelected}
                      data={minimizedcontact}
                      placeholder="Minimized Contact"
                      isAdmin={
                        returnAdminOrUserBasedOnPath(location.pathname) ==
                          UserType.ADMIN
                          ? true
                          : false
                      }
                      isReadOnly={false}
                      rejectionArray={facilitiesRejectArray}
                      setRejectionArray={setFacilitiesRejectArray}
                      name="minimizedcontact"
                    />
                  </div>
                  <div className="flex flex-col gap-y-4">
                    <h2 className="font-fontFamily-sans mb-5 mt-7 text-[27px] font-normal leading-[37.8px] text-txt-primary">
                      Pools
                    </h2>
                    <InputComponent
                      inputValue={numberPools}
                      setInputValue={setNumberPools}
                      placeholder="Number Of Pools"
                      isAdmin={
                        returnAdminOrUserBasedOnPath(location.pathname) ==
                          UserType.ADMIN
                          ? true
                          : false
                      }
                      isReadOnly={false}
                      rejectionArray={propertyRejectArray}
                      setRejectionArray={setPropertyRejectArray}
                      name="numberPools"
                    />
                    <InputComponent
                      inputValue={indoorPools}
                      setInputValue={setIndoorPools}
                      placeholder="Number Of Indoor Pools"
                      isAdmin={
                        returnAdminOrUserBasedOnPath(location.pathname) ==
                          UserType.ADMIN
                          ? true
                          : false
                      }
                      isReadOnly={false}
                      rejectionArray={propertyRejectArray}
                      setRejectionArray={setPropertyRejectArray}
                      name="indoorPools"
                    />
                    <InputComponent
                      inputValue={outdoorPools}
                      setInputValue={setOutdoorPools}
                      placeholder="Number Of Outdoor Pools "
                      isAdmin={
                        returnAdminOrUserBasedOnPath(location.pathname) ==
                          UserType.ADMIN
                          ? true
                          : false
                      }
                      isReadOnly={false}
                      rejectionArray={propertyRejectArray}
                      setRejectionArray={setPropertyRejectArray}
                      name="outdoorPools"
                    />
                    <MultiSelectParent
                      selectedArray={poolsSelected}
                      setSelectedArray={setPoolsSelected}
                      data={pools}
                      placeholder="Pools Tags"
                      isAdmin={
                        returnAdminOrUserBasedOnPath(location.pathname) ==
                          UserType.ADMIN
                          ? true
                          : false
                      }
                      isReadOnly={false}
                      rejectionArray={facilitiesRejectArray}
                      setRejectionArray={setFacilitiesRejectArray}
                      name="pools"
                    />
                  </div>
                  <div>
                    <h2 className="font-fontFamily-sans mb-5 mt-7 text-[27px] font-normal leading-[37.8px] text-txt-primary">
                      Pets
                    </h2>
                    <MultiSelectParent
                      selectedArray={petsSelected}
                      setSelectedArray={setPetsSelected}
                      data={pets}
                      placeholder="Pets Tags"
                      isAdmin={
                        returnAdminOrUserBasedOnPath(location.pathname) ==
                          UserType.ADMIN
                          ? true
                          : false
                      }
                      isReadOnly={false}
                      rejectionArray={facilitiesRejectArray}
                      setRejectionArray={setFacilitiesRejectArray}
                      name="pets"
                    />
                  </div>
                  <div>
                    <h2 className="font-fontFamily-sans mb-5 mt-7 text-[27px] font-normal leading-[37.8px] text-txt-primary">
                      Parking
                    </h2>
                    <MultiSelectParent
                      selectedArray={parkingsSelected}
                      setSelectedArray={setParkingsSelected}
                      data={parkings}
                      placeholder="Parkings"
                      isAdmin={
                        returnAdminOrUserBasedOnPath(location.pathname) ==
                          UserType.ADMIN
                          ? true
                          : false
                      }
                      isReadOnly={false}
                      rejectionArray={facilitiesRejectArray}
                      setRejectionArray={setFacilitiesRejectArray}
                      name="parking"
                    />
                  </div>
                  <div>
                    <h2 className="font-fontFamily-sans mb-5 mt-7 text-[27px] font-normal leading-[37.8px] text-txt-primary">
                      Enhanced Cleaning
                    </h2>
                    <MultiSelectParent
                      selectedArray={enhancedcleaningSelected}
                      setSelectedArray={setEnhancedcleaningSelected}
                      data={enhancedcleaning}
                      placeholder="Enhanced Cleaning Tags"
                      isAdmin={
                        returnAdminOrUserBasedOnPath(location.pathname) ==
                          UserType.ADMIN
                          ? true
                          : false
                      }
                      isReadOnly={false}
                      rejectionArray={facilitiesRejectArray}
                      setRejectionArray={setFacilitiesRejectArray}
                      name="enhancedcleaning"
                    />
                  </div>
                  <div>
                    <h2 className="font-fontFamily-sans mb-5 mt-7 text-[27px] font-normal leading-[37.8px] text-txt-primary">
                      Physical Distancing
                    </h2>
                    <MultiSelectParent
                      selectedArray={physicaldistancingSelected}
                      setSelectedArray={setPhysicaldistancingSelected}
                      data={physicaldistancing}
                      placeholder="Physical Distancing Tags"
                      isAdmin={
                        returnAdminOrUserBasedOnPath(location.pathname) ==
                          UserType.ADMIN
                          ? true
                          : false
                      }
                      isReadOnly={false}
                      rejectionArray={facilitiesRejectArray}
                      setRejectionArray={setFacilitiesRejectArray}
                      name="physicaldistancing"
                    />
                  </div>
                  <div>
                    <h2 className="font-fontFamily-sans mb-5 mt-7 text-[27px] font-normal leading-[37.8px] text-txt-primary">
                      Increased Food Safety
                    </h2>
                    <MultiSelectParent
                      selectedArray={increasedfoodsafetySelected}
                      setSelectedArray={setIncreasedfoodsafetySelected}
                      data={increasedfoodsafety}
                      placeholder="Increased Food Safety"
                      isAdmin={
                        returnAdminOrUserBasedOnPath(location.pathname) ==
                          UserType.ADMIN
                          ? true
                          : false
                      }
                      isReadOnly={false}
                      rejectionArray={facilitiesRejectArray}
                      setRejectionArray={setFacilitiesRejectArray}
                      name="increasedfoodsafety"
                    />
                  </div>
                  <div>
                    <h2 className="font-fontFamily-sans mb-5 mt-7 text-[27px] font-normal leading-[37.8px] text-txt-primary">
                      Wellness
                    </h2>
                    <MultiSelectParent
                      selectedArray={wellnesTagSelected}
                      setSelectedArray={setWellnesTagSelected}
                      data={wellnesTag}
                      placeholder="Wellness Tags"
                      isAdmin={
                        returnAdminOrUserBasedOnPath(location.pathname) ==
                          UserType.ADMIN
                          ? true
                          : false
                      }
                      isReadOnly={false}
                      rejectionArray={facilitiesRejectArray}
                      setRejectionArray={setFacilitiesRejectArray}
                      name="wellness"
                    />
                  </div>
                </div>
                <div>
                  <div className="flex flex-col gap-y-4">
                    <h2 className="font-fontFamily-sans mb-5 mt-7 text-[27px] font-normal leading-[37.8px] text-txt-primary">
                      Property Information
                    </h2>
                    <div className="">
                      <YearPicker
                        selectedValue={renovationDate}
                        setSelectedValue={setRenovationDate}
                        placeholder="Renovation date"
                        isAdmin={
                          returnAdminOrUserBasedOnPath(location.pathname) ==
                            UserType.ADMIN
                            ? true
                            : false
                        }
                        isReadOnly={false}
                        rejectionArray={propertyRejectArray}
                        setRejectionArray={setPropertyRejectArray}
                        name="lastRenovatedYear"
                      />
                    </div>
                    <div className="">
                      <YearPicker
                        selectedValue={buildDate}
                        setSelectedValue={setBuildDate}
                        placeholder="Built date"
                        isAdmin={
                          returnAdminOrUserBasedOnPath(location.pathname) ==
                            UserType.ADMIN
                            ? true
                            : false
                        }
                        isReadOnly={false}
                        rejectionArray={propertyRejectArray}
                        setRejectionArray={setPropertyRejectArray}
                        name="builtYear"
                      />
                    </div>
                    <InputComponent
                      inputValue={rooms}
                      setInputValue={setRooms}
                      placeholder="Rooms"
                      isAdmin={
                        returnAdminOrUserBasedOnPath(location.pathname) ==
                          UserType.ADMIN
                          ? true
                          : false
                      }
                      isReadOnly={false}
                      rejectionArray={propertyRejectArray}
                      setRejectionArray={setPropertyRejectArray}
                      name="rooms"
                    />
                    <InputComponent
                      inputValue={floors}
                      setInputValue={setFloors}
                      placeholder="Floors"
                      isAdmin={
                        returnAdminOrUserBasedOnPath(location.pathname) ==
                          UserType.ADMIN
                          ? true
                          : false
                      }
                      isReadOnly={false}
                      rejectionArray={propertyRejectArray}
                      setRejectionArray={setPropertyRejectArray}
                      name="floors"
                    />
                    <SingleSelectParentReturnObject
                      selectedValue={rentalPropertyClassesSelected}
                      setSelectedValue={setRentalPropertyClassesSelected}
                      data={rentalPropertyClasses}
                      placeholder="Property Type Tags"
                      displayField={"label"}
                      selectedFieldName="value"
                      setSelectedObject={setSelectedObject}
                      isAdmin={
                        returnAdminOrUserBasedOnPath(location.pathname) ==
                          UserType.ADMIN
                          ? true
                          : false
                      }
                      isReadOnly={false}
                      rejectionArray={propertyRejectArray}
                      setRejectionArray={setPropertyRejectArray}
                      name="propertyType"
                    />
                  </div>
                  <div>
                    <h2 className="font-fontFamily-sans mb-5 mt-7 text-[27px] font-normal leading-[37.8px] text-txt-primary">
                      Languages
                    </h2>
                    <MultiSelectParentObjectReturn
                      selectedArray={languageTagSelected}
                      setSelectedArray={setLanguageTagSelected}
                      data={languageTag}
                      placeholder="Language Tags"
                      isAdmin={
                        returnAdminOrUserBasedOnPath(location.pathname) ==
                          UserType.ADMIN
                          ? true
                          : false
                      }
                      isReadOnly={false}
                      rejectionArray={propertyRejectArray}
                      setRejectionArray={setPropertyRejectArray}
                      name="languages"
                    />
                  </div>
                  <div>
                    <h2 className="font-fontFamily-sans mb-5 mt-7 text-[27px] font-normal leading-[37.8px] text-txt-primary">
                      Payment Options
                    </h2>
                    <MultiSelectParent
                      selectedArray={paymentOptionTagSelected}
                      setSelectedArray={setPaymentOptionTagSelected}
                      data={paymentOptionTag}
                      placeholder="Payment Option Tags"
                      isAdmin={
                        returnAdminOrUserBasedOnPath(location.pathname) ==
                          UserType.ADMIN
                          ? true
                          : false
                      }
                      isReadOnly={false}
                      rejectionArray={facilitiesRejectArray}
                      setRejectionArray={setFacilitiesRejectArray}
                      name="paymentoptions"
                    />
                  </div>
                  <div>
                    <h2 className="font-fontFamily-sans mb-5 mt-7 text-[27px] font-normal leading-[37.8px] text-txt-primary">
                      Activities
                    </h2>
                    <MultiSelectParent
                      selectedArray={activitityTagSelected}
                      setSelectedArray={setActivitityTagSelected}
                      data={activitityTag}
                      placeholder="Activity Tags"
                      isAdmin={
                        returnAdminOrUserBasedOnPath(location.pathname) ==
                          UserType.ADMIN
                          ? true
                          : false
                      }
                      isReadOnly={false}
                      rejectionArray={facilitiesRejectArray}
                      setRejectionArray={setFacilitiesRejectArray}
                      name="activities"
                    />
                  </div>
                  <div>
                    <h2 className="font-fontFamily-sans mb-5 mt-7 text-[27px] font-normal leading-[37.8px] text-txt-primary">
                      Connectivity
                    </h2>
                    <MultiSelectParent
                      selectedArray={connectivityTagSelected}
                      setSelectedArray={setConnectivityTagSelected}
                      data={connectivityTag}
                      placeholder="Connectivity Tags"
                      isAdmin={
                        returnAdminOrUserBasedOnPath(location.pathname) ==
                          UserType.ADMIN
                          ? true
                          : false
                      }
                      isReadOnly={false}
                      rejectionArray={facilitiesRejectArray}
                      setRejectionArray={setFacilitiesRejectArray}
                      name="connectivity"
                    />
                  </div>
                  <div className="flex flex-col gap-y-4">
                    <h2 className="font-fontFamily-sans mb-5 mt-7 text-[27px] font-normal leading-[37.8px] text-txt-primary">
                      Business
                    </h2>
                    <InputComponent
                      inputValue={meetingRoom}
                      setInputValue={setMeetingRoom}
                      placeholder="Number of Meeting Rooms"
                      isAdmin={
                        returnAdminOrUserBasedOnPath(location.pathname) ==
                          UserType.ADMIN
                          ? true
                          : false
                      }
                      isReadOnly={false}
                      rejectionArray={propertyRejectArray}
                      setRejectionArray={setPropertyRejectArray}
                      name="meetingRoom"
                    />
                    <div className="">
                      <MultiSelectParent
                        selectedArray={businesssTagSelected}
                        setSelectedArray={setBusinesssTagSelected}
                        data={businesssTag}
                        placeholder="Business Tags"
                        isAdmin={
                          returnAdminOrUserBasedOnPath(location.pathname) ==
                            UserType.ADMIN
                            ? true
                            : false
                        }
                        isReadOnly={false}
                        rejectionArray={facilitiesRejectArray}
                        setRejectionArray={setFacilitiesRejectArray}
                        name="business"
                      />
                    </div>
                  </div>
                  <div>
                    <h2 className="font-fontFamily-sans mb-5 mt-7 text-[27px] font-normal leading-[37.8px] text-txt-primary">
                      Transportation
                    </h2>
                    <MultiSelectParent
                      selectedArray={transportationTagSelected}
                      setSelectedArray={setTransportationTagSelected}
                      data={transportationTag}
                      placeholder="Transportation Tags"
                      isAdmin={
                        returnAdminOrUserBasedOnPath(location.pathname) ==
                          UserType.ADMIN
                          ? true
                          : false
                      }
                      isReadOnly={false}
                      rejectionArray={facilitiesRejectArray}
                      setRejectionArray={setFacilitiesRejectArray}
                      name="transportation"
                    />
                  </div>
                  <div className="flex flex-col gap-y-4">
                    <h2 className="font-fontFamily-sans mb-5 mt-7 text-[27px] font-normal leading-[37.8px] text-txt-primary">
                      Policies
                    </h2>
                    <TimePicker
                      selectedData={checkinTime}
                      setSelectedDate={setCheckinTime}
                      placeholder="Check In Time"
                      isAdmin={
                        returnAdminOrUserBasedOnPath(location.pathname) ==
                          UserType.ADMIN
                          ? true
                          : false
                      }
                      isReadOnly={false}
                      rejectionArray={propertyRejectArray}
                      setRejectionArray={setPropertyRejectArray}
                      name="checkInTime"
                    />
                    <TimePicker
                      selectedData={checkoutTime}
                      setSelectedDate={setCheckoutTime}
                      placeholder="Check Out Time"
                      isAdmin={
                        returnAdminOrUserBasedOnPath(location.pathname) ==
                          UserType.ADMIN
                          ? true
                          : false
                      }
                      isReadOnly={false}
                      rejectionArray={propertyRejectArray}
                      setRejectionArray={setPropertyRejectArray}
                      name="checkOutTime"
                    />
                    <InputComponent
                      inputValue={childAge}
                      setInputValue={setChildAge}
                      placeholder="Maximum Child Age"
                      isAdmin={
                        returnAdminOrUserBasedOnPath(location.pathname) ==
                          UserType.ADMIN
                          ? true
                          : false
                      }
                      isReadOnly={false}
                      rejectionArray={propertyRejectArray}
                      setRejectionArray={setPropertyRejectArray}
                      name="childAge"
                    />
                    <MultiSelectParent
                      selectedArray={policysTagSelected}
                      setSelectedArray={setPolicysTagSelected}
                      data={policysTag}
                      placeholder="Policy Tags"
                      isAdmin={
                        returnAdminOrUserBasedOnPath(location.pathname) ==
                          UserType.ADMIN
                          ? true
                          : false
                      }
                      isReadOnly={false}
                      rejectionArray={facilitiesRejectArray}
                      setRejectionArray={setFacilitiesRejectArray}
                      name="policies"
                    />
                  </div>
                  <div>
                    <h2 className="font-fontFamily-sans mb-5 mt-7 text-[27px] font-normal leading-[37.8px] text-txt-primary">
                      Services
                    </h2>
                    <MultiSelectParent
                      selectedArray={serviceTagSelected}
                      setSelectedArray={setServiceTagSelected}
                      data={serviceTag}
                      placeholder="Service Tags"
                      isAdmin={
                        returnAdminOrUserBasedOnPath(location.pathname) ==
                          UserType.ADMIN
                          ? true
                          : false
                      }
                      isReadOnly={false}
                      rejectionArray={facilitiesRejectArray}
                      setRejectionArray={setFacilitiesRejectArray}
                      name="services"
                    />
                  </div>
                  <div>
                    <h2 className="font-fontFamily-sans mb-5 mt-7 text-[27px] font-normal leading-[37.8px] text-txt-primary">
                      Food and Drink
                    </h2>
                    <InputComponent
                      inputValue={numberRestaurants}
                      setInputValue={setNumberRestaurants}
                      placeholder="Number Of Restaurants"
                      isAdmin={
                        returnAdminOrUserBasedOnPath(location.pathname) ==
                          UserType.ADMIN
                          ? true
                          : false
                      }
                      isReadOnly={false}
                      rejectionArray={propertyRejectArray}
                      setRejectionArray={setPropertyRejectArray}
                      name="numberRestaurants"
                    />
                    <h1 className="font-fontFamily-sans mb-5 mt-7 text-[27px] font-normal leading-[37.8px] text-txt-primary">
                      Restaurants
                    </h1>
                    <MultiSelectParent
                      selectedArray={foodDrinkTagSelected}
                      setSelectedArray={setFoodDrinkTagSelected}
                      data={foodDrinkTag}
                      placeholder="Food & Drink Tags"
                      isAdmin={
                        returnAdminOrUserBasedOnPath(location.pathname) ==
                          UserType.ADMIN
                          ? true
                          : false
                      }
                      isReadOnly={false}
                      rejectionArray={facilitiesRejectArray}
                      setRejectionArray={setFacilitiesRejectArray}
                      name="foodanddrink"
                    />
                  </div>
                  <div>
                    <h2 className="font-fontFamily-sans mb-5 mt-7 text-[27px] font-normal leading-[37.8px] text-txt-primary">
                      Families
                    </h2>
                    <MultiSelectParent
                      selectedArray={familyTagsSelected}
                      setSelectedArray={setFamilyTagsSelected}
                      data={familyTags}
                      placeholder="Family Tags"
                      isAdmin={
                        returnAdminOrUserBasedOnPath(location.pathname) ==
                          UserType.ADMIN
                          ? true
                          : false
                      }
                      isReadOnly={false}
                      rejectionArray={facilitiesRejectArray}
                      setRejectionArray={setFacilitiesRejectArray}
                      name="families"
                    />
                  </div>
                  <div>
                    <h2 className="font-fontFamily-sans mb-5 mt-7 text-[27px] font-normal leading-[37.8px] text-txt-primary">
                      House Keeping
                    </h2>
                    <MultiSelectParent
                      selectedArray={houseKeepingTagSelected}
                      setSelectedArray={sethouseKeepingTagSelected}
                      data={houseKeepingTag}
                      placeholder="House Keeping Tags"
                      isAdmin={
                        returnAdminOrUserBasedOnPath(location.pathname) ==
                          UserType.ADMIN
                          ? true
                          : false
                      }
                      isReadOnly={false}
                      rejectionArray={facilitiesRejectArray}
                      setRejectionArray={setFacilitiesRejectArray}
                      name="housekeeping"
                    />
                  </div>
                  <div>
                    <h2 className="font-fontFamily-sans mb-5 mt-7 text-[27px] font-normal leading-[37.8px] text-txt-primary">
                      Accessibility
                    </h2>
                    <MultiSelectParent
                      selectedArray={accessibilityTagSelected}
                      setSelectedArray={setAccessibilityTagSelected}
                      data={accessibilityTag}
                      placeholder="Accessibility Tags"
                      isAdmin={
                        returnAdminOrUserBasedOnPath(location.pathname) ==
                          UserType.ADMIN
                          ? true
                          : false
                      }
                      isReadOnly={false}
                      rejectionArray={facilitiesRejectArray}
                      setRejectionArray={setFacilitiesRejectArray}
                      name="accessibility"
                    />
                  </div>
                </div>
              </div>
            </Formik>

            {returnAdminOrUserBasedOnPath(location.pathname) ==
              UserType.USER ? (
              <FileMultiple
                setNeedUpdate={setNeedUpdate}
                id={id}
                urlTempStorageArray={urlTempStorageArray}
                setUrlTempStorageArray={setUrlTempStorageArray}
              />
            ) : null}
            <div className="grid grid-cols-5 gap-4 my-14">
              {images &&
                images.length > 0 &&
                images.map((image, index) => {
                  return (
                    <RemoveableImageUploadView
                      // setNeedUpdate={setNeedUpdate}
                      url={image.url}
                      id={image.id}
                      userType={returnAdminOrUserBasedOnPath(location.pathname)}
                      setUrlTempStorageArray={setImages}
                      urlTempStorageArray={images}
                    />
                  );
                })}
              {/* temp uploaded imageview */}
              {urlTempStorageArray &&
                urlTempStorageArray.length > 0 &&
                urlTempStorageArray.map((image, index) => {
                  return (
                    <RemoveableImageUploadView
                      // setNeedUpdate={setNeedUpdate}
                      url={image.url}
                      userType={returnAdminOrUserBasedOnPath(location.pathname)}
                      setUrlTempStorageArray={setUrlTempStorageArray}
                      urlTempStorageArray={urlTempStorageArray}
                      id={-1 * (index + 1)}
                    />
                  );
                })}
            </div>
            <div className="flex">
              <ButtonOutlined
                title="Save"
                buttonOutlinedHandle={buttonOutlinedHandle}
                w="176px"
                h="49px"
                disable={fetchSummeryData.status == "PENDING" ? "yes" : "no"}
              />
              <ButtonFilled
                title="Next"
                buttonFilledHandle={buttonFilledHandle}
                w="291px"
                h="49px"
                disable={fetchSummeryData.status == "PENDING" ? "yes" : "no"}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default MerchantDashboardHotelDetails;
