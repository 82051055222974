import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import ButtonFilled from "../components/ButtonFilled";
import { apiEndPoint } from "../contants/Endpoints";
import { signIn } from "../store/user/api";
import { authAxios } from "../utils/axiosWrapper";
import { noAuthAxios } from "../utils/axiosWrapperOpen";

const Terms = () => {
    const [tnc, setTnc] = useState(null);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const user = useSelector((state) => state.signinReducer?.data);

    useEffect(() => {
        console.log(user, "user");
        noAuthAxios
            .get(
                `${apiEndPoint.FETCH_TERMS_AND_CONDITIONS}?userType=${
                    process.env.REACT_APP_USER_TYPE === "USER"
                        ? "MERCHANT"
                        : "ADMIN"
                }&requestId=string`
            )
            .then((res) => {
                setTnc(res.data.termsAndConditionData);
            })
            .catch((err) => {});
    }, []);

    const acceptTnc = () => {
        authAxios
            .post(`${apiEndPoint.ACCEPT_TERMS_AND_CONDITIONS}`, {
                tncVersion: tnc.tncVersion,
                requestId: "string",
            })
            .then((res) => {
                dispatch(
                    signIn(user.data, () => {
                        window.location.href = "/";
                    })
                );
            })
            .catch((err) => {});
    };
    return (
        <div className=" bg-bg-primary">
            <div className="min-h-[400px] md:w-full lg:max-w-[1024px] xl:max-w-[1280px] xxl:max-w-[1440px] mx-auto px-[1.6rem] xl:px-[4.5rem] leading-relaxed">
               {tnc?.details}
            </div>
            <div className="flex justify-center mt-20 w-full">
                
                <div className="w-72">
                        <ButtonFilled title={'Accept and proceed'} w="full" buttonFilledHandle={acceptTnc} />
                </div>
            
            </div>

            {/* <div className="flex justify-center my-10 w-full">
                <div className="w-72">
                    <ButtonFilled
                        title={"Accept and proceed"}
                        w="full"
                        buttonFilledHandle={acceptTnc}
                    />
                </div>
            </div> */}
        </div>
    );
};

export default Terms;
