import { endOfMonth, format, getDaysInMonth, startOfMonth } from 'date-fns';
import React, { useEffect, useState } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { date } from 'yup';
import { apiEndPoint, baseUrl } from '../../../contants/Endpoints';
import { authAxios } from '../../../utils/axiosWrapper';
import './adminPriceViewCalender.css';
const AdminPriceViewCalender = (props) => {
    const [currentMonth, setCurrentMonth] = useState(new Date());

    const {categoryId, toggleState} = props;

    const [apiStart, setApiStart] = useState('');
    const [apiEnd, setApiEnd] = useState('');

    const [startDateView, setStartDateView] = useState(new Date());
    const [endDateView, setEndDateView] = useState(new Date());
    const [availabilityData, setAvailabilityData] = useState([]);
    const [dayCount,setDayCount] = useState(3);
    const [opens,setOpens] = useState(false)
    const getMonthlyPrice =(start,end)=>{
      if(categoryId){
        authAxios.get(`${baseUrl}${apiEndPoint.ADMIN_GET_AVAILABILITY_DATA}${categoryId}/for-range/${start}/${end}?requestId=16`)
        .then((res)=>{
            setAvailabilityData(res?.data?.availabilityPriceList);
            setDayCount(res.data.noOfEntries);
        })
        .then(() => {setOpens(!opens)})
      }
        
    }

    useEffect(()=>{
      const tempStart = startOfMonth(new Date());
      setCurrentMonth(tempStart)
      handleMonthChange({activeStartDate : tempStart});
    },[categoryId, toggleState])

    useEffect(()=>{
      const tempStart = formatDate(new Date());

    },[])

     // Sample price data
    // const priceData = [
    //     {"date": "2023-05-01", "blackPrice": "300", "redPrice": "50"},
    //     {"date": "2023-05-02", "blackPrice": "280", "redPrice": "55"},

    //     {"date": "2023-06-01", "blackPrice": "400", "redPrice": "40"},
    //     {"date": "2023-06-02", "blackPrice": "480", "redPrice": "45"},

    //     {"date": "2023-04-01", "blackPrice": "100", "redPrice": "10"},
    //     {"date": "2023-04-02", "blackPrice": "180", "redPrice": "15"},
    
    // ];
    // Create a dictionary for prices with date as key
    const pricesByDate = availabilityData.reduce((acc, price) => {
        const { date, blackPrice, redPrice, policyType  } = price;
        acc[date] = { blackPrice: blackPrice, redPrice: redPrice, policyType:policyType };
        return acc;
    }, {});

   // Function to format date for use as key in pricesByDate dictionary
    // function formatDate(date) {
    //     return date.toISOString().slice(0, 10);
    // }
    function formatDate(date) {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
    }

     // Function to get price for a given date
  function getPrice(date, field) {
    const formattedDate = formatDate(date);
    const price = pricesByDate[formattedDate];
    return price ? price[field] : null;
  }
    // Function to handle click on previous month button
  function handleClickPrev() {
    setCurrentMonth(prevMonth => {
      const prevMonthDate = new Date(prevMonth.getFullYear(), prevMonth.getMonth() - 1);
      return prevMonthDate;
    });
  }

  // Function to handle click on next month button
  function handleClickNext() {
    setCurrentMonth(prevMonth => {
      const nextMonthDate = new Date(prevMonth.getFullYear(), prevMonth.getMonth() + 1);
      return nextMonthDate;
    });
  }

  const handleMonthChange = (date) => {
    console.log(date)
     const start =(date?.activeStartDate)
     const formattedStartDay =  format(start, 'yyyy-MM-dd');
    
    // if(start){
    //   const ISOstart = start.slice(0,10);
    //   console.log(start,'date',ISOstart)
    // }
     const end = endOfMonth(date?.activeStartDate);
     const formattedEndDay = format(end, 'yyyy-MM-dd');

     console.log(formattedStartDay,formattedEndDay,'day');
     
     if(formattedStartDay && formattedEndDay){
      getMonthlyPrice(formattedStartDay,formattedEndDay); 
     }
   
  };


    return (
        <div style={{ width: '1000px', height: '700px' }} >
            <div className='w-full flex justify-between hidden'>
                <button onClick={handleClickPrev}>Prev</button>
                <button onClick={handleClickNext}>Next</button>
            </div>
            <Calendar
                value={currentMonth}
                onActiveStartDateChange={handleMonthChange} // Call handleMonthChange when the month changes
                tileContent={({ date, view }) => {
                    // Display black and red prices for day view only
                    if (view === 'month') {
                      const price = getPrice(date, 'blackPrice');
                      const price2 = getPrice(date, 'redPrice');
                     const type = getPrice(date,'policyType');
                      return (
                        <div className="calendar-tile-content text-xs" >
                           {type && <div className="black-price">Type : {type} </div>}
                          {price && <div className="black-price">Black : {price} </div>}
                          {price && <div className="black-price">Red : {price2}</div>}
                        </div>
                      );
                    } else if (view === 'year') {
                      const price = getPrice(date, 'redPrice');
                      return (
                        <div className="calendar-tile-content">
                          <div className="red-price">{price}</div>
                        </div>
                      );
                    }
                  }}
                
            />
            
        </div>
    );
};

export default AdminPriceViewCalender;