import React, { useEffect, useState } from "react";
import ButtonFilled from "../../ButtonFilled";
import ButtonOutlined from "../../ButtonOutlined";
import DocumentUploadRow from "../../DocumentUploadRow";
import FileUpload from "../../FileUpload";
import HorizontalBar from "../../HorizontalBar/HorizontalBar";
import Pagenation from "../../pagenation/Pagenation";
 
import Loading from "../../Loader/Loading";
import { useDispatch, useSelector } from "react-redux";
import { documentsInfo } from "../../../store/admin/adminContract/api/adminContractApi";
import { merchantRoute, PositionMove, ReviewFor, UserType } from "../../../contants/Constants";
import { reviewSubmission } from "../../../store/admin/review/api/reviewApi";
import { returnAdminOrUserBasedOnPath } from "../../../utils/returnAdminOrUserBasedOnPath";
import { useLocation, useNavigate } from "react-router";
import dashboardOrRegistrationRoute, { RouteType } from "../../../utils/dashboardOrRegistrationRoute";

function Documents({ withoutHeader }) {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  let superContractId = localStorage.getItem("superContractId")
  const [documentsData, setDocumentsData] = React.useState();

  const [binIsSelected, setBinIsSelected] = useState(false);
  const [selectedBin, setSelectedBin] = useState();

  const [tinIselected, setTinIsSelected] = useState(false);
  const [selectedTin, setSelectedTin] = useState();

  const [nidIsSelected, setNidIsSelected] = useState(false);
  const [selectedNid, setSelectedNid] = useState();

  const [tradeLicenseIsSelected, setTradeLicenseIsSelected] = useState(false);
  const [selectedTradeLicense, setSelectedTradeLicense] = useState();

  const [contractIsSelected, setContractIsSelected] = useState(false);
  const [selectedContract, setSelectedContract] = useState();

  // some helper state
  const [loading, setLoading] = useState(false);
  const res = useSelector((state) => state.documentsInfoGetReducer);
  const [rejectionArray, setRejectionArray] = useState({});

  // useEffect for loading upper part
  console.log(superContractId, "superContractId", typeof superContractId);
  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(
      documentsInfo(
        process.env.REACT_APP_USER_TYPE,
        superContractId
      )
    );
  }, [superContractId]);

  // var contractid = asyncLocalStorage.getItem("contractid");
  useEffect(() => {
    console.log("wyegrey", res);
    if (res && res.data) {
      setDocumentsData(res.data);
      setLoading(false);
      if (
        res &&
        res.data &&
        res.data.documentList &&
        res.data.documentList.length > 0
      ) {
        res.data.documentList.forEach((item) => {
          if (item.documentType === "CONTRACT") {
            if (
              item &&
              item.rejectionReason &&
              item.rejectionReason.length > 0
            ) {
              setRejectionArray((prev) => {
                return { ...prev, [item.id]: item.rejectionReason };
              });
            }
            setSelectedContract({
              ...item,
              id: item.id,
              name: item.fileName,
              type: item.fileType,
            });
            setContractIsSelected(true);
          }

          if (item.documentType === "BIN") {
            if (
              item &&
              item.rejectionReason &&
              item.rejectionReason.length > 0
            ) {
              setRejectionArray((prev) => {
                return { ...prev, [item.id]: item.rejectionReason };
              });
            }
            setSelectedBin({
              ...item,
              id: item.id,
              name: item.fileName,
              type: item.fileType,
            });
            setBinIsSelected(true);
          }

          if (item.documentType === "TIN") {
            if (
              item &&
              item.rejectionReason &&
              item.rejectionReason.length > 0
            ) {
              setRejectionArray((prev) => {
                return { ...prev, [item.id]: item.rejectionReason };
              });
            }
            setSelectedTin({
              ...item,
              id: item.id,
              name: item.fileName,
              type: item.fileType,
            });
            setTinIsSelected(true);
          }

          if (item.documentType === "NID") {
            if (
              item &&
              item.rejectionReason &&
              item.rejectionReason.length > 0
            ) {
              setRejectionArray((prev) => {
                return { ...prev, [item.id]: item.rejectionReason };
              });
            }
            setSelectedNid({
              ...item,
              id: item.id,
              name: item.fileName,
              type: item.fileType,
            });
            setNidIsSelected(true);
          }

          if (item.documentType === "TRADE_LICENSE") {
            if (
              item &&
              item.rejectionReason &&
              item.rejectionReason.length > 0
            ) {
              setRejectionArray((prev) => {
                return { ...prev, [item.id]: item.rejectionReason };
              });
            }
            setSelectedTradeLicense({
              ...item,
              id: item.id,
              name: item.fileName,
              type: item.fileType,
            });
            setTradeLicenseIsSelected(true);
          }
        });
      }
    }
  }, [res]);

  // click for save
  const buttonOutlinedHandle = () => {
    navigate(merchantRoute.CONTRACT);
  };
  // click for next
  const buttonFilledHandle = () => {
    navigate(merchantRoute.HOTELS);
  };

  // admin submit review
  const adminReviewSubmit = () => {
    dispatch(
      reviewSubmission(ReviewFor.DOCUMENT, superContractId, {
        imageReject: rejectionArray,
        requestId: "string",
      })
    );
  };

  return (
    <div className="w-full">
      {withoutHeader ? (
        <></>
      ) : (
        <>
          {process.env.REACT_APP_USER_TYPE === UserType.USER ? (
            <Pagenation owner="owner" />
          ) : null}
          {dashboardOrRegistrationRoute() === RouteType.REGISTRATION &&
            <HorizontalBar selectedBar="Document" />
          }
        </>
      )}

      {res.loading ? (
        <div className="ml-[45%]">
          <Loading />
        </div>
      ) : (
        <div className="min-h-screen p-10 xl:px-0 xl:container mx-auto">
          <div className="w-full">
            <table className="table-auto w-full rounded-t-3xl overflow-hidden custom-shadow">
              <thead className="">
                <tr className="bg-btn-secondary text-[18px] text-bg-white h-10 text-left">
                  <th className="px-8 font-normal py-2">File Type</th>
                  <th className="font-normal py-2">File Upload</th>
                  <th className="font-normal">Document Name</th>
                  <th className="font-normal">File Type</th>
                  <th className="font-normal">Actions</th>
                  <th className="font-normal">Status</th>
                </tr>
              </thead>
              <tbody className=" divide-y divide-bg-semiblack">
                <DocumentUploadRow
                  // title={"BIN File"}
                  name={"BIN"}
                  isSelected={binIsSelected}
                  setIsSelected={setBinIsSelected}
                  selectedFile={selectedBin}
                  setSelectedFile={setSelectedBin}
                  isAdmin={
                    process.env.REACT_APP_USER_TYPE ===
                    UserType.ADMIN
                      ? true
                      : false
                  }
                  isReadOnly={false}
                  rejectionArray={rejectionArray}
                  setRejectionArray={setRejectionArray}
                />
                <DocumentUploadRow
                  // title={"TIN File"}
                  name={"TIN"}
                  isSelected={tinIselected}
                  setIsSelected={setTinIsSelected}
                  selectedFile={selectedTin}
                  setSelectedFile={setSelectedTin}
                  isAdmin={
                    process.env.REACT_APP_USER_TYPE ===
                    UserType.ADMIN
                      ? true
                      : false
                  }
                  isReadOnly={false}
                  rejectionArray={rejectionArray}
                  setRejectionArray={setRejectionArray}
                />
                <DocumentUploadRow
                  // title={"NID File"}
                  name={"NID"}
                  isSelected={nidIsSelected}
                  setIsSelected={setNidIsSelected}
                  selectedFile={selectedNid}
                  setSelectedFile={setSelectedNid}
                  isAdmin={
                    process.env.REACT_APP_USER_TYPE ===
                    UserType.ADMIN
                      ? true
                      : false
                  }
                  isReadOnly={false}
                  rejectionArray={rejectionArray}
                  setRejectionArray={setRejectionArray}
                />
                <DocumentUploadRow
                  // title={"Trade license File"}
                  name={"TRADE_LICENSE"}
                  isSelected={tradeLicenseIsSelected}
                  setIsSelected={setTradeLicenseIsSelected}
                  selectedFile={selectedTradeLicense}
                  setSelectedFile={setSelectedTradeLicense}
                  isAdmin={
                    process.env.REACT_APP_USER_TYPE ===
                    UserType.ADMIN
                      ? true
                      : false
                  }
                  isReadOnly={false}
                  rejectionArray={rejectionArray}
                  setRejectionArray={setRejectionArray}
                />
                <DocumentUploadRow
                  // title={"Signed Contract File"}
                  name={"CONTRACT"}
                  isSelected={contractIsSelected}
                  setIsSelected={setContractIsSelected}
                  selectedFile={selectedContract}
                  setSelectedFile={setSelectedContract}
                  isAdmin={
                    process.env.REACT_APP_USER_TYPE ===
                    UserType.ADMIN
                      ? true
                      : false
                  }
                  isReadOnly={false}
                  rejectionArray={rejectionArray}
                  setRejectionArray={setRejectionArray}
                />
              </tbody>
            </table>
            {process.env.REACT_APP_USER_TYPE ===
            UserType.USER ? (
              res.loading ? null : (
                <>
                  <div className="flex mt-16 px-10 xl:p-0 xl:container mx-auto">
                    <ButtonOutlined
                      title={"Back"}
                      buttonOutlinedHandle={buttonOutlinedHandle}
                      w="176px"
                      h="49px"
                    />
                    <ButtonFilled
                      title={"Next"}
                      buttonFilledHandle={buttonFilledHandle}
                      w="291px"
                      h="49px"
                    />
                  </div>
                </>
              )
            ) : res.loading ? null : (
              <div className="flex justify-center py-20">
                <ButtonFilled
                  title="Review Submit"
                  buttonFilledHandle={adminReviewSubmit}
                  w="291px"
                  h="49px"
                />
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default Documents;
