import React, { useEffect, useState } from "react";

import { toast } from "react-toastify";
import ButtonFilled from "../../../components/ButtonFilled";
import InputComponent from "../../../components/InputComponent";
import SingleSelectParentCategoryReturn from "../../../components/singleSelect/singleSelectHotelReturn/SingleSelectParentCategoryReturn";
import SingleSelectParentReturnObject from "../../../components/singleSelect/singleSelectObjectReturn/SingleSelectParentReturnObject";
import { apiEndPoint, baseUrl } from "../../../contants/Endpoints";
import { authAxios } from "../../../utils/axiosWrapper";
import { toastMessage } from "../../../utils/toast";
import AdminPriceFilterList from "./AdminPriceFilterList";
import AdminPriceViewCalender from "./AdminPriceViewCalender";
import AdminSetFilterPrice from "./AdminSetFilterPrice";

const AdminSetPrice = () => {
  const [hotelList, setHotelList] = useState([]);
  const [selectedHotel, setSelectedHotel] = useState(null);
  const [selectedCategory, setSelectedCategory] = React.useState({});
  const [baseData,setBaseData]= useState([]);

  const [categories, setCategories] = useState([]);
  const [filterList ,setFilterList] = useState([]);
  const [toggleState,setToggleState] = useState(false);

  const [rackPrice, setRackPrice] = useState(0);
  const [ghuddyCommissionAmount, setGhuddyCommissionAmount] = useState(0);
  const [ghuddyCommissionPercent, setGhuddyCommissionPercent] = useState(0);
  const [corporatePrice, setCorporatePrice] = useState(0);
  const [corporateDiscountPercent, setCorporateDiscountPercent] = useState(0);

  const [shurjoCommissionPercent, setShurjoCommissionPercent] = useState(0);
  const [shurjoCommissionAmount, setShurjoCommissionAmount] = useState(0);

  const [ghuddyWebSiteBlackPrice, setGhuddyWebSiteBlackPrice] = useState(0);

  const [ghuddyWebSiteRedPrice, setGhuddyWebSiteRedPrice] = useState(0);

  const [ghuddySubsidyAmount, setGhuddySubsidyAmount] = useState(0);
  const [ghuddySubsidyPercent, setGhuddySubsidyPercent] = useState(0);

  const [totalDiscountPercent, setTotalDiscountPercent] = useState(0);
  const [totalGhuddyCommissionAmount, setTotalGhuddyCommissionAmount] =
    useState(0);
  const [totalGhuddyCommissionPercent, setTotalGhuddyCommissionPercent] =
    useState(0);
  const [totalShurjoCommissionAmount,setTotalShurjoCommissionAmount] = useState(0);

  const [totalRooms, setTotalRooms] = useState(0);
  const [bookableRooms, setBookableRooms] = useState(0);
  const [deactivatedRooms, setDeactivatedRooms] = useState(0);
  const [afterGhuddyCommission, setAfterGhuddyCommision] = useState(0);

  const allTimePeriod =[  {type:'THIRTY',name:'Thirty Days'},
                          {type:'NINETY',name:'Ninety Days Or Three Months'},
                          {type:'HUNDRED_EIGHTY',name:'Six Months'},
                          {type:'THREE_SIXTY_FIVE',name:'One Year'}
                        ];
  const [period,setPeriod] = useState(null);
  const [periodName,setPeriodName] = useState(null);



 


  useEffect(() => {
    authAxios
      .get(
        `${apiEndPoint.FETCH_ALL_HOTELS_FOR_ADMIN}?contractId=1&requestId=2&pageSize=200&pageNumber=1`
      )
      .then((res) => {
        setHotelList(res.data.entries);
        setSelectedHotel(res.data.entries[0]);
      })
      .catch((err) => {
        toastMessage("Error fecthing data", "error");
      });
     
  }, []);

  useEffect(() => {
    if (selectedHotel) {
      authAxios
        .get(
          `${apiEndPoint.ADMIN_GET_CATEGORY_BY_PROPERTY}${selectedHotel.propertyId}?requestId=11`
        )
        .then((res) => {
          if (res.data.roomCategories.length > 0) {
            setCategories(res.data.roomCategories);
           
            setSelectedCategory(res.data.roomCategories[0]);
          } else {
            setCategories([]);
            setSelectedCategory({});
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [selectedHotel]);

  useEffect(() => {

   if(rackPrice>0){
    var tempTotalDiscount =
    ((rackPrice - ghuddyWebSiteRedPrice) / rackPrice) * 100;
   }

    setTotalDiscountPercent(tempTotalDiscount);
  }, [rackPrice, ghuddyWebSiteRedPrice]);
 
  useEffect(() => {
    // const tempTotalCommission =
    //   parseFloat(ghuddyCommissionAmount) + parseFloat(shurjoCommissionAmount);
    
    const tempTotalCommission =
    parseFloat(ghuddyWebSiteRedPrice) - parseFloat(totalShurjoCommissionAmount) - parseFloat(corporatePrice);
    
      setTotalGhuddyCommissionAmount(tempTotalCommission);
      if(corporatePrice){
        var tempTotalCommisionPercent =
        (tempTotalCommission / corporatePrice) * 100;
      }
   

    setTotalGhuddyCommissionPercent(tempTotalCommisionPercent);
  }, [
    ghuddyCommissionAmount,
    ghuddyCommissionPercent,
    shurjoCommissionAmount,
    shurjoCommissionPercent,
    corporatePrice,
    ghuddyWebSiteRedPrice,
    totalShurjoCommissionAmount
  ]);

  const handleAmountAndPercent = (
    type,
    val,
    setAmount,
    setPercent,
    base,
  ) => {
    if (type === "amount") {
      setAmount(parseFloat(val));
      setPercent(parseFloat((base - val) * 100) / base);
    } else {
      setPercent(parseFloat(val));
      setAmount(parseFloat(base - base * (val / 100)));
    }
  };

  const handleConversion = (type, val, setPercentAmount, setPercent, base) => {
    const valInt = parseFloat(val);
    if (type === "amount") {
      setPercentAmount(valInt);
      setPercent((valInt * 100) / base);
    } else {
      setPercent(valInt);
      setPercentAmount((base * valInt) / 100);
    }
  };

  useEffect(() => {
    const temp = parseFloat(corporatePrice) + parseFloat(ghuddyCommissionAmount);
    setAfterGhuddyCommision(temp);
  }, [
    rackPrice,
    ghuddyCommissionAmount,
    ghuddyCommissionPercent,
    corporatePrice,
    corporateDiscountPercent,
  ]);

  useEffect(() => {
    const temp =
      parseFloat(afterGhuddyCommission) + parseFloat(shurjoCommissionAmount);
    setGhuddyWebSiteBlackPrice(temp);
  }, [afterGhuddyCommission, shurjoCommissionAmount]);

  useEffect(() => {
    const subsidyTemp =
      parseFloat(ghuddyWebSiteBlackPrice) - parseFloat(ghuddyWebSiteRedPrice);

    if(ghuddyWebSiteBlackPrice>0){
      var subsidyPercentTemp =
      (parseFloat(subsidyTemp) / parseFloat(ghuddyWebSiteBlackPrice)) * 100;
    }
    

    const tempTotalShurjoCommision = (parseFloat(ghuddyWebSiteRedPrice)* parseFloat(shurjoCommissionPercent)/100);
    setTotalShurjoCommissionAmount(tempTotalShurjoCommision);

    setGhuddySubsidyAmount(subsidyTemp);
    setGhuddySubsidyPercent(subsidyPercentTemp);
  }, [ghuddyWebSiteBlackPrice, ghuddyWebSiteRedPrice]);

  const handleNumberInput = (val,setval)=>{
    const valInt = parseInt(val);
    setval(valInt);
  }

  const handleSetPrice = () => {
    const apiObject = {
      bookableRooms,
      categoryId: selectedCategory?.categoryId,
      corporateDiscountPercent,
      corporatePrice,
      deactivatedRooms,
      deleteAllFilters: true,
      ghuddyCommissionAmount: Number(ghuddyCommissionAmount.toFixed(3)),
      ghuddyCommissionPercent: Number(ghuddyCommissionPercent.toFixed(3)) ,
      ghuddySubsidyAmount,
      ghuddySubsidyPercent,
      ghuddyWebSiteBlackPrice,
      ghuddyWebSiteRedPrice,
      rackPrice,
      requestId: "11",
      shurjoCommissionAmount,
      shurjoCommissionPercent,
      totalDiscountPercent,
      totalGhuddyCommissionAmount,
      totalGhuddyCommissionPercent,
      totalRooms,
      totalShurjoCommissionAmount
    };
    authAxios.post( `${baseUrl}${apiEndPoint.ADMIN_SET_PRICE_FOR_CATEGORY}`,apiObject)
    .then((res) => {
      toast.success("Price set successfully!");
    })
    .then(()=>{
      setToggleState(!toggleState)
    })
  .catch((err) => {});
    console.log(apiObject, "test payload");
  };

  const handleSetAvailability = ()=>{
    
    if(selectedCategory.categoryId && period){
      authAxios.put( `${baseUrl}${apiEndPoint.ADMIN_SET_AVAILABILITY_FOR_CATEGORY}${selectedCategory.categoryId}/${period.type}?requestId=12`)
      .then((res) => {
        toast.success("availablity set successfully!");
      })
      .then(()=>{
        indexHotel(selectedHotel?.propertyId);
        setToggleState(!toggleState);
      })
    .catch((err) => {});
    }
    else if(period === null){
      toast.warning("Select Time Period!");
     
    }
  }

  const indexHotel = (hotelId)=>{
   authAxios.put(`${baseUrl}${apiEndPoint.ADMIN_ES_INDEXING}${hotelId}?requestId=indexing`)
   .then((res)=>{
    console.log(res?.data?.acknowledged);
   })
 
  
  }
  const initializeAll = ()=>{
    setBaseData([])
        setRackPrice(0);
        setCorporatePrice(0);
        setCorporateDiscountPercent(0);
        setGhuddyCommissionAmount(0);
        setGhuddyCommissionPercent(0);
        setShurjoCommissionAmount(0);
        setShurjoCommissionPercent(0);
        setGhuddyWebSiteRedPrice(0);
        setGhuddySubsidyAmount(0);
        setGhuddySubsidyPercent(0);
        setTotalDiscountPercent(0);
        setTotalGhuddyCommissionAmount(0);
        setTotalGhuddyCommissionPercent(0);
        setTotalRooms(0);
        setDeactivatedRooms(0);
        setBookableRooms(0);
  }


  useEffect(()=>{
     //fetch already set price
     if(selectedCategory?.categoryId){
      authAxios.get(`${baseUrl}${apiEndPoint.ADMIN_GET_PRICE_DATA_BY_ID}${selectedCategory?.categoryId}?requestId=12`)
      .then((res)=>{
        if(res.data){
          setRackPrice(res.data.rackPrice);
          setCorporatePrice(res.data.corporatePrice);
          setCorporateDiscountPercent(res.data.corporateDiscountPercent);
          setGhuddyCommissionAmount(res.data.ghuddyCommissionAmount);
          setGhuddyCommissionPercent(res.data.ghuddyCommissionPercent);
          setShurjoCommissionAmount(res.data.shurjoCommissionAmount);
          setShurjoCommissionPercent(res.data.shurjoCommissionPercent);
          setGhuddyWebSiteRedPrice(res.data.ghuddyWebSiteRedPrice);
          setGhuddySubsidyAmount(res.data.ghuddySubsidyAmount);
          setGhuddySubsidyPercent(res.data.ghuddySubsidyPercent);
          setTotalDiscountPercent(res.data.totalDiscountPercent);
          setTotalGhuddyCommissionAmount(res.data.totalGhuddyCommissionAmount);
          setTotalGhuddyCommissionPercent(res.data.totalGhuddyCommissionPercent);
     
         setTotalRooms(res.data.totalRooms);
         setDeactivatedRooms(res.data.deactivatedRooms);
         setBookableRooms(res.data.bookableRooms);

         setFilterList(res.data.filters);
        
         setBaseData(res.data);
        }
        else
       {
        setBaseData([])
        setRackPrice(0);
        setCorporatePrice(0);
        setCorporateDiscountPercent(0);
        setGhuddyCommissionAmount(0);
        setGhuddyCommissionPercent(0);
        setShurjoCommissionAmount(0);
        setShurjoCommissionPercent(0);
        setGhuddyWebSiteRedPrice(0);
        setGhuddySubsidyAmount(0);
        setGhuddySubsidyPercent(0);
        setTotalDiscountPercent(0);
       //  setTotalGhuddyCommissionAmount(res.data.totalGhuddyCommissionAmount);
       //  setTotalGhuddyCommissionPercent(res.data.totalGhuddyCommissionPercent);
   
       setTotalRooms(0);
       setDeactivatedRooms(0);
       setBookableRooms(0);
       }
      })
      .catch((error) => {
        if(error){
          console.log("Mrror:", error);
          initializeAll();
        }

        
      });
     }
     else {
      setRackPrice(0);
      setCorporatePrice(0);
      setCorporateDiscountPercent(0);
      setGhuddyCommissionAmount(0);
      setGhuddyCommissionPercent(0);
      setShurjoCommissionAmount(0);
      setShurjoCommissionPercent(0);
      setGhuddyWebSiteRedPrice(0);
      setGhuddySubsidyAmount(0);
      setGhuddySubsidyPercent(0);
      setTotalDiscountPercent(0);
     //  setTotalGhuddyCommissionAmount(res.data.totalGhuddyCommissionAmount);
     //  setTotalGhuddyCommissionPercent(res.data.totalGhuddyCommissionPercent);
 
     setTotalRooms(0);
     setDeactivatedRooms(0);
     setBookableRooms(0);
     }
  
  },[selectedCategory,toggleState])
   

  return (
    <div className="w-full mb-24  ">
      <div className=" sticky top-[88px]  z-[100]  shadow pt-[10px] pb-[5px]   bg-btn-secondary">
      <h1 className="w-full text-center text-[#FFFFFF]">SELECT HOTEL AND CATEGORY</h1>
        
      <div className=" flex gap-2 flex-col md:flex-row bg-bg-primary px-[10px] ">
          <div className="md:w-1/2  ">
            <SingleSelectParentCategoryReturn
              warningView={false}
              selectedValue={selectedHotel}
              setSelectedValue={setSelectedHotel}
              data={hotelList}
              placeholder="Select Hotel"
              displayField={"propertyName"}
              selectedFieldName="propertyId"
            />
          </div>
          <div className="md:w-1/2 ">
            <SingleSelectParentCategoryReturn
              warningView={false}
              selectedValue={selectedCategory}
              setSelectedValue={setSelectedCategory}
              data={categories}
              placeholder="Category Name"
              displayField={"categoryName"}
              selectedFieldName="categoryId"
            />
          </div>
        </div>
        
      </div>

      <div className=" px-[14px] flex justify-between py-[25px]">
          <div>Selected Property ID : {selectedHotel && selectedHotel?.propertyId}</div>
          <div>Selected Category ID : {selectedCategory && selectedCategory?.categoryId}</div>
          <div>
          <span>All CategoryIds under this Property : {categories && categories.map(cat=><span className="px-[5px] border" key={cat.categoryId}>{cat.categoryId}</span>)} </span>
          </div>
        </div>
     

      <div className="pt-[40px]  shadow-xl py-[20px] flex flex-col items-center ">

     {/* 1. set price tool */}
      <div className="flex flex-col w-full max-w-[1140px] border rounded-[8px] border-txt-hint  px-[10px] pb-[20px]">
      
              <div className="text-center text-[27px] py-[20px] z-[50] sticky top-[210px] shadow bg-bg-primary ">  <h1>1. SET HOTEL PRICE</h1>
              </div>
              <div className="my-2">
                  <div className="text-lg  mb-2">
                  <span>RackPrice : </span>
                  <InputComponent
                    name={"RackPrice"}
                    inputValue={rackPrice}
                    setInputValue={(val) => handleNumberInput(val,setRackPrice)}
                    warningView={false}
                    type="number"
                    placeholder="Rack Price"
                    required
                    spanValue="BDT"
                    addSpan={true}
                    
                  />
                  {/* <input type="number" value={rackPrice}
                  onChange={(e) => handleNumberInput(e.target.value,setRackPrice)}
                  /> */}
                  <span>BDT</span>
                  </div>

              <input
                type="range"
                min="0"
                max="100000"
                step={1}
                value={`${rackPrice}`}
                onChange={(e) => handleNumberInput(e.target.value,setRackPrice)}
                className="sliderCustom"
                id="myRange"
              />
              </div>

        <div>
          <div className="text-base mb-2">Corporate Discount</div>
          <div className="flex gap-2 items-center">
            <InputComponent
              name={"CorporatediscountAmount"}
              warningView={false}
              placeholder={"Corporate Rate"}
              inputValue={corporatePrice}
              setInputValue={(val) => {
                // setDiscount(val);
                handleAmountAndPercent(
                  "amount",
                  val,
                  setCorporatePrice,
                  setCorporateDiscountPercent,
                  rackPrice
                );
              }}
              addSpan={true}
              spanValue="BDT"
              type="number"
            />
            or
            <InputComponent
              name={"discountPercent"}
              warningView={false}
              inputValue={corporateDiscountPercent}
              setInputValue={(val) => {
                // setDiscountPercent(val);
                handleAmountAndPercent(
                  "percent",
                  val,
                  setCorporatePrice,
                  setCorporateDiscountPercent,
                  rackPrice
                );
              }}
              placeholder={"Corporate discount %"}
              type="number"
              addSpan={true}
              spanValue="%"
            />
          </div>
        </div>

        <div>
          <div className="text-base mb-2">Ghuddy commission </div>
          <div className="flex gap-2 items-center">
            <InputComponent
              name={"GhuddyCommissionAmount"}
              warningView={false}
              inputValue={ghuddyCommissionAmount}
              setInputValue={(val) => {
                // setDiscount(val);
                handleConversion(
                  "amount",
                  val,
                  setGhuddyCommissionAmount,
                  setGhuddyCommissionPercent,
                  corporatePrice
                );
              }}
              addSpan={true}
              spanValue="BDT"
              type="number"
            />
            or
            <InputComponent
              name={"GhuddyCommissionPercemt"}
              warningView={false}
              inputValue={ghuddyCommissionPercent}
              setInputValue={(val) => {
                // setDiscountPercent(val);
                handleConversion(
                  "percent",
                  val,
                  setGhuddyCommissionAmount,
                  setGhuddyCommissionPercent,
                  corporatePrice
                );
              }}
              addSpan={true}
              spanValue="%"
              type="number"
            />
          </div>
        </div>
        <div className="py-4">
          Price after ghuddy commission : {Math.round(afterGhuddyCommission)}
        </div>
        <div>
          <div className="text-base mb-2">Shurjo commission </div>
          <div className="flex gap-2 items-center">
            <InputComponent
              name={"ShurjoCommissionAmount"}
              warningView={false}
              inputValue={shurjoCommissionAmount}
              setInputValue={(val) => {
                // setDiscount(val);
                handleConversion(
                  "amount",
                  val,
                  setShurjoCommissionAmount,
                  setShurjoCommissionPercent,
                  afterGhuddyCommission
                );
              }}
              addSpan={true}
              spanValue="BDT"
              type="number"
            />
            or
            <InputComponent
              name={"ShurjoCommissionPercemt"}
              warningView={false}
              inputValue={shurjoCommissionPercent}
              setInputValue={(val) => {
                // setDiscountPercent(val);
                handleConversion(
                  "percent",
                  val,
                  setShurjoCommissionAmount,
                  setShurjoCommissionPercent,
                  afterGhuddyCommission
                );
              }}
              addSpan={true}
              spanValue="%"
              type="number"
            />
          </div>
        </div>
        <div className="py-4">
          Price after shurjo commision (website black price) :{" "}
          {ghuddyWebSiteBlackPrice.toFixed(3)} BDT
        </div>

        <div className="text-base mb-2">ghuddy website Red price </div>
        <div className="flex gap-2 items-center">
          <InputComponent
            name={"GhuddyWebsiteRedPrice"}
            warningView={false}
            inputValue={ghuddyWebSiteRedPrice}
            setInputValue={(val) => {
              handleNumberInput(val,setGhuddyWebSiteRedPrice)
            }}
            addSpan={true}
            spanValue="BDT"
            type="number"
          />
        </div>

        <div>
          <div>Ghuddy Subsidy Amount : {ghuddySubsidyAmount ? ghuddySubsidyAmount.toFixed(3) : 0 } BDT</div>
          <div>Ghuddy Subsidy Percent : {ghuddySubsidyPercent ? ghuddySubsidyPercent.toFixed(3) : 0} %</div>
          <div>Total Shurjo commision Amount : {totalShurjoCommissionAmount ? totalShurjoCommissionAmount.toFixed(3) : 0}</div>
          <div>Ghuddy total discount : {totalDiscountPercent ? totalDiscountPercent.toFixed(3) : 0 } % </div>
          <div>
            total ghuddy commission amount : {totalGhuddyCommissionAmount ? totalGhuddyCommissionAmount.toFixed(3) : 0 } BDT
          </div>
          <div>
            total ghuddy commission Percent : {totalGhuddyCommissionPercent ? totalGhuddyCommissionPercent.toFixed(3) : 0 } %
          </div>
          
        </div>

        <div className="space-y-[10px]">
          <InputComponent
            name={"TotalRoom"}
            inputValue={totalRooms}
            setInputValue={(val) => handleNumberInput(val,setTotalRooms)}
            warningView={false}
            placeholder="Total Room"
            type="number"
          />

          <InputComponent
            name={"bookableRooms"}
            inputValue={bookableRooms}
            setInputValue={(val) => handleNumberInput(val,setBookableRooms)}
            warningView={false}
            placeholder="Bookable Rooms"
            type="number"
          />

          <InputComponent
            name={"deactivatedRoom"}
            inputValue={deactivatedRooms}
            setInputValue={(val) => handleNumberInput(val,setDeactivatedRooms)}
            warningView={false}
            placeholder="Deactivated Room"
            type="number"
          />
        </div>

        <div className="mt-[2rem] w-full">
          <ButtonFilled
            title="Submit"
            buttonFilledHandle={handleSetPrice}
            w="100%"
            h="49px"
            ml={"ml-[0px]"}
          />
        </div>
      </div>


      {/* 2. filter tool */}
      <div className="flex flex-col w-full max-w-[1140px] border rounded-[8px] border-txt-hint  px-[10px] py-[20px] mt-[140px]">
      <AdminSetFilterPrice baseData={baseData} categoryId= {selectedCategory?.categoryId}  toggleState={toggleState}  setToggleState={setToggleState} />
      </div>

      <div className="w-full">
      <div className="w-full text-center text-[22px] py-[20px] z-[50] sticky top-[210px] shadow bg-bg-primary "> All Filter List  </div>

      <div className="">
        <AdminPriceFilterList filterList={filterList} deleteState={toggleState} setDeleteState={setToggleState} />

      </div>


      </div>




     {/* 3. set availability */}
     <div className="flex flex-col items-center w-full max-w-[1140px] border rounded-[8px] border-txt-hint  px-[10px] py-[20px] mt-[140px]">
      <div className="w-full text-center text-[27px] py-[20px] z-[50] sticky top-[210px] shadow bg-bg-primary ">  <h1>3. GENERATE AVAILABILITY</h1></div>
        <div className='bg-bg-primary w-[450px] pt-[20px]'>
        {Object.keys(allTimePeriod).length>0 &&
                      <SingleSelectParentReturnObject
                      // required={true}
                      selectedValue={
                          periodName
                      }
                      setSelectedValue={
                          setPeriodName
                      }
                      data={allTimePeriod}
                      placeholder="Time Period"
                      displayField={"name"}
                      selectedFieldName={"name"}
                      setSelectedObject={
                          setPeriod
                      }
                      isReadOnly={false}
                      name="methodSelected"
                  />
                    }

              <div className="mt-[2rem] pr-[35px]">
                <ButtonFilled
                  title="Apply"
                  buttonFilledHandle={handleSetAvailability}
                  w="100%"
                  h="49px"
                  ml={"ml-[0px]"}
                />
              </div>
                
             </div>
     
      </div>

      {/* 4. show price per day */}

      <div className="flex flex-col items-center w-full max-w-[1140px] border rounded-[8px] border-txt-hint  px-[10px] py-[20px] mt-[140px]">
      <div className="w-full text-center text-[27px] py-[20px] z-[50] sticky top-[210px] shadow bg-bg-primary ">  <h1>4. VIEW PRICE PER DAY</h1></div>
       {selectedCategory?.categoryId &&  <AdminPriceViewCalender categoryId={selectedCategory?.categoryId} toggleState={toggleState} />
      }
      </div>

      </div>


   
    </div>
  );
};

export default AdminSetPrice;
