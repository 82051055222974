import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router";
import { toast } from "react-toastify";
import { adminRoute } from "../../../../contants/Constants";
import { apiEndPoint, baseUrl } from "../../../../contants/Endpoints";
import { authAxios } from "../../../../utils/axiosWrapper";
import CommonThumbFacility from "../../CommonAllHotelTable/CommonThumbFacility";

const AdminHotelFacility = () => {
  const navigate = useNavigate();
  const [hotelData, setHotelData] = useState([]);

  const [photoUrl, setPhotoUrl] = useState({});
  const [currentPage, setCurrentPage] = useState(1);

  let PageSize = 10;
  const { id } = useParams();
  const { pathname } = useLocation();
  // /{id}/allfacilitiesanddisplayfacilities\
  //     .get(`${baseUrl + apiEndPoint.FETCH_ALL_HOTELS_FOR_ADMIN}?pageNumber=1&pageSize=10&requestId=123&sortcolumn=id&sortdirection=ASC`)

  // useEffect(() => {
  //   authAxios
  //     .get(`${baseUrl + apiEndPoint.FETCH_WITHOUT_ALL_HOTELS_FOR_ADMIN}?requestId=2`)
  //     .then((res) => {
  //       setHotelData(res?.data);
  //     })
  //     .catch((err) => {});
  // }, []);
  const getInitialState = () => {
    const value = "Deactivate";
    return value;
  };
  const [value, setValue] = useState(getInitialState);

  const getThumbData = () => {
    if (value === "active") {
      authAxios
        .get(`${baseUrl + apiEndPoint.FETCH_ALL_HOTELS_FOR_ADMIN}?pageNumber=${currentPage}&pageSize=10&requestId=123`)
        .then((res) => {
          setHotelData(res?.data);
        })
        .catch((err) => { });
    } else {
      authAxios
        .get(`${baseUrl + apiEndPoint.FETCH_WITHOUT_ALL_FACILLITY_FOR_ADMIN}?pageNumber=${currentPage}&pageSize=10&requestId=123&sortcolumn=id&sortdirection=ASC`)
        .then((res) => {
          setHotelData(res?.data);
        })
        .catch((err) => { });
    }
  }

  useEffect(() => {
    getThumbData()
  }, [value, currentPage]);

  const requestDisplayFacility = () => {
    authAxios
      .get(
        `${baseUrl + apiEndPoint.FETCH_ALL_FCILITY_BY_ID_ADMIN
        }/${id}/allfacilitiesanddisplayfacilities?requestId=2`
      )
      .then((res) => {
        setPhotoUrl(res?.data);
      })
      .catch((err) => {
        // toastMessage("Error fetching data", "error");
      });
  };
  useEffect(() => {
    if (id) {
      requestDisplayFacility();
    }
  }, [id]);

  console.log(hotelData)

  const indexOfLastData = currentPage * PageSize;
  const indexOfFirstData = indexOfLastData - PageSize;
  const currentData = value === "active" ? hotelData?.entries : hotelData?.entries
  ;
  const [facilityName, setFacilityName] = useState([]);

  useEffect(() => {
    setFacilityName(photoUrl?.displayFacilities);
  }, [photoUrl]);

  const handleDisplayFacility = (facilityId) => {
    if (facilityId === null) {
      return;
    } else if (facilityName?.length > 0) {
      if (!facilityName.includes(facilityId)) {
        setFacilityName([...facilityName, facilityId]);
      }
    } else {
      setFacilityName([...facilityName, facilityId]);
    }
  };

  const handleRemove = (id) => {
    const newFacility = facilityName?.filter((facility) => facility.id !== id);

    setFacilityName(newFacility);
  };
  // /api/v1/admin/property/{rentalPropertyId}/setdisplayfacility

  let allId = facilityName?.map((facility) => facility?.id) || "";

  const handleSubmitDisplayFacility = () => {
    authAxios
      .post(
        `${baseUrl + apiEndPoint.FETCH_ALL_FCILITY_BY_ID_ADMIN
        }/${id}/setdisplayfacility?requestId=2`,
        allId
      )
      .then((res) => {
        if (res.data.success) {
          requestDisplayFacility();
          toast.success("Successfully added display facility", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        } else {
          toast.warn("Opps! Somthening went wrong", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
      });
  };



  return (
    <div>
      <div className="px-[16px]  min-h-screen pb-[20px]">
        <div className="max-w-[1100px]  mx-auto">
          <h2 className="text-center text-[22px] pt-[30px]">Hotel facility</h2>

          {pathname === "/admin/hotel_facility" ? (
            <CommonThumbFacility
              currentPage={currentPage}
              totalCount={hotelData?.totalPages}
              pageSize={PageSize}
              onPageChange={(page) => setCurrentPage(page)}
              currentData={currentData}
              setCurrentPage={setCurrentPage}
              dataPerPage={PageSize}
              hotelData={hotelData}
              facility={true}
              value={value}
              setValue={setValue}
            />
          ) : (
            <>
              <div>

                <div className="pt-[30px] relative">
                  <div title="back to all hotel" className="cursor-pointer absolute top-[70px]" >
                    <p
                      onClick={() =>
                        navigate(adminRoute.ADMIN_FETCH_ALL_HOTEL_FACILITY)
                      }
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-6 h-6"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M15.75 19.5L8.25 12l7.5-7.5"
                        />
                      </svg>
                    </p>
                  </div>
                  <h2 className="text-center text-[22px] ">
                    {photoUrl?.propertyName}
                  </h2>
                </div>
                <div className="flex flex-row gap-x-[12px] ml-auto justify-end items-end">
                  <div className="flex justify-start items-center gap-x-[4px]">

                    <div className="border rounded-full w-[18px] h-[18px] flex justify-center items-center p-[2px] bg-[#28B463] border-[#28B463]">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                        className="w-6 h-6"
                      >
                        <path
                          fillRule="evenodd"
                          d="M19.916 4.626a.75.75 0 01.208 1.04l-9 13.5a.75.75 0 01-1.154.114l-6-6a.75.75 0 011.06-1.06l5.353 5.353 8.493-12.739a.75.75 0 011.04-.208z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </div>
                    <span>Selected Facility</span>
                  </div>

                  <div className="flex justify-start items-center gap-x-[4px]">

                    <div className="border rounded-full w-[18px] h-[18px] flex justify-center items-center p-[2px] bg-[#E67E22] border-[#E67E22]">
                      {/* <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 24 24"
                                  fill="currentColor"
                                  className="w-6 h-6"
                                >
                                  <path
                                    fillRule="evenodd"
                                    d="M5.47 5.47a.75.75 0 011.06 0L12 10.94l5.47-5.47a.75.75 0 111.06 1.06L13.06 12l5.47 5.47a.75.75 0 11-1.06 1.06L12 13.06l-5.47 5.47a.75.75 0 01-1.06-1.06L10.94 12 5.47 6.53a.75.75 0 010-1.06z"
                                    clipRule="evenodd"
                                  />
                                </svg> */}
                      !
                    </div>
                    <span>Requested Facility</span>
                  </div>
                </div>
                {photoUrl?.allFacilities?.length > 0 &&
                  <div className="flex sticky top-[100px] justify-between items-center text-start text-left mt-[30px] border text-bg-white border-btn-secondary bg-btn-primary">
                    <p className="w-full px-[6px] text-[16px]">All Facility</p>
                    <div className="w-[1px] h-[40px] bg-bg-primary"></div>
                    <p className="w-full text-[16px] pl-[10px]">
                      Selected Facility
                    </p>
                  </div>
                }
                {/* allFacilities */}
                {photoUrl?.allFacilities?.length > 0 ?
                  <div className="flex justify-between items-start h-[450px]  border-b border-l border-r border-btn-primary">
                    <div className="w-full pt-[30px] overflow-y-scroll h-[450px] py-[4px]">
                      <div className="flex w-full flex-col justify-start items-start gap-[12px] px-[12px]">
                        {photoUrl?.allFacilities?.map((facility, index) => (
                          <p
                            key={index}
                            onClick={() =>
                              handleDisplayFacility(
                                allId.includes(facility.id) ? null : facility
                              )
                            }
                            className={`border w-full ${allId?.includes(facility?.id) &&
                              "bg-btn-primary text-bg-white"
                              } border-btn-primary px-[6px] cursor-pointer py-[4px] `}
                          >
                            {facility?.label}
                          </p>
                        ))}
                      </div>
                    </div>
                    <div className="w-[1px] h-[450px] bg-btn-primary"></div>
                    <div className="w-full pt-[30px] overflow-y-scroll h-[450px] py-[4px]">
                      <div className="flex justify-start items-start gap-[12px] flex-col">
                        {facilityName?.map((facility, index) => (
                          <div
                            key={index}
                            className="w-full px-[8px] flex flex-col gap-4  "
                          >
                            <div className="border bg-btn-primary text-bg-primary border-btn-primary flex justify-between px-[6px] cursor-pointer py-[4px] ">
                              {facility?.label}
                              <div
                                onClick={() => handleRemove(facility?.id)}
                                className="text-[#ffeded] flex justify-between items-center gap-x-[8px]"
                              >
                                {/* {
                              photoUrl?.displayFacilities?.includes(facility) ? <p className="w-[10px] rounded-full h-[10px] bg-bg-warning"></p> : <p className="w-[10px] h-[10px] rounded-full bg-[red]"></p>
                             } */}
                                {photoUrl?.displayFacilities?.includes(
                                  facility
                                ) ? (
                                  <div className="border rounded-full w-[18px] h-[18px] flex justify-center items-center p-[2px] bg-[#28B463] border-[#28B463]">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 24 24"
                                      fill="currentColor"
                                      className="w-6 h-6"
                                    >
                                      <path
                                        fillRule="evenodd"
                                        d="M19.916 4.626a.75.75 0 01.208 1.04l-9 13.5a.75.75 0 01-1.154.114l-6-6a.75.75 0 011.06-1.06l5.353 5.353 8.493-12.739a.75.75 0 011.04-.208z"
                                        clipRule="evenodd"
                                      />
                                    </svg>
                                  </div>

                                ) : (
                                  <div className="border rounded-full w-[18px] h-[18px] flex justify-center items-center p-[2px] bg-[#E67E22] border-[#E67E22]">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 24 24"
                                      fill="currentColor"
                                      className="w-6 h-6"
                                    >
                                      <path
                                        fillRule="evenodd"
                                        d="M5.47 5.47a.75.75 0 011.06 0L12 10.94l5.47-5.47a.75.75 0 111.06 1.06L13.06 12l5.47 5.47a.75.75 0 11-1.06 1.06L12 13.06l-5.47 5.47a.75.75 0 01-1.06-1.06L10.94 12 5.47 6.53a.75.75 0 010-1.06z"
                                        clipRule="evenodd"
                                      />
                                    </svg>
                                  </div>

                                )}

                                {/* <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
  <path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
</svg>
 */}

                                <div>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                    fill="currentColor"
                                    className="w-6 h-6"
                                  >
                                    <path
                                      fillRule="evenodd"
                                      d="M5.47 5.47a.75.75 0 011.06 0L12 10.94l5.47-5.47a.75.75 0 111.06 1.06L13.06 12l5.47 5.47a.75.75 0 11-1.06 1.06L12 13.06l-5.47 5.47a.75.75 0 01-1.06-1.06L10.94 12 5.47 6.53a.75.75 0 010-1.06z"
                                      clipRule="evenodd"
                                    />
                                  </svg>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div> : <p className="text-center mt-[20px]"> No facility avialable here</p>}

                {photoUrl?.allFacilities?.length > 0 &&
                  <button
                    onClick={() => handleSubmitDisplayFacility()}
                    className="flex justify-center items-center mx-auto w-[150px] mt-[60px] bg-btn-primary text-bg-primary rounded-sm py-[6px]"
                  >
                    Submit
                  </button>
                }
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default AdminHotelFacility;
