import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import ButtonFilled from "../components/ButtonFilled";
import SingleSelectPhoneTwo from "../components/dropdown/SearchPhoneTwo";
import { adminRoute, merchantRoute, UserType } from "../contants/Constants";
import { signIn } from "../store/user/api";
import { isLoggedIn } from "../utils/isLoggedIn";
import { Formik, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { apiEndPoint } from "../contants/Endpoints";
import { authAxios } from "../utils/axiosWrapper";
import InputComponent from "../components/InputComponent";
import CustomValidation, {
    customValidationSingle,
} from "../utils/customValidation";
import { noAuthAxios } from "../utils/axiosWrapperOpen";

const LogIn = ({ userType }) => {
    const dispatch = useDispatch();
    let navigate = useNavigate();
    const signInData = useSelector((state) => state.signinReducer.data);

    const [phoneCode, setPhoneCode] = useState("+880");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [password, setPassword] = useState("");
    const [submitting, setSubmitting] = useState(false);
    const [validationError, setValidationError] = useState(true);

    const [countryPhoneCodeArr, setCountryPhoneCodeArr] = useState([]);

    useEffect(() => {
        if (signInData) {
            console.log(signInData);
            if (isLoggedIn()) {
                if (userType === UserType.ADMIN) {
                    navigate(adminRoute.ADMIN_LANDING);
                } else {
                    if (signInData.tncRequired === true) {
                        navigate("/terms-and-conditions");
                    } else {
                        navigate(merchantRoute.LANDING);
                    }
                }
            }
        }
    }, [signInData]);

    useEffect(() => {
        noAuthAxios
            .get(`${apiEndPoint.GET_COUNTRY_DETAILS}?requestId=asdf`)
            .then((response) => {
                //console.log("response all country data:", response.data.countries);
                setCountryPhoneCodeArr(response.data.countries);
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);

    const SignInFromApi = () => {
        if (CustomValidation()) {
            setSubmitting(true);
            dispatch(
                signIn(
                    {
                        password,
                        phoneCode,
                        portalType:
                            process.env.REACT_APP_USER_TYPE === "USER"
                                ? "MERCHANT"
                                : "ADMIN",
                        userType:
                            process.env.REACT_APP_USER_TYPE === "USER"
                                ? "MERCHANT"
                                : "ADMIN",
                        phoneNumber:
                            phoneNumber.length > 0 && phoneNumber[0] === "0"
                                ? phoneNumber.substring(1)
                                : phoneNumber,
                    },
                    () => {
                        setSubmitting(false);
                    }
                )
            );
        }
    };

    const toggleShowPass = (elementId) => {
        const input = document.getElementById(elementId);
        if (input.getAttribute("type") === "password") {
            input.setAttribute("type", "text");
        } else {
            input.setAttribute("type", "password");
        }
    };

    // useEffect(() => {
    //   setValidationError(CustomValidation());
    // }, [phoneNumber, password])
    return (
        <div className="grid grid-cols-3 px-2">
            <div className="md:col-span-1 col-span-3 md:col-start-2 pt-10">
                <p className="text-[30px] text-center">Sign in</p>
                <p className="text-[12px] pb-10 text-center">
                    to Merchant Portal
                </p>
                <form
                    onSubmit={(e) => {
                        e.preventDefault();
                        SignInFromApi();
                    }}
                >
                    <div className="my-4">
                        <SingleSelectPhoneTwo
                            // required={true}
                            title={phoneCode}
                            setTitle={setPhoneCode}
                            inputValue={phoneNumber}
                            setInputValue={setPhoneNumber}
                            name={"phone"}
                            warningView={false}
                            dataArray={countryPhoneCodeArr}
                        />
                    </div>
                    <div id="password" className="required">
                        <div className="border-[#9e9989] border validation rounded-[8px] flex">
                            <input
                                role="presentation"
                                autoComplete="off"
                                id="inputpassword"
                                type="password"
                                name="password"
                                value={password}
                                onChange={(e) => {
                                    setPassword(e.target.value);
                                    customValidationSingle("password");
                                }}
                                className="h-[49px] w-full bg-[transparent] pl-6 rounded-[8px] outline-0 focus:outline-0"
                                placeholder="Enter Password"
                                onBlur={() =>
                                    customValidationSingle("password")
                                }
                            />
                            <button
                                type="button"
                                onClick={() => {
                                    toggleShowPass("inputpassword");
                                }}
                                className="p-2 rounded-[8px] text-bg-semiblack hover:text-txt-black"
                            >
                                <i className="fa fa-eye"></i>
                            </button>
                        </div>

                        <div className="errorMessage validationError"></div>
                    </div>
                    <p className="text-[10px] text-left pb-6 pt-3 underline underline-offset-1">
                        Forgot Password?
                    </p>
                    <ButtonFilled
                        w="100%"
                        ml="0px"
                        round="rounded"
                        arrow="yueer"
                        title={submitting ? "Please wait..." : "Sign In"}
                        type="submit"
                        // buttonFilledHandle={() => { SignInFromApi() }}
                        disable={!validationError ? "yes" : "no"}
                    />
                </form>
            </div>
        </div>
    );
};

export default LogIn;
