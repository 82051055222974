import React, { useState, useEffect } from "react";
import { authAxios } from "../../utils/axiosWrapper";
import { useNavigate } from "react-router";
import { adminRoute } from "../../contants/Constants";
import { apiEndPoint } from "../../contants/Endpoints";
import PaginationComponent from "../../components/common/Pagination";

const AllContracts = ({ setPosition, setSuperContractId }) => {
    const navigate = useNavigate();
    // const router = useRoute
    const [categoryListData, setCategoryListData] = useState([]);
    const [contractName, setContractName] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [total, setTotal] = useState(0);

    const [pageSize, setPageSize] = useState(5);
    const [currentPage, setCurrentPage] = useState(0);

    // fetch country all
    useEffect(() => {
        getContracts();
    }, [window.location.pathname, contractName, currentPage, pageSize, phoneNumber]);


    const getContracts = () => {
        authAxios
            .get(
                `${
                    apiEndPoint.FETCH_ALL_CONTRACT
                }?requestId=werwer&phoneNumber=${phoneNumber}&contractName=${contractName}&type=${
                    window.location.pathname.includes("all") ? "" : "SUBMITTED"
                }&pageSize=${pageSize}&pageNo=${currentPage}`
            )
            .then((response) => {
                console.log("category data: ", response.data.contractList);
                setCategoryListData(response.data.contractList);
                setTotal(response.data.totalElements);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const handleClickContract = (superContractId) => {
        navigate(adminRoute.ADMIN_OWNER);
        localStorage.setItem("superContractId", superContractId);
    };
    return (
        <>
            <div className="flex flex-cols w-full">
                <div>
                    <h2 className="text-3xl mb-6 text-center">Ghuddy Admin</h2>
                    <div className="flex justify-between gap-x-6 items-center">
                        <h2 className="text-2xl my-6">
                            Total Contract: {total}
                        </h2>

                        <div className="flex gap-2">
                            <div className="rounded border h-[50px]">
                                <input
                                    className="px-4 w-full bg-[transparent] h-full"
                                    placeholder="Search phone number"
                                    value={phoneNumber}
                                    onChange={(e) =>
                                        setPhoneNumber(e.target.value)
                                    }
                                />
                            </div>
                            <div className="rounded border h-[50px]">
                                <input
                                    className="px-4 w-full bg-[transparent] h-full"
                                    placeholder="Search contract name"
                                    value={contractName}
                                    onChange={(e) =>
                                        setContractName(e.target.value)
                                    }
                                />
                            </div>
                        </div>
                    </div>
                    <table id="students" className="min-w-full leading-normal">
                        <thead>
                            <tr className="h-[78px] px-5 py-3 border-b-2 border-gray-200 bg-btn-primary text-left text-xl font-normal text-bg-white tracking-wider">
                                <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xl font-normal text-gray-700 tracking-wider">
                                    Contract ID
                                </th>
                                <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xl font-normal text-gray-700 tracking-wider">
                                    Contract Name
                                </th>
                                <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xl font-normal text-gray-700 tracking-wider">
                                    Phone Number
                                </th>
                                <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xl font-normal text-gray-700 tracking-wider">
                                    Status
                                </th>
                                <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xl font-normal text-gray-700 tracking-wider">
                                    Owner Status
                                </th>
                                <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xl font-normal text-gray-700 tracking-wider">
                                    Contract Status
                                </th>
                                <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xl font-normal text-gray-700 tracking-wider">
                                    Document Status
                                </th>
                                <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xl font-normal text-gray-700 tracking-wider">
                                    Property Status
                                </th>
                                <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xl font-normal text-gray-700 tracking-wider">
                                    Category Status
                                </th>
                            </tr>
                        </thead>

                        <tbody>
                            {categoryListData &&
                                categoryListData.map((member, index) => {
                                    const {
                                        contractId,
                                        status,
                                        ownerStatus,
                                        contractInfoStatus,
                                        phoneNumber,
                                        documentStatus,
                                        propertyStatus,
                                        categoryStatus,
                                        contractName,
                                    } = member; //destructuring
                                    return (
                                        <tr
                                            className="cursor-pointer"
                                            onClick={() =>
                                                handleClickContract(contractId)
                                            }
                                            key={contractId}
                                        >
                                            <td className="h-[80px] px-5 py-5 border-b border-t border-l bg-white text-sm">
                                                {contractId}
                                            </td>
                                            <td className="h-[80px] px-5 py-5 border-b border-t bg-white text-sm">
                                                {contractName}
                                            </td>
                                            <td className="h-[80px] px-5 py-5 border-b border-t bg-white text-sm">
                                                {phoneNumber}
                                            </td>
                                            <td className="h-[80px] px-5 py-5 border-b border-t bg-white text-sm">
                                                {status}
                                            </td>
                                            <td className="h-[80px] px-5 py-5 border-b border-t bg-white text-sm">
                                                {ownerStatus}
                                            </td>
                                            <td className="h-[80px] px-5 py-5 border-b border-t bg-white text-sm">
                                                {contractInfoStatus}
                                            </td>
                                            <td className="px-5 py-5 border-b border-t bg-white text-sm">
                                                {documentStatus}
                                            </td>
                                            <td className="h-[80px] px-5 py-5 border-b border-t bg-white text-sm">
                                                {propertyStatus}
                                            </td>
                                            <td className="px-5 py-5 border-b border-r border-t bg-white text-sm">
                                                {categoryStatus}
                                            </td>
                                        </tr>
                                    );
                                })}
                        </tbody>
                    </table>

                    <PaginationComponent
                        totalElements={total}
                        currentPage={currentPage}
                        dataSize={categoryListData.length}
                        pageSize={pageSize}
                        setCurrentPage={setCurrentPage}
                        setPageSize={setPageSize}
                        showCount={true}
                    />
                    {/* <div className="py-12 w-1/3 grid grid-flow-col">
        <ButtonFilled buttonFilledHandle={buttonFilledHandle} title={"Load More"} h={40} w={250} />
      </div> */}
                    {/* <ModalGlobal
        header="The request is submitted successfully"
        body="Our team will review the submission, and notify you within 72 hours. Stay in touch!"
        openDefault={modalTrigger}
        setOpenDefault={setModalTrigger}
      /> */}
                </div>
            </div>
        </>
    );
};

export default AllContracts;
