import React from "react";
import "@mobiscroll/react/dist/css/mobiscroll.min.css";
import { Datepicker, Button, Page, setOptions } from "@mobiscroll/react";
import "./index.css";
import "./calender.css";
import RootComponent from "./rootComponent/RootComponent";
import { ErrorMessage, Field } from "formik";
import { customValidationSingle } from "../utils/customValidation";

setOptions({
  theme: "ios",
  themeVariant: "light",
});
function TimePicker({
  setSelectedDate,
  placeholder,
  selectedData,
  type,
  hintText,
  warningText,
  isAdmin,
  isReadOnly,
  warningType,
  rejectionArray,
  setRejectionArray,
  warningView,
  name,
  required = false,
  onBlur = null,
}) {
  const inputProps = {
    className: "md-mobile-picker-input",
    id: `input${name}`,
    onBlur:()=>required && customValidationSingle(name)
  };

  function handleIconClick() {
    document.getElementById(placeholder).click();
  }
  let component = (
    <div id={name} className={`w-full ${required && 'required'}`}>
      <div
        className={`w-full border-opacity-[45%] border border-txt-primary validation flex rounded-[8px] overflow-hidden `}
        onClick={handleIconClick}
      >
        <div className="cursor-auto text-[#9e9989] md:text-sm text-left validation py-3 h-full rounded-[8px] pl-3">
          <span className="w-full text-[#9e9989] md:text-sm">
            {placeholder}
            {`${placeholder ? ":" : ""}`}{required ? "*" : null}
          </span>
        </div>
        <div>
          <Datepicker
            className="w-full bg-bg-primary"
            id={placeholder}
            controls={["time"]}
            inputComponent="input"
            inputProps={inputProps}
            value={selectedData ? selectedData : null}
            onChange={(event) => {
              setSelectedDate(event.value);
              required &&
              setTimeout(()=>{
                customValidationSingle(name);
              },200)
            }}
          />
        </div>
      </div>
      <div className="errorMessage validationError">
      </div>
    </div>
  );

  let readOnlyViewComponent = <p></p>;

  return (
    <div className="w-full">
      <RootComponent
        component={component}
        readOnlyViewComponent={readOnlyViewComponent}
        warningText={warningText}
        warningType={warningType}
        isAdmin={isAdmin}
        isReadOnly={isReadOnly}
        rejectionArray={rejectionArray}
        setRejectionArray={setRejectionArray}
        warningView={warningView}
        name={name}
      />
    </div>
  );
}

export default TimePicker;
