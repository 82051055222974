import React, { useState } from 'react'
import Sidebar from '../../components/common/Sidebar'
import RegistrationLanding from '../../components/root/registration/RegistrationLanding'
import AllContracts from './AllContracts'

const AdminLanding = ({setPosition, position, userType}) => {
    // const [position, setPosition] = useState("nothing");
    
    return (
        <div>
            <div className="flex flex-cols w-full">
                {/* <Sidebar /> */}
                <h1>Welcome to Admin Home Page</h1>
            </div>
        </div>
    )
}

export default AdminLanding
