import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import ButtonFilled from "../../components/ButtonFilled";
import { apiEndPoint } from "../../contants/Endpoints";
import { authAxios } from "../../utils/axiosWrapper";
import { toastMessage } from "../../utils/toast";

export default function AdminInspiredLocations() {
    const [locations, setLocations] = useState([]);

    const navigate = useNavigate();

    const getLocations = () => {
        authAxios
            .get(`${apiEndPoint.ADMIN_GET_INSPIRED}?requestId=123&currency=BDT`)
            .then((res) => {
                setLocations(res.data.inspiredLocationData);
            })
            .catch((err) => {});
    };

    const DeleteLocation = (id) => {
        authAxios
            .delete(`${apiEndPoint.ADMIN_DELETE_INSPIRED}/${id}`)
            .then((res) => {
                toastMessage("Deleted Successfully!");
                getLocations();
            })
            .catch((err) => {
                toastMessage("Operation failed!");
            });
    };

    useEffect(() => {
        getLocations();
    }, []);

    return (
        <div className="container mx-auto">
            <div className="flex gap-10 justify-between items-center mt-10">
                <div className="text-3xl">
                    Inspired Locations ({locations.length}){" "}
                </div>
                <div className="w-[300px]">
                    <ButtonFilled
                        w={"100%"}
                        title={"Add new"}
                        buttonFilledHandle={() => navigate("add")}
                    />
                </div>
            </div>
            <div className="mt-20">
                <table id="students" className="min-w-full leading-normal">
                    <thead>
                        <tr className="h-[78px] px-5 py-3 border-b-2 border-gray-200 bg-btn-primary text-left text-xl font-normal text-bg-white tracking-wider">
                            <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xl font-normal text-gray-700 tracking-wider">
                                Name
                            </th>
                            <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xl font-normal text-gray-700 tracking-wider">
                                Description
                            </th>
                            <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xl font-normal text-gray-700 tracking-wider">
                                Action
                            </th>
                        </tr>
                    </thead>
                    <div className="my-[14px]"></div>
                    <tbody>
                        {locations &&
                            locations.map((location, index) => {
                                const { name, description, id } = location; //destructuring
                                return (
                                    <tr key={id}>
                                        <td className="h-[80px] px-5 py-5 border-b border-t border-l bg-white text-sm">
                                            {name}
                                        </td>
                                        <td className="h-[80px] px-5 py-5 border-b border-t bg-white text-sm text-left">
                                            {description.description}
                                        </td>
                                        <td className="h-[80px] px-5 py-5 border-b border-t border-r bg-white text-sm">
                                            <button
                                                onClick={() => {
                                                    DeleteLocation(id);
                                                }}
                                                className="shadow-sm py-2 px-6 rounded-md"
                                            >
                                                {" "}
                                                Delete{" "}
                                            </button>
                                        </td>
                                    </tr>
                                );
                            })}
                    </tbody>
                </table>
            </div>
        </div>
    );
}
