import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import RoundedDropdownButton from "../components/common/RoundedDropdownButton";
import SecondaryNav from "./admin/comp/SecondaryNav";
import Singletask from "./Singletask";
import "./taskManager.css";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import BookingModal from "../components/modal/BookingModal";
import ModalGlobal from "../components/ModalGlobal";
import BookingRejectReason from "./admin/comp/Booking/BookingRejectReason";
import { apiEndPoint, BOOKING_ENDPOINTS } from "../contants/Endpoints";
import { authAxios } from "../utils/axiosWrapper";
import { integerDateToValidDate } from "../utils/DateFormatTranferToSixDigit";
import { hotelsInfo } from "../store/admin/hotel/api/adminContractApi";
import InfiniteScroll from "react-infinite-scroll-component";
import { UserType } from "../contants/Constants";

const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
};

const move = (source, destination, droppableSource, droppableDestination) => {
    const sourceClone = Array.from(source);
    const destClone = Array.from(destination);
    const [removed] = sourceClone.splice(droppableSource.index, 1);

    destClone.splice(droppableDestination.index, 0, removed);
    const result = {};
    result[droppableSource.droppableId] = sourceClone;
    result[droppableDestination.droppableId] = [];
    if (droppableDestination.droppableId === "0") {
        result[droppableDestination.droppableId] = [
            ...destClone.map((item) => {
                return { ...item, invoiceStatus: "SUCCESSFUL_PAYMENT" };
            }),
        ];
    }
    if (droppableDestination.droppableId === "1") {
        result[droppableDestination.droppableId] = [
            ...destClone.map((item) => {
                return { ...item, invoiceStatus: "In progress" };
            }),
        ];
    }
    if (droppableDestination.droppableId === "2") {
        result[droppableDestination.droppableId] = [
            ...destClone.map((item) => {
                return { ...item, invoiceStatus: "BOOKED" };
            }),
        ];
    }
    if (droppableDestination.droppableId === "3") {
        result[droppableDestination.droppableId] = [
            ...destClone.map((item) => {
                return { ...item, invoiceStatus: "FAILED" };
            }),
        ];
    }

    return result;
};

const initialNew = {
    title: "New",
    items: [],
    hasMore: false,
    pageNumber: 0,
    totalElements: 0,
};
const initialProg = {
    title: "In progress",
    items: [],
    hasMore: false,
    pageNumber: -1,
    totalElements: 0,
};
const initialBooked = {
    title: "Booked",
    items: [],
    hasMore: false,
    pageNumber: -1,
    totalElements: 0,
};
const initialCancel = {
    title: "Cancelled",
    items: [],
    hasMore: false,
    pageNumber: -1,
    totalElements: 0,
};

const TaskManager = () => {
    const dispatch = useDispatch();
    const hotelList = useSelector(
        (state) => state.hotelsInfoGetReducer.data.propertyDataList
    );

    const [selectedHotelId, setSelectedHotelId] = useState(null);
    const [selectedHotel, setSelectedHotel] = useState(null);
    const [bookingOverlay, setBookingOverlay] = useState(false);
    const [openSubmitModal, setOpenSubmitModal] = useState(false);
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [selectedBooking, setSelctedBooking] = useState(null);
    const [pageSize, setPageSize] = useState(4);
    const [loading, setLoading] = useState(false);

    const [selectedContractId, setSelectedContractId] = useState(-1);

    const [newBookings, setNewBookings] = useState(initialNew);
    const [inProgBookings, setInProgBookings] = useState(initialProg);
    const [bookedBookings, setBookedBookings] = useState(initialBooked);
    const [cancelledBookings, setCancelledBookings] = useState(initialCancel);

    const changeHotel = (id) => {
        setSelectedHotelId(id);
    };

    const onConfirm = async (itemIndex, sourceIndex) => {
        const destinationIndex = 2;
        if (parseInt(sourceIndex) !== destinationIndex) {
            const source = inProgBookings.items;
            const destination = bookedBookings.items;
            const droppableSource = {
                index: itemIndex,
                droppableId: sourceIndex.toString(),
            };
            const droppableDestination = {
                index: destination.length > 0 ? destination.length : 0,
                droppableId: destinationIndex.toString(),
            };

            if (
                await changeStatus(
                    selectedBooking.booking.invoiceUuId,
                    "BOOKED"
                )
            ) {
                // const result = move(
                //     source,
                //     destination,
                //     droppableSource,
                //     droppableDestination
                // );
                // console.log({ result });
                // setInProgBookings({
                //     ...inProgBookings,
                //     items: result[parseInt(sourceIndex)],
                // });
                // setBookedBookings({
                //     ...bookedBookings,
                //     items: result[destinationIndex],
                // });
                getAndLoadProgressData(true);
                getAndLoadBookedData(true);
                setBookingOverlay(false);
            }
        }
    };

    const onReject = () => {
        setBookingOverlay(false);
        setOpenSubmitModal(true);
    };

    const handleRejectionSubmit = async (reason, details) => {
        if (
            await changeStatus(
                selectedBooking.booking.invoiceUuId,
                "MERCHANT_CANCELED",
                reason,
                details
            )
        ) {
            const destinationIndex = 3;

            const sourceIndex = selectedBooking.sourceIndex;
            console.log(sourceIndex, destinationIndex);
            if (parseInt(sourceIndex) !== destinationIndex) {
                let setterFunc = null;

                switch (sourceIndex) {
                    case "0":
                        setterFunc = getAndLoadNewData;
                        break;
                    case "1":
                        setterFunc = getAndLoadProgressData;
                        break;
                    case "2":
                        setterFunc = getAndLoadBookedData;
                        break;
                    case "3":
                        setterFunc = getAndLoadCancelData;
                        break;
                    default:
                        break;
                }

                getAndLoadCancelData(true);
                setterFunc(true);
                setOpenSubmitModal(false);
                setShowConfirmModal(true);
            }
        }
    };

    function onDragEnd(result) {
        const { source, destination } = result;
        if (!destination) {
            return;
        }
        const sInd = source.droppableId;
        const dInd = destination.droppableId;

        let itemsSource = [];
        let setterFunc = null;
        switch (sInd) {
            case "0":
                itemsSource = newBookings;
                break;
            case "1":
                itemsSource = inProgBookings;
                break;
            case "2":
                itemsSource = bookedBookings;
                break;
            case "3":
                itemsSource = cancelledBookings;
                break;
            default:
                break;
        }
        switch (sInd) {
            case "0":
                setterFunc = setNewBookings;
                break;
            case "1":
                setterFunc = setInProgBookings;
                break;
            case "2":
                setterFunc = setBookedBookings;
                break;
            case "3":
                setterFunc = setCancelledBookings;
                break;
            default:
                break;
        }

        if (sInd === dInd) {
            const items = reorder(
                itemsSource.items,
                source.index,
                destination.index
            );
            const newState = itemsSource;
            newState.items = items;

            setterFunc(newState);
        } else {
            if (dInd !== "1") {
                onModalOpen(source.index, sInd);
            }
            // else {
            //     const result = move(
            //         state[sInd].items,
            //         state[dInd].items,
            //         source,
            //         destination
            //     );
            //     const newState = [...state];
            //     newState[sInd].items = result[sInd];
            //     newState[dInd].items = result[dInd];
            // }
        }
    }

    const onModalOpen = async (itemIndex, sourceIndex) => {
        console.log(itemIndex, sourceIndex);
        let sourceItems = null;
        switch (sourceIndex) {
            case "0":
                sourceItems = newBookings;
                break;
            case "1":
                sourceItems = inProgBookings;
                break;
            case "2":
                sourceItems = bookedBookings;
                break;
            case "3":
                sourceItems = cancelledBookings;
                break;

            default:
                break;
        }
        if (parseInt(sourceIndex) === 0) {
            const destinationIndex = 1;
            const source = sourceItems.items;
            const destination = inProgBookings.items;
            const droppableSource = {
                index: itemIndex,
                droppableId: sourceIndex.toString(),
            };
            const droppableDestination = {
                index: destination.length > 0 ? destination.length : 0,
                droppableId: destinationIndex.toString(),
            };
            if (
                await changeStatus(
                    newBookings.items[itemIndex].invoiceUuId,
                    "BOOKING_IN_PROGRESS"
                )
            ) {
                const result = move(
                    source,
                    destination,
                    droppableSource,
                    droppableDestination
                );
                // console.log({ result });
                // setNewBookings({ ...newBookings, items: [...result[0]] });
                // setInProgBookings({ ...inProgBookings, items: [...result[1]] });
                setSelctedBooking({
                    ...selectedBooking,
                    booking: result[1][result[1].length - 1],
                    sourceIndex: destinationIndex,
                    itemIndex: droppableDestination.index,
                });

                getAndLoadNewData(true);
                getAndLoadProgressData(true);
            }
        } else {
            setSelctedBooking({
                ...selectedBooking,
                booking: sourceItems.items[itemIndex],
                sourceIndex,
                itemIndex,
            });
        }
        setBookingOverlay(true);
    };

    const onRefuseReject = async (itemIndex, sourceIndex) => {
        if (
            await changeStatus(
                selectedBooking.booking.invoiceUuId,
                "BOOKING_IN_PROGRESS"
            )
        ) {
            const destinationIndex = 1;
            const source = cancelledBookings.items;
            const destination = inProgBookings.items;
            const droppableSource = {
                index: itemIndex,
                droppableId: sourceIndex.toString(),
            };
            const droppableDestination = {
                index: destination.length > 0 ? destination.length : 0,
                droppableId: destinationIndex.toString(),
            };
            const result = move(
                source,
                destination,
                droppableSource,
                droppableDestination
            );
            setCancelledBookings({
                ...cancelledBookings,
                items: [...result[3]],
            });
            setInProgBookings({ ...inProgBookings, items: [...result[1]] });
            setBookingOverlay(false);
        }
    };

    useEffect(() => {
        dispatch(hotelsInfo(process.env.REACT_APP_USER_TYPE, "0"));
    }, []);

    useEffect(() => {
        console.log("hotel changing test");
        if (hotelList && hotelList.length > 0) {
            setSelectedHotel(() =>
                hotelList.find((el) => el.id === selectedHotelId)
            );
        }
        console.log("hotel changing test 2");

        // EMPTY DATA
        setNewBookings(initialNew);
        setCancelledBookings(initialCancel);
        setInProgBookings(initialProg);
        setBookedBookings(initialBooked);

        console.log("hotel changing test 3");

        // REFILL DATA
        const timer = setTimeout(() => {
            getAndLoadNewData(true);
            getAndLoadBookedData(true);
            getAndLoadProgressData(true);
            getAndLoadCancelData(true);
        }, 500);

        return () => {
            clearTimeout(timer);
        };
    }, [selectedHotelId]);

    const getAndLoadNewData = (initLoading) => {
        console.log(selectedHotelId, "  test23  ", newBookings);
        if (initLoading) {
            authAxios
                .get(
                    `${
                        process.env.REACT_APP_USER_TYPE === UserType.USER
                            ? BOOKING_ENDPOINTS.GET_BOOKINGS_BY_PROPERTY
                            : apiEndPoint.ADMIN_BOOKINGS
                    }?propertyId=${
                        selectedHotelId ? selectedHotelId : ""
                    }&requestId=123&invoiceStatus=BOOKING_REQUESTED&pageSize=${pageSize}&pageNo=${0}&contractId=${
                        selectedContractId ? selectedContractId : ""
                    }`
                )
                .then((res) => {
                    setNewBookings({
                        ...newBookings,
                        items: [...res.data.data],
                        hasMore: res.data.data.length < res.data.totalElements,
                        totalElements: res.data.totalElements,
                        pageNumber: 1,
                    });
                })
                .catch((err) => {
                    console.log(err);
                });
        } else {
            authAxios
                .get(
                    `${
                        process.env.REACT_APP_USER_TYPE === UserType.USER
                            ? BOOKING_ENDPOINTS.GET_BOOKINGS_BY_PROPERTY
                            : apiEndPoint.ADMIN_BOOKINGS
                    }?propertyId=${
                        selectedHotelId ? selectedHotelId : ""
                    }&requestId=123&invoiceStatus=BOOKING_REQUESTED&pageSize=${pageSize}&pageNo=${
                        newBookings.pageNumber
                    }&contractId=${
                        selectedContractId ? selectedContractId : ""
                    }`
                )
                .then((res) => {
                    setNewBookings({
                        ...newBookings,
                        items: [...newBookings.items, ...res.data.data],
                        hasMore:
                            newBookings.items.length < res.data.totalElements,
                        totalElements: res.data.totalElements,
                        pageNumber: newBookings.pageNumber + 1,
                    });
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    };
    const getAndLoadBookedData = (initLoading = false) => {
        if (initLoading) {
            authAxios
                .get(
                    `${
                        process.env.REACT_APP_USER_TYPE === UserType.USER
                            ? BOOKING_ENDPOINTS.GET_BOOKINGS_BY_PROPERTY
                            : apiEndPoint.ADMIN_BOOKINGS
                    }?propertyId=${
                        selectedHotelId ? selectedHotelId : ""
                    }&requestId=123&invoiceStatus=BOOKED&pageSize=${pageSize}&pageNo=${0}&contractId=${
                        selectedContractId ? selectedContractId : ""
                    }`
                )
                .then((res) => {
                    setBookedBookings({
                        ...bookedBookings,
                        items: [...res.data.data],
                        hasMore: res.data.data.length < res.data.totalElements,
                        totalElements: res.data.totalElements,
                        pageNumber: 1,
                    });
                })
                .catch((err) => {
                    console.log(err);
                });
        } else {
            authAxios
                .get(
                    `${
                        process.env.REACT_APP_USER_TYPE === UserType.USER
                            ? BOOKING_ENDPOINTS.GET_BOOKINGS_BY_PROPERTY
                            : apiEndPoint.ADMIN_BOOKINGS
                    }?propertyId=${
                        selectedHotelId ? selectedHotelId : ""
                    }&requestId=123&invoiceStatus=BOOKED&pageSize=${pageSize}&pageNo=${
                        bookedBookings.pageNumber
                    }&contractId=${
                        selectedContractId ? selectedContractId : ""
                    }`
                )
                .then((res) => {
                    setBookedBookings({
                        ...bookedBookings,
                        items: [...bookedBookings.items, ...res.data.data],
                        hasMore:
                            bookedBookings.items.length <
                            res.data.totalElements,
                        totalElements: res.data.totalElements,
                        pageNumber: bookedBookings.pageNumber + 1,
                    });
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    };
    const getAndLoadProgressData = (initLoading = false) => {
        if (initLoading) {
            authAxios
                .get(
                    `${
                        process.env.REACT_APP_USER_TYPE === UserType.USER
                            ? BOOKING_ENDPOINTS.GET_BOOKINGS_BY_PROPERTY
                            : apiEndPoint.ADMIN_BOOKINGS
                    }?propertyId=${
                        selectedHotelId ? selectedHotelId : ""
                    }&requestId=123&invoiceStatus=BOOKING_IN_PROGRESS&pageSize=${pageSize}&pageNo=${0}&contractId=${
                        selectedContractId ? selectedContractId : ""
                    }`
                )
                .then((res) => {
                    setInProgBookings({
                        ...inProgBookings,
                        items: [...res.data.data],
                        hasMore: res.data.data.length < res.data.totalElements,
                        totalElements: res.data.totalElements,
                        pageNumber: 1,
                    });
                })
                .catch((err) => {
                    console.log(err);
                });
        } else {
            authAxios
                .get(
                    `${
                        process.env.REACT_APP_USER_TYPE === UserType.USER
                            ? BOOKING_ENDPOINTS.GET_BOOKINGS_BY_PROPERTY
                            : apiEndPoint.ADMIN_BOOKINGS
                    }?propertyId=${
                        selectedHotelId ? selectedHotelId : ""
                    }&requestId=123&invoiceStatus=BOOKING_IN_PROGRESS&pageSize=${pageSize}&pageNo=${
                        inProgBookings.pageNumber
                    }&contractId=${
                        selectedContractId ? selectedContractId : ""
                    }`
                )
                .then((res) => {
                    setInProgBookings({
                        ...inProgBookings,
                        items: [...inProgBookings.items, ...res.data.data],
                        hasMore:
                            inProgBookings.items.length <
                            res.data.totalElements,
                        totalElements: res.data.totalElements,
                        pageNumber: inProgBookings.pageNumber + 1,
                    });
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    };
    const getAndLoadCancelData = (initLoading = false) => {
        if (initLoading) {
            authAxios
                .get(
                    `${
                        process.env.REACT_APP_USER_TYPE === UserType.USER
                            ? BOOKING_ENDPOINTS.GET_BOOKINGS_BY_PROPERTY
                            : apiEndPoint.ADMIN_BOOKINGS
                    }?propertyId=${
                        selectedHotelId ? selectedHotelId : ""
                    }&requestId=123&invoiceStatus=CANCELED&invoiceStatus=MERCHANT_CANCELED&invoiceStatus=REJECTED&pageSize=${pageSize}&pageNo=${0}&contractId=${
                        selectedContractId ? selectedContractId : ""
                    }`
                )
                .then((res) => {
                    setCancelledBookings({
                        ...cancelledBookings,
                        items: [...res.data.data],
                        hasMore: res.data.data.length < res.data.totalElements,
                        totalElements: res.data.totalElements,
                        pageNumber: 1,
                    });
                })
                .catch((err) => {
                    console.log(err);
                });
        } else {
            authAxios
                .get(
                    `${
                        process.env.REACT_APP_USER_TYPE === UserType.USER
                            ? BOOKING_ENDPOINTS.GET_BOOKINGS_BY_PROPERTY
                            : apiEndPoint.ADMIN_BOOKINGS
                    }?propertyId=${
                        selectedHotelId ? selectedHotelId : ""
                    }&requestId=123&invoiceStatus=CANCELED&invoiceStatus=MERCHANT_CANCELED&invoiceStatus=REJECTED&pageSize=${pageSize}&pageNo=${
                        cancelledBookings.pageNumber
                    }&contractId=${
                        selectedContractId ? selectedContractId : ""
                    }`
                )
                .then((res) => {
                    setCancelledBookings({
                        ...cancelledBookings,
                        items: [...cancelledBookings.items, ...res.data.data],
                        hasMore:
                            cancelledBookings.items.length <
                            res.data.totalElements,
                        totalElements: res.data.totalElements,
                        pageNumber: cancelledBookings.pageNumber + 1,
                    });
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    };

    const changeStatus = async (
        uuid,
        status,
        rejectionReason = "string",
        rejectionDetails = "string"
    ) => {
        setLoading(true);
        await authAxios.patch(`${BOOKING_ENDPOINTS.CHANGE_STATUS}/${uuid}`, {
            invoiceStatus: status,
            requestId: "string",
            rejectionDetails,
            rejectionReason,
        });
        setLoading(false);
        return true;
    };

    return (
        <div>
            {process.env.REACT_APP_USER_TYPE === "USER" && <SecondaryNav />}
            <div
                className={`app ${
                    process.env.REACT_APP_USER_TYPE === "USER" && "mt-10"
                } `}
            >
                <div className="container mx-auto p-4">
                    <div className="grid grid-cols-4 mb-4 gap-x-2">
                        {hotelList && hotelList.length > 0 && (
                            <div className="col-span-1">
                                Select Hotel
                                <RoundedDropdownButton
                                    selectHandler={changeHotel}
                                    dropdownData={[
                                        { propertyName: "All", id: null },
                                        ...hotelList,
                                    ]}
                                    title={`${
                                        selectedHotel?.propertyName || "All"
                                    }`}
                                    w="full"
                                    h="49px"
                                    selector={"propertyName"}
                                />
                            </div>
                        )}
                    </div>

                    <div
                        style={{ display: "flex" }}
                        className="project-tasks items-start p-4"
                    >
                        <DragDropContext onDragEnd={onDragEnd}>
                            <Droppable key="key1" droppableId={`0`}>
                                {(provided, snapshot) => (
                                    <div
                                        id="scrollableDiv"
                                        ref={provided.innerRef}
                                        className="project-column custom-shadow rounded-[8px] w-80 h-[70vh] overflow-y-scroll relative pb-10 z-[0]"
                                        {...provided.droppableProps}
                                    >
                                        <div className="project-column-heading bg-btn-secondary rounded-t-[8px] sticky w-full top-0">
                                            <h2 className="project-column-heading__title text-bg-primary py-2">
                                                {newBookings.title} (
                                                {newBookings.items.length}/
                                                {newBookings.totalElements})
                                            </h2>
                                        </div>
                                        <div>
                                            <InfiniteScroll
                                                dataLength={
                                                    newBookings.items.length
                                                }
                                                next={() =>
                                                    getAndLoadNewData(false)
                                                }
                                                hasMore={newBookings.hasMore}
                                                loader={
                                                    <h4 className="mt-20 text-center">
                                                        Loading...
                                                    </h4>
                                                }
                                                scrollableTarget="scrollableDiv"
                                            >
                                                {newBookings.items.map(
                                                    (item, index) => (
                                                        <Draggable
                                                            key={
                                                                item.invoiceUuId?.toString() ||
                                                                Math.random().toString()
                                                            }
                                                            draggableId={
                                                                item.invoiceUuId?.toString() ||
                                                                Math.random().toString()
                                                            }
                                                            index={index}
                                                        >
                                                            {(
                                                                provided,
                                                                snapshot
                                                            ) => (
                                                                <div
                                                                    ref={
                                                                        provided.innerRef
                                                                    }
                                                                    {...provided.draggableProps}
                                                                    {...provided.dragHandleProps}
                                                                >
                                                                    <Singletask
                                                                        id={
                                                                            item.invoiceUuId
                                                                        }
                                                                        title={
                                                                            item.content
                                                                        }
                                                                        total={`${item.totalPrice} ${item.currency}`}
                                                                        name={
                                                                            item.personName
                                                                        }
                                                                        email={
                                                                            item.personEmail
                                                                        }
                                                                        status={
                                                                            item.invoiceStatus
                                                                        }
                                                                        checkIn={new Date(
                                                                            integerDateToValidDate(
                                                                                item
                                                                                    .bookingCategoryData[0]
                                                                                    ?.startDate
                                                                            )
                                                                        ).toDateString()}
                                                                        duration={
                                                                            item.duration
                                                                        }
                                                                        onModalOpen={
                                                                            onModalOpen
                                                                        }
                                                                        itemIndex={
                                                                            index
                                                                        }
                                                                        sourceIndex={
                                                                            "0"
                                                                        }
                                                                    />
                                                                </div>
                                                            )}
                                                        </Draggable>
                                                    )
                                                )}
                                            </InfiniteScroll>
                                        </div>
                                        {provided.placeholder}
                                    </div>
                                )}
                            </Droppable>
                            <Droppable key="key2" droppableId={`1`}>
                                {(provided, snapshot) => (
                                    <div
                                        id="scrollableDiv2"
                                        ref={provided.innerRef}
                                        className="project-column custom-shadow rounded-[8px] w-80 h-[70vh] overflow-y-scroll relative pb-10 z-[0]"
                                        {...provided.droppableProps}
                                    >
                                        <div className="project-column-heading bg-btn-secondary rounded-t-[8px] sticky w-full top-0">
                                            <h2 className="project-column-heading__title text-bg-primary py-2">
                                                {inProgBookings.title} (
                                                {inProgBookings.items.length}/
                                                {inProgBookings.totalElements})
                                            </h2>
                                        </div>
                                        <div>
                                            <InfiniteScroll
                                                dataLength={
                                                    inProgBookings.items.length
                                                }
                                                next={getAndLoadProgressData}
                                                hasMore={inProgBookings.hasMore}
                                                loader={
                                                    <h4 className="mt-20 text-center">
                                                        Loading...
                                                    </h4>
                                                }
                                                scrollableTarget="scrollableDiv2"
                                            >
                                                {inProgBookings.items.map(
                                                    (item, index) => (
                                                        <Draggable
                                                            key={
                                                                item.invoiceUuId?.toString() ||
                                                                Math.random().toString()
                                                            }
                                                            draggableId={
                                                                item.invoiceUuId?.toString() ||
                                                                Math.random().toString()
                                                            }
                                                            index={index}
                                                        >
                                                            {(
                                                                provided,
                                                                snapshot
                                                            ) => (
                                                                <div
                                                                    ref={
                                                                        provided.innerRef
                                                                    }
                                                                    {...provided.draggableProps}
                                                                    {...provided.dragHandleProps}
                                                                >
                                                                    <Singletask
                                                                        id={
                                                                            item.invoiceUuId
                                                                        }
                                                                        title={
                                                                            item.content
                                                                        }
                                                                        total={`${item.totalPrice} ${item.currency}`}
                                                                        name={
                                                                            item.personName
                                                                        }
                                                                        email={
                                                                            item.personEmail
                                                                        }
                                                                        status={
                                                                            item.invoiceStatus
                                                                        }
                                                                        checkIn={new Date(
                                                                            integerDateToValidDate(
                                                                                item
                                                                                    .bookingCategoryData[0]
                                                                                    ?.startDate
                                                                            )
                                                                        ).toDateString()}
                                                                        duration={
                                                                            item.duration
                                                                        }
                                                                        onModalOpen={
                                                                            onModalOpen
                                                                        }
                                                                        itemIndex={
                                                                            index
                                                                        }
                                                                        sourceIndex={
                                                                            "1"
                                                                        }
                                                                    />
                                                                </div>
                                                            )}
                                                        </Draggable>
                                                    )
                                                )}
                                            </InfiniteScroll>
                                        </div>
                                        {provided.placeholder}
                                    </div>
                                )}
                            </Droppable>
                            <Droppable key="key3" droppableId={`2`}>
                                {(provided, snapshot) => (
                                    <div
                                        id="scrollableDiv3"
                                        ref={provided.innerRef}
                                        className="project-column custom-shadow rounded-[8px] w-80 h-[70vh] overflow-y-scroll relative pb-10 z-[0]"
                                        {...provided.droppableProps}
                                    >
                                        <div className="project-column-heading bg-btn-secondary rounded-t-[8px] sticky w-full top-0">
                                            <h2 className="project-column-heading__title text-bg-primary py-2">
                                                {bookedBookings.title} (
                                                {bookedBookings.items.length}/
                                                {bookedBookings.totalElements})
                                            </h2>
                                        </div>
                                        <div>
                                            <InfiniteScroll
                                                dataLength={
                                                    bookedBookings.items.length
                                                }
                                                next={getAndLoadBookedData}
                                                hasMore={bookedBookings.hasMore}
                                                loader={
                                                    <h4 className="mt-20 text-center">
                                                        Loading...
                                                    </h4>
                                                }
                                                scrollableTarget="scrollableDiv3"
                                            >
                                                {bookedBookings.items.map(
                                                    (item, index) => (
                                                        <Draggable
                                                            key={
                                                                item.invoiceUuId?.toString() ||
                                                                Math.random().toString()
                                                            }
                                                            draggableId={
                                                                item.invoiceUuId?.toString() ||
                                                                Math.random().toString()
                                                            }
                                                            index={index}
                                                        >
                                                            {(
                                                                provided,
                                                                snapshot
                                                            ) => (
                                                                <div
                                                                    ref={
                                                                        provided.innerRef
                                                                    }
                                                                    {...provided.draggableProps}
                                                                    {...provided.dragHandleProps}
                                                                >
                                                                    <Singletask
                                                                        id={
                                                                            item.invoiceUuId
                                                                        }
                                                                        title={
                                                                            item.content
                                                                        }
                                                                        total={`${item.totalPrice} ${item.currency}`}
                                                                        name={
                                                                            item.personName
                                                                        }
                                                                        email={
                                                                            item.personEmail
                                                                        }
                                                                        status={
                                                                            item.invoiceStatus
                                                                        }
                                                                        checkIn={new Date(
                                                                            integerDateToValidDate(
                                                                                item
                                                                                    .bookingCategoryData[0]
                                                                                    ?.startDate
                                                                            )
                                                                        ).toDateString()}
                                                                        duration={
                                                                            item.duration
                                                                        }
                                                                        onModalOpen={
                                                                            onModalOpen
                                                                        }
                                                                        itemIndex={
                                                                            index
                                                                        }
                                                                        sourceIndex={
                                                                            "2"
                                                                        }
                                                                    />
                                                                </div>
                                                            )}
                                                        </Draggable>
                                                    )
                                                )}
                                            </InfiniteScroll>
                                        </div>
                                        {provided.placeholder}
                                    </div>
                                )}
                            </Droppable>
                            <Droppable key="key4" droppableId={`3`}>
                                {(provided, snapshot) => (
                                    <div
                                        id="scrollableDiv4"
                                        ref={provided.innerRef}
                                        className="project-column custom-shadow rounded-[8px] w-80 h-[70vh] overflow-y-scroll relative pb-10 z-[0]"
                                        {...provided.droppableProps}
                                    >
                                        <div className="project-column-heading bg-btn-secondary rounded-t-[8px] sticky w-full top-0">
                                            <h2 className="project-column-heading__title text-bg-primary py-2">
                                                {cancelledBookings.title} (
                                                {cancelledBookings.items.length}
                                                /
                                                {
                                                    cancelledBookings.totalElements
                                                }
                                                )
                                            </h2>
                                        </div>
                                        <div id="scrollableDiv4" className="">
                                            <InfiniteScroll
                                                dataLength={
                                                    cancelledBookings.items
                                                        .length
                                                }
                                                next={getAndLoadCancelData}
                                                hasMore={
                                                    cancelledBookings.hasMore
                                                }
                                                loader={
                                                    <h4 className="mt-20 text-center">
                                                        Loading...
                                                    </h4>
                                                }
                                                scrollableTarget="scrollableDiv4"
                                            >
                                                {cancelledBookings.items.map(
                                                    (item, index) => (
                                                        <Draggable
                                                            key={
                                                                item.invoiceUuId?.toString() ||
                                                                Math.random().toString()
                                                            }
                                                            draggableId={
                                                                item.invoiceUuId?.toString() ||
                                                                Math.random().toString()
                                                            }
                                                            index={index}
                                                        >
                                                            {(
                                                                provided,
                                                                snapshot
                                                            ) => (
                                                                <div
                                                                    ref={
                                                                        provided.innerRef
                                                                    }
                                                                    {...provided.draggableProps}
                                                                    {...provided.dragHandleProps}
                                                                >
                                                                    <Singletask
                                                                        id={
                                                                            item.invoiceUuId
                                                                        }
                                                                        title={
                                                                            item.content
                                                                        }
                                                                        total={`${item.totalPrice} ${item.currency}`}
                                                                        name={
                                                                            item.personName
                                                                        }
                                                                        email={
                                                                            item.personEmail
                                                                        }
                                                                        status={
                                                                            item.invoiceStatus
                                                                        }
                                                                        checkIn={new Date(
                                                                            integerDateToValidDate(
                                                                                item
                                                                                    .bookingCategoryData[0]
                                                                                    ?.startDate
                                                                            )
                                                                        ).toDateString()}
                                                                        duration={
                                                                            item.duration
                                                                        }
                                                                        onModalOpen={
                                                                            onModalOpen
                                                                        }
                                                                        itemIndex={
                                                                            index
                                                                        }
                                                                        sourceIndex={
                                                                            "3"
                                                                        }
                                                                    />
                                                                </div>
                                                            )}
                                                        </Draggable>
                                                    )
                                                )}
                                            </InfiniteScroll>
                                        </div>
                                        {provided.placeholder}
                                    </div>
                                )}
                            </Droppable>
                        </DragDropContext>
                    </div>
                </div>
                {bookingOverlay && (
                    <div className="flex_column">
                        <div
                            className={
                                bookingOverlay
                                    ? "overlay_shown"
                                    : "overlay_hidden"
                            }
                        >
                            <BookingModal
                                removeOverlay={() => setBookingOverlay(false)}
                                bookingOverlay={bookingOverlay}
                                onConfirm={onConfirm}
                                onReject={onReject}
                                onRefuseReject={onRefuseReject}
                                hotel={selectedHotel}
                                bookingItem={selectedBooking}
                                loading={loading}
                            />
                        </div>
                    </div>
                )}

                <ModalGlobal
                    header=""
                    body={
                        <BookingRejectReason
                            loading={loading}
                            onSubmit={handleRejectionSubmit}
                            onCancel={() => {
                                setOpenSubmitModal(false);
                                setBookingOverlay(true);
                            }}
                        />
                    }
                    text="left"
                    openDefault={openSubmitModal}
                    setOpenDefault={setOpenSubmitModal}
                ></ModalGlobal>

                <ModalGlobal
                    body={
                        <div className="h-full flex flex-col">
                            <h2 className="text-[27px]">
                                Your request has been accepted
                            </h2>
                            <h5 className="text-[18px]">
                                we will notify you with the result shortly
                            </h5>
                        </div>
                    }
                    openDefault={showConfirmModal}
                    setOpenDefault={setShowConfirmModal}
                    height="250px"
                    width="40vw"
                    closeable={false}
                ></ModalGlobal>
            </div>
        </div>
    );
};

export default TaskManager;
