import React, { useState, useEffect } from "react";
import ReactModal from "react-modal";
import ButtonFilled from "../../components/ButtonFilled";
import ButtonOutlined from "../../components/ButtonOutlined";
import InputComponent from "../../components/InputComponent";
import { apiEndPoint, FAQ_ENDPOINTS } from "../../contants/Endpoints";
import { authAxios } from "../../utils/axiosWrapper";

export default function AdminFaq() {
    const [showModal, setShowModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [selectedItemId, setSelectedItemId] = useState(null);

    // const [faqData, setFaqData] = useState([
    //     {
    //         id: 1,
    //         title: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus vehicula eros vitae tristique vestibulum.?",
    //         description: "Fusce ut diam lobortis, pharetra magna quis, congue quam. Duis ac ligula sit amet arcu fringilla lacinia. Sed pharetra nisl eu metus gravida bibendum. Donec et suscipit orci. Nulla tristique leo leo, ut maximus dui aliquam eget. Curabitur lobortis nulla in eros consequat, sit amet placerat enim convallis. Maecenas vestibulum, dolor id mollis commodo, sapien elit malesuada risus, sit amet viverra magna augue et dui. Praesent faucibus sem eget lobortis tristique."
    //     },
    //     {
    //         id: 2,
    //         title: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus vehicula eros vitae tristique.?",
    //         description: "Fusce ut diam lobortis, pharetra magna quis, congue quam. Duis ac ligula sit amet arcu fringilla lacinia. Sed pharetra nisl eu metus gravida bibendum. Donec et suscipit orci. Nulla tristique leo leo, ut maximus dui aliquam eget. Curabitur lobortis nulla in eros consequat, sit amet placerat enim convallis. Maecenas vestibulum, dolor id mollis commodo, sapien elit malesuada risus, sit amet viverra magna augue et dui. Praesent faucibus sem eget lobortis tristique."
    //     },
    // ]);
    const [faqData, setFaqData] = useState([]);

    const getFaq = ()=>{
        authAxios
        .get(FAQ_ENDPOINTS.GET_FAQ)
        .then((response)=>{
            setFaqData(response?.data?.data);
            console.log(response?.data?.data,'FAQ POST response');
        })
        .catch((error) => {
            console.log(error,'FAQ POST response');
        });

    }

    useEffect(()=>{
       getFaq();
    },[faqData])
   // console.log(faqData,'FAQ POST response');
    const handleDelete = () => {
        if (selectedItemId) {
            // const tmp = faqData.filter(el => el.id !== selectedItemId);
            // setFaqData([...tmp]);
            authAxios
            .delete(`${FAQ_ENDPOINTS.DELETE_FAQ}${selectedItemId}`)
            .then((response)=>{
                console.log(response,'deleted FAQ')
            })
            setSelectedItemId(null);
            setTitle("");
            setDescription("");
            getFaq();
            setShowDeleteModal(false);
        }
    }
    const postFaq = (postObject)=>{
        authAxios
        .post(FAQ_ENDPOINTS.POST_FAQ, postObject)
        .then((response) => {
            console.log(response, "FAQ POST response");
        })
        .catch((error) => {
            console.log(error);
        });
    }

    const handleEdit = () => {
        if (selectedItemId) {
           // const index = faqData.findIndex(el => el.id === selectedItemId)
            //const tmp = faqData;
           // tmp[index] = { ...tmp[index], title, description };
            //setFaqData([...tmp]);
            const tempObject = {
                answer: description,
                question: title,
                requestId: '11'
            }
            authAxios
            .patch(`${FAQ_ENDPOINTS.EDIT_FAQ}${selectedItemId}`, tempObject)
            .then((response)=>{
                console.log(response)
            })
            getFaq();
            setShowModal(false);
        }
    }

    const addNew = () => {
        // const tmp = faqData;
        // tmp.push({ id: faqData.length + 1, title, description });
        // setFaqData([...tmp]);
        const postObject = {
            answer: description,
            question: title,
            requestId: '11'
        }
        console.log(postObject, "FAQ POST response");
        postFaq(postObject);
        getFaq();
        setShowModal(false);
    }

    return (
        <div className="container mx-auto">
            <h2 className="text-2xl my-6 text-center">Frequently Asked Questions</h2>
            <hr />
            <div className="flex justify-end">
                <div className="w-[250px] my-6">
                    <ButtonFilled
                        title={"Add new"}
                        w="100%"
                        arrow={"no"}
                        buttonFilledHandle={() => {
                            setSelectedItemId(null);
                            setTitle("");
                            setDescription("");
                            setShowModal(true);
                        }}
                    />
                </div>
            </div>
            <table id="students" className="min-w-full leading-normal">
                <div className="my-[14px]"></div>
                <tbody>
                    {faqData &&
                        faqData.map((member, index) => {
                            const {
                                id,
                                question,
                               answer
                            } = member;
                            return (
                                <tr key={id}
                                >
                                    <td
                                        onClick={() => {
                                            setSelectedItemId(id);
                                            setTitle(faqData[index].question.question);
                                            setDescription(faqData[index].answer.answer);
                                            setShowModal(true);
                                        }}
                                        className="text-xl hover:text-2xl transition-all cursor-pointer py-2 spanRow">
                                        {index + 1}. {question.question && question.question} 
                                        {/* <span className="spanText opacity-0 text-btn-primary text-sm" > ...click to see details </span> */}
                                    </td>
                                </tr>
                            );
                        })}
                </tbody>
            </table>

            <ReactModal
                style={{ content: { width: '60vw', } }}
                isOpen={showModal}
                onRequestClose={() => { setShowModal(false) }}
            >
                <div className="px-10 py-8 relative">
                        <button className="absolute top-5 right-6" onClick={()=>{setShowModal(false)}}>
                            <svg
                                width="19"
                                height="19"
                                viewBox="0 0 19 19"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M11.4109 8.99928L18.701 1.70928C18.8648 1.51798 18.9504 1.27191 18.9407 1.02023C18.9309 0.768555 18.8266 0.529816 18.6485 0.351722C18.4704 0.173627 18.2317 0.0692943 17.98 0.0595732C17.7283 0.049852 17.4823 0.135459 17.291 0.299285L10.0009 7.58928L2.71095 0.289285C2.51965 0.125458 2.27357 0.0398528 2.0219 0.0495739C1.77022 0.0592951 1.53148 0.163627 1.35339 0.341722C1.17529 0.519816 1.07096 0.758555 1.06124 1.01023C1.05152 1.26191 1.13712 1.50798 1.30095 1.69928L8.59095 8.99928L1.29095 16.2893C1.18627 16.3789 1.10125 16.4893 1.04122 16.6133C0.981202 16.7374 0.947471 16.8725 0.942151 17.0102C0.936832 17.1479 0.960038 17.2853 1.01031 17.4136C1.06059 17.5419 1.13685 17.6585 1.2343 17.7559C1.33175 17.8534 1.4483 17.9296 1.57663 17.9799C1.70495 18.0302 1.84228 18.0534 1.98 18.0481C2.11772 18.0428 2.25286 18.009 2.37692 17.949C2.50098 17.889 2.6113 17.804 2.70095 17.6993L10.0009 10.4093L17.291 17.6993C17.4823 17.8631 17.7283 17.9487 17.98 17.939C18.2317 17.9293 18.4704 17.8249 18.6485 17.6468C18.8266 17.4688 18.9309 17.23 18.9407 16.9783C18.9504 16.7267 18.8648 16.4806 18.701 16.2893L11.4109 8.99928Z"
                                    fill="#2A220E"
                                    fillOpacity="0.45"
                                />
                            </svg>
                        </button>
                    <div className="text-center text-2xl mb-4">
                        {selectedItemId ? "Edit Item" : "Add Item"}
                    </div>
                    <div>Question title</div>
                    <InputComponent
                        inputValue={title}
                        setInputValue={setTitle}
                        required={true}
                    />

                    <div className="mt-6"> Description </div>
                    <textarea
                        className="w-full bg-[transparent] rounded-[8px] validation flex border-[#9e9989] border p-2 outline-none"
                        value={description}
                        rows="6"
                        onChange={e => setDescription(e.target.value)}
                    />

                    <div className="flex justify-center gap-2 mt-8">
                        {selectedItemId &&
                            <ButtonOutlined
                                title={"Delete"}
                                w="200px"
                                buttonOutlinedHandle={() => {
                                    setShowDeleteModal(true);
                                    setShowModal(false);
                                }}
                            />
                        }
                        <ButtonFilled
                            title={"Submit"}
                            w="200px"
                            buttonFilledHandle={() => {
                                selectedItemId &&
                                    handleEdit()
                                !selectedItemId &&
                                    addNew()
                            }}
                        />
                    </div>
                </div>
            </ReactModal>

            <ReactModal
                style={{ content: { width: 'auto', } }}
                isOpen={showDeleteModal}
                onRequestClose={() => { }}
            >
                <div className="px-10 py-8">
                    <div className="text-center text-2xl mb-4">
                        Are you sure you want to delete this item ?
                    </div>
                    <div className="flex justify-center gap-2 mt-8">
                        {selectedItemId &&
                            <ButtonOutlined
                                title={"No"}
                                w="150px"
                                buttonOutlinedHandle={() => {
                                    setShowDeleteModal(false);
                                    setShowModal(true);
                                }}
                            />
                        }
                        <ButtonFilled
                            title={"Yes"}
                            w="150px"
                            arrow={"no"}
                            buttonFilledHandle={() => {
                                handleDelete();
                            }}
                        />
                    </div>
                </div>
            </ReactModal>
        </div>
    )
}