import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "../taskManager.css";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import BookingModal from "../../components/modal/BookingModal";
import InfiniteScroll from "react-infinite-scroll-component";
import { UserType } from "../../contants/Constants";
import { authAxios } from "../../utils/axiosWrapper";
import { apiEndPoint, BOOKING_ENDPOINTS } from "../../contants/Endpoints";
import { hotelsInfo } from "../../store/admin/hotel/api/adminContractApi";
import SecondaryNav from "./comp/SecondaryNav";
import RoundedDropdownButton from "../../components/common/RoundedDropdownButton";
import Singletask from "../Singletask";
import { integerDateToValidDate } from "../../utils/DateFormatTranferToSixDigit";
import ModalGlobal from "../../components/ModalGlobal";
import BookingRejectReason from "./comp/Booking/BookingRejectReason";
import ButtonFilled from "../../components/ButtonFilled";
import DatePicker from "react-datepicker";

const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
};

const move = (source, destination, droppableSource, droppableDestination) => {
    const sourceClone = Array.from(source);
    const destClone = Array.from(destination);
    const [removed] = sourceClone.splice(droppableSource.index, 1);

    destClone.splice(droppableDestination.index, 0, removed);
    const result = {};
    result[droppableSource.droppableId] = sourceClone;
    result[droppableDestination.droppableId] = [];
    if (droppableDestination.droppableId === "0") {
        result[droppableDestination.droppableId] = [
            ...destClone.map((item) => {
                return { ...item, invoiceStatus: "SUCCESSFUL_PAYMENT" };
            }),
        ];
    }
    if (droppableDestination.droppableId === "1") {
        result[droppableDestination.droppableId] = [
            ...destClone.map((item) => {
                return { ...item, invoiceStatus: "In progress" };
            }),
        ];
    }
    if (droppableDestination.droppableId === "2") {
        result[droppableDestination.droppableId] = [
            ...destClone.map((item) => {
                return { ...item, invoiceStatus: "BOOKED" };
            }),
        ];
    }
    if (droppableDestination.droppableId === "3") {
        result[droppableDestination.droppableId] = [
            ...destClone.map((item) => {
                return { ...item, invoiceStatus: "FAILED" };
            }),
        ];
    }

    return result;
};

const initialRequests = {
    title: "Rejection Requests",
    items: [],
    hasMore: false,
    pageNumber: -1,
    totalElements: 0,
};
const initialRejections = {
    title: "Rejected Bookings",
    items: [],
    hasMore: false,
    pageNumber: -1,
    totalElements: 0,
};

const AdminRejectedBookings = () => {
    const dispatch = useDispatch();
    const hotelList = useSelector(
        (state) => state.hotelsInfoGetReducer.data.propertyDataList
    );

    const [selectedHotelId, setSelectedHotelId] = useState(null);
    const [selectedHotel, setSelectedHotel] = useState(null);
    const [bookingOverlay, setBookingOverlay] = useState(false);
    const [openSubmitModal, setOpenSubmitModal] = useState(false);
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [selectedBooking, setSelctedBooking] = useState(null);
    const [responseStatus, setResponseStatus] = useState(1);
    const [pageSize, setPageSize] = useState(4);
    const [loading, setLoading] = useState(false);
    const [contractList, setContractList] = useState([]);

    const [selectedContract, setSelectedContract] = useState(null);
    const [selectedContractId, setSelectedContractId] = useState(-1);

    const [requestedBookings, setRequestedBookings] = useState(initialRequests);
    const [rejectedBookings, setRejectedBookings] = useState(initialRejections);

    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());

    const changeHotel = (id) => {
        setSelectedHotelId(id);
    };

    const changeContract = (id) => {
        setSelectedContractId(id);
    };

    const onConfirm = async (itemIndex, sourceIndex) => {};

    function onDragEnd(result) {
        const { source, destination } = result;
        if (!destination) {
            return;
        }
        const sInd = source.droppableId;
        const dInd = destination.droppableId;

        let itemsSource = [];
        let setterFunc = null;
        switch (sInd) {
            case "0":
                itemsSource = requestedBookings;
                break;
            case "1":
                itemsSource = rejectedBookings;
                break;
            default:
                break;
        }
        switch (sInd) {
            case "0":
                setterFunc = setRequestedBookings;
                break;
            case "1":
                setterFunc = setRejectedBookings;
                break;
            default:
                break;
        }

        if (sInd === dInd) {
            const items = reorder(
                itemsSource.items,
                source.index,
                destination.index
            );
            const newState = itemsSource;
            newState.items = items;

            setterFunc(newState);
        } else {
            onModalOpen(source.index, sInd);
        }
    }

    const onModalOpen = async (itemIndex, sourceIndex) => {
        console.log(itemIndex, sourceIndex);
        let sourceItems = null;
        switch (sourceIndex) {
            case "0":
                sourceItems = requestedBookings;
                break;
            case "1":
                sourceItems = rejectedBookings;
                break;

            default:
                break;
        }

        setSelctedBooking({
            ...selectedBooking,
            booking: sourceItems.items[itemIndex],
            sourceIndex,
            itemIndex,
        });
        setBookingOverlay(true);
    };

    const onRefuseReject = async (itemIndex, sourceIndex) => {
        if (
            await changeStatus(
                selectedBooking.booking.invoiceUuId,
                "BOOKING_IN_PROGRESS"
            )
        ) {
            setResponseStatus(2);
            setBookingOverlay(false);
            setShowConfirmModal(true);
            getAndLoadRejectedData(true);
            getAndLoadRequestData(true);
        }
    };

    const onReject = async () => {
        if (
            await changeStatus(selectedBooking.booking.invoiceUuId, "REJECTED")
        ) {
            setResponseStatus(1);
            setBookingOverlay(false);
            setShowConfirmModal(true);
            getAndLoadRejectedData(true);
            getAndLoadRequestData(true);
        }
    };

    // useEffect(() => {
    //   hotelList?.length > 0 &&
    //     setSelectedHotel(() => hotelList[selectedHotelIndex]);
    //   // console.log(selectedHotel);
    // }, [selectedHotelIndex])

    useEffect(() => {
        if (hotelList && hotelList.length > 0) {
            setSelectedHotel(() =>
                hotelList.find((el) => el.id === selectedHotelId)
            );
        }
    }, [selectedHotelId]);

    useEffect(() => {
        setSelectedHotelId(null);
        if (contractList && contractList.length > 0) {
            setSelectedContract(() =>
                contractList.find((el) => el.contractId === selectedContractId)
            );
        }
    }, [selectedContractId]);

    useEffect(() => {
        authAxios
            .get(
                `${apiEndPoint.FETCH_ALL_CONTRACT}?requestId=werwer&contractName=&type=ALL`
            )
            .then((response) => {
                setContractList(response.data.contractList);
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);

    // useEffect(() => {

    // }, [selectedContractId]);

    useEffect(() => {
        dispatch(
            hotelsInfo(process.env.REACT_APP_USER_TYPE, selectedContractId)
        );

        setRequestedBookings(initialRequests);
        setRejectedBookings(initialRejections);

        const timer = setTimeout(() => {
            getAndLoadRequestData(true);
            getAndLoadRejectedData(true);
        }, 300);

        return () => {
            clearTimeout(timer);
        };
    }, [selectedHotelId, selectedContractId]);

    const getAndLoadRequestData = (initLoading = false) => {
        if (initLoading) {
            authAxios
                .get(
                    `${apiEndPoint.ADMIN_BOOKINGS}?propertyId=${
                        selectedHotelId ? selectedHotelId : ""
                    }&requestId=123&invoiceStatus=MERCHANT_CANCELED&pageSize=${pageSize}&pageNo=${0}&contractId=${
                        selectedContractId ? selectedContractId : ""
                    }`
                )
                .then((res) => {
                    setRequestedBookings({
                        ...requestedBookings,
                        items: [...res.data.data],
                        hasMore: res.data.data.length < res.data.totalElements,
                        totalElements: res.data.totalElements,
                        pageNumber: 1,
                    });
                })
                .catch((err) => {
                    console.log(err);
                });
        } else {
            authAxios
                .get(
                    `${apiEndPoint.ADMIN_BOOKINGS}?propertyId=${
                        selectedHotelId ? selectedHotelId : ""
                    }&requestId=123&invoiceStatus=MERCHANT_CANCELED&pageSize=${pageSize}&pageNo=${
                        requestedBookings.pageNumber
                    }&contractId=${
                        selectedContractId ? selectedContractId : ""
                    }`
                )
                .then((res) => {
                    setRequestedBookings({
                        ...requestedBookings,
                        items: [...requestedBookings.items, ...res.data.data],
                        hasMore:
                            requestedBookings.items.length <
                            res.data.totalElements,
                        totalElements: res.data.totalElements,
                        pageNumber: requestedBookings.pageNumber + 1,
                    });
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    };

    const getAndLoadRejectedData = (initLoading = false) => {
        if (initLoading) {
            authAxios
                .get(
                    `${apiEndPoint.ADMIN_BOOKINGS}?propertyId=${
                        selectedHotelId ? selectedHotelId : ""
                    }&requestId=123&invoiceStatus=REJECTED&pageSize=${pageSize}&pageNo=${0}&contractId=${
                        selectedContractId ? selectedContractId : ""
                    }`
                )
                .then((res) => {
                    setRejectedBookings({
                        ...rejectedBookings,
                        items: [...res.data.data],
                        hasMore: res.data.data.length < res.data.totalElements,
                        totalElements: res.data.totalElements,
                        pageNumber: 1,
                    });
                })
                .catch((err) => {
                    console.log(err);
                });
        } else {
            authAxios
                .get(
                    `${apiEndPoint.ADMIN_BOOKINGS}?propertyId=${
                        selectedHotelId ? selectedHotelId : ""
                    }&requestId=123&invoiceStatus=REJECTED&pageSize=${pageSize}&pageNo=${
                        rejectedBookings.pageNumber
                    }&contractId=${
                        selectedContractId ? selectedContractId : ""
                    }`
                )
                .then((res) => {
                    setRejectedBookings({
                        ...rejectedBookings,
                        items: [...rejectedBookings.items, ...res.data.data],
                        hasMore:
                            rejectedBookings.items.length <
                            res.data.totalElements,
                        totalElements: res.data.totalElements,
                        pageNumber: rejectedBookings.pageNumber + 1,
                    });
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    };

    const changeStatus = async (
        uuid,
        status,
        rejectionDetails = "string",
        rejectionReason = "string"
    ) => {
        setLoading(true);
        await authAxios.patch(
            `${BOOKING_ENDPOINTS.CHANGE_STATUS}/${uuid}`,
            {
                invoiceStatus: status,
                requestId: "string",
                rejectionDetails,
                rejectionReason,
            },
            {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("jwt")}`,
                },
            }
        );
        setLoading(false);
        return true;
    };

    // const groupBy = function (xs, key) {
    //     return xs.reduce(function (rv, x) {
    //         (rv[x[key]] = rv[x[key]] || []).push(x);
    //         return rv;
    //     }, {});
    // };

    return (
        <div>
            {process.env.REACT_APP_USER_TYPE === "USER" && <SecondaryNav />}
            <div
                className={`app ${
                    process.env.REACT_APP_USER_TYPE === "USER" && "mt-10"
                } `}
            >
                <div className="container mx-auto p-4">
                    <div className="grid grid-cols-4 mb-4 gap-x-2">
                        {contractList && contractList.length > 0 && (
                            <div className="col-span-1">
                                Select Contract
                                <RoundedDropdownButton
                                    type="contract"
                                    selectHandler={changeContract}
                                    dropdownData={[
                                        { contractName: "All", contractId: -1 },
                                        ...contractList,
                                    ]}
                                    title={`${
                                        selectedContract?.contractName || "All"
                                    }`}
                                    w="full"
                                    h="49px"
                                    selector={"contractName"}
                                />
                            </div>
                        )}
                        {hotelList && hotelList.length > 0 && (
                            <div className="col-span-1">
                                Select Hotel
                                <RoundedDropdownButton
                                    selectHandler={changeHotel}
                                    dropdownData={[
                                        { propertyName: "All", id: null },
                                        ...hotelList,
                                    ]}
                                    title={`${
                                        selectedHotel?.propertyName || "All"
                                    }`}
                                    w="full"
                                    h="49px"
                                    selector={"propertyName"}
                                />
                            </div>
                        )}
                        <div className="col-span-1">
                            Select Start Date
                            <div>
                                <DatePicker
                                    className="border border-btn-primary text-btn-primary  pl-6 rounded-[32px] bg-[transparent] h-[49px]"
                                    selected={startDate}
                                    onChange={(date) => setStartDate(date)}
                                />
                            </div>
                        </div>
                        <div className="col-span-1">
                            Select End Date
                            <div>
                                <DatePicker
                                    className="border border-btn-primary text-btn-primary  pl-6 rounded-[32px] bg-[transparent] h-[49px]"
                                    selected={endDate}
                                    onChange={(date) => setStartDate(date)}
                                />
                            </div>
                        </div>
                    </div>

                    <div
                        style={{ display: "flex" }}
                        className="project-tasks items-start p-4"
                    >
                        <DragDropContext onDragEnd={onDragEnd}>
                            <Droppable key="key1" droppableId={`0`}>
                                {(provided, snapshot) => (
                                    <div
                                        id="scrollableDiv"
                                        ref={provided.innerRef}
                                        className="project-column custom-shadow rounded-[8px] w-80 h-[70vh] overflow-y-scroll relative pb-10 z-[0]"
                                        {...provided.droppableProps}
                                    >
                                        <div className="project-column-heading bg-btn-secondary rounded-t-[8px] sticky w-full top-0">
                                            <h2 className="project-column-heading__title text-bg-primary py-2">
                                                {requestedBookings.title} (
                                                {requestedBookings.items.length}
                                                /
                                                {
                                                    requestedBookings.totalElements
                                                }
                                                )
                                            </h2>
                                        </div>
                                        <div>
                                            <InfiniteScroll
                                                dataLength={
                                                    requestedBookings.items
                                                        .length
                                                }
                                                next={getAndLoadRequestData}
                                                hasMore={
                                                    requestedBookings.hasMore
                                                }
                                                loader={
                                                    <h4 className="mt-20 text-center">
                                                        Loading...
                                                    </h4>
                                                }
                                                scrollableTarget="scrollableDiv"
                                            >
                                                {requestedBookings.items.map(
                                                    (item, index) => (
                                                        <Draggable
                                                            key={
                                                                item.invoiceUuId?.toString() ||
                                                                Math.random().toString()
                                                            }
                                                            draggableId={
                                                                item.invoiceUuId?.toString() ||
                                                                Math.random().toString()
                                                            }
                                                            index={index}
                                                        >
                                                            {(
                                                                provided,
                                                                snapshot
                                                            ) => (
                                                                <div
                                                                    ref={
                                                                        provided.innerRef
                                                                    }
                                                                    {...provided.draggableProps}
                                                                    {...provided.dragHandleProps}
                                                                >
                                                                    <Singletask
                                                                        id={
                                                                            item.invoiceUuId
                                                                        }
                                                                        title={
                                                                            item.content
                                                                        }
                                                                        total={
                                                                            item.totalAmount
                                                                        }
                                                                        name={
                                                                            item.personName
                                                                        }
                                                                        email={
                                                                            item.personEmail
                                                                        }
                                                                        status={
                                                                            item.invoiceStatus
                                                                        }
                                                                        checkIn={new Date(
                                                                            integerDateToValidDate(
                                                                                item
                                                                                    .bookingCategoryData[0]
                                                                                    ?.startDate
                                                                            )
                                                                        ).toDateString()}
                                                                        duration={
                                                                            item
                                                                                .bookingCategoryData[0]
                                                                                ?.endDate -
                                                                            item
                                                                                .bookingCategoryData[0]
                                                                                ?.startDate +
                                                                            1
                                                                        }
                                                                        onModalOpen={
                                                                            onModalOpen
                                                                        }
                                                                        itemIndex={
                                                                            index
                                                                        }
                                                                        sourceIndex={
                                                                            "0"
                                                                        }
                                                                    />
                                                                </div>
                                                            )}
                                                        </Draggable>
                                                    )
                                                )}
                                            </InfiniteScroll>
                                        </div>
                                        {provided.placeholder}
                                    </div>
                                )}
                            </Droppable>
                            <Droppable key="key2" droppableId={`1`}>
                                {(provided, snapshot) => (
                                    <div
                                        id="scrollableDiv2"
                                        ref={provided.innerRef}
                                        className="project-column custom-shadow rounded-[8px] w-80 h-[70vh] overflow-y-scroll relative pb-10 z-[0]"
                                        {...provided.droppableProps}
                                    >
                                        <div className="project-column-heading bg-btn-secondary rounded-t-[8px] sticky w-full top-0">
                                            <h2 className="project-column-heading__title text-bg-primary py-2">
                                                {rejectedBookings.title} (
                                                {rejectedBookings.items.length}/
                                                {rejectedBookings.totalElements}
                                                )
                                            </h2>
                                        </div>
                                        <div>
                                            <InfiniteScroll
                                                dataLength={
                                                    rejectedBookings.items
                                                        .length
                                                }
                                                next={getAndLoadRejectedData}
                                                hasMore={
                                                    rejectedBookings.hasMore
                                                }
                                                loader={
                                                    <h4 className="mt-20 text-center">
                                                        Loading...
                                                    </h4>
                                                }
                                                scrollableTarget="scrollableDiv2"
                                            >
                                                {rejectedBookings.items.map(
                                                    (item, index) => (
                                                        <Draggable
                                                            key={
                                                                item.invoiceUuId?.toString() ||
                                                                Math.random().toString()
                                                            }
                                                            draggableId={
                                                                item.invoiceUuId?.toString() ||
                                                                Math.random().toString()
                                                            }
                                                            index={index}
                                                        >
                                                            {(
                                                                provided,
                                                                snapshot
                                                            ) => (
                                                                <div
                                                                    ref={
                                                                        provided.innerRef
                                                                    }
                                                                    {...provided.draggableProps}
                                                                    {...provided.dragHandleProps}
                                                                >
                                                                    <Singletask
                                                                        id={
                                                                            item.invoiceUuId
                                                                        }
                                                                        title={
                                                                            item.content
                                                                        }
                                                                        total={
                                                                            item.totalAmount
                                                                        }
                                                                        name={
                                                                            item.personName
                                                                        }
                                                                        email={
                                                                            item.personEmail
                                                                        }
                                                                        status={
                                                                            item.invoiceStatus
                                                                        }
                                                                        checkIn={new Date(
                                                                            integerDateToValidDate(
                                                                                item
                                                                                    .bookingCategoryData[0]
                                                                                    ?.startDate
                                                                            )
                                                                        ).toDateString()}
                                                                        duration={
                                                                            item
                                                                                .bookingCategoryData[0]
                                                                                ?.endDate -
                                                                            item
                                                                                .bookingCategoryData[0]
                                                                                ?.startDate +
                                                                            1
                                                                        }
                                                                        onModalOpen={
                                                                            onModalOpen
                                                                        }
                                                                        itemIndex={
                                                                            index
                                                                        }
                                                                        sourceIndex={
                                                                            "1"
                                                                        }
                                                                    />
                                                                </div>
                                                            )}
                                                        </Draggable>
                                                    )
                                                )}
                                            </InfiniteScroll>
                                        </div>
                                        {provided.placeholder}
                                    </div>
                                )}
                            </Droppable>
                        </DragDropContext>
                    </div>
                </div>
                {bookingOverlay && (
                    <div className="flex_column">
                        <div
                            className={
                                bookingOverlay
                                    ? "overlay_shown"
                                    : "overlay_hidden"
                            }
                        >
                            <BookingModal
                                removeOverlay={() => setBookingOverlay(false)}
                                bookingOverlay={bookingOverlay}
                                onConfirm={onConfirm}
                                onRefuseReject={onRefuseReject}
                                hotel={selectedHotel}
                                bookingItem={selectedBooking}
                                loading={loading}
                                onReject={onReject}
                            />
                        </div>
                    </div>
                )}

                <ModalGlobal
                    header=""
                    body={
                        <BookingRejectReason
                            onSubmit={() => {}}
                            onCancel={() => {
                                setOpenSubmitModal(false);
                                setBookingOverlay(true);
                            }}
                        />
                    }
                    text="left"
                    openDefault={openSubmitModal}
                    setOpenDefault={setOpenSubmitModal}
                ></ModalGlobal>

                <ModalGlobal
                    body={
                        <div className="h-full flex flex-col">
                            <h2 className="text-[27px] mb-8">
                                {responseStatus === 1
                                    ? "Request Approved!"
                                    : "Request Refused!"}
                            </h2>
                            <div className="flex justify-center">
                                <ButtonFilled
                                    arrow={"none"}
                                    title={"Ok"}
                                    buttonFilledHandle={() =>
                                        setShowConfirmModal(false)
                                    }
                                />
                            </div>
                        </div>
                    }
                    openDefault={showConfirmModal}
                    setOpenDefault={setShowConfirmModal}
                    height="250px"
                    width="40vw"
                    closeable={false}
                ></ModalGlobal>
            </div>
        </div>
    );
};

export default AdminRejectedBookings;
