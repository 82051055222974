export const baseUrl = process.env.REACT_APP_BASE_API;

export const apiEndPoint = {
  CREATE_OWNER: "/user/owner/",
  CREATE_CONTRACT: "contract/addinfo/",
  EDIT_CONTRACT: "contract/updateinfo/",
  CREATE_HOTEL_PROPERTY: "admin/controller/rentalproperty/",
  // CREATE_CATEGORY: "admin/controller/roomcategory/",
  CREATE_CATEGORY: "/admin/controller/rental-property/room-category/create",
  SUBMIT_FOR_REVIEW: "contract/submit/",
  GET_COUNTRY_DETAILS: "open/v1/frontendcontentcontroller/fetchallcountry",
  SIGNUP: "open/firebase/sign-up",
  SIGNIN: "open/sign-in/",
  SIGN_UP_IN: "open/firebase/sign-up-and-in",
  CHECK_USER: "open/firebase/user-exists-by-phone-number",
  RENTAL_PROPERTY_IMAGE_UPLOAD: "admin/controller/rentalproperty/images/add",
  FETCH_SUMMERY: "contract/fetchsummary/byuserid/",
  ADMIN_CONTRACT: "admin/contract/",
  ADMIN_CREATE_USER: "admin/createUser/",
  ADMIN_PAYMENTS: "admin/payment",
  ADMIN_BOOKINGS: "admin/booking/contract",
  ADMIN_BOOKINGS_BY_USER_ID: "admin/booking/user",
  ADMIN_GET_NON_EXPRESS_BOOKINGS: "admin/express-booking",
  ADMIN_DIRECT_BOOKINGS: "admin/direct-booking",
  ADMIN_DIRECT_BOOKING_MERGE: "admin/booking/direct",
  ADMIN_REFUNDS: "admin/booking/contract/refund",
  ADMIN_PAYMENT_VERIFY: "admin/payment/",
  ADMIN_BOOKING_PAYMENT_STATUS: "admin/booking/payment-status/",
  ADMIN_BOOKING_INVOICE_STATUS: "admin/booking/invoice-status/",
  ADMIN_BOOKING_REFUND_STATUS: "admin/booking/refund-status/",
  ADMIN_ADD_LOCATION: "admin/inspired-location",
  ADMIN_DELETE_INSPIRED: "admin/inspired-location",
  ADMIN_SEND_EMAIL: "admin/email",
  ADMIN_SEND_EMAIL_HOTEL_LIST: "admin/email/hotel-list",
  ADMIN_SEND_EMAIL_PAYMENT_LINK: "admin/email/payment-link",
  ADMIN_GET_INSPIRED: "open/rental-property/all-destination-locations?requestId=22",
  // CATEGORY_IMAGE_UPLOAD: "admin/rentalproperty/category/images/add",
  CATEGORY_IMAGE_UPLOAD: "admin/controller/rental-property/room-category/image/add",



  CATEGORY_FETCH_BY_ID: "open/controller/roomcategory/fetchbyid/",
  // CATEGORY_FETCH_BY_CONTRACT_ID: "open/controller/roomcategory/fetchall/",
  CATEGORY_FETCH_BY_CONTRACT_ID: "open/controller/rental-property/room-category/fetch-all-by-property-id/",
  // ADMIN_GET_CATEGORY_BY_PROPERTY: "admin/property/",

  ADMIN_GET_CATEGORY_BY_PROPERTY: "open/controller/rental-property/room-category/fetch-all-by-property-id/",
  ADMIN_GET_PAYMENT_LINK: "admin/booking/direct/confirm",
  ADMIN_BOOKING_CONFIRM: "booking/confirm",
  ADMIN_ICON_POST: "admin/icon",
  ADMIN_ICON_EDIT: "admin/icon/",
  ADMIN_GET_FACILITY_TYPE: "admin/facility/drop-downs",
  ADMIN_GET_FACILITY_ALL: "admin/facility",
  ADMIN_BILL_CALCULATE: "admin/availability/category",
  ADMIN_SET_AVAILABILITY: "admin/availability/category/",
  ADMIN_GET_AVAILABILITY: "admin/availability/category/",
  ADMIN_CREATE_DEAL: `admin/deal/property`,
  ADMIN_GET_DEAL_BY_PROPERTY: `admin/deal/property`,
  ADMIN_DELETE_DEAL: `admin/deal/property`,
  ADMIN_SYNC_HOTEL: `admin/availability/property/`,
  ADMIN_CREATE_TOUR: `admin/tour`,
  ADMIN_GET_TOUR: `admin/tour`,
  ADMIN_GET_ALL_DISCOUNT: `admin/discount`,
  ADMIN_CREATE_DISCOUNT: `admin/discount`,
  ADMIN_GET_ALL_ACTIVE_DISCOUNT: `admin/discount/active`,
  ADMIN_BOOKING_DAHSBOARD: `admin/booking/dashboard`,
  ADMIN_GET_TOUR_BOOKINGS: `admin/tour/booking`,
  ADMIN_CREATE_TOUR_PACKAGE: `admin/tour/category`,
  ADMIN_ADD_TOUR_SERVICE: `admin/tour/service/booking`,

  //coupons api
  ADMIN_GET_SEARCH_COUPONS: `admin/coupon/get/all/paginated`,
  ADMIN_UPDATE_COUPONS: `admin/coupon/update`,
  ADMIN_ACTIVATE_COUPONS: `admin/coupon/activate`,
  ADMIN_DEACTIVATE_COUPONS: `admin/coupon/deactivate`,

  ADMIN_ADD_MANUAL_BOOKING: `admin/manual-booking/create`,
  ADMIN_ALL_INVOICE_RECORDS: `admin/manual-booking/get-all`,
  ADMIN_GET_INVOICE_BY_PHONE: `admin/manual-booking/invoice/search/phone-no`,
  ADMIN_GET_SINGLE_INVOICE_DETAILS: `admin/manual-booking/invoice`,
  ADMIN_GET_PAYMENT_METHOD_TYPE: `admin/manual-booking/payment-method/types/get-all?requestId=11`,
  ADMIN_CREATE_PAYMENT_METHOD: `admin/manual-booking/payment-method/create`,
  ADMIN_GET_ALL_PAYMENT_METHOD: `admin/manual-booking/payment-method/get-all?requestId=11`,
  ADMIN_CREATE_PAYMENT_SLIP: `admin/manual-booking/payment/create`,
  ADMIN_GET_ALL_UNVERIFIED_PAYMENTS: `admin/manual-booking/invoice/payment/fetchall/unverified`,
  ADMIN_VERIFY_PAYMENT_BY_ID: `admin/manual-booking/payment/verify`,
  ICON_LIST: "open/user/icon",

  //availability controller
  ADMIN_SET_PRICE_FOR_CATEGORY: "admin/price-availability/category/set",
  ADMIN_SET_AVAILABILITY_FILTER: "admin/price-availability/filter/add/by-category-id/",
  ADMIN_GET_PRICE_DATA_BY_ID: "admin/price-availability/category/get/",
  ADMIN_SET_AVAILABILITY_FOR_CATEGORY: "admin/price-availability/daily/generate/for-category/for-period/",
  ADMIN_GET_AVAILABILITY_DATA: "admin/price-availability/daily/get/for-category/",
  ADMIN_DELETE_FILTER_BY_ID: "admin/price-availability/filter/delete/by-filter-id/",
  ADMIN_ES_INDEXING: "admin/rental-property/index/by/propertyId/",

  // create role function
  CREATE_ROLE: "admin/roles",
  FETCH_ROLE: "admin/roles",
  EDIT_ROLE: "admin/roles/",
  DELETE_ROLE: "admin/roles/",
  GET_FUNCTION_TO_ROLE: "admin/roles/",
  ASSIGN_FUNCTION_ROLE: "admin/roles/",
  ADMIN_GET_FACILITY: "admin/facility/",
  ADMIN_CREATE_FACILITY: "admin/facility/",

  CREATE_FUNCTION: "admin/functions",
  FETCH_FUNCTION: "admin/functions",
  EDIT_FUNCTION: "admin/functions/",
  DELETE_FUNCTION: "admin/functions/",

  // owner and admin
  FETCH_OWNER_INFO: "user/review/owner",
  FETCH_CONTRACT_INFO: "user/review/contract-info",
  FETCH_DOCUMENTS_INFO: "user/review/document",
  FETCH_HOTELS_INFO: "user/review/property",
  FETCH_HOTELS_DETAILS: "user/review/property/",
  FETCH_CATEGORYS_INFO: "user/review/category",
  FETCH_CATEGORY_DETAILS: "user/review/category/",

  FETCH_TERMS_AND_CONDITIONS: "open/terms-and-condition/latest",
  ACCEPT_TERMS_AND_CONDITIONS: "terms-and-condition/accept",

  FETCH_OWNER_INFO_ADMIN: "admin/review/contract/",

  FETCH_CONTRACT_INFO_ADMIN: "admin/review/contract/",
  FETCH_DOCUMENTS_INFO_ADMIN: "admin/review/contract/",
  FETCH_HOTELS_INFO_ADMIN: "admin/review/contract/",
  FETCH_HOTELS_LIST: "admin/controller/contractId/",
  FETCH_HOTELS_DETAILS_ADMIN: "admin/review/property/",
  FETCH_CATEGORYS_INFO_ADMIN: "admin/review/contract/",
  FETCH_CATEGORY_DETAILS_ADMIN: "admin/review/category/",

  FETCH_SUBMITTED_CONTRACT: "contract/fetchall",
  FETCH_ALL_CONTRACT: "contract/fetchall",
  USER_DETAIL: "user/",

  GET_TERMS_CONDITIONS: `open/terms-and-condition/latest`,
  GET_TERMS_CONDITIONS_BY_ID: `terms-and-condition/`,
  EDIT_TERMS_CONDITIONS_BY_ID: `terms-and-condition/`,
  GET_ALL_TERMS_CONDITIONS: `terms-and-condition`,
  ADD_TERMS_CONDITIONS: `terms-and-condition`,

  GET_CURRENCY: `open/booking/currency?requestId=1`,
  MERCHANT_DASHBOARD_DATA: `merchant/booking/dashboard`,
  MERCHANT_GET_OWN_HOTELS: `merchant/property`,
  FETCH_ALL_HOTELS_BY_NAME: `admin/property/property-name`,
  FETCH_ALL_HOTELS_FOR_ADMIN: `admin/all-rental-properties`,
  FETCH_WITHOUT_ALL_HOTELS_FOR_ADMIN: `admin/rental-properties/without-thumb-image`,
  FETCH_ALL_IMAGES_BY_ID_ADMIN: `admin/rentalpropertyimages/getallbyid`,
  SET_THUMB_IMAGES: "admin/image/thumbnail",
  FETCH_ALL_FCILITY_BY_ID_ADMIN: `admin/property`,

  FETCH_PROPERTY_HOTEL_ID: `admin/controller/rentalproperty/fetchbyid`,
  FETCH_PROPERTY_WITHOUT_SPECIALITY: `admin/rental-properties/without-property-speciality`,
  FETCH_PROPERTY_SPECIALITY_BY_ID: `admin/rental-properties/property-speciality/fetch-by-propertyId`,
  CREATE_UPDATE_SPECIALITY: `admin/rental-properties/property-speciality/update-property-speciality`,
  FETCH_WITHOUT_ALL_FACILLITY_FOR_ADMIN: `admin/rental-properties/without-display-facility`,
  DELETE_SPECIALITY: `admin/rental-properties/property-speciality/delete-by-id`,
  MERCHANT_ADD_POLICY: "merchant/property/policy",
  MERCHANT_GET_POLICY: "merchant/property",
  MERCHANT_EDIT_POLICY: "merchant/property/policy",
  MERCHANT_DELETE_POLICY: "merchant/property/policy",
  // https://dev.ghuddy.link/api/v1/open/booking/currency?requestId=1



  ADMIN_ADD_DISPLAY_SPECIALITY: "admin/facility/update/display-group",
  ADMIN_GET_DISPLAY_SPECIALITY: "admin/facility/get/all/display-group",
  ADMIN_ADD_UIFILTER: 'admin/facility/update/update-ui-filter',
  ADMIN_GET_UIFILTER: "admin/facility/get/all/ui-filters",
  ADMIN_ADD_PROPERTY_FACILITY: "admin/facility/update/property-facility-type",
  ADMIN_GET_PROPERY_FACILITY: "admin/facility/get/all/rental-property-facility-type",
  ADMIN_GET_ALL_PROPERTY: "admin/facility/get/paginated/property-facility",
  ADMIN_ADD_ALL_PROPERTY: "admin/facility/update/property-facility",
  ADMIN_GET_CATEGORY_PROPERTY_TYPE: "admin/facility/get/all/category-facility-type",
  ADMIN_ADD_CATEGORY_PROPERTY_TYPE: "admin/facility/update/category-facility-type",
  ADMIN_GET_CETEGORY_FACILITY: "admin/facility/get/paginated/category-facility",
  ADMIN_ADD_CATEGORY_FACILITY: "admin/facility/update/category-facility"
};

export const apiForDashboard = {
  CATEGORY_AVAILABILITY: "user/availability/category/",
  HOTEL_CALENDAR_AVAILABILITY: "user/availability/rental-property/",
};

export const apiEndPointForOwnerReviewSubmission = {
  OWNER_REVIEW_SUBMIT: "admin/review/owner/",
  CONTRACT_REVIEW_SUBMIT: "admin/review/contract/",
  DOCUMENT_REVIEW_SUBMIT: "admin/review/contract/",
  HOTEL_REVIEW_SUBMIT: "admin/review/property/",
  CATEGORY_REVIEW_SUBMIT: "admin/review/category/",
};

export const CATEGORY_APIS = {
  ENABLE: `admin/controller/roomcategory/activate/`,
  DISABLE: `admin/rentalproperty/category/deactivate/`,
  DELETE: `admin/rentalproperty/category/delete/`,
  REMOVE_IMAGE: `admin/rentalproperty/category/image/delete/`,
};

export const PROPERTY_ENDPOINTS = {
  FETCH_FACILITIES: `open/v1/frontendcontentcontroller/fetchallfacilities`,
  FETCH_RENTAL_ADMIN: `admin/controller/contractId/property`,
  FETCH_RENTAL_ADMIN_BY_ID: `admin/controller/rentalproperty/fetchbyid/`,
  FETCH_ADDRESS_ATTRIBUTE_BY_COUNTRY_CODE: `open/v1/frontendcontentcontroller/fetchaddressattributes/bycountrycode/`,
};

export const BOOKING_ENDPOINTS = {
  GET_BOOKINGS: "merchant/booking",
  GET_BOOKINGS_BY_PROPERTY: "merchant/booking/property",
  CHANGE_STATUS: "merchant/booking/invoice-status",
  DIRECT_CHANGE_STATUS: "admin/booking/direct/status",
};

export const CANCELLATION_ENDPOINTS = {
  POST_CANCELLATION: `${baseUrl}merchant/property/cancellation-policy/`,
  POST_CANCELLATION_CATEGORY: `${baseUrl}merchant/property/category/cancellation`,
  //"https://dev.ghuddy.link/api/v1/merchant/property/category/cancellation",
  GET_CANCELLATION_BY_PROPERTY: `${baseUrl}merchant/property/`,
  //https://dev.ghuddy.link/api/v1/merchant/property/1/cancellation?requestId=11
  GET_CANCELLATION_BY_CATEGORY: `${baseUrl}merchant/category/`,
  //https://dev.ghuddy.link/api/v1/merchant/category/1/cancellation?requestId=11
};

export const FAQ_ENDPOINTS = {
  POST_FAQ: `${baseUrl}admin/faq`,
  //https://dev.ghuddy.link/api/v1/admin/faq
  GET_FAQ: `${baseUrl}open/user/faq?pageNo=0&pageSize=20`,
  EDIT_FAQ: `${baseUrl}admin/faq/`,
  DELETE_FAQ: `${baseUrl}admin/faq/`,
};
