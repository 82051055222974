
import { useNavigate } from "react-router";
import { adminRoute } from "../../../contants/Constants";
import Paginations from "../Paginations/Paginations";


const CommonHotelSpeciality = (props) => {
    const navigate = useNavigate()

    const { currentPage, totalCount, pageSize, currentData, setCurrentPage, value, setValue } = props;

    const handleChange = (e) => {
        setValue(e.target.value);
    };
    return (
        <>

            {/* searchbar */}
            <div className="mt-[20px] grid grid-cols-12 gap-x-[30px] justify-between items-center">
                <label className="relative block col-span-8">
                    <span className="absolute inset-y-0 left-0 flex items-center pl-2 ">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="w-6 h-6"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
                            />
                        </svg>
                    </span>
                    <input
                        className="placeholder:italic text-txt-primary placeholder:text-txt-hint block bg-bg-white w-full border border-bg-semiblack rounded-md py-2 pl-9 pr-3 shadow-sm focus:outline-none focus:border-bg-semiblack focus:ring-bg-semiblack focus:ring-1 sm:text-sm"
                        placeholder="Search for hotel name..."
                        type="text"
                        name="search"
                    />
                </label>
                <div className="col-span-4 flex gap-x-[10px] items-center justify-end">
                    <p>Show Result for : </p>
                    <select value={value} onChange={handleChange} className="p-[6px]">
                        <option value="active">All</option>
                        <option value="Deactivate">Without Speciality</option>
                    </select>
                </div>
            </div>
            <div className="w-full bg-bg-primary mt-[25px]">
                {/* table all hotel images */}
                <table className="table-fixed  border-collapse  w-full">
                    <thead className="sticky top-[88px] shadow-sm bg-btn-primary text-bg-primary  text-left">
                        <tr>
                            <th className="px-[8px] border-b py-[10px] border-bg-semiblack w-[700px]">
                                Hotel Name
                            </th>
                            <th className=" border-b border-bg-semiblack w-[400px]">
                                Contact Number
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {currentData?.entries?.length > 1 && currentData?.entries?.map((property, index) => (
                            <tr
                                key={index}
                                onClick={() => navigate(`${adminRoute.ADMIN_SET_HOTEL_SPECIALITY}/${property?.propertyId}`)}
                                className="hover:bg-bg-primaryShadow cursor-pointer border-b border-bg-semiblack"
                            >
                                <td className="py-[8px] flex justify-start items-center gap-x-[12px]">
                                    {property?.propertyName}
                                </td>
                                <td className="  py-[8px]">
                                    {property?.contactNo}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                <div className="">
                    <Paginations
                        currentPage={currentPage}
                        totalCount={totalCount}
                        pageSize={pageSize}
                        onPageChange={page => setCurrentPage(page)}
                    />
                </div>
            </div>

        </>
    )
}

export default CommonHotelSpeciality;