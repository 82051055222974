import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router";
import { toast } from "react-toastify";
import { adminRoute } from "../../../../contants/Constants";
import { apiEndPoint, baseUrl } from "../../../../contants/Endpoints";
import { authAxios } from "../../../../utils/axiosWrapper";
import CommonThumbFacility from "../../CommonAllHotelTable/CommonThumbFacility";
import "./AdminAddThumbImage.css";

const AdminAddThumbImage = () => {
  const navigate = useNavigate()
  const [imageId, setImageId] = useState(0)
  const [imageurl, setImageUrl] = useState("")
  const [hotelData, setHotelData] = useState([]);
  const [photoUrl, setPhotoUrl] = useState([]);

  let PageSize = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const { pathname } = useLocation();
  const { id } = useParams();

  const getInitialState = () => {
    const value = "Deactivate";
    return value;
  };
  const [value, setValue] = useState(getInitialState);

  const handleThumbImage = (imgId, url) => {

    setImageId(imgId);
    setImageUrl(url)
  }


  const requestDisplayThumb = () => {
    authAxios
      .get(
        `${baseUrl + apiEndPoint.FETCH_ALL_IMAGES_BY_ID_ADMIN}/${id}?requestId=2`
      )
      .then((res) => {
        setPhotoUrl(res?.data);
      })
      .catch((err) => {
        // toastMessage("Error fetching data", "error");
      });
  };

  const getThumbData = () => {
    if (value === "active") {
      console.log("active")
      authAxios
        .get(`${baseUrl + apiEndPoint.FETCH_ALL_HOTELS_FOR_ADMIN}?pageNumber=${currentPage}&pageSize=10&requestId=123`)
        .then((res) => {
          setHotelData(res?.data);
        })
        .catch((err) => { });
    } else {
      // FETCH_WITHOUT_ALL_HOTELS_FOR_ADMIN
      console.log("DEACTIVE")
      authAxios
        .get(`${baseUrl + apiEndPoint.FETCH_WITHOUT_ALL_HOTELS_FOR_ADMIN}?pageNumber=${currentPage}&pageSize=10&requestId=123&sortcolumn=id&sortdirection=ASC`)
        .then((res) => {
          setHotelData(res?.data);
        })
        .catch((err) => { });
    }
  }

  useEffect(() => {
    getThumbData()
  }, [value, currentPage]);


  // FETCH_ALL_HOTELS_FOR_ADMIN

  useEffect(() => {
    if (id) {
      requestDisplayThumb()
    }
  }, [id]);

  console.log(hotelData)
  const handleSubmitThumImage = () => {
    authAxios.put(`${baseUrl + apiEndPoint.SET_THUMB_IMAGES}/${id}/${imageId}?requestId=2`).then(res => {
      if (res.data.success) {
        requestDisplayThumb();
        toast.success("Successfully added display facility", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else {
        toast.warn("Opps! Somthening went wrong", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    })
  }






  const indexOfLastData = currentPage * PageSize;
  const indexOfFirstData = indexOfLastData - PageSize;
  const currentData = value === "active" ? hotelData?.entries : hotelData?.rentalPropertyThumbnailImageDataList
  const urlCheck = (imageurl || photoUrl?.thumbImageUrl) || "";

  // totalElements
  return (
    <div className="px-[16px]  min-h-screen pb-[20px]">
      <div className="max-w-[1100px]  mx-auto">
        <h2 className="text-center text-[22px] pt-[30px]">
          Hotel Thumbnail Image Selector
        </h2>

        {
          pathname === "/admin/hotel_data_management/thumbImage" ? <CommonThumbFacility
            currentPage={currentPage}
            totalCount={hotelData?.totalPages}
            pageSize={PageSize}
            onPageChange={(page) => setCurrentPage(page)}
            currentData={currentData}
            setCurrentPage={setCurrentPage}
            dataPerPage={PageSize}
            hotelData={hotelData}
            value={value}
            setValue={setValue}
          /> : <>
            <div className="mt-[16px]">
              <div className="relative">
                <div title="back to all hotel" className="cursor-pointer absolute top-[20px]">
                  <p onClick={() => navigate(adminRoute.ADMIN_THUMB_IMAGE)}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-6 h-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M15.75 19.5L8.25 12l7.5-7.5"
                      />
                    </svg>
                  </p>
                </div>
                <div className="flex justify-center items-center flex-col">
                  <h2 className="text-[22px] text-txt-primary text-center">
                    {photoUrl?.propertyName}
                  </h2>
                  <p className="text-sm">location</p>
                </div>
              </div>

              <div className="w-full">


                <div className=" mt-[30px]">
                  {
                    photoUrl?.images?.length > 0? <div div className="grid grid-cols-3 gap-[24px]">
                      {photoUrl?.images?.map((image, index) => (
                        <div
                          key={index}
                          onClick={() => handleThumbImage(image?.id, image?.url)}
                          className={`${urlCheck === (image?.url) &&
                            "relative selectImage_wrapperActive"
                            } rounded-[8px] selectImage_wrapper  w-[350px] h-[240px] cursor-pointer`}
                        >
                          <img
                            className={`w-full h-full img-fluid rounded-[8px] selectImage ${urlCheck === (image?.url) && "selectImageActive"
                              } `}
                            src={image?.url.split('?')[0]}
                            alt=""
                          />

                          {urlCheck === (image?.url) && (
                            <p className="absolute top-[120px] left-[120px] flex justify-center items-center text-bg-primary">
                              Active Thumb
                            </p>
                          )}
                        </div>
                      ))}
                    </div> : <p className="text-center justify-center w-full flex">No image avialable here</p>
                  }

                </div>
              </div>
              {
                photoUrl?.images?.length > 0 && <button onClick={() => handleSubmitThumImage()} className=" flex justify-center text-bg-white bg-btn-secondary px-[6px] py-[4px] rounded-lg w-[160px] mx-auto mt-[60px]">
                  Submit
                </button>
              }

            </div>
          </>
        }

      </div>
    </div>
  );
};

export default AdminAddThumbImage;