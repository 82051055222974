import React, { useEffect } from "react";
import { adminRoute, DashboardRoutes, merchantRoute, UserType } from "../../contants/Constants";
import { useNavigate } from "react-router";

const HomePage = () => {
  const navigate = useNavigate();
  useEffect(()=>{
    if (process.env.REACT_APP_USER_TYPE === UserType.ADMIN) {
      if (localStorage.getItem('jwt')) {
        navigate(adminRoute.ADMIN_LANDING);
      }
      else{
        navigate(merchantRoute.LOGIN);
      }
    }
    else{
      if (localStorage.getItem('jwt')) {
        navigate(merchantRoute.LANDING);
      }
      else{
        navigate(merchantRoute.LOGIN);
      }
    }
  },[])

  return (
    <div>
      <h1>Home Page</h1>
    </div>
  );
};

export default HomePage;
